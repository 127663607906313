<div class="content-wrapper w-full-no-scroll shadow-md">
  <p-table
    #dt1
    [globalFilterFields]="['year', 'status', 'createdBy', 'division.name']"
    [loading]="isLoading"
    [paginator]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [rows]="10"
    [showCurrentPageReport]="true"
    [value]="projectActivities"
    class="block w-full-no-scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    dataKey="id"
    responsiveLayout="scroll"
    styleClass="p-datatable-gridlines w-screen md:w-full flow-root"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
        <ng-container *ngIf="project && plan.status == 'Approved'">
          <button
            (click)="openAddPlanForm()"
            *hasPermissions="['plan:createAny']"
            class="p-button-outlined"
            icon="pi pi-plus"
            label="Add"
            pButton
          ></button>
        </ng-container>
        <div class="p-d-flex flex justify-between gap-2">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input (input)="filter($event)" pInputText placeholder="Search keyword" type="text" />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="year">
          <div class="p-d-flex p-jc-between p-ai-center">
            Major
            <p-columnFilter display="menu" field="year" type="numeric"></p-columnFilter>
            <p-sortIcon field="year"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="task">
          <div class="p-d-flex p-jc-between p-ai-center">
            Task
            <p-columnFilter display="menu" field="task" type="text"></p-columnFilter>
            <p-sortIcon field="task"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="assignedTo">
          <div class="p-d-flex p-jc-between p-ai-center">
            Assigned To
            <p-columnFilter display="menu" field="assignedTo" type="text"></p-columnFilter>
            <p-sortIcon field="assignedTo"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="startMonthString">
          <div class="p-d-flex p-jc-between p-ai-center">
            Start Month
            <p-columnFilter display="menu" field="startMonthString" matchMode="equals">
              <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                <p-dropdown
                  (onChange)="filter($event.value)"
                  [filter]="false"
                  [ngModel]="value"
                  [options]="getMonthChoices()"
                  placeholder="Any"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            <p-sortIcon field="startMonthString"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="startYear">
          <div class="p-d-flex p-jc-between p-ai-center">
            Start Year
            <p-columnFilter display="menu" field="startYear" type="numeric"></p-columnFilter>
            <p-sortIcon field="startYear"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="endMonthString">
          <div class="p-d-flex p-jc-between p-ai-center">
            End Month
            <p-columnFilter display="menu" field="endMonthString" matchMode="equals">
              <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                <p-dropdown
                  (onChange)="filter($event.value)"
                  [filter]="false"
                  [ngModel]="value"
                  [options]="getMonthChoices()"
                  placeholder="Any"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            <p-sortIcon field="endMonthString"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="endYear">
          <div class="p-d-flex p-jc-between p-ai-center">
            End Year
            <p-columnFilter display="menu" field="endYear" type="numeric"></p-columnFilter>
            <p-sortIcon field="endYear"></p-sortIcon>
          </div>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>

    <ng-template let-activity let-expanded="expanded" pTemplate="body">
      <tr class="dark:bg-gray-700">
        <td>
          {{ activity.project.title }}
        </td>
        <td>
          {{ activity.task }}
        </td>
        <td>
          {{ activity.assignedTo }}
        </td>
        <td>
          {{ activity.startMonthString }}
        </td>
        <td>
          {{ activity.startYear }}
        </td>
        <td>
          {{ activity.endMonthString }}
        </td>
        <td>
          {{ activity.endYear }}
        </td>
        <td>
          <div *ngIf="plan && plan.status == 'Approved'" class="flex gap-2">
            <button
              (click)="openEditDialog(activity)"
              *hasPermissions="['plan:editAny', 'plan:editOwn']"
              class="p-button-rounded p-button-outlined"
              icon="pi pi-pencil"
              pButton
              pRipple
              pTooltip="Edit Activities"
              type="button"
            ></button>
            <button
              (click)="deleteActivity($event, activity)"
              *hasPermissions="['plan:deleteAny', 'plan:deleteOwn']"
              class="p-button-rounded p-button-outlined p-button-danger"
              icon="pi pi-times"
              pButton
              pRipple
              pTooltip="Delete Activities"
              type="button"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template let-commission pTemplate="rowexpansion">
      <tr class="expanded-tr-logs">
        <td colspan="9">
          <div class="details"></div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="9">No activities found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  *ngIf="showActivityDialog"
  [(visible)]="showActivityDialog"
  [blockScroll]="true"
  [breakpoints]="{ '960px': '75vw' }"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'clamp(250px, 120ch, 90vw)', overflowY: 'auto !important', zIndex: '9999' }"
  contentStyleClass="overflow-y-auto lg:overflow-y-visible"
  header="Add Project Activity"
  styleClass="overflow-y-visible"
>
  <app-project-activity-form #activityForm [project]="project"></app-project-activity-form>
  <ng-template pTemplate="footer">
    <p-button
      (click)="showActivityDialog = false; activityForm.resetForm()"
      icon="pi pi-cross"
      label="Cancel"
      styleClass="p-button-outlined p-button-danger"
    ></p-button>
    <p-button (click)="showActivityDialog = !activityForm.submit()" icon="pi pi-check" label="Save"></p-button>
  </ng-template>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
