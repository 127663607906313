import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { userHasPermission } from '../functions/has-permission';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UserHasPermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly toastr: ToastrService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions: string[] = next.data?.permissions;
    if (permissions && userHasPermission(permissions)) {
      return true;
    }
    this.toastr.error('You are not permitted to view that page', 'Unauthorized');
    this.router.navigate(['..']);
    return true;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions: string[] = next.data?.permissions;
    if (permissions && userHasPermission(permissions)) {
      return true;
    }
    this.toastr.error('You are not permitted to view that page', 'Unauthorized');
    this.router.navigate(['..']);
    return true;
  }
}
