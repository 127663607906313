import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Country } from '../../../models';

type CountryData = Country & { isLoading?: boolean };

@Component({
  selector: 'app-state-form-dialog',
  templateUrl: './state-form-dialog.component.html',
  styleUrls: ['./state-form-dialog.component.scss'],
})
export class StateFormDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<StateFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public countryData: CountryData
  ) {}

  ngOnInit(): void {}

  onSubmit(event) {
    this.dialogRef.close();
  }
}
