import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { appReducer, AppState } from './app';
import { AuthEffects, authReducer, AuthState } from './auth';
import { LibraryEffects } from './library';
import { UserEffects, userReducer, UserState } from './user';
import { environment } from '../../environments/environment';
import { PlanEffects, planReducer, PlanState } from './plan';

export interface MainState {
  app: AppState;
  auth: AuthState;
  plan: PlanState;
  user: UserState;
}

export const reducers: ActionReducerMap<MainState> = {
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  plan: planReducer,
};

export const effects = [AuthEffects, LibraryEffects, UserEffects, PlanEffects];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log('action', action);
    console.log('state', state);

    const newState = reducer(state, action);

    console.log('new state', newState);
    return newState;
  };
}

export const metaReducers: MetaReducer<MainState>[] = environment.production ? [] : [];

export * from './sidebar';
export * from './auth';
export * from './country';
export * from './app';
export * from './library';
