import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { User } from '../../models';
import { MainPageComponent } from '../../pages';
import { userHasPermission } from '../../utils/functions/has-permission';
import { AuthService } from '../../utils/services';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  public currentUser: User;
  public model: MenuItem[];

  constructor(public appMain: MainPageComponent, private authService: AuthService) {
    this.currentUser = this.authService.getUserObject();
  }

  ngOnInit() {
    this.model = [
      {
        label: 'WFP',
        icon: 'pi pi-fw pi-database',
        visible: this.currentUser.username != 'admin',
        items: [
          {
            label: 'List',
            icon: 'pi pi-fw pi-list',
            routerLink: '/plans/list',
          },
        ],
      },
      {
        label: 'Journals',
        icon: 'pi pi-fw pi-file',
        visible: false,
        items: [
          { label: 'JournalMenu1', icon: 'pi pi-fw pi-book' },
          { label: 'JournalMenu2', icon: 'pi pi-fw pi-book' },
        ],
      },
      {
        label: 'Libraries',
        icon: 'pi pi-fw pi-book',
        visible: userHasPermission(['library:createAny', 'library:editAny', 'library:deleteAny']),
        items: [
          {
            label: 'TLMO',
            icon: 'pi pi-fw pi-book',
            routerLink: '/libraries/TLMOList',
          },
          {
            label: 'Category',
            icon: 'pi pi-fw pi-book',
            routerLink: '/libraries/categoryList',
          },
          {
            label: 'Functional Objectives',
            icon: 'pi pi-fw pi-book',
            routerLink: '/libraries/funcObjList',
          },
          {
            label: 'Divisions/Units',
            icon: 'pi pi-fw pi-book',
            routerLink: '/libraries/journalDivList',
          },
        ],
      },
      {
        label: 'Reports',
        icon: 'pi pi-fw pi-file',
        visible: userHasPermission([
          'report:generateWfp',
          'report:generateMMAE',
          'report:generatePPMR',
          'report:generateMIPS',
          'report:generateQPMR',
        ]),
        items: [
          {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-file',
            routerLink: '/reports/dashboard',
            visible: userHasPermission(['library:editSettings']),
          },
          {
            label: 'WFP',
            icon: 'pi pi-fw pi-file',
            routerLink: '/reports/wfp',
            visible: userHasPermission(['report:generateWfp']),
          },
          {
            label: 'MMMAE',
            icon: 'pi pi-fw pi-file',
            routerLink: '/reports/monthly',
            visible: userHasPermission(['report:generateMMAE']),
          },
          {
            label: 'PPMR',
            icon: 'pi pi-fw pi-file',
            routerLink: '/reports/programs',
            visible: userHasPermission(['report:generatePPMR']),
          },
          {
            label: 'MIP',
            icon: 'pi pi-fw pi-file',
            routerLink: '/reports/mips',
            visible: userHasPermission(['report:generateMIPS']),
          },
          {
            label: 'QPMR',
            icon: 'pi pi-fw pi-file',
            routerLink: '/reports/qpmr',
            visible: userHasPermission(['report:generateQPMR']),
          },
        ],
      },
      {
        label: 'Users',
        icon: 'pi pi-fw pi-users',
        visible: userHasPermission(['user:createAny', 'user:editAny', 'user:deleteAny']),
        items: [
          {
            label: 'User List',
            icon: 'pi pi-fw pi-users',
            routerLink: '/users/list',
          },
          {
            label: 'Roles List',
            icon: 'pi pi-fw pi-user',
            routerLink: '/users/roles',
          },
        ],
      },
      {
        label: 'Settings',
        icon: 'pi pi-fw pi-cog',
        routerLink: '/settings',
        visible: userHasPermission(['library:editSettings']),
      },
      {
        label: 'Help',
        icon: 'pi pi-fw pi-question-circle',
        // target: '_blank',1
        routerLink: '/help',
        // url: 'https://ddbwiki.mbesites.com/wiki/doku.php?id=user_s_manual_page',
      },
      // {
      //   label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
      //   items: [
      //     { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
      //     { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
      //     { label: 'Float Label', icon: 'pi pi-bookmark', routerLink: ['/uikit/floatlabel'] },
      //     { label: 'Invalid State', icon: 'pi pi-exclamation-circle', routerLink: ['/uikit/invalidstate'] },
      //     { label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon' },
      //     { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
      //     { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
      //     { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
      //     { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
      //     { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
      //     { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
      //     { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'] },
      //     { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
      //     { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
      //     { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
      //     { label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc'] },
      //   ],
      // },
      // {
      //   label: 'Mega', icon: 'pi pi-fw pi-list', badge: 2, mega: true,
      //   items: [
      //     {
      //       label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
      //       items: [
      //         { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
      //         { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
      //         { label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon' },
      //         { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
      //         { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
      //         { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
      //         { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
      //         { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
      //         { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
      //         { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'] },
      //         { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
      //         { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
      //         { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
      //         { label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc'] },
      //       ],
      //     },
      //     {
      //       label: 'Templates',
      //       items: [
      //         { label: 'Ultima', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/ultima-ng' },
      //         { label: 'Serenity', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/serenity-ng' },
      //         { label: 'Avalon', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/avalon-ng' },
      //         { label: 'Apollo', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/apollo-ng' },
      //         { label: 'Roma', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/roma-ng' },
      //         { label: 'Babylon', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/babylon-ng' },
      //         { label: 'Manhattan', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/manhattan-ng' },
      //         { label: 'Verona', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/verona-ng' },
      //         { label: 'Olympia', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/olympia-ng' },
      //         { label: 'Ecuador', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/layouts/ecuador-ng' },
      //       ],
      //     },
      //     {
      //       label: 'Demo',
      //       items: [
      //         { label: 'PrimeFaces', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/showcase' },
      //         { label: 'PrimeNG', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/primeng' },
      //         { label: 'PrimeReact', icon: 'pi pi-desktop', url: 'https://www.primefaces.org/primereact' },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['utilities'],
      //   items: [
      //     { label: 'Display', icon: 'pi pi-fw pi-desktop', routerLink: ['utilities/display'] },
      //     { label: 'Elevation', icon: 'pi pi-fw pi-external-link', routerLink: ['utilities/elevation'] },
      //     { label: 'FlexBox', icon: 'pi pi-fw pi-directions', routerLink: ['utilities/flexbox'] },
      //     { label: 'Icons', icon: 'pi pi-fw pi-search', routerLink: ['utilities/icons'] },
      //     { label: 'Text', icon: 'pi pi-fw pi-pencil', routerLink: ['utilities/text'] },
      //     { label: 'Widgets', icon: 'pi pi-fw pi-star-o', routerLink: ['utilities/widgets'] },
      //     { label: 'Grid System', icon: 'pi pi-fw pi-th-large', routerLink: ['utilities/grid'] },
      //     { label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', routerLink: ['utilities/spacing'] },
      //     { label: 'Typography', icon: 'pi pi-fw pi-align-center', routerLink: ['utilities/typography'] },
      //   ],
      // },
      // {
      //   label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
      //   items: [
      //     { label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud'] },
      //     { label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar'] },
      //     { label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline'] },
      //     { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
      //     { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'] },
      //     { label: 'Invoice', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/invoice'] },
      //     { label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/pages/help'] },
      //     { label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error'] },
      //     { label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound'] },
      //     { label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access'] },
      //     { label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty'] },
      //   ],
      // },
      // {
      //   label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
      //   items: [
      //     {
      //       label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
      //       items: [
      //         {
      //           label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
      //           items: [
      //             { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left' },
      //             { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left' },
      //             { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left' },
      //           ],
      //         },
      //         {
      //           label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
      //           items: [
      //             { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left' },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
      //       items: [
      //         {
      //           label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
      //           items: [
      //             { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left' },
      //             { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left' },
      //           ],
      //         },
      //         {
      //           label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
      //           items: [
      //             { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left' },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: ['https://www.primefaces.org/store'],
      // },
      // {
      //   label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/documentation'],
      // },
    ];
  }

  openWFPReportsDialog() {}
}
