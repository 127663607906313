import { createAction, props } from '@ngrx/store';
import { Invoice } from '../../models/ops';
import { OpsStatus } from '../../utils/constants';

export const loadInvoices = createAction(
  '[OPS] load Invoices',
  props<{ status: string }>()
);
export const loadedInvoices = createAction(
  '[OPS] loaded Invoices',
  props<{ invoices: Invoice[]; invoiceStatus: string }>()
);
export const clearInvoices = createAction('[OPS] clear Invoices');

export const loadNextOrderStatuses = createAction(
  '[OPS] load Next Order Statuses',
  props<{ invoice: Invoice }>()
);
export const loadedNextOrderStatuses = createAction(
  '[OPS] loaded Next Order Statuses',
  props<{ nextStatuses: OpsStatus[] }>()
);
export const clearNextOrderStatuses = createAction(
  '[OPS] clear Next Order Statuses'
);
export const updateInvoiceStatus = createAction(
  '[OPS] update Invoice Status',
  props<{ invoice: Invoice; nextStatus: OpsStatus; remarks: string }>()
);
