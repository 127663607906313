import { Component, Inject, OnInit } from '@angular/core';
import { Project, ProjectActivity } from '../../../models/plans';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type DialogData = {
  project?: Project;
  activity?: ProjectActivity;
};

@Component({
  selector: 'app-project-activity-form-dialog',
  templateUrl: './project-activity-form-dialog.component.html',
  styleUrls: ['./project-activity-form-dialog.component.scss'],
})
export class ProjectActivityFormDialogComponent implements OnInit {
  public project: Project;
  public projectActivity: ProjectActivity;

  constructor(
    public dialogRef: MatDialogRef<ProjectActivityFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    dialogRef.updateSize('90%', '90%');
    this.project = data.project;
    this.projectActivity = data.activity;
  }

  ngOnInit(): void {}

  onSubmit(event) {
    this.dialogRef.close();
  }
}
