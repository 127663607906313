<div class='flex'>
  <div (click)='onWrapperClick()' [class]="app.topbarColor + ' ' + app.menuColor + ' w-full'"
       [ngClass]="{'layout-menu-horizontal': app.horizontal,
                'layout-menu-active': menuActive,
                'layout-top-small': app.topbarSize === 'small',
                'layout-top-medium': app.topbarSize === 'medium',
                'layout-top-large': app.topbarSize === 'large', 'p-ripple-disabled': !app.ripple, 'p-input-filled': app.inputStyle === 'filled'}"
       class='layout-container'>
    <div *ngrxLet='breadCrumbs$;let breadcrumbs' class='layout-top'>
      <app-main-header></app-main-header>

      <div class='layout-topbar-separator'></div>

      <div class='layout-breadcrumb'>
        <ul>
          <li><a routerLink='' class='flex items-center gap-2'><i class='pi pi-home'></i> Home</a></li>
          <li><i class='pi pi-chevron-right'></i></li>
          <ng-template [ngForOf]='breadcrumbs' let-item let-last='last' ngFor>
            <li>
              <a *ngIf='item.routerLink' [routerLink]='item.routerLink'>{{item.label}}</a>
              <ng-container *ngIf='!item.routerLink'>{{item.label}}</ng-container>
            </li>
            <li *ngIf='!last'><i class='pi pi-chevron-right'></i></li>
          </ng-template>
        </ul>
      </div>

    </div>
    <div class='layout-content m-0 p-0 flex flex-col'>
      <router-outlet></router-outlet>
    </div>

    <!--  <app-config></app-config>-->

    <div *ngIf='menuActive' [@mask-anim]="'visible'" class='layout-mask'></div>

    <app-footer></app-footer>

    <div *ngIf='configDialogActive' [@mask-anim]="'visible'" class='layout-config-mask'></div>
  </div>

</div>

<!--<div class='wrapper relative flex flex-row justify-start items-stretch h-full flex-grow dark:bg-gray-700'>-->
<!--  <div class='main flex-grow flex flex-col items-stretch'>-->
<!--    -->
<!--    <div class='mainWrapper w-full mx-auto overflow-x-hidden'>-->
<!--      <router-outlet></router-outlet>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


