<app-input [inputControl]="getNameControl()" inputId="name" label="Name" placeHolder="ex. Zone 01">
    <ng-container error>
      <div *ngIf="(getNameControl().touched || getNameControl().dirty) && getNameControl().invalid" class="alert">
        <ul>
          <ng-container *ngFor="let err of getNameControl()?.errors | keyvalue">
            <li *ngIf="err.key == 'required'">Please enter the zone name</li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </app-input>
  
  <app-input [inputControl]="getCodeControl()" inputId="code" label="Code" placeHolder="ex. Z01">
    <ng-container error>
      <div *ngIf="(getCodeControl().touched || getCodeControl().dirty) && getCodeControl().invalid" class="alert">
        <ul>
          <ng-container *ngFor="let err of getCodeControl()?.errors | keyvalue">
            <li *ngIf="err.key == 'required'">Please enter the zone code</li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </app-input>

  <!-- <app-input [inputControl]="getAmountControl()" type="number" inputId="amount" label="Amount" placeHolder="ex. 1.00" [step]="0.01" [min]=0>
    <ng-container error>
      <div *ngIf="(getAmountControl().touched || getAmountControl().dirty) && getAmountControl().invalid" class="alert">
        <ul>
          <ng-container *ngFor="let err of getAmountControl()?.errors | keyvalue">
            <li *ngIf="err.key == 'required'">Please enter the amount</li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </app-input> -->
  
  <app-input [inputControl]="getDescriptionControl()" inputId="description" label="Description" placeHolder="">
  </app-input>
  
  <div class="buttons flex w-full gap-4">
    <app-button (click)="submit()" [type]="'button'" [disabled]="isLoading" [block]="isLoading"
      [text]="company ? 'Save' : 'Add Zone'"
      class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md" [loading]="isLoading">
    </app-button>
  
    <app-button *ngIf="courierIndex > -1 && showDeleteButton" (click)="deleteZone()" [type]="'button'"
      [disabled]="isLoading" [block]="isLoading" [text]="'Delete Zone'"
      class="w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md" [loading]="isLoading">
    </app-button>
  </div>