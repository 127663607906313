<div class="page-wrapper">
  <div class="header flex justify-between items-center">
    <h2 class="mb-2 md:mb-4">Users</h2>
  </div>
  <div class="content-wrapper relative w-full-no-scroll shadow-md">
    <p-table
      #dt1
      [globalFilterFields]="['username', 'status', 'createdBy', 'division.name', 'roleNames']"
      [loading]="isLoading"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      [rows]="10"
      [showCurrentPageReport]="true"
      [value]="users"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      dataKey="id"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines w-screen md:w-full flow-root"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-between items-center">
          <button
            (click)="openAddUserFormDialog()"
            *hasPermissions="['user:createAny', 'user:createOwn']"
            class="p-button-outlined"
            icon="pi pi-plus"
            label="Add"
            pButton
          ></button>
          <div class="p-d-flex flex justify-between gap-2">
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input (input)="filter($event)" pInputText placeholder="Search keyword" type="text" />
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="username">
            <div class="p-d-flex p-jc-between p-ai-center">
              Username
              <p-columnFilter display="menu" field="username" type="text"></p-columnFilter>
              <p-sortIcon field="username"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="division.name">
            <div class="p-d-flex p-jc-between p-ai-center">
              Fullname
              <p-columnFilter display="menu" field="division.name" type="text"></p-columnFilter>
              <p-sortIcon field="division.name"></p-sortIcon>
            </div>
          </th>
          <!-- <th pSortableColumn='status'>
              <div class='p-d-flex p-jc-between p-ai-center'>
                Status
                <p-columnFilter display='menu' field='status' matchMode='equals'>
                  <ng-template let-filter='filterCallback' let-value pTemplate='filter'>
                    <p-dropdown
                      (onChange)='filter($event.value)'
                      [filter]='false'
                      [ngModel]='value'
                      [options]='[]'
                      placeholder='Any'
                    >
                      <ng-template let-option pTemplate='item'>
                        <span>{{ option }}</span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
                <p-sortIcon field='status'></p-sortIcon>
              </div>
            </th> -->
          <th pSortableColumn="roleNames">
            <div class="p-d-flex p-jc-between p-ai-center">
              Roles
              <p-columnFilter display="menu" field="roleNames" type="text"></p-columnFilter>
              <p-sortIcon field="roleNames"></p-sortIcon>
            </div>
          </th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template let-expanded="expanded" let-user pTemplate="body">
        <tr class="dark:bg-gray-700">
          <td>
            <div class="button-container flex items-center gap-2">
              <button
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                [pRowToggler]="user"
                class="p-button-text p-button-rounded p-button-plain"
                pButton
                pRipple
                type="button"
              ></button>
              {{ user.username }}
            </div>
          </td>
          <td>
            {{ user.fullName }}
          </td>
          <!-- <td>
              {{ user.status }}
            </td> -->
          <td>
            {{ getRoles(user) }}
          </td>
          <td>
            <div class="flex gap-2">
              <button
                *hasPermissions="['user:editAny', 'user:editOwn']"
                [routerLink]="['/users', 'edit', user.id]"
                class="p-button-rounded p-button-outlined"
                icon="pi pi-fw pi-pencil"
                pButton
                pRipple
                pTooltip="Edit user"
                type="button"
              ></button>
              <button
                (click)="generateUserPassword($event, user)"
                *hasPermissions="['user:editAny', 'user:editOwn']"
                class="p-button-rounded p-button-outlined"
                icon="pi pi-fw pi-refresh"
                pButton
                pRipple
                pTooltip="Generate temporary password"
                type="button"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template let-user pTemplate="rowexpansion">
        <tr class="expanded-tr-logs">
          <td colspan="4">
            <div class="content">
              <div class="form grid grid-cols-1 md:grid-cols-2">
                <div class="data">
                  <span><strong>First Name: </strong></span>
                  <span>{{ user.firstName }}</span>
                </div>

                <div class="data">
                  <span><strong>Last Name: </strong></span>
                  <span>{{ user.lastName }}</span>
                </div>

                <div class="data">
                  <span><strong>Mobile Number: </strong></span>
                  <span>{{ user.mobile }}</span>
                </div>

                <div class="data">
                  <span><strong>Email: </strong></span>
                  <span>{{ user.email }}</span>
                </div>
              </div>
            </div>

            <div class="buttons flex gap-2">
              <div *ngIf="!isUserActivated(user)" class="resend-verification-email-button-container w-max">
                <app-button
                  (click)="resendUserVerificationEmail(user)"
                  [block]="true"
                  [disabled]="user.isLoading"
                  [loading]="user.isLoading"
                  [text]="'Resend email verification'"
                  [type]="'button'"
                  class="bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md"
                >
                </app-button>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No users found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-confirmPopup></p-confirmPopup>
