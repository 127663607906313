import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../app-config.service';
import { Origin, User } from '../../models';
import { AppConfigKeyValue } from '../../models/app-settings';
import { Permission } from '../../models/permission';
import {
  AUTH_TOKEN_EXPIRY_KEY,
  AUTH_TOKEN_KEY,
  MONTHLY_UPDATES_LIMITS_KEY,
  ORIGIN_KEY,
  PERMISSIONS_KEY,
  REFRESH_TOKEN_EXPIRY_KEY,
  REFRESH_TOKEN_KEY,
  REMEMBER_ME_KEY,
  ROLE_KEY,
  USERNAME_KEY,
  USER_KEY,
} from '../constants';

interface TokenResponse {
  authToken: string;
  refreshToken: string;
}

interface LoginCredentials {
  password?: string;
  realm?: string;
  username?: string;
}

@Injectable({
  providedIn: 'root',
  deps: [AppConfigService],
})
export class AuthService {
  private idp: string;

  constructor(private router: Router, private httpClient: HttpClient, private appConfigService: AppConfigService) {
    if (appConfigService.getConfig() && appConfigService.getConfig().idp) {
      this.idp = appConfigService.getConfig().idp;
    } else {
      this.idp = environment.idp;
    }
  }

  getAuthToken(): string | null {
    if (this.isLoggedIn()) {
      return localStorage.getItem(AUTH_TOKEN_KEY);
    }
    return null;
  }

  getOnlineUserPermissions() {
    const url = `${this.idp}/api/oms/permissions`;
    const headers = {
      Authorization: `Bearer ${this.getAuthToken()}`,
    };
    return this.httpClient.get<Permission[]>(url, { headers });
  }

  getOriginObject(): Origin {
    if (this.isLoggedIn()) {
      return JSON.parse(localStorage.getItem(ORIGIN_KEY));
    }

    return {};
  }

  getRefreshToken(): string | null {
    if (this.isLoggedIn()) {
      return localStorage.getItem(REFRESH_TOKEN_KEY);
    }
    return null;
  }

  getRefreshTokenExpiryDate(): Date {
    if (this.isLoggedIn() && localStorage.getItem(REFRESH_TOKEN_EXPIRY_KEY) != null) {
      return new Date(localStorage.getItem(REFRESH_TOKEN_EXPIRY_KEY) as string);
    }
    return new Date(Date.now());
  }

  getRememberMe() {
    if (this.isLoggedIn()) {
      const rememberMeString = localStorage.getItem(REMEMBER_ME_KEY);
      return rememberMeString === 'true';
    }

    return false;
  }

  getRole(): string | null {
    if (this.isLoggedIn()) {
      return localStorage.getItem(ROLE_KEY);
    }
    return null;
  }

  getTokenExpiryDate(): Date {
    if (this.isLoggedIn() && localStorage.getItem(AUTH_TOKEN_EXPIRY_KEY) != null) {
      return new Date(localStorage.getItem(AUTH_TOKEN_EXPIRY_KEY) as string);
    }
    return new Date(Date.now());
  }

  getUserObject(): User {
    if (this.isLoggedIn()) {
      return JSON.parse(localStorage.getItem(USER_KEY));
    }

    return new User();
  }

  saveUserObject(user: User) {
    if (this.isLoggedIn()) {
      localStorage.setItem(USER_KEY, JSON.stringify(user));
      return true;
    }

    return false;
  }

  getUserPermissions(): Permission[] {
    const stringPermissions = localStorage.getItem(PERMISSIONS_KEY);
    if (stringPermissions) {
      return JSON.parse(stringPermissions) as Permission[];
    }

    return [];
  }

  getUsername(): string | null {
    if (this.isLoggedIn()) {
      return localStorage.getItem(USERNAME_KEY);
    }
    return null;
  }

  isLoggedIn() {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);

    if (token) {
      return true;
    }

    return false;
  }

  login(loginCredentials: LoginCredentials, rememberMe: boolean = false) {
    const url = `${this.idp}/auth/login`;

    const headers = new HttpHeaders();

    return this.httpClient
      .post<TokenResponse>(url, loginCredentials, {
        headers,
      })
      .pipe(
        tap((response) => {
          const authToken = jwtDecode<JwtPayload | any>(response.authToken);
          const authTokenExpiryDate = new Date(authToken.exp * 1000);
          const refreshToken = jwtDecode<JwtPayload | any>(response.refreshToken);
          const refreshTokenExpiryDate = new Date(refreshToken.exp * 1000);

          localStorage.setItem(USERNAME_KEY, authToken.sub);
          localStorage.setItem(ROLE_KEY, authToken.role);
          localStorage.setItem(AUTH_TOKEN_KEY, response.authToken);
          localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);
          localStorage.setItem(AUTH_TOKEN_EXPIRY_KEY, authTokenExpiryDate.toLocaleString());
          localStorage.setItem(REFRESH_TOKEN_EXPIRY_KEY, refreshTokenExpiryDate.toLocaleString());
          localStorage.setItem(REMEMBER_ME_KEY, String(rememberMe));
        })
      );
  }

  getMonthylyUpdatesConfig(): AppConfigKeyValue {
    if (this.isLoggedIn()) {
      return JSON.parse(localStorage.getItem(MONTHLY_UPDATES_LIMITS_KEY));
    }
    return null;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['auth', 'login']);
  }

  refreshToken() {
    const authToken = this.getAuthToken();
    const refreshToken = this.getRefreshToken();
    const url = `${this.idp}/auth/refresh?authToken=${authToken}&refreshToken=${refreshToken}`;

    const headers = {
      'x-mbe-realm': 'INTERNAL',
    };

    return this.httpClient.get<TokenResponse>(url, { headers }).pipe(
      tap((response) => {
        const authToken = jwtDecode<JwtPayload | any>(response.authToken);
        const authTokenExpiryDate = new Date(authToken.exp * 1000);
        const refreshToken = jwtDecode<JwtPayload | any>(response.refreshToken);
        const refreshTokenExpiryDate = new Date(refreshToken.exp * 1000);

        localStorage.setItem(USERNAME_KEY, authToken.sub);
        localStorage.setItem(ROLE_KEY, authToken.role);
        localStorage.setItem(AUTH_TOKEN_KEY, response.authToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);
        localStorage.setItem(AUTH_TOKEN_EXPIRY_KEY, authTokenExpiryDate.toLocaleString());
        localStorage.setItem(REFRESH_TOKEN_EXPIRY_KEY, refreshTokenExpiryDate.toLocaleString());
      })
    );
  }

  register() {
    localStorage.setItem(AUTH_TOKEN_KEY, 'LOGGED_IN');
    this.router.navigate(['/']);
  }
}
