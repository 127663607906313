<div class="main-content mb-4 flex flex-col gap-4">
  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class="block w-full">
      <label class="block font-raleway font-bold">Division *</label>
      <p-dropdown
        [formControl]="getFormControl('divisionUnit')"
        [options]="divisionUnits"
        label="Division"
        optionLabel="name"
        styleClass="w-full"
      ></p-dropdown>
      <ul *ngIf="isFormControlInvalid('divisionUnit')" class="p-error">
        <ng-container *ngFor="let err of getFormControlErrors('divisionUnit') | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a Division</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class="block w-full">
      <label for="name" class="block font-raleway font-bold">Functional Objective *</label>
      <input
        [formControl]="getFormControl('name')"
        [pTooltip]="isFormControlInvalid('name') ? 'Functional Objective is required' : ''"
        class="w-full rounded-none"
        id="name"
        pInputText
        tooltipEvent="focus"
        tooltipPosition="bottom"
        type="text"
      />
      <ul *ngIf="isFormControlInvalid('name')" class="p-error">
        <ng-container *ngFor="let err of getFormControlErrors('name') | keyvalue">
          <li *ngIf="err.key == 'required'">Enter Functional Objective</li>
        </ng-container>
      </ul>
    </span>
  </div>
</div>

<div *ngIf="false" class="buttons flex w-full gap-4">
  <app-button
    (click)="submit()"
    [block]="isLoading"
    [disabled]="isLoading"
    [loading]="isLoading"
    [text]="project && project.id ? 'Save' : 'Add Functional Objective'"
    [type]="'button'"
    class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md"
  >
  </app-button>

  <app-button
    (click)="deleteObjective()"
    *ngIf="project.id && showDeleteButton"
    [block]="isLoading"
    [disabled]="isLoading"
    [loading]="isLoading"
    [text]="'Delete Functional Objective'"
    [type]="'button'"
    class="w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md"
  >
  </app-button>
</div>
