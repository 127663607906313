import { createAction, props } from '@ngrx/store';
import { MonthlyUpdate, Plan, PPMR, Project, ProjectActivity, QuarterlyUpdate, Remarks } from '../../models/plans';

export const loadedPLan = createAction('[Plan] loadedPLan');
export const addPlan = createAction('[Plan] addPlan', props<{ plan: Plan }>());
export const updatePlan = createAction('[Plan] updatePlan', props<{ plan: Plan }>());
export const updatePlanStatus = createAction('[Plan] updatePlanStatus', props<{ plan: Plan }>());
export const deletePlan = createAction('[Plan] deletePlan', props<{ planId: number }>());
export const addProject = createAction('[Project] addProject', props<{ project: Project }>());
export const addedProject = createAction('[Project] addedProject', props<{ project: Project }>());
export const updateProject = createAction('[Project] updateProject', props<{ project: Project }>());
export const deleteProject = createAction('[Project] deleteProject', props<{ projectId: number }>());
export const addProjectActivity = createAction(
  '[ProjectActivity] addProjectActivity',
  props<{ projectActivity: ProjectActivity }>()
);
export const updateProjectActivity = createAction(
  '[ProjectActivity] updateProjectActivity',
  props<{ projectActivity: ProjectActivity }>()
);
export const deleteProjectActivity = createAction(
  '[ProjectActivity] deleteProjectActivity',
  props<{ projectActivityId: number }>()
);
export const addMonthlyUpdate = createAction(
  '[MonthlyUpdate] addMonthlyUpdate',
  props<{ monthlyUpdate: MonthlyUpdate }>()
);
export const addedMonthlyUpdate = createAction(
  '[MonthlyUpdate] addedMonthlyUpdate',
  props<{ monthlyUpdate: MonthlyUpdate }>()
);
export const updateMonthlyUpdate = createAction(
  '[MonthlyUpdate] updateMonthlyUpdate',
  props<{ monthlyUpdate: MonthlyUpdate }>()
);
export const updatedMonthlyUpdate = createAction(
  '[MonthlyUpdate] updatedMonthlyUpdate',
  props<{ monthlyUpdate: MonthlyUpdate }>()
);
export const deleteMonthlyUpdate = createAction(
  '[MonthlyUpdate] deleteMonthlyUpdate',
  props<{ monthlyUpdateId: number }>()
);

export const addPpmr = createAction('[PPMR] addPpmr', props<{ ppmr: PPMR }>());
export const addedPpmr = createAction('[PPMR] addedPpmr', props<{ ppmr: PPMR }>());
export const updatePpmr = createAction('[PPMR] updatePpmr', props<{ ppmr: PPMR }>());
export const updatedPpmr = createAction('[PPMR] updatedPpmr', props<{ ppmr: PPMR }>());
export const deletePpmr = createAction('[PPMR] deletePpmr', props<{ ppmrId: number }>());

export const addQuarterlyUpdate = createAction(
  '[QuarterlyUpdate] addQuarterlyUpdate',
  props<{ quarterlyUpdate: QuarterlyUpdate }>()
);
export const updateQuarterlyUpdate = createAction(
  '[QuarterlyUpdate] updateQuarterlyUpdate',
  props<{ quarterlyUpdate: QuarterlyUpdate }>()
);
export const deleteQuarterlyUpdate = createAction(
  '[QuarterlyUpdate] deleteQuarterlyUpdate',
  props<{ quarterlyUpdateId: number }>()
);
export const addRemark = createAction('[Project] addRemark', props<{ remark: Remarks }>());
export const updateRemark = createAction('[Project] updateRemark', props<{ remark: Remarks }>());
export const deleteRemark = createAction('[Project] deleteRemark', props<{ remarkId: number }>());
