import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Plan } from '../../../models/plans/plan';
import { PlanService } from '../../../utils/services';
import { exhaustMap } from 'rxjs/operators';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-add-project-page',
  templateUrl: './add-project-page.component.html',
  styleUrls: ['./add-project-page.component.scss'],
})
export class AddProjectPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/plans',
      label: 'Plans',
    },
  ];
  public plan: Plan;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private planService: PlanService,
    private store: Store<MainState>
  ) {}

  goBack() {
    this.router.navigate(['/plans', this.plan.id]);
  }

  ngOnInit(): void {
    this.activeRoute.params
      .pipe(
        exhaustMap((params) => {
          return this.planService.getPlanById(params.planId);
        })
      )
      .subscribe({
        next: (plan) => {
          this.plan = plan;
          this.breadCrumbLinks.push({
            routerLink: `/plans/${this.plan.id}`,
            label: `Plan - ${this.plan.year}`,
          });
          this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
}
