<div class="overflow-visible" mat-dialog-content>
  <app-category-form
    #form
    (onSubmit)="onSubmit($event)"
    [categoryList]="categoryList"
    [category]="category"
    [project]="project"
    [parent]="parent"
  ></app-category-form>
</div>

<div class="flex gap-2" mat-dialog-actions>
  <p-button
    (click)="form.resetForm(); dialogRef.close()"
    icon="pi pi-cross"
    label="Cancel"
    styleClass="p-button-outlined p-button-danger"
  ></p-button>
  <p-button (click)="form.submit()" icon="pi pi-check" label="Save"></p-button>
</div>
