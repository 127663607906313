import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../app-config.service';
import { ChagePasswordFromForgotPassword, UserPasswordUpdate } from '../../models';
import { User } from '../../models/user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private idp: string;
  private usersApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().regionalApi) {
      this.idp = appConfigService.getConfig().idp;
      this.usersApi = appConfigService.getConfig().usersApi;
    } else {
      this.idp = environment.idp;
      this.usersApi = environment.usersApi;
    }
  }

  checkEmailIfExists(email: string) {
    const url = `${this.idp}/users/exists?email=${email}`;
    return this.httpClient.get<boolean>(url);
  }

  checkMobileIfExists(mobile: string) {
    const url = `${this.idp}/users/exists?mobile=${mobile}`;
    return this.httpClient.get<boolean>(url);
  }

  checkUsernameIfExists(username: string) {
    const url = `${this.idp}/users/exists?username=${username}`;
    return this.httpClient.get<boolean>(url);
  }

  generateTempPasswordUser(user: User) {
    const url = `${this.usersApi}/users/generate-password/${user.id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
      'x-mbe-realm': 'INTERNAL',
    };
    return this.httpClient.put<User>(url, user, { headers });
  }

  getUser() {
    console.log('UserService.getUser');

    const username = this.authService.getUsername();
    const url = `${this.usersApi}/users/by-username/${username}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
      'x-mbe-realm': 'INTERNAL',
    };
    return this.httpClient.get<User>(url, { headers });
  }

  getUserById(id: string, realm: 'EXTERNAL' | 'INTERNAL' = 'INTERNAL') {
    const url = `${this.usersApi}/users/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
      'x-mbe-realm': realm,
    };
    return this.httpClient.get<User>(url, { headers });
  }

  getUsers(realm = 'EXTERNAL') {
    const url = `${this.usersApi}/users`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
      'x-mbe-realm': realm,
    };
    return this.httpClient.get<User[]>(url, { headers });
  }

  registerUser(user: User) {
    const url = `${this.idp}/users`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<User>(url, user, { headers });
  }

  resendUserVerificationEmail(user: User) {
    const url = `${this.usersApi}/users/resend/email-verification/${user.username}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<string>(url, { headers });
  }

  sendChagePasswordFromForgotPassword(user: ChagePasswordFromForgotPassword) {
    const url = `${this.usersApi}/users/forgot-password/change-password`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<string>(url, user, { headers });
  }

  sendForgotPasswordForm(email: string) {
    const url = `${this.usersApi}/users/forgot-password?email=${email}`;
    return this.httpClient.get(url);
  }

  updateUser(user: User) {
    const url = `${this.usersApi}/users`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
      'x-mbe-realm': 'INTERNAL',
    };
    return this.httpClient.put<User>(url, user, { headers });
  }

  updateUserPassword(userUpdatePassword: UserPasswordUpdate) {
    const url = `${this.usersApi}/users/password`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
      'x-mbe-realm': 'INTERNAL',
    };
    return this.httpClient.put<User>(url, userUpdatePassword, { headers });
  }

  verifyUserPayment(user: User) {
    const url = `${this.usersApi}/users/verify-payment/${user.id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<User>(url, {}, { headers });
  }
}
