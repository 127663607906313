<div class="list-countries-wrapper relative">
  <div class="header flex justify-between mb-4">
    <h2>Plans</h2>
    <div class="buttons">
      <!--            <app-button (click)="openAddCountryForm()" [block]='true' [disabled]='false' [loading]='false'-->
      <!--                        [text]="'Add Product'"-->
      <!--                        [type]="'button'"-->
      <!--                        class='w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md'>-->
      <!--            </app-button>-->
    </div>
  </div>

  <div class="content-wrapper w-full-no-scroll shadow-md">
    <p-table
      #dt1
      [globalFilterFields]="['year', 'status', 'createdBy', 'division.name']"
      [loading]="isLoading"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      [rows]="10"
      [showCurrentPageReport]="true"
      sortField="year"
      [sortOrder]="-1"
      [value]="plans"
      class="block w-full-no-scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      dataKey="id"
      responsiveLayout="stack"
      breakpoint="640px"
      scrollDirection="horizontal"
      styleClass="p-datatable-gridlines w-screen md:w-full-no-scroll flow-root"
    >
      <ng-template pTemplate="caption">
        <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
          <button
            (click)="openAddPlanForm()"
            *hasPermissions="['plan:createAny']"
            class="p-button-outlined"
            icon="pi pi-plus"
            label="Add"
            pButton
          ></button>
          <div class="p-d-flex flex justify-between gap-2">
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input (input)="filter($event)" pInputText placeholder="Search keyword" type="text" />
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="year">
            <div class="p-d-flex p-jc-between p-ai-center">
              Year
              <p-columnFilter display="menu" field="year" type="numeric"></p-columnFilter>
              <p-sortIcon field="year"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="createdDate">
            <div class="p-d-flex p-jc-between p-ai-center">
              Division
              <p-columnFilter display="menu" field="division.name" type="text"></p-columnFilter>
              <p-sortIcon field="division.name"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="status">
            <div class="p-d-flex p-jc-between p-ai-center">
              Status
              <p-columnFilter display="menu" field="status" matchMode="equals">
                <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                  <p-dropdown
                    (onChange)="filter($event.value)"
                    [filter]="false"
                    [ngModel]="value"
                    [options]="statuses"
                    placeholder="Any"
                  >
                    <ng-template let-option pTemplate="item">
                      <span>{{ option }}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
              <p-sortIcon field="status"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="planCreator">
            <div class="p-d-flex p-jc-between p-ai-center">
              Prepared By
              <p-columnFilter display="menu" field="planCreator" type="text"></p-columnFilter>
              <p-sortIcon field="planCreator"></p-sortIcon>
            </div>
          </th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template let-editing="editing" let-expanded="expanded" let-plan pTemplate="body">
        <tr [pEditableRow]="plan" class="dark:bg-gray-700">
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input [(ngModel)]="plan.year" pInputText type="text" />
              </ng-template>
              <ng-template pTemplate="output">
                <div class="button-container flex items-center gap-2">
                  <a [routerLink]="['/plans', plan.id]">
                    {{ plan.year }}
                  </a>
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.division.name }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.division.name }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown [(ngModel)]="plan.status" [options]="statuses" [style]="{ width: '100%' }"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.status }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.planCreator }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.planCreator }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <div class="flex gap-2">
              <button
                [routerLink]="['/plans', plan.id]"
                class="p-button-rounded p-button-outlined"
                icon="pi pi-eye"
                pButton
                pRipple
                pTooltip="View plan"
                type="button"
              ></button>
              <div *ngIf="plan && (plan.status == 'Draft' || plan.status == 'For Revision')">
                <button
                  (click)="editPlan($event, plan)"
                  *hasPermissions="['plan:editAny', 'plan:editOwn']"
                  class="p-button-rounded p-button-outlined"
                  icon="pi pi-pencil"
                  pButton
                  pRipple
                  pTooltip="Edit plan"
                  type="button"
                ></button>
              </div>
              <div *ngIf="plan && (plan.status == 'Draft' || plan.status == 'For Revision')">
                <button
                  (click)="deletePlan($event, plan)"
                  *hasPermissions="['plan:deleteAny', 'plan:deleteOwn']"
                  class="p-button-rounded p-button-outlined p-button-danger"
                  icon="pi pi-times"
                  pButton
                  pRipple
                  pTooltip="Delete plan"
                  type="button"
                ></button>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template let-commission pTemplate="rowexpansion">
        <tr class="expanded-tr-logs">
          <td colspan="3">
            <div class="details"></div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No plans found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog
  *ngIf="showActivityDialog"
  [(visible)]="showActivityDialog"
  [blockScroll]="true"
  [breakpoints]="{ '960px': '75vw' }"
  [contentStyle]="{ height: 'clamp(500px, 90%, 90%)' }"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'clamp(250px, 120ch, 90vw)' }"
  contentStyleClass=""
  header="Prepare a Plan"
  styleClass="block"
>
  <app-plan-form #planForm [plan]="{}"></app-plan-form>

  <ng-template pTemplate="footer">
    <p-button
      (click)="showActivityDialog = false"
      icon="pi pi-cross"
      label="Cancel"
      styleClass="p-button-outlined p-button-danger"
    ></p-button>
    <p-button (click)="showActivityDialog = !planForm.submit()" icon="pi pi-check" label="Save"></p-button>
  </ng-template>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
