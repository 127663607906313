import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { MainState, updateBreadCrumbs } from '../../../redux';

@Component({
  selector: 'app-add-user-page',
  templateUrl: './add-user-page.component.html',
  styleUrls: ['./add-user-page.component.scss'],
})
export class AddUserPageComponent implements OnInit {
  private breadcrumbLinks: MenuItem[] = [
    {
      routerLink: '/users/list',
      label: 'Users',
    },
    {
      label: 'Add User',
    },
  ];
  constructor(private router: Router, private store: Store<MainState>) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadcrumbLinks }));
  }

  ngOnInit(): void {}

  onSubmit(event) {
    this.router.navigate(['users', 'list']);
  }
}
