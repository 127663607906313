import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { MainState, updateBreadCrumbs } from 'src/app/redux';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss'],
})
export class HelpPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/help',
      label: 'Help',
    },
  ];

  constructor(private store: Store<MainState>) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
  }

  ngOnInit(): void {}
}
