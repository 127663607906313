import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { MonthlyUpdate, Plan, Project, QuarterlyUpdate } from '../../../models/plans';
import { QuarterlyUpdateService } from '../../../utils/services';
import { MatDialog } from '@angular/material/dialog';
import { QuarterlyUpdateFormDialogComponent } from '../quarterly-update-form-dialog/quarterly-update-form-dialog.component';
import { deleteMonthlyUpdate, deleteQuarterlyUpdate } from '../../../redux/plan';
import { Store } from '@ngrx/store';
import { MainState } from '../../../redux';
import { ConfirmationService } from 'primeng/api';

const quarters = ['1st Quarter', '2nd Quarter', '3rd Quarter', '4th Quarter'];

@Component({
  selector: 'app-quarterly-update-list',
  templateUrl: './quarterly-update-list.component.html',
  styleUrls: ['./quarterly-update-list.component.scss'],
})
export class QuarterlyUpdateListComponent implements OnInit {
  public isLoading = false;
  public plan: Plan;
  public quarterlyUpdates: QuarterlyUpdate[];
  public showActivityDialog = false;
  public statuses: string[] = ['Draft', 'Approved', 'Rejected'];
  @ViewChild(Table)
  private table: Table;

  constructor(
    private quarterlyUpdateService: QuarterlyUpdateService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService
  ) {}

  private _project: Project;

  get project() {
    return this._project;
  }

  @Input()
  set project(project: Project) {
    if (!project || !project.id) {
      return;
    }
    this._project = project;
    this.plan = project.plan;
    this.loadData();
  }

  deleteUpdate($event: Event, update: QuarterlyUpdate) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this update?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteQuarterlyUpdate({ quarterlyUpdateId: update.id }));
      },
      reject: () => {},
    });
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      //dt1.filterGlobal($event.target, 'contains')
    }
  }

  loadData() {
    this.isLoading = true;
    this.quarterlyUpdateService.getQuarterlyUpdatesByProjectId(this.project.id, false).subscribe({
      next: (updates) => {
        this.quarterlyUpdates = updates.map((update) => {
          update.yearQuarterString = quarters[update.yearQuarter];
          return update;
        });
        this.isLoading = false;
      },
      error: (err) => {
        if (err.error.statusCode && err.error.statusCode != 404) {
          this.toaster.error(err.message, 'Error');
        }
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  ngOnInit(): void {
    if (!this.project || !this.project.id) {
      return;
    }
  }

  openAddPlanForm() {
    this.showActivityDialog = true;
  }

  openEditDialog(quarterlyUpdate: QuarterlyUpdate) {
    const ref = this.dialog.open(QuarterlyUpdateFormDialogComponent, {
      data: {
        project: this.project,
        quarterlyUpdate: quarterlyUpdate,
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
    });
  }
}
