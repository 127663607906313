import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { UserRoleFormDialogComponent } from '../../../components';
import { Role, User } from '../../../models';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { fadeUpAndFadeDown } from '../../../utils/animations';
import { RoleService, UserService } from '../../../utils/services';

@Component({
  selector: 'app-role-list-page',
  templateUrl: './role-list-page.component.html',
  styleUrls: ['./role-list-page.component.scss'],
  animations: [fadeUpAndFadeDown],
})
export class RoleListPageComponent implements OnInit, OnDestroy {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/users/roles',
      label: 'Roles',
    },
  ];
  public displayedColumns: string[] = ['username', 'email', 'status', 'buttons'];
  public filterString: string;
  public getUsersSubscription: Subscription;
  public isLoading = false;
  public realm = 'EXTERNAL';
  public realms = ['EXTERNAL', 'INTERNAL'];
  public roles: Array<Role> = [];
  public users$: Observable<User[]>;
  @ViewChild(Table)
  private table: Table;

  constructor(
    private usersService: UserService,
    private roleService: RoleService,
    private addUserDialog: MatDialog,
    private router: Router,
    private store: Store<MainState>
  ) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
  }

  public filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      // dt1.filterGlobal($event.target, 'contains')
    }
  }

  public getUsersUnsubscribe() {
    if (this.getUsersSubscription) {
      this.getUsersSubscription.unsubscribe();
    }
  }

  public isUserActivated(user: User) {
    return user.accountNonLocked && user.enabled;
  }

  public loadData() {
    this.getUsersSubscription = this.roleService.getRoles().subscribe({
      next: (roles) => {
        this.roles = roles;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  public ngOnDestroy(): void {
    this.getUsersUnsubscribe();
  }

  public ngOnInit(): void {
    this.loadData();
  }

  public async openAddUserFormDialog() {
    await this.router.navigate(['users', 'add']);
  }

  openEditDialog(role?: Role) {
    const ref = this.addUserDialog.open(UserRoleFormDialogComponent, {
      data: { role, dialogTitle: role?.id ? 'Edit Role' : 'Add Role' },
      disableClose: false,
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
    });
  }

  public resendUserVerificationEmail(user: User & { isLoading?: boolean }) {
    const userParam = cloneDeep(user);
    userParam.isLoading = true;
    this.usersService.resendUserVerificationEmail(userParam).subscribe({
      next: () => {
        userParam.isLoading = false;
        this.loadData();
      },
      error: (err) => {
        console.error(err);
        userParam.isLoading = false;
      },
    });
  }

  public submit(user: User & { isLoading?: boolean }) {
    const userParam = cloneDeep(user);
    userParam.isLoading = true;
    this.usersService.verifyUserPayment(userParam).subscribe({
      next: (updatedUser) => {
        userParam.company.status = updatedUser.company.status;
        userParam.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        userParam.isLoading = false;
      },
    });
  }
}
