import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MenuItem, Message } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { EMPTY } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { ProjectFormDialogComponent } from '../../../components/plans';
import { Plan, Project, Status } from '../../../models/plans';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { deleteProject, updatePlan, updatePlanStatus } from '../../../redux/plan';
import { PlanService, ProjectService } from '../../../utils/services';

type PlanYearString = Plan & { yearString?: string };

@Component({
  selector: 'app-plan-view-page',
  templateUrl: './plan-view-page.component.html',
  styleUrls: ['./plan-view-page.component.scss'],
  providers: [DialogService],
})
export class PlanViewPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/plans',
      label: 'Plans',
    },
  ];
  public currentPlan: PlanYearString = {
    year: 0,
  };
  public editPlan = false;
  public isLoading = false;
  public mainProjects: Project[];
  public plans: Array<PlanYearString>;
  public revisionRemarkMessage: Message;
  public revisionRemarks = '';
  public selectedPlan: number;
  public showActivityDialog = false;
  public showForRevisionDialog = false;
  public statuses: string[] = ['Active', 'Partially Completed', 'Completed', 'Deferred'];
  public supplementalProjects: Project[];
  private currentYear = new Date().getFullYear();
  @ViewChild('dt2')
  private supplementalProjectsTable: Table;
  @ViewChild('dt1')
  private table: Table;

  constructor(
    private activeRoute: ActivatedRoute,
    private planService: PlanService,
    private toaster: ToastrService,
    private router: Router,
    private projectService: ProjectService,
    private dialogService: DialogService,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService
  ) {}

  changedCurrentPlan() {
    this.router.navigate(['/plans', this.selectedPlan]);
  }

  deleteProject($event: Event, project: Project) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this project?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteProject({ projectId: project.id }));
      },
      reject: () => {},
    });
  }

  editProject($event: MouseEvent, project: Project) {
    const dialogRef = this.dialogService.open(ProjectFormDialogComponent, {
      header: 'Edit Project',
      width: '100vw',
      height: '90vh',
      data: {
        project: project,
        plan: this.currentPlan,
      },
    });
    dialogRef.onClose.subscribe({
      next: (result) => {
        console.log('onClose:', result);
      },
    });
  }

  filterMain(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      // dt1.filterGlobal($event.target, 'contains')
    }
  }

  filterSupplemental(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.supplementalProjectsTable.filterGlobal(el.value, 'contains');
      // dt1.filterGlobal($event.target, 'contains')
    }
  }

  ngOnInit(): void {
    this.activeRoute.params
      .pipe(
        exhaustMap((params) => {
          return this.planService.getPlanById(params.planId).pipe(
            exhaustMap((plan) => {
              this.currentPlan = plan;
              this.breadCrumbLinks.push({
                label: `Plan - ${this.currentPlan.year}`,
              });
              this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
              return this.projectService.getProjectsByPlanId(this.currentPlan.id, 'MAIN_PROJECT').pipe(
                exhaustMap((projects) => {
                  this.mainProjects = projects;
                  if (this.currentPlan.status == 'Approved') {
                    return this.projectService.getProjectsByPlanId(this.currentPlan.id, 'SUPPLEMENTAL_PROJECT');
                  } else {
                    return EMPTY;
                  }
                })
              );
            })
          );
        })
      )
      .subscribe({
        next: (projects) => {
          this.supplementalProjects = projects;
        },
        error: (err) => {
          if (err.error.statusCode && err.error.statusCode != 404) {
            this.toaster.error(err.message, 'Error');
          }
          console.error(err);
        },
      });
  }

  openAddProjectForm() {
    this.showActivityDialog = true;
  }

  showRevisionDialog() {
    this.showForRevisionDialog = true;
  }

  submitForApproval(newStatus: Status) {
    this.currentPlan.status = newStatus;
    this.store.dispatch(updatePlan({ plan: this.currentPlan }));
  }

  updatePlanStatus(newStatus: Status) {
    this.currentPlan.status = newStatus;
    this.store.dispatch(updatePlanStatus({ plan: this.currentPlan }));
  }

  updatePlanStatusForRevision() {
    this.currentPlan.status = 'For Revision';
    this.currentPlan.revisionRemarks = this.revisionRemarks;
    this.store.dispatch(updatePlanStatus({ plan: this.currentPlan }));
  }
}
