import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../models';

type UserData = User & {
  isLoading?: boolean;
};

@Component({
  selector: 'app-user-form-dialog',
  templateUrl: './user-form-dialog.component.html',
  styleUrls: ['./user-form-dialog.component.scss'],
})
export class UserFormDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UserFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public userData: UserData
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  onSubmit(event) {
    this.dialogRef.close();
  }
}
