import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
  addDivisionUnit,
  addObjective,
  addTlmo,
  deleteDivision,
  deleteDivisionUnit,
  deleteObjective,
  deleteTlmo,
  updateDivision,
  updateDivisionUnit,
  updateObjective,
  updateTlmo,
} from '.';
import { AuthService, LibraryService } from '../../utils/services';
import { refreshPage } from '../app';
import { addCategory, addDivision, deleteCategory, updateCategory } from './library-actions';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LibraryEffects {
  addCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addCategory),
      exhaustMap((action) => {
        return this.divisionService.addCategory(action.payload).pipe(
          map((category) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error adding category', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addDivision$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addDivision),
      exhaustMap((action) => {
        return this.divisionService.addDivision(action.payload).pipe(
          map((division) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error adding division', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addDivisionUnit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addDivisionUnit),
      exhaustMap((action) => {
        return this.divisionService.addDivisionUnit(action.payload).pipe(
          map((division) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error adding division unit', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addObjective$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addObjective),
      exhaustMap((action) => {
        return this.divisionService.addObjective(action.payload).pipe(
          map((division) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error adding objective', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addTlmo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addTlmo),
      exhaustMap((action) => {
        return this.divisionService.addTlmo(action.payload).pipe(
          map((division) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error adding tlmo', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deleteCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteCategory),
      exhaustMap((action) => {
        const category = action.payload;
        return this.divisionService.deleteCategory(category.id).pipe(
          map((response) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting category', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deleteDivision$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteDivision),
      exhaustMap((action) => {
        const division = action.payload;
        return this.divisionService.deleteDivision(division.id).pipe(
          map((division) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting division', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deleteDivisionUnit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteDivisionUnit),
      exhaustMap((action) => {
        const division = action.payload;
        return this.divisionService.deleteDivisionUnit(division.id).pipe(
          map((division) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting division unit', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deleteObjective$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteObjective),
      exhaustMap((action) => {
        const objective = action.payload;
        return this.divisionService.deleteObjective(objective.id).pipe(
          map((division) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting objective', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deleteTlmo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteTlmo),
      exhaustMap((action) => {
        const tlmo = action.payload;
        return this.divisionService.deleteTlmo(tlmo.id).pipe(
          map((division) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting tlmo', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updateCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateCategory),
      exhaustMap((action) => {
        return this.divisionService.updateCategory(action.payload).pipe(
          map((product) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating category', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updateDivision$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateDivision),
      exhaustMap((action) => {
        return this.divisionService.updateDivision(action.payload).pipe(
          map((product) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating division', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updateDivisionUnit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateDivisionUnit),
      exhaustMap((action) => {
        return this.divisionService.updateDivisionUnit(action.payload).pipe(
          map((product) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating division unit', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updateObjective$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateObjective),
      exhaustMap((action) => {
        return this.divisionService.updateObjective(action.payload).pipe(
          map((product) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating objective', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updateTlmo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateTlmo),
      exhaustMap((action) => {
        return this.divisionService.updateTlmo(action.payload).pipe(
          map((product) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating tlmo', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private divisionService: LibraryService,
    private toaster: ToastrService
  ) {}
}
