import { CdkTableModule } from '@angular/cdk/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveComponentModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';
import { AppConfigService } from './app-config.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {EditorModule} from 'primeng/editor';
import {
  AddCountryDialogComponent,
  AddCountryFormComponent,
  AppButtonComponent,
  AppFooterComponent,
  AppMenuComponent,
  AppMenuitemComponent,
  AppSelectComponent,
  BreadCrumbComponent,
  CityFormComponent,
  CityFormDialogComponent,
  CountryFormComponent,
  CourierFormComponent,
  CourierFormDialogComponent,
  InputChipComponent,
  InputComponent,
  InputErrorDirective,
  MainHeaderComponent,
  MainSidebarComponent,
  OrderListComponent,
  PasswordInputComponent,
  PriceFormComponent,
  PriceFormDialogComponent,
  SingeImageUploadComponent,
  SpinnerComponent,
  SpinnerOverlayComponent,
  StateFormComponent,
  StateFormDialogComponent,
  TextAreaComponent,
  UpdateInvoiceStatusDialogComponent,
  UserAccountFormComponent,
  UserFormComponent,
  UserFormDialogComponent,
  UserPermissionsFormComponent,
  UserProfileFormComponent,
  UserReferrerFormComponent,
  UserRoleFormComponent,
  UserRoleFormDialogComponent,
  ZoneFormComponent,
  ZoneFormDialogComponent,
} from './components';
import { UpdateCommissionStatusDialogComponent, UpdateReleaseStatusDialogComponent } from './components/commissions';
import {
  CategoryFormComponent,
  CategoryFormDialogComponent,
  CategoryListComponent,
  FunctionalFormComponent,
  FunctionalFormDialogComponent,
  FunctionalObjectivesListComponent,
  JournalDivisionUnitListComponent,
  JournalFormComponent,
  JournalFormDialogComponent,
  TlmoFormComponent,
  TlmoFormDialogComponent,
  TlmoListComponent,
} from './components/libraries';
import {
  MonthlyUpdateFormComponent,
  MonthlyUpdateFormDialogComponent,
  MonthlyUpdateListComponent,
  PlanFormComponent,
  PlanFormDialogComponent,
  ProjectActivityFormComponent,
  ProjectActivityFormDialogComponent,
  ProjectActivityListComponent,
  ProjectFormComponent,
  ProjectFormDialogComponent,
  ProjectListComponent,
  QuarterlyUpdateFormComponent,
  QuarterlyUpdateFormDialogComponent,
  QuarterlyUpdateListComponent,
  RemarksFormComponent,
} from './components/plans';
import { PpmrFormComponent } from './components/plans/ppmr-form/ppmr-form.component';
import { PpmrListComponent } from './components/plans/ppmr-list/ppmr-list.component';
import { RemarksListComponent } from './components/plans/remarks-list/remarks-list.component';
import { MIPSFormDialogComponent } from './components/reports/mipsform-dialog/mipsform-dialog.component';
import { MIPSFormComponent } from './components/reports/mipsform/mipsform.component';
import { MonthlyReportContentComponent } from './components/reports/monthly-report-content/monthly-report-content.component';
import { MonthlyformComponent } from './components/reports/monthlyform/monthlyform.component';
import { ProgramsformComponent } from './components/reports/programsform/programsform.component';
import { QPMRformComponent } from './components/reports/qpmrform/qpmrform.component';
import { SignatoriesComponent } from './components/reports/signatories/signatories.component';
import { WFPFormDialogComponent } from './components/reports/wfpform-dialog/wfpform-dialog.component';
import { WFPFormComponent } from './components/reports/wfpform/wfpform.component';
import { SwitchDivisionFomDialogComponent } from './components/users/switch-division-fom-dialog/switch-division-fom-dialog.component';
import { SwitchDivisionFomComponent } from './components/users/switch-division-fom/switch-division-fom.component';
import {
  AddMonthlyUpdatePageComponent,
  AddProjectPageComponent,
  CreateNewPasswordForgottenPageComponent,
  DashboardPageComponent,
  EditMonthlyUpdatePageComponent,
  EditProjectPageComponent,
  EmailVerifiedPageComponent,
  ForgotPasswordPageComponent,
  LoginPageComponent,
  LogoutPageComponent,
  MainPageComponent,
  NotFoundPageComponent,
  OrderListPageComponent,
  OrderViewPageComponent,
  PlansListPageComponent,
  PlanViewPageComponent,
  ProgramsreportPageComponent,
  ProjectViewPageComponent,
  SignUpNotifPageComponent,
  SignupPageComponent,
  StepFormSampleComponent,
  UserListPageComponent,
  UserProfileEditPageComponent,
  UserProfilePageComponent,
} from './pages';
import {
  CommisionViewPageComponent,
  CommissionListPageComponent,
  ReleaseListPageComponent,
  ReleaseViewPageComponent,
} from './pages/commissions';
import { DivisionListPageComponent } from './pages/library';
import { AddPpmrPageComponent } from './pages/plans/add-ppmr-page/add-ppmr-page.component';
import { EditPpmrPageComponent } from './pages/plans/edit-ppmr-page/edit-ppmr-page.component';
import { MonthlyreportPageComponent, WFPReportPageComponent } from './pages/reports';
import { MIPSReportPageComponent } from './pages/reports/mipsreport-page/mipsreport-page.component';
import { QPMRreportPageComponent } from './pages/reports/qpmrreport-page/qpmrreport-page.component';
import { AddUserPageComponent, EditUserPageComponent, RoleListPageComponent } from './pages/users';
import { effects, metaReducers, reducers } from './redux';
import { HasPermissionDirective } from './utils/directives';
import { HasNoPermissionDirective } from './utils/directives/has-no-permission.directive';
import { SpinnerInterceptor } from './utils/interceptors';
import { RouterService } from './utils/services';
import { AppSettingsPageComponent } from './pages/app/app-settings-page/app-settings-page.component';
import {
  ProjectViewReadonlyPageComponent
} from './pages/plans/project-view-readonly-page/project-view-readonly-page.component';
import { HelpPageComponent } from './pages/plans/help-page/help-page.component';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    AppButtonComponent,
    DashboardPageComponent,
    NotFoundPageComponent,
    MainPageComponent,
    LogoutPageComponent,
    MainHeaderComponent,
    MainSidebarComponent,
    AppSelectComponent,
    UserProfilePageComponent,
    SignupPageComponent,
    EmailVerifiedPageComponent,
    SignUpNotifPageComponent,
    StepFormSampleComponent,
    SpinnerComponent,
    UserListPageComponent,
    UserProfileEditPageComponent,
    ForgotPasswordPageComponent,
    CreateNewPasswordForgottenPageComponent,
    AddCountryFormComponent,
    AddCountryDialogComponent,
    InputComponent,
    InputChipComponent,
    InputErrorDirective,
    CountryFormComponent,
    StateFormComponent,
    StateFormDialogComponent,
    CityFormComponent,
    CityFormDialogComponent,
    CourierFormComponent,
    CourierFormDialogComponent,
    ZoneFormComponent,
    ZoneFormDialogComponent,
    TextAreaComponent,
    SingeImageUploadComponent,
    PriceFormComponent,
    PriceFormDialogComponent,
    UserFormComponent,
    UserFormDialogComponent,
    SpinnerOverlayComponent,
    UserProfileFormComponent,
    UserAccountFormComponent,
    UserReferrerFormComponent,
    PasswordInputComponent,
    AddUserPageComponent,
    OrderListComponent,
    OrderListPageComponent,
    OrderViewPageComponent,
    BreadCrumbComponent,
    UpdateInvoiceStatusDialogComponent,
    CommissionListPageComponent,
    CommisionViewPageComponent,
    UpdateCommissionStatusDialogComponent,
    ReleaseListPageComponent,
    ReleaseViewPageComponent,
    UpdateReleaseStatusDialogComponent,
    PlansListPageComponent,
    PlanFormDialogComponent,
    PlanFormComponent,
    HasPermissionDirective,
    HasNoPermissionDirective,
    PlanViewPageComponent,
    ProjectFormComponent,
    ProjectFormDialogComponent,
    ProjectViewPageComponent,
    ProjectActivityListComponent,
    RoleListPageComponent,
    ProjectActivityFormDialogComponent,
    ProjectActivityFormComponent,
    MonthlyUpdateListComponent,
    QuarterlyUpdateListComponent,
    MonthlyUpdateFormComponent,
    QuarterlyUpdateFormComponent,
    MonthlyUpdateFormDialogComponent,
    QuarterlyUpdateFormDialogComponent,
    DivisionListPageComponent,
    UserRoleFormDialogComponent,
    UserRoleFormComponent,
    UserPermissionsFormComponent,
    AppFooterComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    EditUserPageComponent,
    TlmoListComponent,
    FunctionalObjectivesListComponent,
    CategoryListComponent,
    JournalDivisionUnitListComponent,
    ProjectListComponent,
    TlmoFormDialogComponent,
    TlmoFormComponent,
    FunctionalFormDialogComponent,
    FunctionalFormComponent,
    JournalFormDialogComponent,
    JournalFormComponent,
    CategoryFormDialogComponent,
    CategoryFormComponent,
    WFPFormComponent,
    WFPFormDialogComponent,
    MIPSFormComponent,
    MIPSFormDialogComponent,
    WFPReportPageComponent,
    MIPSReportPageComponent,
    MonthlyreportPageComponent,
    QPMRreportPageComponent,
    MonthlyformComponent,
    QPMRformComponent,
    RemarksListComponent,
    RemarksFormComponent,
    ProgramsreportPageComponent,
    ProgramsformComponent,
    AddProjectPageComponent,
    EditProjectPageComponent,
    AddMonthlyUpdatePageComponent,
    EditMonthlyUpdatePageComponent,
    MonthlyReportContentComponent,
    PpmrFormComponent,
    AddPpmrPageComponent,
    PpmrListComponent,
    EditPpmrPageComponent,
    SignatoriesComponent,
    SwitchDivisionFomDialogComponent,
    SwitchDivisionFomComponent,
    AppSettingsPageComponent,
    ProjectViewReadonlyPageComponent,
    HelpPageComponent,
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      toastClass: 'ngx-toastr',
      closeButton: true,
      preventDuplicates: true,
    }),
    FontAwesomeModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
      features: {
        pause: false,
        lock: true,
        persist: true,
      },
    }),
    CdkTableModule,
    MatExpansionModule,
    MatDialogModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    MatStepperModule,
    MatMenuModule,
    ReactiveComponentModule,
    TableModule,
    ProgressBarModule,
    InputTextModule,
    ButtonModule,
    MenubarModule,
    MenuModule,
    AvatarModule,
    DropdownModule,
    DynamicDialogModule,
    InputNumberModule,
    DialogModule,
    SlideMenuModule,
    SelectButtonModule,
    RadioButtonModule,
    TooltipModule,
    KeyFilterModule,
    CheckboxModule,
    BreadcrumbModule,
    MultiSelectModule,
    ConfirmPopupModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    TagModule,
    TreeTableModule,
    TreeSelectModule,
    CalendarModule,
    EditorModule,
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [PlanFormDialogComponent],
})
export class AppModule {
  constructor(private routerService: RouterService) {}
}
