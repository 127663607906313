import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Referrer, User } from '../../../models';
import { select, Store } from '@ngrx/store';
import { AuthService, UserService } from '../../../utils/services';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { MainState } from '../../../redux';
import { selectUserStateUsers } from '../../../redux/user/user.selector';
import { loadUsers } from '../../../redux/user';

@Component({
  selector: 'app-user-referrer-form',
  templateUrl: './user-referrer-form.component.html',
  styleUrls: ['./user-referrer-form.component.scss'],
})
export class UserReferrerFormComponent implements OnInit, OnDestroy {
  @Input()
  public user: User;

  @Input()
  public showDeleteButton = false;

  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();

  public users$: Observable<User[]>;

  public users: User[];
  public referralForm: FormGroup;
  public isAuthLoading = false;
  public packageList = ['PARTNER', 'RESELLER'];

  private usersSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private store: Store<MainState>,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService
  ) {
    this.users$ = this.store.pipe(select(selectUserStateUsers));

    this.usersSubscription = this.users$.subscribe({
      next: (users) => {
        this.users = users;
        if (users && users.length <= 0) {
          this.store.dispatch(loadUsers({ realm: 'EXTERNAL' }));
        }
      },
    });

    this.getReferrerForm();
  }

  ngOnDestroy(): void {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {}

  getReferrerForm() {
    if (!this.referralForm) {
      this.referralForm = this.fb.group(
        {
          referrer: [null],
          userPackage: ['PARTNER', Validators.required],
        },
        {
          updateOn: 'blur',
        }
      );

      if (this.user && this.user.id) {
        this.referralForm.setValue({
          referrer: this.user.company.referrer,
          userPackage: this.user.company.userPackage,
        });
      }
    }
    return this.referralForm;
  }

  getReferrerControl() {
    return this.referralForm.get('referrer') as FormControl;
  }

  getPackageControl() {
    return this.referralForm.get('userPackage') as FormControl;
  }

  referrerSelected(referrer: Referrer) {
    this.getReferrerControl().setValue(referrer);
  }

  packageSelected(userPackage: 'RESELLER' | 'PARTNER') {
    if (userPackage == 'PARTNER') {
      this.getReferrerControl().setValue(null);
    }
    this.getPackageControl().setValue(userPackage);
  }
}
