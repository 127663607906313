import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MainState } from '../../../redux';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Fulfillment, Invoice, Payment } from '../../../models/ops';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fadeUpAndFadeDown } from '../../../utils/animations';
import { AuthService, OpsService } from '../../../utils/services';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { formatDate } from '@angular/common';

type InvoiceWithLoading = Invoice & {
  isOpen?: boolean;
  stringCreatedDate?: string;
};
type Log = Payment & Fulfillment;

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  animations: [fadeUpAndFadeDown],
})
export class OrderListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  public orderStatus: string;

  public isLoading = false;
  public invoices$: Observable<{
    invoices: Invoice[];
    invoiceStatus: string;
  }>;
  public invoices: InvoiceWithLoading[] = [];
  public invoiceSubscription: Subscription;
  public source: MatTableDataSource<InvoiceWithLoading>;
  @ViewChild(MatPaginator)
  private paginator: MatPaginator;
  @ViewChild(Table)
  private table: Table;

  constructor(
    private store: Store<MainState>,
    private toaster: ToastrService,
    private authService: AuthService,
    private opsService: OpsService
  ) {
    console.log('Constructor: ', this.orderStatus);

    this.source = new MatTableDataSource([]);
  }

  loadData() {
    const origin = this.authService.getOriginObject();
    this.invoiceSubscription = this.opsService
      .getInvoices(origin.code, this.orderStatus)
      .subscribe({
        next: (invoices) => {
          this.invoices = invoices.map((invoice: InvoiceWithLoading) => {
            invoice.createdDate = new Date(invoice.createdDate);
            invoice.stringCreatedDate = formatDate(
              invoice.createdDate,
              'mm/dd/yyyy',
              'en-US'
            );
            return invoice;
          });
          this.isLoading = false;
        },
        error: (err) => {
          console.error(err);
          this.toaster.error('Error loading invoices', 'Error');
          this.invoices = [];
          this.isLoading = false;
        },
      });
  }

  getPagedData() {
    return this.source._pageData(this.invoices);
  }

  ngAfterViewInit(): void {
    this.source.paginator = this.paginator;
  }

  ngOnInit(): void {
    console.log('ngOnInit: ', this.orderStatus);
    this.loadData();
  }

  ngOnDestroy(): void {
    if (this.invoiceSubscription) {
      this.invoiceSubscription.unsubscribe();
    }
    this.invoices = [];
  }

  clear(dt1: Table) {}

  getLogs(invoice: InvoiceWithLoading): Log[] {
    let paymentList = [];
    if (invoice.paymentList && invoice.paymentList.length > 0) {
      paymentList = invoice.paymentList.map((payment: Payment) => {
        const newPayment: Payment = { ...payment, statusType: 'PAYMENT' };
        return newPayment;
      });
    }

    let fulfillmentList = [];
    if (invoice.fulfillmentList && invoice.fulfillmentList.length > 0) {
      fulfillmentList = invoice.fulfillmentList.map(
        (fulfillment: Fulfillment) => {
          const newFulfillment: Fulfillment = {
            ...fulfillment,
            statusType: 'FULFILLMENT',
          };
          return newFulfillment;
        }
      );
    }
    const logs: Log[] = [...paymentList, ...fulfillmentList];

    return logs;
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      //dt1.filterGlobal($event.target, 'contains')
    }
  }
}
