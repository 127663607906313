import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AppConfigService } from '../../app-config.service';
import { environment } from '../../../environments/environment';
import { Remarks } from '../../models/plans';

const CURRENT_YEAR = new Date().getFullYear();

@Injectable({
  providedIn: 'root',
})
export class RemarksService {
  private wfpApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
    } else {
      this.wfpApi = environment.wfpApi;
    }
  }

  createRemark(remarks: Remarks) {
    const url = `${this.wfpApi}/remarks`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Remarks>(url, remarks, { headers });
  }

  deleteRemark(remarkId: number) {
    const url = `${this.wfpApi}/remarks/${remarkId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<any>(url, { headers });
  }

  getRemarkById(id: string) {
    const url = `${this.wfpApi}/remarks/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Remarks>(url, { headers });
  }

  getRemarksByPlanId(planId: number) {
    const url = `${this.wfpApi}/remarks/by-plan-id/${planId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Remarks[]>(url, { headers });
  }

  updateRemark(remarks: Remarks) {
    const url = `${this.wfpApi}/remarks`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<Remarks>(url, remarks, { headers });
  }
}
