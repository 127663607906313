<div *ngIf='user && user.id' class='mb-4'>
  <label class='block font-raleway font-bold'>Roles</label>
  <p-multiSelect
    (onChange)='onRolesChange($event)'
    [formControl]="getFormControl('roles')"
    [options]='roles'
    defaultLabel='Select roles'
    display='chip'
    optionLabel='title'
    styleClass='w-full'
  ></p-multiSelect>
</div>

<div class='mb-4'>
  <label class='block font-raleway font-bold'>Division</label>
  <p-dropdown
    [autoDisplayFirst]='false'
    [formControl]="getFormControl('divisionId')"
    [options]='divisions'
    label='Division'
    optionLabel='name'
    optionValue='id'
    styleClass='w-full'
  ></p-dropdown>
</div>

<app-input [inputControl]='getUsernameControl()' inputId='username' label='Username' placeHolder='ex. admin'>
  <ng-container error>
    <div
      *ngIf='(getUsernameControl().touched || getUsernameControl().dirty) && getUsernameControl().invalid'
      class='alert'
    >
      <ul>
        <ng-container *ngFor='let err of getUsernameControl()?.errors | keyvalue'>
          <li *ngIf="err.key == 'required'">Please enter your username</li>
          <li *ngIf="err.key == 'isUsernameExists'">This username is already registered</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>

<ng-container *ngIf='hasPassword'>
  <app-password-input [inputControl]='getPasswordControl()' inputId='password' label='Password'></app-password-input>
</ng-container>
