<div class='main-content mb-4 flex flex-col gap-4'>
  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label for='task'>Task</label>
      <input
        [formControl]="getFormControl('task')"
        [pTooltip]="isFormControlInvalid('task') ? 'Task is required' : ''"
        class='w-full rounded-none'
        id='task'
        pInputText
        tooltipEvent='focus'
        tooltipPosition='bottom'
        type='text'
      />
      <ul *ngIf="isFormControlInvalid('task')" class='p-error'>
        <ng-container *ngFor="let err of getFormControl('task')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a task</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label for='assignedTo'>Assigned To</label>
      <input
        [formControl]="getFormControl('assignedTo')"
        [pTooltip]="isFormControlInvalid('assignedTo') ? 'Assigned To is required' : ''"
        class='w-full rounded-none'
        id='assignedTo'
        pInputText
        tooltipEvent='focus'
        tooltipPosition='bottom'
        type='text'
      />
      <ul *ngIf="isFormControlInvalid('assignedTo')" class='p-error'>
        <ng-container *ngFor="let err of getFormControl('assignedTo')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Enter assigned to</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div #div class='grid grid-cols-1 gap-2 lg:gap-0 md:grid-cols-2 lg:grid-cols-4'>
    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label class='block font-raleway font-bold'>Start Month:</label>
      <p-dropdown
        [formControl]="getFormControl('startMonth')"
        [options]='getMonths()'
        inputId='startMonth'
        label='Start Month'
        onChange='onStartMonthSelect'
        optionLabel='name'
        optionValue='id'
        placeholder='Select start month'
        scrollHeight='200px'
        styleClass='w-full rounded-none'
      ></p-dropdown>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label class='block font-raleway font-bold'>Start Year:</label>
      <p-dropdown
        [formControl]="getFormControl('startYear')"
        [options]='yearChoices'
        inputId='startYear'
        label='Start Year'
        placeholder='Select start year'
        scrollHeight='200px'
        styleClass='w-full rounded-none'
      ></p-dropdown>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label class='block font-raleway font-bold'>End Month:</label>
      <p-dropdown
        [formControl]="getFormControl('endMonth')"
        [options]='getMonths()'
        inputId='endMonth'
        label='End Month'
        optionLabel='name'
        optionValue='id'
        placeholder='Select end month'
        scrollHeight='200px'
        styleClass='w-full rounded-none'
      ></p-dropdown>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label class='block font-raleway font-bold'>End Year:</label>
      <p-dropdown
        [formControl]="getFormControl('endYear')"
        [options]='yearChoices'
        inputId='endYear'
        label='End Year'
        placeholder='Select end year'
        scrollHeight='200px'
        styleClass='w-full rounded-none'
      ></p-dropdown>
    </div>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label for='task'>Target/Milestones</label>
      <input
        [formControl]="getFormControl('milestones')"
        [pTooltip]="isFormControlInvalid('milestones') ? 'Milestones is required' : ''"
        class='w-full rounded-none'
        id='milestones'
        pInputText
        tooltipEvent='focus'
        tooltipPosition='bottom'
        type='text'
      />
      <ul *ngIf="isFormControlInvalid('milestones')" class='p-error'>
        <ng-container *ngFor="let err of getFormControl('milestones')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Enter milestones</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label for='task'>Remarks</label>
      <input
        [formControl]="getFormControl('remarks')"
        class='w-full rounded-none'
        id='remarks'
        pInputText
        type='text'
      />
    </span>
  </div>
</div>

<div *ngIf='false' class='buttons flex w-full gap-4'>
  <app-button
    (click)='submit()'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="project && project.id ? 'Save' : 'Add Project Activity'"
    [type]="'button'"
    class='w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md'
  >
  </app-button>

  <app-button
    (click)='deletePlan()'
    *ngIf='project.id && showDeleteButton'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="'Delete Project Activity'"
    [type]="'button'"
    class='w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md'
  >
  </app-button>
</div>
