import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { userHasPermission } from '../../../utils/functions/has-permission';
import { ToastrService } from 'ngx-toastr';
import { addRemark, updateRemark } from '../../../redux/plan';
import { Plan, Remarks } from '../../../models/plans';
import { User } from '../../../models';
import { AuthService } from '../../../utils/services';

@Component({
  selector: 'app-remarks-form',
  templateUrl: './remarks-form.component.html',
  styleUrls: ['./remarks-form.component.scss'],
})
export class RemarksFormComponent implements OnInit {
  public form: FormGroup;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  private currentUser: User;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private toaster: ToastrService,
    private authService: AuthService
  ) {
    this.form = this.fb.group({
      remark: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
    });
    this.currentUser = this.authService.getUserObject();
  }

  private _plan: Plan;

  public get plan() {
    return this._plan;
  }

  @Input()
  public set plan(plan: Plan) {
    this._plan = plan;
  }

  canEdit() {
    return userHasPermission(['remark:createAny', 'remark:createOwn']);
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  ngOnInit(): void {}

  submit() {
    this.form.markAsTouched();
    this.form.markAsDirty();
    if (this.form.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }

    this.isLoading = true;
    const remark: Remarks = this.form.value;
    remark.joinId = this._plan.id;
    remark.remarkCreator = `${this.currentUser.firstName} ${this.currentUser.lastName}`;
    if (remark.id) {
      this.store.dispatch(updateRemark({ remark: remark }));
    } else {
      this.store.dispatch(addRemark({ remark: remark }));
    }
    this.onSubmit.emit('Done');

    return true;
  }
}
