import { Component, Inject, OnInit } from '@angular/core';
import { MonthlyUpdate, Project } from '../../../models/plans';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type DialogData = {
  project?: Project;
  monthlyUpdate?: MonthlyUpdate;
};

@Component({
  selector: 'app-monthly-update-form-dialog',
  templateUrl: './monthly-update-form-dialog.component.html',
  styleUrls: ['./monthly-update-form-dialog.component.scss'],
})
export class MonthlyUpdateFormDialogComponent implements OnInit {
  public monthlyUpdate: MonthlyUpdate;
  public project: Project;

  constructor(
    public dialogRef: MatDialogRef<MonthlyUpdateFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    dialogRef.updateSize('90%', '90%');
    this.project = data.project;
    this.monthlyUpdate = data.monthlyUpdate;
  }

  ngOnInit(): void {}

  onSubmit(event) {
    this.dialogRef.close();
  }
}
