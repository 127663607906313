<div class='content-wrapper w-full-no-scroll shadow-md'>
  <p-table
    #dt1
    [globalFilterFields]="['year', 'status', 'createdBy', 'division.name']"
    [loading]='isLoading'
    [paginator]='true'
    [rowsPerPageOptions]='[10, 25, 50]'
    [rows]='10'
    [showCurrentPageReport]='true'
    [value]='quarterlyUpdates'
    class='block w-full-no-scroll'
    currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
    dataKey='id'
    responsiveLayout='scroll'
    styleClass='p-datatable-gridlines w-screen md:w-full flow-root'
  >
    <ng-template pTemplate='caption'>
      <div class='flex flex-col md:flex-row justify-between items-start md:items-center'>
        <ng-container *ngIf="project && (plan.status == 'Approved')">
          <button
            (click)='openAddPlanForm()'
            *hasPermissions="['plan:createAny']"
            class='p-button-outlined text-blue-500'
            icon='pi pi-plus'
            label='Add'
            pButton
          ></button>
        </ng-container>
        <div class='p-d-flex flex justify-between gap-2'>
          <span class='p-input-icon-left p-ml-auto'>
            <i class='pi pi-search'></i>
            <input (input)='filter($event)' pInputText placeholder='Search keyword' type='text' />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate='header'>
      <tr>
        <th pSortableColumn='update.project.title'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Program
            <p-columnFilter display='menu' field='update.project.title' type='text'></p-columnFilter>
            <p-sortIcon field='update.project.title'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='actualCostOfBudget'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Actual Budget
          </div>
        </th>
        <th pSortableColumn='yearQuarterString'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Quarter
            <p-columnFilter display='menu' field='yearQuarterString' type='text'></p-columnFilter>
            <p-sortIcon field='yearQuarterString'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='reportingYear'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Year
            <p-columnFilter display='menu' field='reportingYear' type='numeric'></p-columnFilter>
            <p-sortIcon field='reportingYear'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='milestones'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Target/Milestone
            <p-columnFilter display='menu' field='milestones' type='text'></p-columnFilter>
            <p-sortIcon field='milestones'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='accomplishments'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Actual Accomplishment
            <p-columnFilter display='menu' field='accomplishments' type='text'></p-columnFilter>
            <p-sortIcon field='accomplishments'></p-sortIcon>
          </div>
        </th>
        <th>
          <div class='p-d-flex p-jc-between p-ai-center'>Actions</div>
        </th>
      </tr>
    </ng-template>

    <ng-template let-expanded='expanded' let-update pTemplate='body'>
      <tr class='dark:bg-gray-700'>
        <td>
          {{ update.project.title }}
        </td>
        <td>
          {{ project.budget }}
        </td>
        <td>
          {{ update.yearQuarterString }}
        </td>
        <td>
          {{ update.reportingYear }}
        </td>
        <td>
          {{ update.milestones }}
        </td>
        <td>
          {{ update.accomplishments }}
        </td>
        <td>
          <div *ngIf="plan && (plan.status == 'Approved')" class='flex gap-2'>
            <button (click)='openEditDialog(update)'
                    *hasPermissions="['plan:editAny','plan:editOwn']"
                    class='p-button-rounded p-button-outlined'
                    icon='pi pi-pencil' pButton
                    pRipple
                    pTooltip='Edit updates' type='button'>
            </button>
            <button (click)='deleteUpdate($event, update)'
                    *hasPermissions="['plan:deleteAny','plan:deleteOwn']"
                    class='p-button-rounded p-button-outlined p-button-danger'
                    icon='pi pi-times' pButton
                    pRipple
                    pTooltip='Delete updates' type='button'>
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template let-commission pTemplate='rowexpansion'>
      <tr class='expanded-tr-logs'>
        <td colspan='9'>
          <div class='details'></div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate='emptymessage'>
      <tr>
        <td colspan='9'>No activities found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]='showActivityDialog'
  [blockScroll]='true'
  [breakpoints]="{ '960px': '75vw' }"
  [contentStyle]="{ height: 'clamp(500px, 90%, 90%)' }"
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: 'clamp(250px, 120ch, 90vw)' }"
  contentStyleClass=''
  header='Add Quarterly Update'
  styleClass='overflow-y-auto block'
>
  <app-quarterly-update-form #activityForm [project]='project'></app-quarterly-update-form>
  <ng-template pTemplate='footer'>
    <p-button
      (click)='showActivityDialog = false'
      icon='pi pi-cross'
      label='Cancel'
      styleClass='p-button-outlined p-button-danger'
    ></p-button>
    <p-button (click)='showActivityDialog = !activityForm.submit()' icon='pi pi-check' label='Save'></p-button>
  </ng-template>
</p-dialog>

<p-confirmPopup></p-confirmPopup>