import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { fadeUpAndFadeDown } from '../../../utils/animations';

@Component({
  selector: 'app-order-list-page',
  templateUrl: './order-list-page.component.html',
  styleUrls: ['./order-list-page.component.scss'],
  animations: [fadeUpAndFadeDown],
})
export class OrderListPageComponent implements OnInit, OnDestroy {
  public orderStatus = 'PENDING';

  private paramsSubscription: Subscription;

  constructor(private activeRoute: ActivatedRoute) {
    this.paramsSubscription = this.activeRoute.params.subscribe({
      next: (params) => {
        this.orderStatus = params.orderStatus;
      },
    });
  }

  ngOnInit(): void {}

  openAddCountryForm() {}

  ngOnDestroy(): void {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }
}
