import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Plan, Project, ProjectActivity } from '../../../models/plans';
import { ProjectActivityService } from '../../../utils/services';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { MatDialog } from '@angular/material/dialog';
import { ProjectActivityFormDialogComponent } from '../project-activity-form-dialog/project-activity-form-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { MainState } from '../../../redux';
import { deleteProjectActivity } from '../../../redux/plan';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-project-activity-list',
  templateUrl: './project-activity-list.component.html',
  styleUrls: ['./project-activity-list.component.scss'],
})
export class ProjectActivityListComponent implements OnInit {
  public isLoading = false;
  public plan: Plan;
  public projectActivities: ProjectActivity[];
  public showActivityDialog = false;
  public statuses: string[] = ['Draft', 'Approved', 'Rejected'];
  @ViewChild(Table)
  private table: Table;

  constructor(
    private projectActivityService: ProjectActivityService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService
  ) {}

  private _project: Project;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Project) {
    if (!project || !project.id) {
      return;
    }
    this._project = project;
    this.plan = project.plan;
    this.loadData();
  }

  deleteActivity($event: Event, activity: ProjectActivity) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this activity?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteProjectActivity({ projectActivityId: activity.id }));
      },
      reject: () => {},
    });
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      //dt1.filterGlobal($event.target, 'contains')
    }
  }

  getMonthChoices() {
    return monthNames;
  }

  getMonthString(monthNumber: number) {
    return monthNames[monthNumber];
  }

  loadData() {
    this.isLoading = true;
    this.projectActivityService.getProjectActivitiesByProjectId(this.project.id, false).subscribe({
      next: (activities) => {
        this.projectActivities = activities.map((activity) => {
          activity.startMonthString = this.getMonthString(activity.startMonth);
          activity.endMonthString = this.getMonthString(activity.endMonth);
          return activity;
        });
        this.isLoading = false;
      },
      error: (err) => {
        if (err.error.statusCode && err.error.statusCode != 404) {
          this.toaster.error(err.message, 'Error');
        }
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  ngOnInit(): void {
    if (!this.project || !this.project.id) {
      return;
    }
  }

  openAddPlanForm() {
    this.showActivityDialog = true;
  }

  openEditDialog(activity: ProjectActivity) {
    const ref = this.dialog.open(ProjectActivityFormDialogComponent, {
      data: {
        project: this.project,
        activity: activity,
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
    });
  }
}
