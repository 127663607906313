import { createReducer, on } from '@ngrx/store';
import { loadedPLan } from './plan.actions';
import { PlanState } from './plan.state';

export const initialOpsState: PlanState = {
  planName: '',
};

const _planReducer = createReducer(
  initialOpsState,
  on(loadedPLan, (state, action) => {
    return {
      ...state,
    };
  })
);

export function planReducer(state: PlanState | undefined, action: any) {
  return _planReducer(state, action);
}
