import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';
import { environment } from 'src/environments/environment';
import { PPMR } from '../../models/plans';
import { IS_SPINNER_ENABLED } from '../constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
  deps: [AuthService],
})
export class PpmrService {
  private wfpApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
    } else {
      this.wfpApi = environment.wfpApi;
    }
  }

  public addPpmr(ppmr: PPMR) {
    const url = `${this.wfpApi}/ppmrs`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<PPMR>(url, ppmr, {
      headers,
    });
  }

  public deletePpmr(id: number) {
    const url = `${this.wfpApi}/ppmrs/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<any>(url, { headers });
  }

  public getPpmrById(id: number) {
    const url = `${this.wfpApi}/ppmrs/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<PPMR>(url, { headers });
  }

  public getPpmrsByProjectId(projectId: number, showSpinner = false) {
    const url = `${this.wfpApi}/ppmrs?projectId=${projectId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    const context = new HttpContext().set(IS_SPINNER_ENABLED, showSpinner);
    return this.httpClient.get<PPMR[]>(url, { headers, context });
  }

  public updatePpmr(ppmr: PPMR) {
    const url = `${this.wfpApi}/ppmrs`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<PPMR>(url, ppmr, {
      headers,
    });
  }
}
