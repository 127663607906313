import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { DashboardDataNumeric } from 'src/app/models/plans';
import { Asset } from '../../models/asset';
import { AssetService, MonthlyUpdateService } from '../../utils/services';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  public companies: Asset[] = [];
  public currentCompanyId = '';
  public currentMonth = new Date().getMonth().toString();
  public currentYear = new Date().getFullYear().toString();
  public dashboardData: DashboardDataNumeric[] = [];
  public isLoading = false;
  public monthMap = monthNames.map((month, index) => ({
    name: month,
    id: index.toString(),
  }));
  public yearMap = (() => {
    const curYear = new Date().getFullYear();
    const yearList = [];
    for (let i = curYear; i >= 2010; i--) {
      yearList.push(i.toString());
    }
    return yearList;
  })();
  public total: DashboardDataNumeric={};
  private subscription: Subscription;
  @ViewChild(Table)
  private table: Table;

  constructor(private assetService: AssetService, private monthlyService: MonthlyUpdateService ) {}
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getMonths() {
    // const currentDate = new Date();
    // const currentMonth = currentDate.getMonth();
    // return monthNames
    //   .filter((month, index) => {
    //     console.log(currentMonth, index, this.project.projectType);
    //     if (this.project.projectType == 'MAIN_PROJECT') {
    //       return currentMonth == index || currentMonth - 1 == index;
    //     }
    //     return true;
    //   })
    //   .map((month, index) => ({
    //     name: month,
    //     id: index.toString(),
    //   }));
    return monthNames.map((month, index) => ({
      name: month,
      id: index.toString(),
    }));
  }

  onMonthChange(param: any) {
    this.currentMonth = param.value;
    this.getDashboardData(param.value, this.currentYear);
  }

  onYearChange(param: any) {
    this.currentMonth = param.value;
    this.getDashboardData(this.currentYear, param.value);
  }

  ngOnInit(): void {
    const date = new Date();
    this.currentMonth = date.getMonth().toString();
    this.currentYear = date.getFullYear().toString();
    this.subscription = this.assetService.getAssets().subscribe((assets) => {
      this.currentCompanyId = assets[0]?.id;
      this.companies = assets;
    });
    this.getDashboardData(this.currentMonth, this.currentYear);
  }

  getDashboardData(month: string, year: string) {
    this.isLoading = true;
    this.monthlyService.getDashboardData(Number(month), Number(year)).subscribe({
        next: (result) => {
          var data = result.map(function(item) {
            return {
              ...item,
              ACC: parseInt(item.ACC),
              AHEAD_OF_TIME: parseInt(item.AHEAD_OF_TIME),
              DEFERRED: parseInt(item.DEFERRED),
              DELAYED: parseInt(item.DELAYED),
              NOT_ATTAINED: parseInt(item.NOT_ATTAINED),
              NOT_IMPLEMENTED: parseInt(item.NOT_IMPLEMENTED),
              ON_TRACK: parseInt(item.ON_TRACK),
              ON_GOING: item.ON_GOING? parseInt(item.ON_GOING):0
            };
        
        });
         
        this.dashboardData = data;
        this.isLoading = false;
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  openAddPlanForm() {}

  onFilter(event: Event) {

    var data =event['filteredValue'];
    this.total.ACC= data.reduce((acc, val) => acc += val.ACC, 0);
    this.total.AHEAD_OF_TIME=data.reduce((aot, val) => aot += val.AHEAD_OF_TIME, 0);
    this.total.DEFERRED=data.reduce((deferred, val) => deferred += val.DEFERRED, 0);
    this.total.DELAYED=data.reduce((delayed, val) => delayed += val.DELAYED, 0);
    this.total.NOT_ATTAINED=data.reduce((na, val) => na += val.NOT_ATTAINED, 0);
    this.total.NOT_IMPLEMENTED=data.reduce((ni, val) => ni += val.NOT_IMPLEMENTED, 0);
    this.total.ON_TRACK=data.reduce((ot, val) => ot += val.ON_TRACK, 0);
    this.total.ON_GOING=data.reduce((ot, val) => ot += val.ON_GOING, 0);
  }

  onChange() {
    console.debug('currentCompany', this.currentCompanyId);
  }
}
