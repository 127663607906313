<div class='main-content mb-4 flex flex-col gap-4'>
<!--  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">-->
<!--    <span class='block w-full'>-->
<!--      <label class='block font-raleway font-bold'>-->
<!--        Budget Utilized for the Month per Program/Project/Activity: *-->
<!--      </label>-->
<!--      <p-inputNumber-->
<!--        [formControl]="getFormControl('actualCostOfBudget')"-->
<!--        [maxFractionDigits]='5'-->
<!--        [minFractionDigits]='2'-->
<!--        class='w-full'-->
<!--        inputId='actualCostOfBudget'-->
<!--        inputStyleClass='rounded-none w-full'-->
<!--        mode='decimal'-->
<!--        styleClass='w-full'-->
<!--      >-->
<!--      </p-inputNumber>-->
<!--    </span>-->
<!--  </div>-->

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold'> Timeline/Target for each Program/Project/Activity: * </label>
      <!-- <input
             [formControl]="getFormControl('milestones')"
             class='w-full rounded-none'
             id='milestones'
             pInputText
             type='text'
           /> -->
      <textarea [formControl]="getFormControl('timeline')" class='w-full' pInputTextarea rows='3'></textarea>
      <ul *ngIf="isFormControlInvalid('timeline')">
        <ng-container *ngFor="let err of getFormControlErrors('timeline') | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a milestone</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold'> Actual Output/Type/No. of Participants: * </label>
      <!--      <input-->
      <!--        [formControl]="getFormControl('accomplishments')"-->
      <!--        class='w-full rounded-none'-->
      <!--        id='accomplishments'-->
      <!--        pInputText-->
      <!--        type='text'-->
      <!--      />-->
      <textarea [formControl]="getFormControl('actualOutput')" class='w-full' pInputTextarea rows='3'></textarea>
      <ul *ngIf="isFormControlInvalid('actualOutput')">
        <ng-container *ngFor="let err of getFormControlErrors('actualOutput') | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter an actual output</li>
        </ng-container>
      </ul>
    </span>
  </div>


  <div *ngIf='false' class='grid grid-cols-1 gap-2 lg:gap-0 md:grid-cols-3 items-end'>
    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class='block w-full'>
        <label class='block font-raleway font-bold'>
          Reasons For Variance Non-Attainment of Target No. of Participants/Delay
        </label>
        <!--        <input-->
        <!--          [formControl]="getFormControl('varianceReason')"-->
        <!--          class='w-full rounded-none'-->
        <!--          id='varianceReason'-->
        <!--          pInputText-->
        <!--          type='text'-->
        <!--        />-->
        <textarea [formControl]="getFormControl('varianceReason')" class='w-full' pInputTextarea rows='3'></textarea>
        <ul *ngIf="isFormControlInvalid('varianceReason')">
          <ng-container *ngFor="let err of getFormControlErrors('varianceReason') | keyvalue">
            <li *ngIf="err.key == 'required'">Please enter Reasons For Variance</li>
          </ng-container>
        </ul>
      </span>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class='block w-full'>
        <label class='block font-raleway font-bold' for='actionPlanVariance'>
          Action Plan To Attain Target No. Of Participants/ Address Delay
        </label>
        <input
          [formControl]="getFormControl('actionPlanVariance')"
          class='w-full rounded-none'
          id='actionPlanVariance'
          pInputText
          type='text'
        />
        <ul *ngIf="isFormControlInvalid('actionPlanVariance')">
          <ng-container *ngFor="let err of getFormControlErrors('actionPlanVariance') | keyvalue">
            <li *ngIf="err.key == 'required'">Please enter an Action Plan</li>
          </ng-container>
        </ul>
      </span>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class='block w-full'>
        <label class='block font-raleway font-bold'> Remarks </label>
        <!--        <input-->
        <!--          [formControl]="getFormControl('monitoringRemarks')"-->
        <!--          class='w-full rounded-none'-->
        <!--          id='monitoringRemarks'-->
        <!--          pInputText-->
        <!--          type='text'-->
        <!--        />-->
        <textarea [formControl]="getFormControl('monitoringRemarks')" pInputTextarea></textarea>
        <ul *ngIf="isFormControlInvalid('monitoringRemarks')">
          <ng-container *ngFor="let err of getFormControlErrors('monitoringRemarks') | keyvalue">
            <li *ngIf="err.key == 'required'">Please enter remarks</li>
          </ng-container>
        </ul>
      </span>
    </div>
  </div>
</div>

<div *ngIf='false' class='buttons flex w-full gap-4'>
  <app-button
    (click)='submit()'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="project && project.id ? 'Save' : 'Add Project Activity'"
    [type]="'button'"
    class='w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md'
  >
  </app-button>

  <app-button
    (click)='deletePlan()'
    *ngIf='project.id && showDeleteButton'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="'Delete Project Activity'"
    [type]="'button'"
    class='w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md'
  >
  </app-button>
</div>
