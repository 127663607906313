<h1>Edit Project</h1>
<div class='bg-white p-4 md:p-8 rounded-md shadow-md'>
  <app-project-form #projectForm [plan]='plan' [project]='project'></app-project-form>

  <div class='flex gap-2'>
    <p-button
      (click)='goBack()'
      icon='pi pi-cross'
      label='Cancel'
      styleClass='p-button-outlined p-button-danger'
    ></p-button>
    <p-button (click)='projectForm.submit()' icon='pi pi-check' label='Save'></p-button>
  </div>
</div>
