<app-input [inputControl]='getNameControl()' inputId='name' label='Name' placeHolder='ex. Philippines'>
  <ng-container error>
    <div *ngIf='(getNameControl().touched || getNameControl().dirty) && getNameControl().invalid' class='alert'>
      <ul *ngIf='getNameControl().errors'>
        <ng-container *ngFor='let err of getNameControl().errors | keyvalue'>
          <li *ngIf="err.key == 'required'">Please enter the country name</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>
<app-input [inputControl]='getCodeControl()' inputId='code' label='Code' placeHolder='ex. PH'>
  <ng-container error>
    <div *ngIf='(getCodeControl().touched || getCodeControl().dirty) && getCodeControl().invalid' class='alert'>
      <ul>
        <ng-container *ngFor='let err of getCodeControl()?.errors | keyvalue'>
          <li *ngIf="err.key == 'required'">Please enter the country code</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>
<app-input [inputControl]='getDescriptionControl()' inputId='description' label='Description' placeHolder=''>
  <ng-container error>
    <div *ngIf='(getDescriptionControl().touched || getDescriptionControl().dirty) && getDescriptionControl().invalid'
         class='alert'>
      <ul>
        <ng-container *ngFor='let err of getDescriptionControl()?.errors | keyvalue'>
          <li *ngIf="err.key == 'required'">Please enter the country name</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>

<app-button (click)='submit()' [block]='isLoading' [disabled]='isLoading' [loading]='isLoading' [text]="'Add Country'"
            [type]="'button'" class='w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md'>
</app-button>
