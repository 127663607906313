import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AppConfigService } from '../../app-config.service';
import { environment } from '../../../environments/environment';
import { Plan } from '../../models/plans';

const CURRENT_YEAR = new Date().getFullYear();

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  private wfpApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
    } else {
      this.wfpApi = environment.wfpApi;
    }
  }

  createPlan(plan: Plan) {
    const url = `${this.wfpApi}/plans`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Plan>(url, plan, { headers });
  }

  deletePlan(planId: number) {
    const url = `${this.wfpApi}/plans/${planId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<any>(url, { headers });
  }

  getPlanById(id: string) {
    const url = `${this.wfpApi}/plans/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Plan>(url, { headers });
  }

  getPlans(fromYear: number = CURRENT_YEAR, toYear: number = CURRENT_YEAR, divisionId: number = 0) {
    const url = `${this.wfpApi}/plans?fromYear=${fromYear}&toYear=${toYear}&divisionId=${divisionId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Plan[]>(url, { headers });
  }

  updatePlan(plan: Plan) {
    const url = `${this.wfpApi}/plans`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<Plan>(url, plan, { headers });
  }

  updatePlanStatus(plan: Plan) {
    const url = `${this.wfpApi}/plans/${plan.id}/${plan.status}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<Plan>(url, plan, { headers });
  }
}
