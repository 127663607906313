import { User } from '../../models';
import { USER_KEY } from '../constants';

export function userHasPermission(permissions: string[]) {
  const userData = JSON.parse(localStorage.getItem(USER_KEY));
  const user: User = Object.assign(new User(), userData);

  const hasPermission = user.userPermissions.some((permission) => permissions.includes(permission));
  return hasPermission;
}

export function userHasNoPermission(permissions: string[]) {
  const userData = JSON.parse(localStorage.getItem(USER_KEY));
  const user: User = Object.assign(new User(), userData);

  const hasPermission = user.userPermissions.some((permission) => permissions.includes(permission));
  return !hasPermission;
}

export function userDivisionMatches(divisionId: number) {
  const userData = JSON.parse(localStorage.getItem(USER_KEY));
  const user: User = Object.assign(new User(), userData);

  const userDivisionMatches = user.divisionId == divisionId
  return userDivisionMatches;
}