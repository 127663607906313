import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FuncObjective, Plan } from '../../../models/plans';
import { LibraryService } from '../../../utils/services';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { FunctionalFormDialogComponent } from '../functional-form-dialog/functional-form-dialog.component';
import { ConfirmationService, MenuItem, TreeNode } from 'primeng/api';
import { Store } from '@ngrx/store';
import { deleteObjective, MainState, updateBreadCrumbs } from '../../../redux';
import { TreeTable } from 'primeng/treetable';

@Component({
  selector: 'app-functional-list',
  templateUrl: './functional-objective-list.component.html',
  styleUrls: ['./functional-objective-list.component.scss'],
})
export class FunctionalObjectivesListComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/libraries/funcObjList',
      label: 'Functional Objectives',
    },
  ];
  public functionalObjcts: FuncObjective[];
  public isLoading = false;
  public plan: Plan;
  public showActivityDialog = false;
  public statuses: string[] = ['Draft', 'Approved', 'Rejected'];
  public treeObjectives: TreeNode[] = [];
  @ViewChild(TreeTable)
  private table: TreeTable;

  constructor(
    private libraryService: LibraryService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService
  ) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
  }

  private _project: FuncObjective;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: FuncObjective) {
    if (!project || !project.id) {
      return;
    }
    this._project = project;
    this.plan = project;
    this.loadData();
  }

  convertToTreeNodes(objectives: FuncObjective[]) {
    return objectives.map((obj) => {
      const node: TreeNode<FuncObjective> = {
        data: obj,
        key: obj.id.toString(),
        children: obj.children ? this.convertToTreeNodes(obj.children) : [],
        leaf: obj.children.length <= 0,
      };
      return node;
    });
  }

  deleteObjective($event: Event, objective: FuncObjective) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this Functional Objective?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteObjective({ payload: objective }));
      },
      reject: () => {},
    });
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      //dt1.filterGlobal($event.target, 'contains')
    }
  }

  loadData() {
    this.isLoading = true;
    this.libraryService.getFunctionalObjectivesForLibraryForm().subscribe({
      next: (objectives) => {
        this.functionalObjcts = objectives;
        this.treeObjectives = this.convertToTreeNodes(objectives);
        this.isLoading = false;
      },
      error: (err) => {
        if (err.error.statusCode && err.error.statusCode != 404) {
          this.toaster.error(err.message, 'Error');
        }
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  openAddPlanForm() {
    this.showActivityDialog = true;
  }

  openEditDialog(objective: FuncObjective, rowNode: TreeNode<FuncObjective>) {
    const ref = this.dialog.open(FunctionalFormDialogComponent, {
      width: 'max-content',
      data: {
        project: this.project,
        objective: objective,
        objList: this.functionalObjcts,
        parent: rowNode.parent,
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
    });
  }
}
