import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-switch-division-fom-dialog',
  templateUrl: './switch-division-fom-dialog.component.html',
  styleUrls: ['./switch-division-fom-dialog.component.scss'],
})
export class SwitchDivisionFomDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SwitchDivisionFomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public userData: any
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  onSubmit(event) {
    this.dialogRef.close();
  }
}
