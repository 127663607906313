import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { MonthlyUpdate, Plan, Project } from '../../../models/plans';
import { MainState } from '../../../redux';
import { deleteMonthlyUpdate } from '../../../redux/plan';
import { AuthService, MonthlyUpdateService } from '../../../utils/services';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-monthly-update-list',
  templateUrl: './monthly-update-list.component.html',
  styleUrls: ['./monthly-update-list.component.scss'],
})
export class MonthlyUpdateListComponent implements OnInit {
  public isLoading = false;
  public monthlyUpdates: MonthlyUpdate[];
  public plan: Plan;
  public showActivityDialog = false;
  public statuses: string[] = ['Draft', 'Approved', 'Rejected'];
  @ViewChild(Table)
  private table: Table;

  constructor(
    private monthlyUpdateService: MonthlyUpdateService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService,
    private router: Router,
    private authService: AuthService
  ) {}

  private _project: Project;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Project) {
    if (!project || !project.id) {
      return;
    }
    this._project = project;
    this.plan = project.plan;
    this.loadData();
  }

  deleteUpdate($event: Event, update: MonthlyUpdate) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this update?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteMonthlyUpdate({ monthlyUpdateId: update.id }));
      },
      reject: () => {},
    });
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      //dt1.filterGlobal($event.target, 'contains')
    }
  }

  getMonthChoices() {
    return monthNames;
  }

  getMonthString(monthNumber: number) {
    return monthNames[monthNumber];
  }

  removeTags(text: string) {
    return text.replace(/(<([^>]+)>)/gi, '');
  }

  loadData() {
    this.isLoading = true;
    this.monthlyUpdateService.getMonthlyUpdatesByProjectId(this.project.id, false).subscribe({
      next: (updates) => {
        this.monthlyUpdates = updates.map((activity) => {
          activity.reportingMonthString = this.getMonthString(activity.reportingMonth);
          return activity;
        });
        this.isLoading = false;
      },
      error: (err) => {
        if (err.error.statusCode && err.error.statusCode != 404) {
          this.toaster.error(err.message, 'Error');
        }
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  isWithinValidDate() {
    const config = this.authService.getMonthylyUpdatesConfig();
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const validDate = config?.jsonValue?.dayOfMonthClose + config?.jsonValue?.extensionDays;
    if (this.project?.projectType == 'MAIN_PROJECT') {
      return currentDay <= validDate;
    }
    return true;
  }

  ngOnInit(): void {
    if (!this.project || !this.project.id) {
      return;
    }
  }

  openAddPlanForm() {
    this.router.navigate(['/projects', this.project.id, 'add-monthly-update']);
  }

  openEditDialog(monthlyUpdate: MonthlyUpdate) {
    this.router.navigate(['/projects', this.project.id, 'edit-monthly-update', monthlyUpdate.id]);
  }
}
