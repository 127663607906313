import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { concat } from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DivisionUnit, User } from '../../models';
import { MainPageComponent } from '../../pages';
import { logoutAction, SidebarComponentStore } from '../../redux';
import { backInDownAndBackOutDown, fadeInAndFadeOut } from '../../utils/animations';
import { AuthService, LibraryService } from '../../utils/services';
import { PlanFormDialogComponent } from '../plans';
import { SwitchDivisionFomDialogComponent } from '../users/switch-division-fom-dialog/switch-division-fom-dialog.component';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  animations: [fadeInAndFadeOut, backInDownAndBackOutDown],
  providers: [DialogService],
})
export class MainHeaderComponent implements OnInit {
  public avatarMenuItems: MenuItem[];
  public burgerMenuItems: MenuItem[];
  public isDark = false;
  public items: MenuItem[];
  public journals: MenuItem[];
  public libraries: MenuItem[];
  public reports: MenuItem[];
  public user: User;
  public userDivision: DivisionUnit;
  public userMenuItems: MenuItem[];

  constructor(
    private sidebarStore: SidebarComponentStore,
    private library: FaIconLibrary,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private authStore: Store,
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private libraryService: LibraryService,
    public app: MainPageComponent,
    public switchDivisionDialog: MatDialog
  ) {
    library.addIconPacks(fas, far);
    this.user = this.authService.getUserObject();
    this.libraryService.getDivisionUnitById(this.user.divisionId).subscribe({
      next: (division) => {
        this.userDivision = division;
      },
      error: (err) => {
        console.error(err);
      },
    });
    this.burgerMenuItems = [
      {
        label: 'WFP',
        icon: 'pi pi-fw pi-database',
        items: [
          {
            label: 'New',
            icon: 'pi pi-fw pi-plus',
            command: ($event) => {
              const dialogRef = this.dialogService.open(PlanFormDialogComponent, {
                header: 'Add a Plan',
                width: 'clamp(250px, 50%, 700px)',
                height: 'clamp(250px, 50%, 400px)',
              });
              dialogRef.onClose.subscribe({
                next: (result) => {
                  console.log(result);
                },
              });
            },
          },
          {
            label: 'List',
            icon: 'pi pi-fw pi-list',
            routerLink: '/plans/list',
          },
        ],
      },
      {
        label: 'Journals',
        icon: 'pi pi-fw pi-file',
        items: [
          { label: 'JournalMenu1', icon: 'pi pi-fw pi-book' },
          { label: 'JournalMenu2', icon: 'pi pi-fw pi-book' },
        ],
      },
      {
        label: 'Libraries',
        icon: 'pi pi-fw pi-book',
      },
      {
        label: 'Reports',
        icon: 'pi pi-fw pi-file',
        items: [
          {
            label: 'WFP',
            icon: 'pi pi-fw pi-file',
          },
        ],
      },
      {
        label: 'Users',
        icon: 'pi pi-fw pi-users',
        items: [
          {
            label: 'User List',
            icon: 'pi pi-fw pi-users',
            routerLink: '/users/list',
          },
          {
            label: 'Roles List',
            icon: 'pi pi-fw pi-user',
            routerLink: '/users/roles',
          },
        ],
      },
    ];
    this.items = concat([], this.burgerMenuItems[0]);
    this.journals = concat([], this.burgerMenuItems[1].items);
    this.libraries = concat([], this.burgerMenuItems[2]);
    this.reports = concat([], this.burgerMenuItems[3].items);
    this.userMenuItems = concat([], this.burgerMenuItems[4].items);

    this.avatarMenuItems = [
      {
        label: 'Sign out',
        icon: 'pi pi-power-off',
        command: (event) => {
          this.logout();
        },
      },
    ];
  }

  public logout() {
    this.authStore.dispatch(logoutAction());
  }

  public ngOnInit(): void {}

  public toggleDark() {
    this.isDark = !this.isDark;
    this.document.documentElement.classList.toggle('dark');
  }

  public toggleMenu() {
    this.sidebarStore.toggleMenu();
    // this.authStore.dispatch(toggleMenu());
  }

  public openSwitchDivisionMenu() {
    const ref = this.switchDivisionDialog.open(SwitchDivisionFomDialogComponent, {
      height: '60%',
      width: '120ch',
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
    });
  }
}
