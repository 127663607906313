import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-up-notif-page',
  templateUrl: './sign-up-notif-page.component.html',
  styleUrls: ['./sign-up-notif-page.component.scss'],
})
export class SignUpNotifPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
