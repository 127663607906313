<div class="input-container mb-5">
  <label [for]="inputId" class="font-bold mb-1 text-gray-700 dark:text-gray-200 block">{{label}}</label>
  <ng-content select="[error]"></ng-content>

  <div class="input-icon-container bg-white dark:bg-gray-700 focus:outline-none focus:shadow-outline"
    [ngClass]="{'error': (inputControl.touched || inputControl.dirty) && inputControl.invalid}">
    <ng-content select="[before]"></ng-content>
    <ng-container *ngIf="type == 'number'">
      <input [type]="type" [formControl]="inputControl" [id]="inputId" [autofocus]="autofocus"
        class="text-input bg-white dark:bg-gray-700 dark:text-white w-full rounded-lg font-medium px-4 py-3 outline-none"
        [placeholder]="placeHolder" [max]="max" [min]="min" [step]="step">
    </ng-container>
    <ng-container *ngIf="type != 'number'">
      <input [type]="type" [formControl]="inputControl" [id]="inputId" [autofocus]="autofocus"
        class="text-input bg-white dark:bg-gray-700 dark:text-white w-full rounded-lg font-medium px-4 py-3 outline-none"
        [placeholder]="placeHolder">
    </ng-container>

    <fa-icon title="Required" class="text-red-500"
      *ngIf="(inputControl.touched || inputControl.dirty) && inputControl.invalid"
      [icon]="['fas', 'exclamation-circle']">
    </fa-icon>

    <ng-content select="[after]"></ng-content>
  </div>
</div>