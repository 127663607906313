import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
  @Input()
  public matAutocomplete: MatAutocomplete;
  @Input()
  public type = 'text';
  @Input()
  public inputId: string;
  @Input()
  public label = '';
  @Input()
  public step = 1;
  @Input()
  public min: number = Number.MIN_SAFE_INTEGER;
  @Input()
  public max: number = Number.MAX_SAFE_INTEGER;
  @Input()
  public placeHolder = '';
  @Input()
  public autofocus = false;
  @Input()
  public isPasswordVisible = false;

  public _inputControl: FormControl | AbstractControl;

  public get inputControl(): FormControl {
    return this._inputControl as FormControl;
  }

  @Input()
  public set inputControl(control: AbstractControl) {
    this._inputControl = control;
  }

  ngOnInit(): void {}
}
