import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../app-config.service';
import { AppConfigKeyValue } from '../../models/app-settings';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  private wfpApi: string;
  private baseUrl: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
      this.baseUrl = `${this.wfpApi}/app-configs`;
    } else {
      this.wfpApi = environment.wfpApi;
      this.baseUrl = `${this.wfpApi}/app-configs`;
    }
  }

  createConfig(appConfigKeyValue: AppConfigKeyValue) {
    const url = this.baseUrl;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<AppConfigKeyValue>(url, appConfigKeyValue, { headers });
  }

  deleteConfig(configId: number) {
    const url = `${this.baseUrl}/${configId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<any>(url, { headers });
  }

  getConfigById(id: string) {
    const url = `${this.baseUrl}/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<AppConfigKeyValue>(url, { headers });
  }

  getConfigByKey(key: string) {
    const url = `${this.baseUrl}/by-key/${key}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<AppConfigKeyValue>(url, { headers });
  }

  getConfigs() {
    const url = this.baseUrl;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<AppConfigKeyValue[]>(url, { headers });
  }

  updateConfig(config: AppConfigKeyValue) {
    const url = this.baseUrl;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<AppConfigKeyValue>(url, config, { headers });
  }
}
