import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { PPMR, Project } from '../../../models/plans';
import { addPpmr, deletePlan, updatePpmr } from '../../../redux/plan';
import { userHasPermission } from '../../../utils/functions/has-permission';

@Component({
  selector: 'app-ppmr-form',
  templateUrl: './ppmr-form.component.html',
  styleUrls: ['./ppmr-form.component.scss'],
})
export class PpmrFormComponent implements OnInit {
  public form: FormGroup;
  public isLoading = false;
  public monitoredChoices = [
    { name: 'Ahead of Time', code: 'Ahead of time' },
    { name: 'On Time', code: 'Ahead on time' },
    { name: 'Delayed', code: 'Delayed' },
    { name: 'Not Implemented', code: 'Not yet implemented' },
  ];
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  @Input()
  public showDeleteButton = false;

  constructor(private fb: FormBuilder, private store: Store, private toaster: ToastrService) {
    this.form = this.fb.group({
      actualCostOfBudget: [{ value: 0, disabled: !this.canEdit() }],
      actionPlanVariance: [{ value: '', disabled: !this.canEdit() }],
      actualOutput: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      expectedOutput: [{ value: '', disabled: !this.canEdit() }],
      timeline: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      participantsCount: [{ value: 0, disabled: !this.canEdit() }],
      varianceReason: [{ value: '', disabled: !this.canEdit() }],
    });
  }

  private _ppmr: PPMR;

  get ppmr() {
    return this._ppmr;
  }

  @Input()
  set ppmr(ppmr: PPMR) {
    this._ppmr = ppmr;
    if (ppmr?.id) {
      this.form.patchValue({
        ...this._ppmr,
      });
    }
  }

  private _project: Project;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Project) {
    this._project = project;
  }

  canEdit() {
    return userHasPermission(['plan:editAny']);
  }

  deletePlan() {
    this.store.dispatch(deletePlan({ planId: this.project.id }));
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  getFormControlErrors(name: string) {
    return this.getFormControl(name).errors as ReadonlyMap<any, any>;
  }

  isFormControlInvalid(name: string) {
    return (this.getFormControl(name).touched || this.getFormControl(name).dirty) && this.getFormControl(name).invalid;
  }

  isMonitoredOptionSelected(option: any) {
    if (this.getFormControl('monitored').valid) {
      return this.getFormControl('monitored').value == option.code;
    }

    return false;
  }

  ngOnInit(): void {}

  resetForm() {
    this.form.patchValue({
      accomplishments: '',
      actionPlanVariance: '',
      actions: '',
      actualCostOfBudget: 0,
      conditions: '',
      dateImplemented: '',
      milestones: '',
      monitored: '',
      monitoringRemarks: '',
      ofi: '',
      participantsCount: '',
      percentage: 0,
      remarks: '',
      reportingMonth: 0,
      reportingYear: 0,
      status: '',
      varianceReason: '',
    });
  }

  submit() {
    this.form.markAsTouched();
    this.form.markAsDirty();
    if (this.form.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }
    this.isLoading = true;
    const ppmr: PPMR = Object.assign(this._ppmr ?? {}, this.form.value);
    ppmr.project = this.project;
    if (ppmr.id) {
      this.store.dispatch(updatePpmr({ ppmr: ppmr }));
    } else {
      this.store.dispatch(addPpmr({ ppmr: ppmr }));
    }
    return true;
  }
}
