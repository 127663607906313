<div class='overflow-visible' mat-dialog-content>
  <app-journal-form
    #form
    (onSubmit)='onSubmit($event)'
    [division]='division'
    [project]='project'
  ></app-journal-form>
</div>

<div class='flex gap-2' mat-dialog-actions>
  <p-button
    (click)='form.resetForm(); dialogRef.close()'
    icon='pi pi-cross'
    label='Cancel'
    styleClass='p-button-outlined p-button-danger'
  ></p-button>
  <p-button (click)='form.submit()' icon='pi pi-check' label='Save'></p-button>
</div>