import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { userHasNoPermission } from '../functions/has-permission';

@Directive({
  selector: '[hasNoPermissions]',
})
export class HasNoPermissionDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input() set hasNoPermissions(permissions: string[]) {
    // const user: User = JSON.parse(localStorage.getItem('admin_user_object'));
    // const hasPermission = user.permissions.some((permission) => permissions.includes(permission));
    const noPermission = userHasNoPermission(permissions);
    if (noPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
