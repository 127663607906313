import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelpPageComponent } from 'src/app/pages/plans/help-page/help-page.component';
import {
  CategoryListComponent,
  FunctionalObjectivesListComponent,
  JournalDivisionUnitListComponent,
  TlmoListComponent
} from './components/libraries';
import {
  CreateNewPasswordForgottenPageComponent,
  DashboardPageComponent,
  EditMonthlyUpdatePageComponent,
  EmailVerifiedPageComponent,
  ForgotPasswordPageComponent,
  LoginPageComponent,
  LogoutPageComponent,
  MainPageComponent,
  NotFoundPageComponent,
  PlansListPageComponent,
  PlanViewPageComponent,
  ProgramsreportPageComponent,
  ProjectViewPageComponent,
  SignUpNotifPageComponent,
  StepFormSampleComponent,
  UserListPageComponent,
  UserProfileEditPageComponent
} from './pages';
import { AppSettingsPageComponent } from './pages/app/app-settings-page/app-settings-page.component';
import { AddMonthlyUpdatePageComponent } from './pages/plans/add-monthly-update-page/add-monthly-update-page.component';
import { AddPpmrPageComponent } from './pages/plans/add-ppmr-page/add-ppmr-page.component';
import { AddProjectPageComponent } from './pages/plans/add-project-page/add-project-page.component';
import { EditPpmrPageComponent } from './pages/plans/edit-ppmr-page/edit-ppmr-page.component';
import { EditProjectPageComponent } from './pages/plans/edit-project-page/edit-project-page.component';
import { ProjectViewReadonlyPageComponent } from './pages/plans/project-view-readonly-page/project-view-readonly-page.component';
import { MIPSReportPageComponent } from './pages/reports/mipsreport-page/mipsreport-page.component';
import { MonthlyreportPageComponent } from './pages/reports/monthlyreport-page/monthlyreport-page.component';
import { QPMRreportPageComponent } from './pages/reports/qpmrreport-page/qpmrreport-page.component';
import { WFPReportPageComponent } from './pages/reports/wfpreport-page/wfpreport-page.component';
import { AddUserPageComponent, EditUserPageComponent, RoleListPageComponent } from './pages/users';
import { AuthGuard, NonAuthGuard, UserHasPermissionGuard } from './utils/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    component: MainPageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'MainPageComponent',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/plans/list',
      },
      {
        path: 'settings',
        component: AppSettingsPageComponent,
        canActivate: [AuthGuard, UserHasPermissionGuard],
        data: {
          animation: 'AppSettingsPageComponent',
          permissions: ['library:editSettings'],
        },
      },
      {
        path: 'users',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/users/list',
          },
          {
            path: 'verified',
            component: EmailVerifiedPageComponent,
            canActivate: [NonAuthGuard],
            data: {
              animation: 'EmailVerifiedPageComponent',
            },
          },
          {
            path: 'roles',
            component: RoleListPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'RoleListPageComponent',
            },
          },
          {
            path: 'list',
            component: UserListPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'UserListPageComponent',
            },
          },
          {
            path: 'profile',
            component: UserProfileEditPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'UserProfileEditPageComponent',
            },
          },
          {
            path: 'add',
            component: AddUserPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'AddUserPageComponent',
            },
          },
          {
            path: 'edit/:id',
            component: EditUserPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'EditUserPageComponent',
            },
          },
        ],
      },
      {
        path: 'plans',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/plans/list',
          },
          {
            path: 'list',
            component: PlansListPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'PlansListPageComponent',
            },
          },
          {
            path: ':planId',
            component: PlanViewPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'PlanViewPageComponent',
            },
          },
          {
            path: ':planId/add-project',
            component: AddProjectPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'AddProjectPageComponent',
            },
          },
          {
            path: ':planId/edit-project/:projectId',
            component: EditProjectPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'EditProjectPageComponent',
            },
          },
        ],
      },
      {
        path: 'projects',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/projects/list',
          },
          {
            path: 'list',
            component: PlansListPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'PlansListPageComponent',
            },
          },
          {
            path: ':projectId/add-monthly-update',
            component: AddMonthlyUpdatePageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'AddMonthlyUpdatePageComponent',
            },
          },
          {
            path: ':projectId/view',
            component: ProjectViewReadonlyPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'ProjectViewReadonlyPageComponent',
            },
          },
          {
            path: ':projectId/add-mip',
            component: AddPpmrPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'AddPpmrPageComponent',
            },
          },
          {
            path: ':projectId/edit-mip/:ppmrId',
            component: EditPpmrPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'EditPpmrPageComponent',
            },
          },
          {
            path: ':projectId/edit-monthly-update/:monthlyUpdateId',
            component: EditMonthlyUpdatePageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'EditMonthlyUpdatePageComponent',
            },
          },
          {
            path: ':projectId',
            component: ProjectViewPageComponent,
            canActivate: [AuthGuard],
            data: {
              animation: 'ProjectViewPageComponent',
            },
          },
        ],
      },
      {
        path: 'libraries',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/libraries/list',
          },
          {
            path: 'TLMOList',
            component: TlmoListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'categoryList',
            component: CategoryListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'funcObjList',
            component: FunctionalObjectivesListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'journalDivList',
            component: JournalDivisionUnitListComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'reports',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/reports/dashboard',
          },
          {
            path: 'dashboard',
            component: DashboardPageComponent,
            canActivate: [AuthGuard, UserHasPermissionGuard],
            data: {
              animation: 'DashboardPageComponent',
              permissions: ['library:editSettings'],
            },
          },
          {
            path: 'wfp',
            component: WFPReportPageComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'monthly',
            component: MonthlyreportPageComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'programs',
            component: ProgramsreportPageComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'mips',
            component: MIPSReportPageComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'qpmr',
            component: QPMRreportPageComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'help',
        component: HelpPageComponent,
      },
    ],
  },
  {
    path: 'auth',
    component: LoginPageComponent,
    children: [
      {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [NonAuthGuard],
        data: {
          animation: 'LoginPageComponent',
        },
      },
      {
        path: 'logout',
        component: LogoutPageComponent,
        canActivate: [AuthGuard],
        data: {
          animation: 'LogoutPageComponent',
        },
      },
    ],
  },
  {
    path: 'forgot-password',
    canActivate: [NonAuthGuard],
    component: ForgotPasswordPageComponent,
    data: {
      animation: 'ForgotPasswordPageComponent',
    },
  },
  {
    path: 'forgot-password/verify',
    canActivate: [NonAuthGuard],
    component: CreateNewPasswordForgottenPageComponent,
    data: {
      animation: 'CreateNewPasswordForgottenPageComponent',
    },
  },
  {
    path: 'step-form-sample',
    canActivate: [NonAuthGuard],
    component: StepFormSampleComponent,
    data: {
      animation: 'StepFormSampleComponent',
    },
  },
  {
    path: 'sign-up-notif',
    canActivate: [NonAuthGuard],
    component: SignUpNotifPageComponent,
    data: {
      animation: 'SignUpNotifPageComponent',
    },
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    data: {
      animation: 'NotFoundPageComponent',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
