import { createAction, props } from '@ngrx/store';
import { User } from '../../models/user';

export const loginUser = createAction('[User] log in user', props<{ username?: string; password?: string }>());
export const loggedinUser = createAction(
  '[User] logged in user',
  props<{ authToken?: string; refreshToken?: string }>()
);
export const loadedUser = createAction('[User] loaded in user', props<{ user?: User }>());
export const loadUsers = createAction('[User] load Users', props<{ realm?: string }>());
export const loadedUsers = createAction('[User] loaded Users', props<{ users?: User[] }>());
export const addUser = createAction('[User] add User', props<{ user: User }>());
export const addedUser = createAction('[User] added User');
export const updateUser = createAction('[User] updateUser', props<{ user: User }>());
export const generateTempPassword = createAction('[User] generateTempPassword', props<{ user: User }>());
export const updatedUser = createAction('[User] updatedUser');
export const deleteUser = createAction('[User] deleteUser', props<{ user: User }>());
export const deletedUser = createAction('[User] deletedUser');
