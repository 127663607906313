<div class='mb-4'>
  <h1 class='mb-0'>Application Settings</h1>
</div>
<div class='bg-white rounded-md p-8 shadow-md'>
  <h2>Monthly Update Lock</h2>
  <p>Entries to monthly updates for the previous month can only be added until <strong>{{getMonthlyUpdateDateLimit()}} </strong> of the
    current month.</p>

  <div class='mb-4'>
    Extend deadline for:
    <div class='flex items-center gap-4'>
      <p-dropdown
        [formControl]="getFormControl('extensionDays')"
        [options]='extensionOptions'
        inputId='extensionDays'
        label='Extension Days'
        emptyMessage='Zero'
        emptyFilterMessage='Zero'
        scrollHeight='300px'
        styleClass='rounded-none'
      >

      </p-dropdown>
      <label class='block font-raleway font-bold'> Days</label>
    </div>
  </div>

  <p>The new deadline is now every <strong>{{getNewDeadline()}}</strong> of the
    current month.</p>

  <div class="buttons flex w-full gap-4">
    <app-button
      (click)="submit()"
      [block]="isLoading"
      [disabled]="isLoading"
      [loading]="isLoading"
      text="Save"
      [type]="'button'"
      class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md"
    >
    </app-button>
  </div>
</div>

