import { createReducer, on } from '@ngrx/store';
import {
  clearInvoices,
  clearNextOrderStatuses,
  loadedInvoices,
  loadedNextOrderStatuses,
} from './ops.actions';
import { OpsState } from './ops.state';

export const initialOpsState: OpsState = {
  invoices: [],
  invoiceStatus: '',
  nextOrderStatuses: [],
};

const _opsReducer = createReducer(
  initialOpsState,
  on(loadedInvoices, (state, action) => {
    return {
      ...state,
      invoices: action.invoices,
      invoiceStatus: action.invoiceStatus,
    };
  }),
  on(clearInvoices, (state, action) => {
    return {
      ...state,
      invoices: [],
      invoiceStatus: '',
    };
  }),
  on(loadedNextOrderStatuses, (state, action) => {
    return { ...state, nextOrderStatuses: action.nextStatuses };
  }),
  on(clearNextOrderStatuses, (state, action) => {
    return {
      ...state,
      nextOrderStatuses: [],
    };
  })
);

export function opsReducer(state: OpsState | undefined, action: any) {
  return _opsReducer(state, action);
}
