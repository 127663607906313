<div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold'>Remarks *</label>
      <textarea [cols]='30' [formControl]="getFormControl('remark')" [rows]='5'
                pInputTextarea></textarea>
      <ul
        *ngIf="
          (getFormControl('remark').touched || getFormControl('remark').dirty) &&
          getFormControl('remark').invalid
        "
      >
        <ng-container *ngFor="let err of getFormControl('remark')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a remark</li>
        </ng-container>
      </ul>
    </span>
</div>