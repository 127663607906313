import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';
import { environment } from 'src/environments/environment';
import { QuarterlyUpdate } from '../../models/plans';
import { IS_SPINNER_ENABLED } from '../constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
  deps: [AuthService],
})
export class QuarterlyUpdateService {
  private wfpApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
    } else {
      this.wfpApi = environment.wfpApi;
    }
  }

  public addQuarterlyUpdate(monthlyUpdate: QuarterlyUpdate) {
    const url = `${this.wfpApi}/quarterly-updates`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<QuarterlyUpdate>(url, monthlyUpdate, {
      headers,
    });
  }

  public deleteQuarterlyUpdate(id: number) {
    const url = `${this.wfpApi}/quarterly-updates/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<any>(url, { headers });
  }

  public getQuarterlyUpdateById(id: number) {
    const url = `${this.wfpApi}/quarterly-updates/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<QuarterlyUpdate>(url, { headers });
  }

  public getQuarterlyUpdatesByProjectId(projectId: number, showSpinner = false) {
    const url = `${this.wfpApi}/quarterly-updates?projectId=${projectId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    const context = new HttpContext().set(IS_SPINNER_ENABLED, showSpinner);
    return this.httpClient.get<QuarterlyUpdate[]>(url, { headers, context });
  }

  public updateQuarterlyUpdate(monthlyUpdate: QuarterlyUpdate) {
    const url = `${this.wfpApi}/quarterly-updates`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<QuarterlyUpdate>(url, monthlyUpdate, {
      headers,
    });
  }
}
