import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService, RoleService, UserService } from '../../../utils/services';
import { Role } from '../../../models';
import { assignIn } from 'lodash-es';
import { refreshPage } from '../../../redux';
import { UserPermissionsFormComponent } from '../user-permissions-form/user-permissions-form.component';

@Component({
  selector: 'app-user-role-form',
  templateUrl: './user-role-form.component.html',
  styleUrls: ['./user-role-form.component.scss'],
})
export class UserRoleFormComponent implements OnInit {
  public form: FormGroup;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  public roles: Role[];
  @Input()
  public showDeleteButton = false;
  @ViewChild(UserPermissionsFormComponent)
  public userPermissionsFormComponent: UserPermissionsFormComponent;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private authService: AuthService,
    private userService: UserService,
    private roleService: RoleService,
    private toastr: ToastrService,
  ) {
    this.getAccountForm();
  }

  private _role: Role;

  get role() {
    return this._role;
  }

  @Input()
  set role(role: Role) {
    this._role = role;
    if (role && role.id) {
      this.form.patchValue(role);
    }
  }

  getAccountForm() {
    if (!this.form) {
      this.form = this.fb.group(
        {
          roleName: ['', [Validators.required]],
          title: ['', [Validators.required]],
        },
        {
          updateOn: 'blur',
        },
      );

      if (this.role && this.role.id) {
        this.form.patchValue({
          roleName: this.role.roleName,
          title: this.role.title,
        });
      }
    }
    return this.form;
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  ngOnInit(): void {
    this.roleService.getRoles().subscribe({
      next: (roles) => {
        this.roles = roles;
      },
      error: (err) => {
        this.toastr.error(err.message, 'Error');
        console.error(err);
      },
    });
  }

  submitForm() {
    this.form.markAllAsTouched();
    this.form.markAsDirty();
    if (!this.form.valid) {
      this.toastr.error('Check if all inputs are valid', 'Invalid Form');
    }

    const role: Role = assignIn(this._role, this.form.value);
    role.active = true;
    if (role && role.id) {
      if (this.userPermissionsFormComponent) {
        role.permissions = this.userPermissionsFormComponent.getSelectedPermissions();
      }
      this.roleService.updateRole(role).subscribe({
        next: () => {
          this.store.dispatch(refreshPage());
        },
        error: (err) => {
          this.toastr.error(err.message, 'Update Error');
          console.error(err);
        },
      });
    } else {
      this.roleService.addRole(role).subscribe({
        next: () => {
          this.store.dispatch(refreshPage());
        },
        error: (err) => {
          this.toastr.error(err.message, 'Add Error');
          console.error(err);
        },
      });
    }
  }

  private checkUsernameIfExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.userService.checkUsernameIfExists(control.value).pipe(
        map((isUsernameExists) => {
          if (isUsernameExists) {
            return { isUsernameExists };
          }
          return null;
        }),
        catchError((err: Error) => {
          console.error(err);
          return null;
        }),
      );
    };
  }
}
