<div class='flex flex-row items-center overflow-hidden'>
  <!--    <div class="h-screen w-1/2 overflow-hidden hidden md:inline-block object-cover">-->
  <!--        <img class="h-full w-full object-cover shadow-md" src="/assets/img/login-bg.jpg" />-->
  <!--    </div>-->
  <div
    class='h-screen w-full p-4 md:p-10 xl:p-52 flex flex-col justify-center items-center bg-blue-400 bg-gradient-to-bl from-blue-400 to-blue-700'>
    <div class='content w-full max-w-3xl shadow-md bg-white p-4 md:p-10 rounded-xl'>
      <div class='login-logo mb-4 md:mb-8 flex justify-center'>
        <a [routerLink]="['/']" class='flex flex-col items-center h-max'>
          <img src='/assets/img/ddb_logo.png' class='block w-1/3 aspect-square' />
          <h1 class='text-lg xs:text-xl md:text-3xl mt-0'>PIMS Log In</h1>
        </a>
      </div>

      <!-- /.login-logo -->
      <div>
        <div class='card-body login-card-body'>
          <p class='mb-4 text-sm'>Sign in to start your session</p>
          <p *ngIf='message' class='bg-red-500 text-white py-2 px-4 mb-4 rounded-md'>{{message}}</p>
          <form (ngSubmit)='login()' [formGroup]='loginForm'>
            <label class='text-gray-600 inline-block mb-1' for='username'>Username</label>
            <div
              class='input-group shadow focus-within:border-blue-400 focus-within:shadow-md-blue-500 border-gray-300 rounded-md p-2 border flex w-full'>
              <div class='inline-block grayscale-0 text-gray-400 mr-2'>
                <fa-icon [icon]="['fas', 'user']"></fa-icon>
              </div>
              <input class='form-control focus:outline-none w-full' formControlName='username' id='username'
                     placeholder='ex. admin' type='text' />
            </div>
            <div *ngIf='!isValidUsername()' @enterAndLeave class='alert'>
              Username is required.
            </div>

            <label class='text-gray-600 md:mt-4 inline-block mb-1' for='password'>Password</label>
            <div
              class='input-group shadow focus-within:border-blue-400 focus-within:shadow-md-blue-500 border-gray-300 rounded-md p-2 border flex'>
              <div class='inline-block grayscale-0 text-gray-400 mr-2'>
                <fa-icon [fixedWidth]='true' [icon]="['fas', 'lock']"></fa-icon>
              </div>
              <input [type]="isPwordVisible ? 'text' : 'password'" class='form-control focus:outline-none w-full'
                     formControlName='password' id='password' placeholder='' />
              <div class='inline-block grayscale-0 text-blue-400 mx-2'>
                <a (click)='$event.preventDefault(); togglePasswordVisibility()' href='#'
                   title='Toggle password visibility'>
                  <fa-icon *ngIf='!isPwordVisible' [fixedWidth]='true' [icon]="['fas', 'eye']">
                  </fa-icon>
                  <fa-icon *ngIf='isPwordVisible' [fixedWidth]='true' [icon]="['fas', 'eye-slash']">
                  </fa-icon>
                </a>
              </div>
            </div>
            <div *ngIf='!isValidPassword()' @enterAndLeave class='alert'>
              <div *ngIf="loginForm.get('password').errors?.required">
                <small>Password is required.</small>
              </div>
            </div>

            <div class='flex flex-col lg:flex-row justify-between mt-4 md:mt-4'>
              <div class='w-3/5 mb-4'>
                <div class='flex items-center'>
                  <input class='checked:bg-blue-600 checked:border-transparent border border-gray-100 h-4 w-4 mr-2'
                         formControlName='rememberMe'
                         id='remember' type='checkbox' />
                  <label for='remember'> Remember Me </label>
                </div>
              </div>
              <!-- /.col -->
<!--              <a [routerLink]="['/forgot-password']" class='hover:underline text-sm'>Forgot password?</a>-->
              <!-- /.col -->
            </div>
            <div class='my-3 flex flex-row items-center'>
              <app-button [block]='true' [disabled]='isAuthLoading' [loading]='isAuthLoading' [text]="'Sign In'"
                          [type]="'submit'"
                          class='bg-blue-700 text-white border-blue-600 hover:bg-blue-600 rounded-md mr-4'>
              </app-button>
              <!-- <a routerLink="/sign-up" class="hover:underline">Register</a> -->
            </div>
          </form>

        </div>
        <!-- /.login-card-body -->
      </div>
    </div>

  </div>
</div>
