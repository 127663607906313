import { Component, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-country-dialog',
  templateUrl: './add-country-dialog.component.html',
  styleUrls: ['./add-country-dialog.component.scss'],
})
export class AddCountryDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddCountryDialogComponent>) {}

  ngOnInit(): void {}

  onSubmit(event) {
    console.log('EVENT: ', event);
    this.dialogRef.close();
  }
}
