<h2>Divisions/Units</h2>
<div class='content-wrapper w-full-no-scroll shadow-md'>
  <p-table
    #dt1
    [globalFilterFields]="['name', 'divisionUnit.name', 'personnelCount']"
    [loading]='isLoading'
    [paginator]='true'
    [rowsPerPageOptions]='[10, 25, 50]'
    [rows]='10'
    [showCurrentPageReport]='true'
    [value]='divisionList'
    class='block w-full-no-scroll'
    currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
    dataKey='id'
    responsiveLayout='scroll'
    styleClass='p-datatable-gridlines w-screen md:w-full flow-root'
  >
    <ng-template pTemplate='caption'>
      <div class='flex flex-col md:flex-row justify-between items-start md:items-center'>
        <ng-container>
          <button
            (click)='openAddPlanForm()'
            *hasPermissions="['library:createAny', 'library:createOwn']"
            class='p-button-outlined'
            icon='pi pi-plus'
            label='Add'
            pButton
          ></button>
        </ng-container>
        <div class='p-d-flex flex justify-between gap-2'>
          <span class='p-input-icon-left p-ml-auto'>
            <i class='pi pi-search'></i>
            <input (input)='filter($event)' pInputText placeholder='Search keyword' type='text' />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate='header'>
      <tr>
        <th pSortableColumn='name'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Name
            <p-columnFilter display='menu' field='name' type='numeric'></p-columnFilter>
            <p-sortIcon field='name'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='personnelCount'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Number Of Personnel
            <p-columnFilter display='menu' field='personnelCount' type='numeric'></p-columnFilter>
            <p-sortIcon field='personnelCount'></p-sortIcon>
          </div>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>

    <ng-template let-expanded='expanded' let-journal pTemplate='body'>
      <tr class='dark:bg-gray-700'>
        <td>
          {{ journal.divisionUnit.name }}
        </td>
        <td>
          {{ journal.personnelCount }}
        </td>
        <td>
          <div class='flex gap-2'>
            <button
              (click)='openEditDialog(journal)'
              *hasPermissions="['library:editAny']"
              class='p-button-rounded p-button-outlined'
              icon='pi pi-pencil'
              pButton
              pRipple
              pTooltip='Edit Activities'
              type='button'
            ></button>
            <button
              (click)='deleteDivision($event, journal)'
              *hasPermissions="['library:deleteAny']"
              class='p-button-rounded p-button-outlined p-button-danger'
              icon='pi pi-times'
              pButton
              pRipple
              pTooltip='Delete Journal Division / Unit'
              type='button'
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template let-commission pTemplate='rowexpansion'>
      <tr class='expanded-tr-logs'>
        <td colspan='9'>
          <div class='details'></div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate='emptymessage'>
      <tr>
        <td colspan='4'>No journal division found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog *ngIf='showActivityDialog'
          [(visible)]='showActivityDialog'
          [blockScroll]='true'
          [breakpoints]="{ '960px': '75vw' }"
          [draggable]='false'
          [modal]='true'
          [resizable]='false'
          [style]="{ width: 'clamp(250px, 120ch, 90vw)', overflowY: 'auto !important', zIndex: '9999' }"
          contentStyleClass='overflow-y-auto lg:overflow-y-visible'
          header='Add Division / Unit'
          styleClass='overflow-y-visible'
>
  <app-journal-form #activityForm [project]='project'></app-journal-form>
  <ng-template pTemplate='footer'>
    <p-button
      (click)='showActivityDialog = false; activityForm.resetForm()'
      icon='pi pi-cross'
      label='Cancel'
      styleClass='p-button-outlined p-button-danger'
    ></p-button>
    <p-button (click)='showActivityDialog = !activityForm.submit()' icon='pi pi-check' label='Save'></p-button>
  </ng-template>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
