import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthService, LibraryService } from '../../../utils/services';
import { ToastrService } from 'ngx-toastr';
import { DivisionUnit, User } from '../../../models';
import { QueryPlanParams, Signatories } from '../../../models/reports/query-params';
import { SignatoriesComponent } from '../signatories/signatories.component';

@Component({
  selector: 'app-wfpform',
  templateUrl: './wfpform.component.html',
  styleUrls: ['./wfpform.component.scss'],
})
export class WFPFormComponent implements OnInit {
  public currentUser: User;
  public divisions: DivisionUnit[];
  public form: FormGroup;
  public planStatusChoices = ['Draft', 'Approved', 'For Revision', 'For Approval', 'Rejected'];
  public projectTypesChoices = [
    {
      name: 'Main Project',
      value: 'MAIN_PROJECT',
    },
    {
      name: 'Supplemental Project',
      value: 'SUPPLEMENTAL_PROJECT',
    },
  ];
  public signatories: Signatories[][] = [[{}]];
  @ViewChild(SignatoriesComponent)
  public signatoriesComponent: SignatoriesComponent;
  public yearChoices: string[] = [];
  @Output()
  private onSubmit = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private libraryService: LibraryService,
    private toaster: ToastrService,
    private authService: AuthService
  ) {
    this.form = this.fb.group({
      divisionId: [{ value: 0, disabled: !this.canEdit() }, [Validators.required]],
      year: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
    });
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    for (let i = nextYear; i >= currentYear - 10; i--) {
      this.yearChoices.push(i.toString());
    }
    this.getFormControl('year').setValue(currentYear.toString());

    this.currentUser = this.authService.getUserObject();
  }

  allSignatoriesFormIsValid() {
    let isSignatoryNull = false;
    isSignatoryNull = this.signatories.some((value) => {
      return value.some((signatory) => {
        return !signatory.name || !signatory.action || !signatory.designation;
      });
    });
    console.log(isSignatoryNull);
    return !isSignatoryNull;
  }

  canEdit() {
    // return userHasPermission(['reports:createAny']);
    return true;
  }

  downloadExcel() {
    this.form.markAsTouched();
    this.form.markAllAsTouched();
    this.signatories = this.signatoriesComponent.signatories;
    if (this.form.valid && this.allSignatoriesFormIsValid()) {
      const query: QueryPlanParams = {
        ...this.form.getRawValue(),
        signers: this.signatories,
        format: 'excel',
      };
      this.onSubmit.emit(query);
    } else {
      this.toaster.error('Check if every input has values', 'Invalid Form');
    }
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  ngOnInit(): void {
    this.libraryService.getDivisionUnits().subscribe({
      next: (divisions) => {
        this.divisions = divisions;
        const selectedDivision = this.divisions.filter((division) => division.id == this.currentUser.divisionId)[0];
        this.getFormControl('divisionId').setValue(selectedDivision.id);
        this.getFormControl('divisionId').disable();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  removeSignatory(index: number) {
    this.signatories = this.signatories.filter((signatory, i) => i != index);
  }

  submit() {
    this.form.markAsTouched();
    this.form.markAllAsTouched();
    this.signatories = this.signatoriesComponent.signatories;
    if (this.form.valid && this.allSignatoriesFormIsValid()) {
      const query: QueryPlanParams = {
        ...this.form.getRawValue(),
        signers: this.signatories,
        format: 'pdf',
      };
      this.onSubmit.emit(query);
    } else {
      this.toaster.error('Check if every input has values', 'Invalid Form');
    }
  }
}
