import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { exhaustMap, tap } from 'rxjs/operators';
import { loginSuccessAction } from '../../redux';
import { enterAndLeaveAnimation } from '../../utils/animations';
import { MONTHLY_UPDATES_LIMITS_KEY, USER_KEY } from '../../utils/constants';
import { AuthService, UserService } from '../../utils/services';
import { AppSettingsService } from '../../utils/services/app-settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  animations: [enterAndLeaveAnimation],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public isAuthLoading = false;
  public isPwordVisible = false;
  public message = '';

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private library: FaIconLibrary,
    private store: Store,
    private userService: UserService,
    private appSettingsService: AppSettingsService
  ) {
    library.addIconPacks(fas);
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.message = params['message'];
    });
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [''],
    });
  }

  togglePasswordVisibility() {
    this.isPwordVisible = !this.isPwordVisible;
  }

  isValidPassword() {
    if (this.loginForm.get('password').touched || this.loginForm.get('password').dirty) {
      return this.loginForm.get('password').valid;
    }
    return true;
  }

  isValidUsername() {
    if (this.loginForm.get('username').touched || this.loginForm.get('username').dirty) {
      return this.loginForm.get('username').valid;
    }
    return true;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  login() {
    this.isAuthLoading = true;
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.authService
        .login({ ...this.loginForm.value, realm: 'INTERNAL' }, this.loginForm.get('rememberMe').value)
        .pipe(
          exhaustMap((response) => {
            return this.userService.getUser().pipe(
              tap((user) => {
                localStorage.setItem(USER_KEY, JSON.stringify(user));
              }),
              exhaustMap((response) => {
                return this.appSettingsService.getConfigByKey(MONTHLY_UPDATES_LIMITS_KEY).pipe(
                  tap((appSettings) => {
                    localStorage.setItem(MONTHLY_UPDATES_LIMITS_KEY, JSON.stringify(appSettings));
                  })
                );
              })
            );
          })
        )
        .subscribe(
          (response) => {
            this.store.dispatch(loginSuccessAction());
            this.router.navigate(['/']);
          },
          (err) => {
            this.isAuthLoading = false;
            this.loginForm.enable();
            console.error(err);
            this.message = err.error;
            this.toastr.error(err.error, 'Error', {
              positionClass: 'toast-top-right',
              // disableTimeOut: true,
            });
          }
        );
    } else {
      this.loginForm.enable();
      this.isAuthLoading = false;
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }
}
