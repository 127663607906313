import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { QueryPlanParams } from '../../../models/reports/query-params';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { ReportService } from '../../../utils/services/report.service';

@Component({
  selector: 'app-monthlyreport-page',
  templateUrl: './monthlyreport-page.component.html',
  styleUrls: ['./monthlyreport-page.component.scss'],
})
export class MonthlyreportPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/reports/wfp',
      label: 'Monthly Report',
    },
  ];

  constructor(private reportService: ReportService, private store: Store<MainState>, private toaster: ToastrService) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
  }

  ngOnInit(): void {}

  openReport(query: QueryPlanParams) {
    let options = { type: 'text/html' };
    if (query.format == 'excel') {
      options.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    this.reportService.openMonthlyReport(query).subscribe({
      next: (result) => {
        const blob = new Blob([result], options);
        const file = URL.createObjectURL(blob);
        window.open(file);
      },
      error: (err) => {
        console.error(err);
        if (err.status == 404) {
          this.toaster.warning('No report was generated', 'Empty report');
        } else {
          this.toaster.warning('An error was encountered during report generation', 'Error');
        }
      },
    });
  }
}
