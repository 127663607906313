import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AuthService, UserService } from '../../utils/services';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
})
export class ForgotPasswordPageComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public isAuthLoading = false;
  public isPwordVisible = false;
  public message = '';
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private library: FaIconLibrary
  ) {
    library.addIconPacks(fas);
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.message = params['message'];
    });
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.required],
    });
  }

  isValidEmail() {
    if (
      this.forgotPasswordForm.get('email').touched ||
      this.forgotPasswordForm.get('email').dirty
    ) {
      return this.forgotPasswordForm.get('email').valid;
    }
    return true;
  }

  submit() {
    this.isAuthLoading = true;
    this.forgotPasswordForm.markAllAsTouched();
    if (this.forgotPasswordForm.valid) {
      this.userService
        .sendForgotPasswordForm(this.forgotPasswordForm.get('email').value)
        .subscribe(
          (response) => {
            this.toastr.success(
              'A link has been sent to your email. Please check',
              'Success',
              {
                positionClass: 'toast-top-right',
                timeOut: 5000,
              }
            );
            this.isAuthLoading = true;
            this.forgotPasswordForm.disable();
            this.router.navigate(['auth', 'login']);
          },
          (err: HttpErrorResponse) => {
            if (err.status >= 200 && err.status < 400) {
              this.toastr.success(
                'A link has been sent to your email. Please check',
                'Success',
                {
                  positionClass: 'toast-top-right',
                  timeOut: 5000,
                }
              );
              this.isAuthLoading = true;
              this.forgotPasswordForm.disable();
              this.router.navigate(['auth', 'login']);
              return;
            }
            this.isAuthLoading = false;
            this.forgotPasswordForm.enable();
            console.error(err);
            this.message = err.error;
            this.toastr.error(err.error, 'Error', {
              positionClass: 'toast-top-right',
            });
          }
        );
    } else {
      this.forgotPasswordForm.enable();
      this.isAuthLoading = false;
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }
}
