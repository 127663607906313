import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { exhaustMap } from 'rxjs/operators';
import { PPMR, Project } from '../../../models/plans';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { ProjectService } from '../../../utils/services';
import { PpmrService } from '../../../utils/services/ppmr.service';

@Component({
  selector: 'app-edit-ppmr-page',
  templateUrl: './edit-ppmr-page.component.html',
  styleUrls: ['./edit-ppmr-page.component.scss'],
})
export class EditPpmrPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/plans',
      label: 'Plans',
    },
  ];
  public ppmr: PPMR = {};
  public ppmrId: number;
  public project: Project = {};

  constructor(
    private projectService: ProjectService,
    private ppmrService: PpmrService,
    private activeRoute: ActivatedRoute,
    private store: Store<MainState>,
    private router: Router
  ) {}

  goBack() {
    this.router.navigate(['/projects', this.project.id]);
  }

  ngOnInit(): void {
    this.activeRoute.params
      .pipe(
        exhaustMap((params) => {
          this.ppmrId = params.ppmrId;
          return this.projectService.getProjectById(params.projectId).pipe(
            exhaustMap((project) => {
              this.project = project;
              this.breadCrumbLinks.push(
                {
                  routerLink: `/plans/${this.project.plan.id}`,
                  label: `Plan - ${this.project.plan.year}`,
                },
                {
                  routerLink: `/projects/${this.project.id}`,
                  label: `Project - ${this.project.title}`,
                },
                {
                  routerLink: `/projects/${this.project.id}/edit-ppmr/${this.ppmrId}`,
                  label: `Edit MIP`,
                }
              );
              this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
              return this.ppmrService.getPpmrById(this.ppmrId);
            })
          );
        })
      )
      .subscribe({
        next: (monthlyUpdate) => {
          this.ppmr = monthlyUpdate;
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
}
