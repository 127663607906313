<div class='flex gap-2 items-center mb-4'>
  <h2 class='mb-0'>{{ currentPlan.year }} Plan</h2>
  <ng-container *ngIf='false'>
    <button
      (click)='editPlan = true'
      *hasPermissions="['plan:editAny']"
      class='p-button-rounded p-button-outlined'
      icon='pi pi-pencil'
      pButton
      pRipple
      pTooltip='Edit plan'
      type='button'
    ></button>
  </ng-container>
  <p-tag *ngIf="currentPlan.status && currentPlan.status != 'Approved'" [value]='currentPlan.status'></p-tag>
  <p-tag
    *ngIf="currentPlan.status && currentPlan.status == 'Approved'"
    [value]='currentPlan.status'
    severity='success'
  ></p-tag>
</div>

<p-message
  *ngIf="currentPlan.status == 'For Revision' && currentPlan.revisionRemarks"
  [text]="'Revision Remarks: ' + currentPlan.revisionRemarks"
  severity='warn'
  styleClass='p-mr-2'
></p-message>

<hr />

<h3 class='mb-4'>Projects</h3>
<div class='content-wrapper shadow-md'>
  <p-table
    #dt1
    [globalFilterFields]="['title', 'objective', 'timeTable', 'budget', 'otherSourceOfFunds', 'status']"
    [loading]='isLoading'
    [paginator]='true'
    [rowsPerPageOptions]='[10, 25, 50]'
    [rows]='10'
    [showCurrentPageReport]='true'
    [value]='mainProjects'
    class='block w-full-no-scroll'
    currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
    dataKey='id'
    responsiveLayout='scroll'
    styleClass='p-datatable-gridlines w-screen md:w-full-no-scroll flow-root'
  >
    <ng-template pTemplate='caption'>
      <div class='flex flex-col md:flex-row justify-between items-start md:items-center'>
        <div class='buttons flex gap-2'>
          <ng-container *ngIf="currentPlan.status == 'Draft' || currentPlan.status == 'For Revision'">
            <button
              *hasPermissions="['project:createAny']"
              [routerLink]="['/plans', currentPlan.id, 'add-project']"
              class='p-button-outlined'
              icon='pi pi-plus'
              label='Add'
              pButton
            ></button>
          </ng-container>
          <ng-container *ngIf="currentPlan.status == 'Draft' || currentPlan.status == 'For Revision'">
            <button
              (click)="submitForApproval('For Approval')"
              *hasPermissions="['plan:editAny']"
              class='p-button-primary'
              icon='pi pi-check'
              label='Submit For Approval'
              pButton
            ></button>
          </ng-container>
          <ng-container *ngIf="currentPlan.status == 'For Approval'">
            <button
              (click)="updatePlanStatus('Approved')"
              *hasPermissions="['plan:approveAny']"
              class='p-button-primary'
              icon='pi pi-check'
              label='Approve'
              pButton
            ></button>
          </ng-container>
          <ng-container *ngIf="currentPlan.status == 'For Approval'">
            <button
              (click)='updatePlanStatusForRevision()'
              *hasPermissions="['plan:approveAny']"
              class='p-button-danger'
              icon='pi pi-check'
              label='For Revision'
              pButton
            ></button>
          </ng-container>
        </div>
        <div class='p-d-flex flex justify-between gap-2'>
          <span class='p-input-icon-left p-ml-auto'>
            <i class='pi pi-search'></i>
            <input (input)='filterMain($event)' pInputText placeholder='Search keyword' type='text' />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate='header'>
      <tr>
        <th pSortableColumn='title'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Project Title
            <p-columnFilter display='menu' field='title' type='text'></p-columnFilter>
            <p-sortIcon field='title'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='objective'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Objective
            <p-columnFilter display='menu' field='objective' type='text'></p-columnFilter>
            <p-sortIcon field='objective'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='timeTable'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Timetable
            <p-columnFilter display='menu' field='timeTable' type='text'></p-columnFilter>
            <p-sortIcon field='timeTable'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='budget'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Budget
            <p-columnFilter display='menu' field='budget' type='numeric'></p-columnFilter>
            <p-sortIcon field='budget'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='otherSourceOfFunds'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Other Source of Funds
            <p-columnFilter display='menu' field='otherSourceOfFunds' type='text'></p-columnFilter>
            <p-sortIcon field='otherSourceOfFunds'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='status'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Status
            <p-columnFilter display='menu' field='status' matchMode='equals'>
              <ng-template let-filter='filterCallback' let-value pTemplate='filter'>
                <p-dropdown
                  (onChange)='filter($event.value)'
                  [filter]='false'
                  [ngModel]='value'
                  [options]='statuses'
                  placeholder='Any'
                >
                  <ng-template let-option pTemplate='item'>
                    <span>{{ option }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            <p-sortIcon field='status'></p-sortIcon>
          </div>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template let-expanded='expanded' let-project pTemplate='body'>
      <tr class='dark:bg-gray-700'>
        <td>
          <div class='button-container flex items-center gap-2'>
            <a *ngIf="currentPlan && currentPlan.status == 'Approved'" [routerLink]="['/projects', project.id]">
              {{ project.title }}
            </a>
            <span *ngIf="currentPlan && currentPlan.status != 'Approved'">
              {{ project.title }}
            </span>
          </div>
        </td>
        <td>
          {{ project.objective }}
        </td>
        <td>
          {{ project.timeTable }}
        </td>
        <td>
          {{ project.budget | number: '1.2-2' }}
        </td>
        <td>
          {{ project.otherSourceOfFunds }}
        </td>
        <td>
          {{ project.status }}
        </td>
        <td>
          <div class='flex gap-2'>
            <button
              *ngIf="currentPlan && currentPlan.status != 'Draft'"
              [routerLink]="['/projects', project.id, 'view']"
              class='p-button-rounded p-button-outlined'
              icon='pi pi-eye'
              pButton
              pRipple
              pTooltip='View project'
              type='button'
            ></button>
            <div *ngIf="currentPlan && (currentPlan.status == 'Draft' || currentPlan.status == 'For Revision') ">
              <button
                *hasPermissions="['project:editAny', 'project:editOwn']"
                [routerLink]="['/plans', currentPlan.id, 'edit-project', project.id]"
                class='p-button-rounded p-button-outlined'
                icon='pi pi-pencil'
                pButton
                pRipple
                pTooltip='Edit Project'
                type='button'
              ></button>
            </div>
            <div *ngIf="currentPlan && (currentPlan.status == 'Draft' || currentPlan.status == 'For Revision')">
              <button
                (click)='deleteProject($event, project)'
                *hasPermissions="['project:deleteAny', 'project:deleteOwn']"
                class='p-button-rounded p-button-outlined p-button-danger'
                icon='pi pi-times'
                pButton
                pRipple
                pTooltip='Delete Project'
                type='button'
              ></button>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template let-commission pTemplate='rowexpansion'>
      <tr class='expanded-tr-logs'>
        <td colspan='3'>
          <div class='details'></div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate='emptymessage'>
      <tr>
        <td colspan='7'>No projects found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<!-- /MAIN PROJECTS -->

<hr />

<h3 *ngIf="currentPlan.status == 'Approved'" class='mb-4'>Supplemental Projects</h3>
<div *ngIf="currentPlan.status == 'Approved'" class='content-wrapper shadow-md'>
  <p-table
    #dt2
    [globalFilterFields]="['title', 'objective', 'timeTable', 'budget', 'otherSourceOfFunds', 'status']"
    [loading]='isLoading'
    [paginator]='true'
    [rowsPerPageOptions]='[10, 25, 50]'
    [rows]='10'
    [showCurrentPageReport]='true'
    [value]='supplementalProjects'
    class='block w-full-no-scroll'
    currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
    dataKey='id'
    responsiveLayout='scroll'
    styleClass='p-datatable-gridlines w-screen md:w-full-no-scroll flow-root'
  >
    <ng-template pTemplate='caption'>
      <div class='flex flex-col md:flex-row justify-between items-start md:items-center'>
        <div class='buttons flex gap-2'>
          <ng-container>
            <button
              *hasPermissions="['plan:createAny']"
              [queryParams]="{type:'SUPPLEMENTAL_PROJECT'}"
              [routerLink]="['/plans', currentPlan.id, 'add-project']"
              class='p-button-outlined'
              icon='pi pi-plus'
              label='Add'
              pButton
            ></button>
          </ng-container>
        </div>
        <div class='p-d-flex flex justify-between gap-2'>
          <span class='p-input-icon-left p-ml-auto'>
            <i class='pi pi-search'></i>
            <input (input)='filterSupplemental($event)' pInputText placeholder='Search keyword' type='text' />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate='header'>
      <tr>
        <th pSortableColumn='title'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Project Title
            <p-columnFilter display='menu' field='title' type='text'></p-columnFilter>
            <p-sortIcon field='title'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='objective'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Objective
            <p-columnFilter display='menu' field='objective' type='text'></p-columnFilter>
            <p-sortIcon field='objective'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='timeTable'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Timetable
            <p-columnFilter display='menu' field='timeTable' type='text'></p-columnFilter>
            <p-sortIcon field='timeTable'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='budget'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Budget
            <p-columnFilter display='menu' field='budget' type='numeric'></p-columnFilter>
            <p-sortIcon field='budget'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='otherSourceOfFunds'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Other Source of Funds
            <p-columnFilter display='menu' field='otherSourceOfFunds' type='text'></p-columnFilter>
            <p-sortIcon field='otherSourceOfFunds'></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn='status'>
          <div class='p-d-flex p-jc-between p-ai-center'>
            Status
            <p-columnFilter display='menu' field='status' matchMode='equals'>
              <ng-template let-filter='filterCallback' let-value pTemplate='filter'>
                <p-dropdown
                  (onChange)='filter($event.value)'
                  [filter]='false'
                  [ngModel]='value'
                  [options]='statuses'
                  placeholder='Any'
                >
                  <ng-template let-option pTemplate='item'>
                    <span>{{ option }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            <p-sortIcon field='status'></p-sortIcon>
          </div>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template let-expanded='expanded' let-project pTemplate='body'>
      <tr class='dark:bg-gray-700'>
        <td>
          <div class='button-container flex items-center gap-2'>
            <a *ngIf="currentPlan && currentPlan.status == 'Approved'" [routerLink]="['/projects', project.id]">
              {{ project.title }}
            </a>
            <span *ngIf="currentPlan && currentPlan.status != 'Approved'">
              {{ project.title }}
            </span>
          </div>
        </td>
        <td>
          {{ project.objective }}
        </td>
        <td>
          {{ project.timeTable }}
        </td>
        <td>
          {{ project.budget | number: '1.2-2' }}
        </td>
        <td>
          {{ project.otherSourceOfFunds }}
        </td>
        <td>
          {{ project.status }}
        </td>
        <td>
          <div class='flex gap-2'>
            <button
              [routerLink]="['/projects', project.id, 'view']"
              class='p-button-rounded p-button-outlined'
              icon='pi pi-eye'
              pButton
              pRipple
              pTooltip='View project'
              type='button'
            ></button>

            <div *ngIf='currentPlan'>
              <button
                *hasPermissions="['project:editAny', 'project:editOwn']"
                [routerLink]="['/plans', currentPlan.id, 'edit-project', project.id]"
                [queryParams]="{type:'SUPPLEMENTAL_PROJECT'}"
                class='p-button-rounded p-button-outlined'
                icon='pi pi-pencil'
                pButton
                pRipple
                pTooltip='Edit Project'
                type='button'
              ></button>
            </div>
            <div *ngIf='currentPlan'>
              <button
                (click)='deleteProject($event, project)'
                *hasPermissions="['project:deleteAny', 'project:deleteOwn']"
                class='p-button-rounded p-button-outlined p-button-danger'
                icon='pi pi-times'
                pButton
                pRipple
                pTooltip='Delete Project'
                type='button'
              ></button>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template let-commission pTemplate='rowexpansion'>
      <tr class='expanded-tr-logs'>
        <td colspan='3'>
          <div class='details'></div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate='emptymessage'>
      <tr>
        <td colspan='7'>No projects found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<!-- /SUPPLEMENTAL PROJECTS -->

<hr />

<h3 class='mb-4'>Remarks</h3>
<app-remarks-list [plan]='currentPlan'></app-remarks-list>

<p-dialog
  *ngIf='showForRevisionDialog'
  [(visible)]='showForRevisionDialog'
  [blockScroll]='true'
  [breakpoints]="{ '960px': '75vw' }"
  [contentStyle]="{ height: 'clamp(500px, 90%, 90%)' }"
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: 'clamp(250px, 50ch, 70vw)' }"
  contentStyleClass=''
  header='For Revision'
  styleClass='overflow-y-auto overflow-x-visible block'
>
  <ng-template pTemplate='content'>
    <label for='revisionRemark'>Remarks:</label>
    <textarea [(ngModel)]='revisionRemarks' class='w-full' id='revisionRemark' pInputTextarea></textarea>
  </ng-template>

  <ng-template pTemplate='footer'>
    <p-button
      (click)='showForRevisionDialog = false'
      icon='pi pi-cross'
      label='Cancel'
      styleClass='p-button-outlined p-button-danger'
    ></p-button>
    <p-button (click)='updatePlanStatusForRevision()' icon='pi pi-check' label='Save'></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  *ngIf='editPlan'
  [(visible)]='editPlan'
  [blockScroll]='true'
  [breakpoints]="{ '960px': '75vw' }"
  [contentStyle]="{ height: 'clamp(500px, 90%, 90%)' }"
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: 'clamp(250px, 120ch, 90vw)' }"
  contentStyleClass=''
  header='Edit Plan'
  styleClass='overflow-y-auto overflow-x-visible block'
>
  <app-plan-form #planForm [plan]='currentPlan'></app-plan-form>

  <ng-template pTemplate='footer'>
    <p-button
      (click)='editPlan = false'
      icon='pi pi-cross'
      label='Cancel'
      styleClass='p-button-outlined p-button-danger'
    ></p-button>
    <p-button (click)='editPlan = !planForm.submit()' icon='pi pi-check' label='Save'></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  *ngIf='showActivityDialog'
  [(visible)]='showActivityDialog'
  [blockScroll]='true'
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: '100%', minHeight: '100vh', maxHeight: '100%' }"
  contentStyleClass='h-full'
  header='Add Project'
  position='center'
  styleClass='overflow-y-auto flex'
>
  <app-project-form #projectForm [plan]='currentPlan' [project]='{}'></app-project-form>

  <ng-template pTemplate='footer'>
    <p-button
      (click)='showActivityDialog = false; projectForm.resetForm()'
      icon='pi pi-cross'
      label='Cancel'
      styleClass='p-button-outlined p-button-danger'
    ></p-button>
    <p-button (click)='showActivityDialog = !projectForm.submit()' icon='pi pi-check' label='Save'></p-button>
  </ng-template>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
