import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { uniqBy } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { Division, DivisionUnit, Role, User } from '../../../models';
import { AuthService, LibraryService, RoleService, UserService } from '../../../utils/services';

@Component({
  selector: 'app-user-account-form',
  templateUrl: './user-account-form.component.html',
  styleUrls: ['./user-account-form.component.scss'],
})
export class UserAccountFormComponent implements OnInit {
  public accountForm: FormGroup;
  public divisions: DivisionUnit[];
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  public roles: Role[] = [];
  public selectedRoles: Role[];
  @Input()
  public showDeleteButton = false;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private authService: AuthService,
    private userService: UserService,
    private roleService: RoleService,
    private toastr: ToastrService,
    private libraryService: LibraryService
  ) {
    this.getAccountForm();
  }

  private _hasPassword: boolean = false;

  get hasPassword() {
    return this._hasPassword;
  }

  @Input()
  set hasPassword(hasPassword: boolean) {
    this._hasPassword = hasPassword;
    console.log('hasPassword:', hasPassword);
    if (hasPassword) {
      this.accountForm.addControl('password', new FormControl('', [Validators.required, Validators.minLength(4)]));
    }
  }

  private _user: User;

  get user() {
    return this._user;
  }

  @Input()
  set user(user: User) {
    this._user = user;
    if (user && user.id) {
      this.accountForm.patchValue({
        username: user.username,
        divisionId: user.divisionId,
        roles: uniqBy(user.roles, 'id'),
      });
    }
  }

  private _userDivision: Division;

  get userDivision() {
    return this._userDivision;
  }

  @Input()
  set userDivision(userDivision: Division) {
    this._userDivision = userDivision;
    if (userDivision && userDivision.id) {
      this.accountForm.patchValue({
        divisionId: userDivision.id,
      });
    }
  }

  getAccountForm() {
    if (!this.accountForm) {
      this.accountForm = this.fb.group(
        {
          username: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_.-]*')], [this.checkUsernameIfExists()]],
          divisionId: [0, [Validators.required]],
          roles: [null],
        },
        {
          updateOn: 'blur',
        }
      );
    }
    return this.accountForm;
  }

  getFormControl(name: string) {
    return this.accountForm.get(name) as FormControl;
  }

  getPasswordControl() {
    return this.accountForm.get('password') as FormControl;
  }

  getUsernameControl() {
    return this.accountForm.get('username') as FormControl;
  }

  ngOnInit(): void {
    this.libraryService
      .getDivisionUnits()
      .pipe(
        exhaustMap((divisions) => {
          this.divisions = divisions;
          return this.roleService.getRoles();
        })
      )
      .subscribe({
        next: (roles) => {
          this.roles = roles;
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  onRolesChange(event: any) {
    this.getFormControl('roles').setValue(event.value);
    console.log(this.accountForm.value);
  }

  private checkUsernameIfExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (this.user && this.user.id) {
        if (this.user.username !== control.value) {
          return this.userService.checkUsernameIfExists(control.value).pipe(
            map((isUsernameExists) => {
              if (isUsernameExists) {
                return { isUsernameExists };
              }
              return null;
            }),
            catchError((err) => {
              return null;
            })
          );
        }
        return of(null);
      }
      return this.userService.checkUsernameIfExists(control.value).pipe(
        map((isUsernameExists) => {
          if (isUsernameExists) {
            return { isUsernameExists };
          }
          return null;
        }),
        catchError((err) => {
          return null;
        })
      );
    };
  }
}
