<div class='main-content mb-4 flex flex-col gap-4'>
  <div [ngClass]='{"cursor-not-allowed": !canEdit()}'>
    <label class='block font-raleway font-bold'>Year:</label>
    <p-dropdown [formControl]='getFormControl("year")' [options]='yearChoices'
                label='Year' styleClass='w-full'></p-dropdown>
  </div>
  <div [ngClass]='{"cursor-not-allowed": !canEdit()}'>
    <label class='block font-raleway font-bold'>Division:</label>
    <p-dropdown [autoDisplayFirst]='false' [formControl]='getFormControl("division")'
                [options]='divisions' label='Division' optionLabel='name'
                styleClass='w-full'></p-dropdown>
  </div>
</div>

<div *ngIf='plan && showButtons' class='buttons flex w-full gap-4'>
  <app-button (click)='submit()' [block]='isLoading' [disabled]='isLoading' [loading]='isLoading'
              [text]="plan && plan.id ? 'Save' : 'Add Plan'"
              [type]="'button'" class='w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md'>
  </app-button>

  <app-button (click)='deletePlan()' *ngIf='plan.id && showDeleteButton' [block]='isLoading'
              [disabled]='isLoading' [loading]='isLoading' [text]="'Delete Plan'"
              [type]="'button'" class='w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md'>
  </app-button>
</div>
