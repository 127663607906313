import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { exhaustMap } from 'rxjs/operators';
import { Project } from '../../../models/plans';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { ProjectService } from '../../../utils/services';

@Component({
  selector: 'app-add-ppmr-page',
  templateUrl: './add-ppmr-page.component.html',
  styleUrls: ['./add-ppmr-page.component.scss'],
})
export class AddPpmrPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/plans',
      label: 'Plans',
    },
  ];
  public project: Project = {};

  constructor(
    private projectService: ProjectService,
    private activeRoute: ActivatedRoute,
    private store: Store<MainState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activeRoute.params
      .pipe(
        exhaustMap((params) => {
          return this.projectService.getProjectById(params.projectId);
        })
      )
      .subscribe({
        next: (project) => {
          this.project = project;
          this.breadCrumbLinks.push(
            {
              routerLink: `/plans/${this.project.plan.id}`,
              label: `Plan - ${this.project.plan.year}`,
            },
            {
              routerLink: `/projects/${this.project.id}`,
              label: `Project - ${this.project.title}`,
            },
            {
              routerLink: `/projects/${this.project.id}/add-monthly-update`,
              label: `Add MIP`,
            }
          );
          this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
  goBack() {
    this.router.navigate(['/projects', this.project.id]);
  }
}
