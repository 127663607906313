import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Division } from './../../../models/library/division';
import { addDivision, deleteDivision, updateDivision } from '../../../redux/library';
import { userHasPermission } from '../../../utils/functions/has-permission';
import { LibraryService, UserService } from '../../../utils/services';
import { DivisionUnit, User } from '../../../models';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-journal-form',
  templateUrl: './journal-form.component.html',
  styleUrls: ['./journal-form.component.scss'],
})
export class JournalFormComponent implements OnInit {
  public divisionUnits: DivisionUnit[];
  public form: FormGroup;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  @Input()
  public showDeleteButton = false;
  public users: User[] = [];
  public yearChoices: string[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private divisionService: LibraryService,
    private toaster: ToastrService,
    private userService: UserService
  ) {
    this.form = this.fb.group({
      name: [{ value: null, disabled: !this.canEdit() }],
      divisionUnit: [{ value: null, disabled: !this.canEdit() }, [Validators.required]],
      chiefId: [{ value: null, disabled: !this.canEdit() }],
      personnelCount: [{ value: null, disabled: !this.canEdit() }, [Validators.required]],
    });
  }

  private _division: Division;

  public get division() {
    return this._division;
  }

  @Input()
  public set division(division: Division) {
    this._division = division;
    this.form.patchValue({
      ...this._division,
    });
  }

  private _project: Division;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Division) {
    this._project = project;
  }

  canEdit() {
    const result = userHasPermission(['library:editAny', 'library:createAny']);
    return result;
  }

  deleteDivision() {
    this.store.dispatch(deleteDivision({ payload: this.project }));
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  isFormControlInvalid(name: string) {
    return (this.getFormControl(name).touched || this.getFormControl(name).dirty) && this.getFormControl(name).invalid;
  }

  ngOnInit(): void {
    this.divisionService.getDivisionUnits().subscribe({
      next: (divisionUnits) => {
        this.divisionUnits = divisionUnits;
      },
      error: (err) => {
        console.error(err);
      },
    });

    this.userService.getUsers('EXTERNAL').subscribe({
      next: (users) => {
        this.users = users;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  onStartMonthSelect(event: any, value: string) {
    console.log(event);
    console.log(value);
  }

  resetForm() {
    this.form.reset({
      name: '',
      divisionUnit: '',
      personnelCount: '',
    });
  }

  submit() {
    this.form.markAsTouched();
    this.form.markAsDirty();
    if (this.form.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }
    this.isLoading = true;
    const newDivision: Division = Object.assign(this._division ?? {}, this.form.value);
    // projectActivity.project = this.project;
    if (newDivision.id) {
      this.store.dispatch(updateDivision({ payload: newDivision }));
    } else {
      this.store.dispatch(addDivision({ payload: newDivision }));
    }
    return true;
  }
}
