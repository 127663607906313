import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DivisionUnit } from '../../../models';
import { FuncObjective } from '../../../models/plans';
import { addObjective, deleteObjective, updateObjective } from '../../../redux';
import { userHasPermission } from '../../../utils/functions/has-permission';
import { ParentForm } from '../../../utils/parent-form';
import { LibraryService } from '../../../utils/services';

type ObjectiveListWithSequenceNumber = FuncObjective & { sequenceTitle?: string };
const maxFuncObjectNameCount = 50;

@Component({
  selector: 'app-functional-form',
  templateUrl: './functional-form.component.html',
  styleUrls: ['./functional-form.component.scss'],
})
export class FunctionalFormComponent extends ParentForm implements OnInit {
  public divisionUnits: DivisionUnit[];
  public isLoading = false;
  public mainForm: FormGroup;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  @Input()
  public showDeleteButton = false;
  public yearChoices: string[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private libraryService: LibraryService,
    private toaster: ToastrService
  ) {
    super();
    this.mainForm = this.fb.group({
      divisionUnit: [{ value: null, disabled: !this.canEdit() }, [Validators.required]],
      name: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      sequenceNumber: [{ value: 0, disabled: !this.canEdit() }],
    });
    this.form = this.mainForm;
  }

  private _objList: ObjectiveListWithSequenceNumber[];

  public get objList() {
    return this._objList;
  }

  @Input()
  public set objList(objList: FuncObjective[]) {
    this._objList = objList.map((obj) => {
      const newObj: ObjectiveListWithSequenceNumber = {
        ...obj,
        sequenceTitle: `(${obj.sequenceNumber}) ${obj.name}`,
      };
      return newObj;
    });
  }

  private _objective: FuncObjective;

  public get objective() {
    return this._objective;
  }

  @Input()
  public set objective(objective: FuncObjective) {
    this._objective = objective;
    this.mainForm.patchValue({
      ...this._objective,
    });
  }

  private _parent: FuncObjective;

  public get parent() {
    return this._parent;
  }

  @Input()
  public set parent(parent: FuncObjective) {
    this._parent = parent;
    if (parent) {
      this.getFormControl('parent').setValue(this._parent);
      this.getFormControl('divisionUnit').setValue(this._parent.divisionUnit);
      this.getFormControl('divisionUnit').disable();
    }
  }

  private _project: FuncObjective;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: FuncObjective) {
    this._project = project;
  }

  canEdit() {
    const result = userHasPermission(['library:editAny', 'library:createAny']);
    return result;
  }

  deleteObjective() {
    this.store.dispatch(deleteObjective({ payload: this.project }));
  }

  ngOnInit(): void {
    this.libraryService.getDivisionUnits().subscribe({
      next: (divisionUnits) => {
        this.divisionUnits = divisionUnits;
      },
      error: (err) => {
        this.toaster.error('Error retrieving division units', 'Error');
        console.error(err);
      },
    });
  }

  onStartMonthSelect(event: any, value: string) {
    console.log(event);
    console.log(value);
  }

  parentSelected(event: Event) {
    const parent: FuncObjective = this.getFormControl('parent').value;
    if (parent) {
      this.getFormControl('divisionUnit').setValue(parent.divisionUnit);
      this.getFormControl('divisionUnit').disable();
    } else {
      this.getFormControl('divisionUnit').setValue(null);
      this.getFormControl('divisionUnit').enable();
    }
  }

  resetForm() {
    this.mainForm.reset({
      description: '',
      name: '',
    });
  }

  submit() {
    this.mainForm.markAsTouched();
    this.mainForm.markAsDirty();
    if (this.mainForm.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }
    this.isLoading = true;
    const newObjective: FuncObjective = Object.assign(this._objective ?? {}, this.mainForm.getRawValue());
    if (newObjective.id) {
      this.store.dispatch(updateObjective({ payload: newObjective }));
    } else {
      this.store.dispatch(addObjective({ payload: newObjective }));
    }
    return true;
  }
}
