import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  animate,
  group,
} from '@angular/animations';

const triggers = `MainPageComponent => *,
                  DashboardPageComponent => *,
                  LoginPageComponent => *,
                  SignupPageComponent => *,
                  NotFoundPageComponent => *,
                  ForgotPasswordPageComponent => *,
                  EmailVerifiedPageComponent => *,
                  SignUpNotifPageComponent => *,
                  UserListPageComponent => *,
                  UserProfileEditPageComponent => *,
                  CreateNewPasswordForgottenPageComponent => *,
                  StepFormSampleComponent => *,
                  ListCountriesComponent => *,
                  ViewCountryComponent => *,
                  ViewStatePageComponent => *,
                  ListOriginsPageComponent => *,
                  LogoutPageComponent => *`;

const duration = '250ms';
const animationFunction = 'ease-in';

export const slideInAnimation = trigger('routeAnimations', [
  transition(triggers, [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [style({ opacity: '0' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [
        animate(`${duration} ${animationFunction}`, style({ opacity: '0' })),
      ]),
      query(':enter', [
        animate(`${duration} ${animationFunction}`, style({ opacity: '100%' })),
      ]),
    ]),
    query(':enter', animateChild()),
  ]),
]);
