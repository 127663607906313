<app-input [inputControl]="getFormControl('title')" inputId="title" label="Role" placeHolder="ex. admin">
  <ng-container error>
    <div
      *ngIf="(getFormControl('title').touched || getFormControl('title').dirty) && getFormControl('title').invalid"
      class="alert"
    >
      <ul>
        <ng-container *ngFor="let err of getFormControl('title')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter role</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>

<app-input [inputControl]="getFormControl('roleName')" inputId="roleName" label="Role Code" placeHolder="ex. admin">
  <ng-container error>
    <div
      *ngIf="
        (getFormControl('roleName').touched || getFormControl('roleName').dirty) && getFormControl('roleName').invalid
      "
      class="alert"
    >
      <ul>
        <ng-container *ngFor="let err of getFormControl('roleName')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter role code</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>

<ng-container *ngIf="role?.id">
  <app-user-permissions-form [role]="role"></app-user-permissions-form>
</ng-container>
