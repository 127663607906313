import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '.';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../app-config.service';
import { Origin } from '../../models';

interface OriginWrapper {
  origin: Origin;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private profileApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (
      this.appConfigService.getConfig() &&
      this.appConfigService.getConfig().profileApi
    ) {
      this.profileApi = this.appConfigService.getConfig().profileApi;
    } else {
      this.profileApi = environment.profileApi;
    }
  }

  getOrigins(): Observable<Origin[]> {
    const username = this.authService.getUsername();
    const url = `${this.profileApi}/origin/list`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<OriginWrapper[]>(url, { headers }).pipe(
      map((originWrappers) => {
        return originWrappers.map((wrapper) => wrapper.origin);
      })
    );
  }

  getOriginById(id: string): Observable<Origin> {
    const url = `${this.profileApi}/origin/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<OriginWrapper>(url, { headers }).pipe(
      map((originWrapper) => {
        return originWrapper.origin;
      })
    );
  }

  getOriginByCode(code: string): Observable<Origin> {
    const url = `${this.profileApi}/origin/byCode/${code}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<OriginWrapper>(url, { headers }).pipe(
      map((originWrapper) => {
        return originWrapper.origin;
      })
    );
  }
}
