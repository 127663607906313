import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  Amount,
  Country,
  Courier,
  RegionalCompany,
  Zone,
} from '../../../models';
import { saveCompany } from '../../../redux/company';
import { AuthService, CountryService } from '../../../utils/services';

@Component({
  selector: 'app-price-form',
  templateUrl: './price-form.component.html',
  styleUrls: ['./price-form.component.scss'],
})
export class PriceFormComponent implements OnInit {
  @Input()
  public company: RegionalCompany;

  @Input()
  public courierIndex = -1;

  @Input()
  public zoneIndex = -1;

  @Input()
  public priceIndex = -1;

  @Input()
  public currentUrl: string;

  @Input()
  public showDeleteButton = false;

  public form: FormGroup;
  public isLoading = false;
  public courier: Courier;
  public zone: Zone;
  public country: Country;
  public price: Amount;
  public currencyCode: string;

  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private countryService: CountryService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.currencyCode = this.authService.getOriginObject().currency.code;
    this.form = this.fb.group({
      weight: [0, [Validators.required, Validators.pattern('[0-9]*')]],
      price: [0, Validators.required],
    });

    if (this.courierIndex > -1 && this.zoneIndex > -1 && this.priceIndex > -1) {
      this.courier = this.company.courierList[this.courierIndex];
      this.zone = this.courier.zoneList[this.zoneIndex];
      this.price = this.zone.amountList[this.priceIndex];
      this.form.setValue({
        weight: this.price.weight,
        price: this.price.price,
      });
    }
  }

  getWeightControl() {
    return this.form.get('weight') as FormControl;
  }
  getCodeControl() {
    return this.form.get('code') as FormControl;
  }
  getAmountControl() {
    return this.form.get('price') as FormControl;
  }
  getDescriptionControl() {
    return this.form.get('description') as FormControl;
  }

  submit() {
    this.isLoading = true;
    if (this.priceIndex > -1) {
      this.price.weight = this.form.value.weight;
      this.price.price = this.form.value.price;

      this.company.courierList[this.courierIndex].zoneList[
        this.zoneIndex
      ].amountList[this.priceIndex] = this.price;
    } else {
      if (
        this.company.courierList[this.courierIndex].zoneList[this.zoneIndex]
          .amountList
      ) {
        this.company.courierList[this.courierIndex].zoneList[
          this.zoneIndex
        ].amountList.push({
          ...this.form.value,
        });
      } else {
        this.company.courierList[this.courierIndex].zoneList[
          this.zoneIndex
        ].amountList = [
          {
            ...this.form.value,
          },
        ];
      }
    }

    console.log(this.company);
    this.store.dispatch(
      saveCompany({ payload: { ...this.company, currentUrl: this.currentUrl } })
    );
    this.onSubmit.emit('Done');
  }

  deleteZone() {
    if (
      this.company.courierList[this.courierIndex].zoneList[this.zoneIndex]
        .amountList
    ) {
      this.company.courierList[this.courierIndex].zoneList[
        this.zoneIndex
      ].amountList.splice(this.priceIndex, 1);
    }
    this.store.dispatch(
      saveCompany({ payload: { ...this.company, currentUrl: this.currentUrl } })
    );
  }
}
