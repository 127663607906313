import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  public _inputControl: FormControl | AbstractControl;

  @Input()
  public set inputControl(control: AbstractControl) {
    this._inputControl = control;
  }

  public get inputControl(): FormControl {
    return this._inputControl as FormControl;
  }

  @Input()
  public matAutocomplete: MatAutocomplete;

  @Input()
  public type = 'text';

  @Input()
  public inputId: string;

  @Input()
  public label = '';

  @Input()
  public step = 1;

  @Input()
  public min: number = Number.MIN_SAFE_INTEGER;

  @Input()
  public max: number = Number.MAX_SAFE_INTEGER;

  @Input()
  public placeHolder = '';

  @Input()
  public autofocus = false;

  constructor() {}

  ngOnInit(): void {}
}
