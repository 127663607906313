<div class="mb-4">
  <h2 class="mb-0">Add Monthly Update</h2>
  <small><strong>Project: </strong>{{ project.title }}</small>
</div>
<div class="bg-white rounded-md p-8 shadow-md">
  <app-monthly-update-form #projectForm [project]="project"></app-monthly-update-form>
  <div class="flex gap-2">
    <p-button
      (click)="goBack()"
      icon="pi pi-cross"
      label="Cancel"
      styleClass="p-button-outlined p-button-danger"
    ></p-button>
    <p-button (click)="projectForm.submit()" icon="pi pi-check" label="Save"></p-button>
  </div>
</div>
