import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '.';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../app-config.service';
import { Country } from '../../models';

interface CountryWrapper {
  country: Country;
}

@Injectable({
  providedIn: 'root',
  deps: [AuthService],
})
export class CountryService {
  private regionalApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (
      this.appConfigService.getConfig() &&
      this.appConfigService.getConfig().regionalApi
    ) {
      this.regionalApi = this.appConfigService.getConfig().regionalApi;
    } else {
      this.regionalApi = environment.regionalApi;
    }
  }

  addCountry(country: Country): Observable<Country> {
    const username = this.authService.getUsername();
    const url = `${this.regionalApi}/country/addUp`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient
      .post<CountryWrapper>(url, { country: country }, { headers })
      .pipe(
        map((countryWrappers) => {
          return countryWrappers.country;
        })
      );
  }

  updateCountry(country: Country): Observable<Country> {
    const url = `${this.regionalApi}/country/modify`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient
      .put<CountryWrapper>(url, { country: country }, { headers })
      .pipe(
        map((countryWrappers) => {
          return countryWrappers.country;
        })
      );
  }

  getCountries(): Observable<Country[]> {
    const username = this.authService.getUsername();
    const url = `${this.regionalApi}/country/list`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<CountryWrapper[]>(url, { headers }).pipe(
      map((countryWrappers) => {
        return countryWrappers.map((wrapper) => wrapper.country);
      })
    );
  }

  getCountryById(id: string): Observable<Country> {
    const username = this.authService.getUsername();
    const url = `${this.regionalApi}/country/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<CountryWrapper>(url, { headers }).pipe(
      map((countryWrappers) => {
        return countryWrappers.country;
      })
    );
  }

  getCountryByCode(code: string): Observable<Country> {
    const username = this.authService.getUsername();
    const url = `${this.regionalApi}/country/byCode/${code}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<CountryWrapper>(url, { headers }).pipe(
      map((countryWrappers) => {
        return countryWrappers.country;
      })
    );
  }

  deleteCountry(id: string, rev: string): Observable<Country> {
    const username = this.authService.getUsername();
    const url = `${this.regionalApi}/country/${id}/${rev}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<any>(url, { headers });
  }
}
