import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';
import { environment } from 'src/environments/environment';
import { DashboardData, MonthlyUpdate } from '../../models/plans';
import { IS_SPINNER_ENABLED } from '../constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
  deps: [AuthService],
})
export class MonthlyUpdateService {
  private wfpApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
    } else {
      this.wfpApi = environment.wfpApi;
    }
  }

  public getDashboardData(month: number, year: number) {
    const url = `${this.wfpApi}/monthly-updates/dashboard/${month}/${year}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<DashboardData[]>(url, {
      headers,
    });
  }

  public addMonthlyUpdate(monthlyUpdate: MonthlyUpdate) {
    const url = `${this.wfpApi}/monthly-updates`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<MonthlyUpdate>(url, monthlyUpdate, {
      headers,
    });
  }

  public deleteMonthlyUpdate(id: number) {
    const url = `${this.wfpApi}/monthly-updates/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<any>(url, { headers });
  }

  public getMonthlyUpdateById(id: number) {
    const url = `${this.wfpApi}/monthly-updates/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<MonthlyUpdate>(url, { headers });
  }

  public getMonthlyUpdatesByProjectId(projectId: number, showSpinner = false) {
    const url = `${this.wfpApi}/monthly-updates?projectId=${projectId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    const context = new HttpContext().set(IS_SPINNER_ENABLED, showSpinner);
    return this.httpClient.get<MonthlyUpdate[]>(url, { headers, context });
  }

  public updateMonthlyUpdate(monthlyUpdate: MonthlyUpdate) {
    const url = `${this.wfpApi}/monthly-updates`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<MonthlyUpdate>(url, monthlyUpdate, {
      headers,
    });
  }
}
