import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MenuItem, TreeNode } from 'primeng/api';
import { TreeTable } from 'primeng/treetable';
import { Plan, Project } from '../../../models/plans';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { deleteCategory } from '../../../redux/';
import { LibraryService } from '../../../utils/services';
import { CategoryFormDialogComponent } from '../category-form-dialog/category-form-dialog.component';
import { Category } from '../../../models';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/libraries/categoryList',
      label: 'Categories',
    },
  ];
  public categoryList: Category[];
  public categoryTreeList: TreeNode<Category>[];
  public isLoading = false;
  public plan: Plan;
  public showActivityDialog = false;
  public statuses: string[] = ['Draft', 'Approved', 'Rejected'];
  @ViewChild(TreeTable)
  private table: TreeTable;

  constructor(
    private libraryService: LibraryService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService
  ) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
  }

  private _project: Project;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Project) {
    if (!project || !project.id) {
      return;
    }
    this._project = project;
    this.plan = project.plan;
    this.loadData();
  }

  convertToTreeNodes(categories: Category[], parent: Category = null) {
    return categories.map((obj) => {
      const node: TreeNode<Category> = {
        data: obj,
        label: obj.name,
        parent: {
          data: parent,
        },
        key: obj.id.toString(),
        children: obj.children ? this.convertToTreeNodes(obj.children, obj) : [],
        leaf: obj.children.length <= 0,
        expandedIcon: 'pi pi-folder-open',
        collapsedIcon: 'pi pi-folder',
      };
      return node;
    });
  }

  deleteCategory($event: Event, category: Category) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this Category?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteCategory({ payload: category }));
      },
      reject: () => {},
    });
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      //dt1.filterGlobal($event.target, 'contains')
    }
  }

  getMonthChoices() {
    return monthNames;
  }

  getMonthString(monthNumber: number) {
    return monthNames[monthNumber];
  }

  loadData() {
    this.isLoading = true;
    this.libraryService.getCategories().subscribe({
      next: (categories) => {
        this.categoryList = categories;
        this.categoryTreeList = this.convertToTreeNodes(categories);
        this.isLoading = false;
      },
      error: (err) => {
        if (err.error.statusCode && err.error.statusCode != 404) {
          this.toaster.error(err.message, 'Error');
        }
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  openAddDialog(rowNode: TreeNode<Category>) {
    const ref = this.dialog.open(CategoryFormDialogComponent, {
      data: {
        project: this.project,
        categoryList: [...this.categoryTreeList],
        parent: rowNode.parent,
        operation: 'AddChild',
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
    });
  }

  openAddPlanForm() {
    this.showActivityDialog = true;
  }

  openEditDialog(category: Category, rowNode: TreeNode<Category>) {
    const ref = this.dialog.open(CategoryFormDialogComponent, {
      minHeight: '500px',
      data: {
        project: this.project,
        category: category,
        categoryList: [...this.categoryTreeList],
        parent: rowNode.parent,
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {},
    });
  }
}
