import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreeNode } from 'primeng/api';
import { Category } from './../../../models/library/category';

type DialogData = {
  project?: Category;
  category?: Category;
  parent?: TreeNode<Category>;
  categoryList?: TreeNode<Category>[];
  operation?: 'AddChild' | 'Add' | 'Edit' | 'EditChild';
};

@Component({
  selector: 'app-category-form-dialog',
  templateUrl: './category-form-dialog.component.html',
  styleUrls: ['./category-form-dialog.component.scss'],
})
export class CategoryFormDialogComponent implements OnInit {
  public category: Category;
  public categoryList: TreeNode<Category>[];
  public parent: TreeNode<Category>;
  public project: Category;
  public operation: 'AddChild' | 'Add' | 'Edit' | 'EditChild';

  constructor(
    public dialogRef: MatDialogRef<CategoryFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    dialogRef.updateSize('90%', '90%');
    this.project = data.project;
    this.category = data.category;
    this.categoryList = data.categoryList;
    this.parent = data.parent;
    this.operation = data.operation;
  }

  ngOnInit(): void {}

  onSubmit(event) {
    this.dialogRef.close();
  }
}
