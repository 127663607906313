<div class="profile-wrapper dark:bg-gray-800">
  <h1>{{ user.firstName }} {{ user.lastName }}</h1>
  <small>Edit your profile and your password.</small>

  <div class="form-container flex flex-col lg:flex-row mt-4 md:mt-8 gap-2 md:gap-8">
    <div class="form w-full order-2 lg:order-1 lg:w-3/5">
      <div class="piFormContainer bg-white shadow-md">
        <h4 class="mb-4 md:mb-8">Personal Information</h4>
        <form [formGroup]="profileForm">
          <app-input [inputControl]="profileForm.get('firstName')" inputId="firstName" label="First Name"
            placeHolder="ex. John">
            <ng-container error>
              <div
                *ngIf="(profileForm.get('firstName').touched || profileForm.get('firstName').dirty) && profileForm.get('firstName').invalid"
                class="alert">
                <ul>
                  <ng-container *ngFor="let err of profileForm.get('firstName')?.errors | keyvalue">
                    <li *ngIf="err.key == 'required'">Please enter your first name</li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
          </app-input>

          <app-input [inputControl]="profileForm.get('lastName')" inputId="lastName" label="Last Name"
            placeHolder="ex. Doe">
            <ng-container error>
              <div
                *ngIf="(profileForm.get('lastName').touched || profileForm.get('lastName').dirty) && profileForm.get('lastName').invalid"
                class="alert">
                <ul>
                  <ng-container *ngFor="let err of profileForm.get('lastName')?.errors | keyvalue">
                    <li *ngIf="err.key == 'required'">Please enter your last name</li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
          </app-input>

          <app-input [inputControl]="profileForm.get('email')" type="email" inputId="email" label="Email"
            placeHolder="ex. john@example.com">
            <ng-container error>
              <div
                *ngIf="(profileForm.get('email').touched || profileForm.get('email').dirty) && profileForm.get('email').invalid"
                class="alert">
                <ul>
                  <ng-container *ngFor="let err of profileForm.get('email')?.errors | keyvalue">
                    <li *ngIf="err.key == 'email'">Please enter a valid email</li>
                    <li *ngIf="err.key == 'isEmailExists'">This email is already registered</li>
                    <li *ngIf="err.key == 'required'">Please enter your email</li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
          </app-input>

          <app-input [inputControl]="profileForm.get('mobile')" type="mobile" inputId="mobile" label="Mobile Number &ndash; (optional, min 8 digits)"
            placeHolder="ex. john@example.com">
            <ng-container error>
              <div
                *ngIf="(profileForm.get('mobile').touched || profileForm.get('mobile').dirty) && profileForm.get('mobile').invalid"
                class="alert">
                <ul>
                  <ng-container *ngFor="let err of profileForm.get('mobile')?.errors | keyvalue">
                    <li *ngIf="err.key == 'pattern'">Please enter at least 8 digit mobile number
                    </li>
                    <li *ngIf="err.key == 'isMobileExists'">This mobile is already registered
                    </li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
          </app-input>

          <div class="buttons text-right">
            <app-button (click)="submitPIForm()" [type]="'button'" [disabled]="profileForm.isLoading"
              [block]="profileForm.isLoading" [text]="'Submit'"
              class="w-32 bg-blue-500 text-white border-blue-600 hover:bg-blue-400 rounded-md"
              [loading]="profileForm.isLoading">
            </app-button>
          </div>
        </form>
      </div> <!-- End piFormContainer -->

      <div class="passwordForm  bg-white shadow-md">
        <h4 class="mb-4 md:mb-8">Password Change</h4>
        <form [formGroup]="passwordForm">

          <div class="input-container mb-5">
            <label for="oldPassword" class="font-bold mb-1 text-gray-700 block">Old Password</label>
            <div
              class="relative input-icon-container bg-white dark:bg-gray-400 focus:outline-none focus:shadow-outline p-1"
              [ngClass]="{'error': (passwordForm.get('oldPassword').touched || passwordForm.get('oldPassword').dirty) && passwordForm.get('oldPassword').invalid}">
              <input [type]="isOldPasswordVisible ? 'text' : 'password'" formControlName="oldPassword" id="oldPassword"
                class="w-full px-4 py-3 focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                placeholder="Your current password...">

              <div class="absolute right-0 bottom-0 top-0 px-3 py-3 cursor-pointer">
                <a href="#" tabindex="-1"
                  (click)="$event.preventDefault();isOldPasswordVisible = !isOldPasswordVisible">
                  <svg *ngIf="isOldPasswordVisible" class="block" xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                    <path
                      d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757C12.568 16.983 12.291 17 12 17c-5.351 0-7.424-3.846-7.926-5 .204-.47.674-1.381 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379-.069.205-.069.428 0 .633C2.073 12.383 4.367 19 12 19zM12 5c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657.069-.205.069-.428 0-.633C21.927 11.617 19.633 5 12 5zM16.972 15.558l-2.28-2.28C14.882 12.888 15 12.459 15 12c0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501C9.796 7.193 10.814 7 12 7c5.351 0 7.424 3.846 7.926 5C19.624 12.692 18.76 14.342 16.972 15.558z" />
                  </svg>

                  <svg *ngIf="!isOldPasswordVisible" class="block" xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                    <path
                      d="M12,9c-1.642,0-3,1.359-3,3c0,1.642,1.358,3,3,3c1.641,0,3-1.358,3-3C15,10.359,13.641,9,12,9z" />
                    <path
                      d="M12,5c-7.633,0-9.927,6.617-9.948,6.684L1.946,12l0.105,0.316C2.073,12.383,4.367,19,12,19s9.927-6.617,9.948-6.684 L22.054,12l-0.105-0.316C21.927,11.617,19.633,5,12,5z M12,17c-5.351,0-7.424-3.846-7.926-5C4.578,10.842,6.652,7,12,7 c5.351,0,7.424,3.846,7.926,5C19.422,13.158,17.348,17,12,17z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="input-container mb-5">
            <label for="newPassword" class="font-bold mb-1 text-gray-700 block">New Password</label>
            <div class="relative input-icon-container bg-white dark:bg-gray-400 focus:outline-none focus:shadow-outline"
              [ngClass]="{'error': (passwordForm.get('newPassword').touched || passwordForm.get('newPassword').dirty) && passwordForm.get('newPassword').invalid}">
              <input [type]="isNewPasswordVisible ? 'text' : 'password'" formControlName="newPassword" id="newPassword"
                class="w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                placeholder="Your new strong password...">

              <div class="absolute right-0 bottom-0 top-0 px-3 py-3 cursor-pointer">
                <a href="#" tabindex="-1"
                  (click)="$event.preventDefault();isNewPasswordVisible = !isNewPasswordVisible">
                  <svg *ngIf="isNewPasswordVisible" class="block" xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                    <path
                      d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757C12.568 16.983 12.291 17 12 17c-5.351 0-7.424-3.846-7.926-5 .204-.47.674-1.381 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379-.069.205-.069.428 0 .633C2.073 12.383 4.367 19 12 19zM12 5c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657.069-.205.069-.428 0-.633C21.927 11.617 19.633 5 12 5zM16.972 15.558l-2.28-2.28C14.882 12.888 15 12.459 15 12c0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501C9.796 7.193 10.814 7 12 7c5.351 0 7.424 3.846 7.926 5C19.624 12.692 18.76 14.342 16.972 15.558z" />
                  </svg>

                  <svg *ngIf="!isNewPasswordVisible" class="block" xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                    <path
                      d="M12,9c-1.642,0-3,1.359-3,3c0,1.642,1.358,3,3,3c1.641,0,3-1.358,3-3C15,10.359,13.641,9,12,9z" />
                    <path
                      d="M12,5c-7.633,0-9.927,6.617-9.948,6.684L1.946,12l0.105,0.316C2.073,12.383,4.367,19,12,19s9.927-6.617,9.948-6.684 L22.054,12l-0.105-0.316C21.927,11.617,19.633,5,12,5z M12,17c-5.351,0-7.424-3.846-7.926-5C4.578,10.842,6.652,7,12,7 c5.351,0,7.424,3.846,7.926,5C19.422,13.158,17.348,17,12,17z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="input-container mb-5">
            <label for="password" class="font-bold mb-1 text-gray-700 block">Confirm Password</label>
            <div *ngIf="(passwordForm.touched || passwordForm.dirty) && passwordForm.invalid" class="alert">
              <ul>
                <ng-container *ngFor="let err of passwordForm.errors | keyvalue">
                  <li *ngIf="err.key == 'passwordsDontMatch'">
                    Passwords do not match
                  </li>
                  <li *ngIf="err.key == 'isMobileExists'">
                    This mobile is already registered
                  </li>
                </ng-container>
              </ul>
            </div>
            <div class="relative input-icon-container bg-white dark:bg-gray-400 focus:outline-none focus:shadow-outline"
              [ngClass]="{'error': (passwordForm.get('password').touched || passwordForm.get('password').dirty) && passwordForm.get('password').invalid}">
              <input [type]="isPasswordVisible ? 'text' : 'password'" formControlName="password" id="password"
                class="w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                placeholder="Your new strong password...">

              <div class="absolute right-0 bottom-0 top-0 px-3 py-3 cursor-pointer">
                <a href="#" tabindex="-1" (click)="$event.preventDefault();isPasswordVisible = !isPasswordVisible">
                  <svg *ngIf="isPasswordVisible" class="block" xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                    <path
                      d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757C12.568 16.983 12.291 17 12 17c-5.351 0-7.424-3.846-7.926-5 .204-.47.674-1.381 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379-.069.205-.069.428 0 .633C2.073 12.383 4.367 19 12 19zM12 5c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657.069-.205.069-.428 0-.633C21.927 11.617 19.633 5 12 5zM16.972 15.558l-2.28-2.28C14.882 12.888 15 12.459 15 12c0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501C9.796 7.193 10.814 7 12 7c5.351 0 7.424 3.846 7.926 5C19.624 12.692 18.76 14.342 16.972 15.558z" />
                  </svg>

                  <svg *ngIf="!isPasswordVisible" class="block" xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                    <path
                      d="M12,9c-1.642,0-3,1.359-3,3c0,1.642,1.358,3,3,3c1.641,0,3-1.358,3-3C15,10.359,13.641,9,12,9z" />
                    <path
                      d="M12,5c-7.633,0-9.927,6.617-9.948,6.684L1.946,12l0.105,0.316C2.073,12.383,4.367,19,12,19s9.927-6.617,9.948-6.684 L22.054,12l-0.105-0.316C21.927,11.617,19.633,5,12,5z M12,17c-5.351,0-7.424-3.846-7.926-5C4.578,10.842,6.652,7,12,7 c5.351,0,7.424,3.846,7.926,5C19.422,13.158,17.348,17,12,17z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="buttons text-right">
            <app-button (click)="submitPasswordForm()" [type]="'button'" [disabled]="passwordForm.isLoading"
              [block]="passwordForm.isLoading" [text]="'Submit'"
              class="w-32 bg-blue-500 text-white border-blue-600 hover:bg-blue-400 rounded-md"
              [loading]="passwordForm.isLoading">
            </app-button>
          </div>

        </form>
      </div> <!-- End passwordForm -->
    </div>
    <div class="company">
      <h4>Company</h4>
    </div>
  </div>
</div>