import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../utils/services/report.service';
import { MenuItem } from 'primeng/api';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { Store } from '@ngrx/store';
import { QueryPlanParams } from '../../../models/reports/query-params';

@Component({
  selector: 'app-qpmrreport-page',
  templateUrl: './qpmrreport-page.component.html',
  styleUrls: ['./qpmrreport-page.component.scss'],
})
export class QPMRreportPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/reports/wfp',
      label: 'QPMR Report',
    },
  ];

  constructor(private reportService: ReportService, private store: Store<MainState>) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
  }

  ngOnInit(): void {}

  openReport(query: QueryPlanParams) {
    let options = { type: 'text/html' };
    if (query.format == 'excel') {
      options.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    this.reportService.openQpmrReport(query).subscribe({
      next: (result) => {
        console.log(result);
        const blob = new Blob([result], options);
        const file = URL.createObjectURL(blob);
        window.open(file);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
