import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MainState } from '..';
import { AppState } from './app-state';

export const selectAppState = createFeatureSelector<MainState,
  AppState>('app');

export const selectAppBreadCrumbs = createSelector(
  selectAppState,
  state => state.breadCrumbs!,
);
