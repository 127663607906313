import { createAction, props } from '@ngrx/store';
import { Category, Division, DivisionUnit } from '../../models';
import { FuncObjective, TLMO } from '../../models/plans';

export const addDivision = createAction('[Library] addDivision', props<{ payload: Division }>());
export const deleteDivision = createAction('[Library] deleteDivision', props<{ payload: Division }>());
export const updateDivision = createAction('[Library] updateDivision', props<{ payload: Division }>());

export const addDivisionUnit = createAction('[Library] addDivisionUnit', props<{ payload: DivisionUnit }>());
export const deleteDivisionUnit = createAction('[Library] deleteDivisionUnit', props<{ payload: DivisionUnit }>());
export const updateDivisionUnit = createAction('[Library] updateDivisionUnit', props<{ payload: DivisionUnit }>());

export const addCategory = createAction('[Library] addCategory', props<{ payload: Category }>());
export const deleteCategory = createAction('[Library] deleteCategory', props<{ payload: Category }>());
export const updateCategory = createAction('[Library] updateCategory', props<{ payload: Category }>());

export const addTlmo = createAction('[Library] addTlmo', props<{ payload: TLMO }>());
export const deleteTlmo = createAction('[Library] deleteTlmo', props<{ payload: TLMO }>());
export const updateTlmo = createAction('[Library] updateTlmo', props<{ payload: TLMO }>());

export const addObjective = createAction('[Library] addObjective', props<{ payload: FuncObjective }>());
export const deleteObjective = createAction('[Library] deleteObjective', props<{ payload: FuncObjective }>());
export const updateObjective = createAction('[Library] updateObjective', props<{ payload: FuncObjective }>());
