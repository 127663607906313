import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '.';
import { MainState } from '../index';

export const selectUserState = createFeatureSelector<MainState, UserState>(
  'user'
);

export const selectUserStateVendor = createSelector(
  selectUserState,
  (state) => state.vendor!
);

export const selectUserStateUsers = createSelector(
  selectUserState,
  (state) => state.users!
);
