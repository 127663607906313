import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { EMPTY } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { Project } from '../../../models/plans';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { PlanService, ProjectService } from '../../../utils/services';

@Component({
  selector: 'app-project-view-readonly-page',
  templateUrl: './project-view-readonly-page.component.html',
  styleUrls: ['./project-view-readonly-page.component.scss'],
})
export class ProjectViewReadonlyPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/plans',
      label: 'Plans',
    },
  ];
  public editPlan = false;
  public project: Project;
  public projectTypes = {
    MAIN_PROJECT: 'Main Project',
    SUPPLEMENTAL_PROJECT: 'Supplemental Project',
  };

  constructor(
    private activeRoute: ActivatedRoute,
    private projectService: ProjectService,
    private planService: PlanService,
    private toaster: ToastrService,
    private store: Store<MainState>
  ) {}

  public get plan() {
    return this.project?.plan;
  }

  public ngOnInit(): void {
    this.activeRoute.params
      .pipe(
        exhaustMap((params) => {
          return this.projectService.getProjectById(params.projectId).pipe(
            exhaustMap((project) => {
              this.project = project;
              this.breadCrumbLinks.push({
                routerLink: `/plans/${this.plan.id}`,
                label: `Plan - ${this.plan.year}`,
              });
              this.breadCrumbLinks.push({
                label: this.project.title,
              });
              this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
              return EMPTY;
            })
          );
        })
      )
      .subscribe({
        next: (value) => {
          console.log(value);
        },
        error: (err) => {
          if (err.error.statusCode && err.error.statusCode != 404) {
            this.toaster.error(err.message, 'Error');
          }
          console.error(err);
        },
      });
  }
}
