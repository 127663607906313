import { Injectable } from '@angular/core';
import { AppConfig } from './models';

@Injectable()
export class AppConfigService {
  private appConfig: AppConfig;

  constructor() {
    console.log('Initialize appconfigservice');
  }

  loadAppConfig() {
    console.log('Loading configs');
    const req = new Request('/assets/data/appConfig.json');
    const myInit: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    };

    return fetch(req, myInit).then((response) => {
      return response.json().then((config) => {
        this.appConfig = config;
      });
    });
  }

  getConfig() {
    return this.appConfig;
  }
}
