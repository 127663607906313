<div class='flex flex-col mb-5 w-full'>
  <label for='package' class='font-bold mb-1 text-gray-700 dark:text-gray-200 block text-sm'>Package</label>
  <ng-select (change)='packageSelected($event)'
             [ngModel]='getPackageControl().value'
             appearance='outline'
             labelForId='package'
             class='w-full' placeholder='Select a package'>
    <ng-option *ngFor='let package of packageList' [value]='package'>{{package}}</ng-option>
  </ng-select>
</div>

<div class='flex flex-col mb-5 w-full' *ngIf='getPackageControl().value == "RESELLER"'>
  <label for='referrer' class='font-bold mb-1 text-gray-700 dark:text-gray-200 block text-sm'>Referrer</label>
  <ng-select (change)='referrerSelected($event)'
             [ngModel]='getReferrerControl().value'
             appearance='outline'
             labelForId='referrer'
             class='w-full' placeholder='Select a referrer'>
    <ng-option *ngFor='let user of users' [value]='user'>{{user.username}}: {{user.firstName}} {{user.lastName}}</ng-option>
  </ng-select>
</div>
