import { Component, OnInit, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AuthService, UserService } from '../../utils/services';

@Component({
  selector: 'app-create-new-password-forgotten-page',
  templateUrl: './create-new-password-forgotten-page.component.html',
  styleUrls: ['./create-new-password-forgotten-page.component.scss'],
})
export class CreateNewPasswordForgottenPageComponent implements OnInit {
  public newPasswordForm: FormGroup & {
    isNewPasswordVisible?: boolean;
    isPasswordVisible?: boolean;
  };
  public isAuthLoading = false;
  public isPwordVisible = false;
  public message = '';
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private library: FaIconLibrary
  ) {
    library.addIconPacks(fas);
  }

  ngOnInit() {
    this.newPasswordForm = this.fb.group(
      {
        id: ['', Validators.required],
        forgotPasswordCode: ['', Validators.required],
        newPassword: ['', Validators.required],
        password: ['', Validators.required],
      },
      {
        updateOn: 'blur',
        validators: this.oldAndNewPasswordValidator,
      }
    );
    this.activeRoute.queryParams.subscribe((params) => {
      this.message = params['message'];
      this.newPasswordForm.get('id').setValue(params.id);
      this.newPasswordForm.get('forgotPasswordCode').setValue(params.code);
    });
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
  }

  togglePasswordVisibility() {
    console.log(event);
    this.isPwordVisible = !this.isPwordVisible;
  }

  isValidPassword() {
    if (
      this.newPasswordForm.get('password').touched ||
      this.newPasswordForm.get('password').dirty
    ) {
      return this.newPasswordForm.get('password').valid;
    }
    return true;
  }

  oldAndNewPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const password = control.get('password');
    const newPassword = control.get('newPassword');

    if (password.value === newPassword.value) {
      return null;
    }

    return {
      passwordsDontMatch: true,
    };
  };

  isValidUsername() {
    if (
      this.newPasswordForm.get('username').touched ||
      this.newPasswordForm.get('username').dirty
    ) {
      return this.newPasswordForm.get('username').valid;
    }
    return true;
  }

  convertToFormData() {
    const params = new URLSearchParams();
    params.append('username', this.newPasswordForm.get('username').value);
    params.append('password', this.newPasswordForm.get('password').value);
    params.append('client_id', 'prime-apps');
    params.append('grant_type', 'password');
    return params;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  updatePassword() {
    this.isAuthLoading = true;
    this.newPasswordForm.markAllAsTouched();
    if (this.newPasswordForm.valid) {
      this.userService
        .sendChagePasswordFromForgotPassword(this.newPasswordForm.value)
        .subscribe(
          (response) => {
            this.toastr.success('Password change was successful!', 'Error', {
              positionClass: 'toast-top-right',
              // disableTimeOut: true,
            });
            setTimeout(() => {
              this.router.navigate(['auth', 'login']);
            }, 5000);
          },
          (err) => {
            if (err.status >= 200 && err.status < 400) {
              this.toastr.success('Password change was successful!', 'Error', {
                positionClass: 'toast-top-right',
                // disableTimeOut: true,
              });
              this.isAuthLoading = true;
              this.newPasswordForm.disable();
              this.router.navigate(['auth', 'login']);
              return;
            }
            this.isAuthLoading = false;
            this.newPasswordForm.enable();
            console.error(err);
            this.message = err.error;
            this.toastr.error(err.error, 'Error', {
              positionClass: 'toast-top-right',
              // disableTimeOut: true,
            });
          }
        );
    } else {
      this.newPasswordForm.enable();
      this.isAuthLoading = false;
      this.toastr.error('Invalid form!', 'Error');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }
}
