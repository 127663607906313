import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from 'primeng/api';
import { addCategory, deleteCategory, updateCategory } from '../../../redux/library';
import { userHasPermission } from '../../../utils/functions/has-permission';
import { Category } from './../../../models/library/category';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss'],
})
export class CategoryFormComponent implements OnInit {
  @Input()
  public categoryList: TreeNode<Category>[];
  public form: FormGroup;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  @Input()
  public showDeleteButton = false;
  public yearChoices: string[] = [];

  constructor(private fb: FormBuilder, private store: Store, private toaster: ToastrService) {
    this.form = this.fb.group({
      parent: [{ value: null, disabled: !this.canEdit() }],
      sequenceNumber: [{ value: null, disabled: !this.canEdit() }, [Validators.required]],
      name: [{ value: null, disabled: !this.canEdit() }, [Validators.required]],
    });
  }

  private _category: Category;

  public get category() {
    return this._category;
  }

  @Input()
  public set category(category: Category) {
    this._category = category;
    this.form.patchValue({
      ...this._category,
    });
  }

  private _parent: TreeNode<Category>;

  public get parent() {
    return this._parent;
  }

  @Input()
  public set parent(parent: TreeNode<Category>) {
    this._parent = parent;

    if (parent?.data?.id) {
      this._parent.children = [];
      parent.children = [];
      this.getFormControl('parent').setValue(parent);
    }
  }

  private _project: Category;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Category) {
    this._project = project;
  }

  canEdit() {
    const result = userHasPermission(['library:editAny', 'library:createAny']);
    return result;
  }

  deleteCategory() {
    this.store.dispatch(deleteCategory({ payload: this.project }));
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  isFormControlInvalid(name: string) {
    return (this.getFormControl(name).touched || this.getFormControl(name).dirty) && this.getFormControl(name).invalid;
  }

  ngOnInit(): void {}

  onStartMonthSelect(event: any, value: string) {
    console.log(event);
    console.log(value);
  }

  resetForm() {
    this.form.reset({
      name: '',
      parent: null,
      sequenceNumber: 0,
    });
  }

  submit() {
    this.form.markAsTouched();
    this.form.markAsDirty();
    if (this.form.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }
    this.isLoading = true;
    const selectedParent: TreeNode<Category> = this.getFormControl('parent').value;
    const newCategory: Category = Object.assign(this._category ?? {}, this.form.getRawValue());

    if (selectedParent?.data?.id) {
      newCategory.parent = {
        id: selectedParent.data.id,
        name: selectedParent.data.name,
        sequenceNumber: selectedParent.data.sequenceNumber,
        children: [],
      };
    } else {
      newCategory.parent = null;
    }
    if (newCategory.id) {
      this.store.dispatch(updateCategory({ payload: newCategory }));
    } else {
      this.store.dispatch(addCategory({ payload: newCategory }));
    }
    return true;
  }
}
