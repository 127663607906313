import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DivisionUnit } from '../../../models';
import { refreshPage } from '../../../redux';
import { AuthService, LibraryService } from '../../../utils/services';

@Component({
  selector: 'app-switch-division-fom',
  templateUrl: './switch-division-fom.component.html',
  styleUrls: ['./switch-division-fom.component.scss'],
})
export class SwitchDivisionFomComponent implements OnInit {
  public divisions: DivisionUnit[];
  public form: FormGroup;

  constructor(
    private libraryService: LibraryService,
    private fb: FormBuilder,
    private authService: AuthService,
    private toast: ToastrService,
    private store: Store
  ) {
    const user = this.authService.getUserObject();
    this.form = this.fb.group({
      newDivision: [user.divisionId, [Validators.required]],
    });
  }

  getForm() {
    return this.form;
  }

  submitForm() {
    this.form.markAllAsTouched();
    this.form.markAsDirty();
    if (!this.form.valid) {
      this.toast.error('Check if all inputs are valid', 'Invalid Form');
    }

    const user = this.authService.getUserObject();
    user.divisionId = this.form.value.newDivision;

    this.authService.saveUserObject(user);
    this.store.dispatch(refreshPage());
  }

  getDivisions() {
    this.libraryService.getDivisionUnits().subscribe({
      next: (value) => {
        this.divisions = value;
      },
      error: (err) => {
        console.error(err);
        this.toast.error('Error fetching divisions', 'Error');
      },
    });
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  ngOnInit(): void {
    this.getDivisions();
  }

  public onDivisionChange($event: any) {
    console.log($event);
  }
}
