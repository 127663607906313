import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Division } from '../../../models';
import { Plan, Project, QuarterlyUpdate } from '../../../models/plans';
import { addQuarterlyUpdate, deletePlan, updateQuarterlyUpdate } from '../../../redux/plan';
import { userHasPermission } from '../../../utils/functions/has-permission';
import { LibraryService, ProjectActivityService } from '../../../utils/services';

const quarters = ['1st Quarter', '2nd Quarter', '3rd Quarter', '4th Quarter'];

const monitoredChoices = [
  { name: 'Implemented Ahead of Time', code: 'Ahead of time' },
  { name: 'Implemented Ahead on Time', code: 'Ahead on time' },
  { name: 'Implemented Though Delayed', code: 'Delayed' },
  { name: 'Not Yet Implemented', code: 'Not yet implemented' },
];

@Component({
  selector: 'app-quarterly-update-form',
  templateUrl: './quarterly-update-form.component.html',
  styleUrls: ['./quarterly-update-form.component.scss'],
})
export class QuarterlyUpdateFormComponent implements OnInit {
  public divisions: Division[];
  public form: FormGroup;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  public plan: Plan;
  public selectedDivision: Division;
  @Input()
  public showDeleteButton = false;
  public yearChoices: string[] = [];
  private quarterChoices: { name: string; id: number }[];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private divisionService: LibraryService,
    private toaster: ToastrService,
    private projectActivityService: ProjectActivityService
  ) {
    this.form = this.fb.group({
      yearQuarter: [{ value: 0, disabled: !this.canEdit() }, [Validators.required]],
      reportingYear: [{ value: 0, disabled: !this.canEdit() }, [Validators.required]],
      accomplishments: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      milestones: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      remarks: [{ value: '', disabled: !this.canEdit() }],
    });
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    for (let i = currentYear + 1; i >= currentYear - 5; i--) {
      this.yearChoices.push(i.toString());
    }
    this.getFormControl('reportingYear').setValue(currentYear.toString());
  }

  private _project: Project;

  get project() {
    return this._project;
  }

  @Input()
  set project(project: Project) {
    if (!project || !project.id) {
      return;
    }
    this._project = project;
    this.plan = project.plan;
  }

  private _quarterlyUpdate: QuarterlyUpdate;

  get quarterlyUpdate() {
    return this._quarterlyUpdate;
  }

  @Input()
  set quarterlyUpdate(quarterlyUpdate: QuarterlyUpdate) {
    this._quarterlyUpdate = quarterlyUpdate;
    this.form.patchValue({
      ...this._quarterlyUpdate,
      reportingYear: this._quarterlyUpdate.reportingYear.toString(),
    });
  }

  public canEdit() {
    return userHasPermission(['plan:editAny']);
  }

  public deletePlan() {
    this.store.dispatch(deletePlan({ planId: this.project.id }));
  }

  public getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  public getMonitoredOptions() {
    return monitoredChoices;
  }

  public getQuarters() {
    if (!this.quarterChoices) {
      this.quarterChoices = quarters.map((quarter, index) => ({
        name: quarter,
        id: index,
      }));
    }
    return this.quarterChoices;
  }

  public isMonitoredOptionSelected(option: any) {
    if (this.getFormControl('monitored').valid) {
      return this.getFormControl('monitored').value == option.code;
    }

    return false;
  }

  public ngOnInit(): void {}

  resetForm() {}

  public submit() {
    this.form.markAsTouched();
    this.form.markAsDirty();
    if (this.form.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }
    this.isLoading = true;
    const quarterlyUpdate: QuarterlyUpdate = Object.assign(this._quarterlyUpdate ?? {}, this.form.value);
    quarterlyUpdate.project = this.project;
    if (quarterlyUpdate.id) {
      this.store.dispatch(updateQuarterlyUpdate({ quarterlyUpdate: quarterlyUpdate }));
    } else {
      this.store.dispatch(addQuarterlyUpdate({ quarterlyUpdate: quarterlyUpdate }));
    }

    return true;
  }
}
