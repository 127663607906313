export const USERNAME_KEY = 'admin_username';
export const AUTH_TOKEN_KEY = 'admin_token';
export const ROLE_KEY = 'admin_role';
export const PERMISSIONS_KEY = 'admin_permissions';
export const AUTH_TOKEN_EXPIRY_KEY = 'admin_token_expiry';
export const REFRESH_TOKEN_EXPIRY_KEY = 'admin_refresh_token_expiry';
export const USER_KEY = 'admin_user_object';
export const ORIGIN_KEY = 'admin_origin_object';
export const REMEMBER_ME_KEY = 'admin_remember_me';
export const REFRESH_TOKEN_KEY = 'admin_refresh_token';
export const MONTHLY_UPDATES_LIMITS_KEY = 'MONTHLY_UPDATES_LIMITS';
