import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
  addedMonthlyUpdate,
  addedPpmr,
  addedProject,
  addPpmr,
  addRemark,
  deleteMonthlyUpdate,
  deletePpmr,
  deleteProjectActivity,
  deleteQuarterlyUpdate,
  deleteRemark,
  updatedMonthlyUpdate,
  updatedPpmr,
  updateMonthlyUpdate,
  updatePlanStatus,
  updatePpmr,
  updateProjectActivity,
  updateQuarterlyUpdate,
  updateRemark,
} from '.';
import {
  AuthService,
  MonthlyUpdateService,
  PlanService,
  ProjectActivityService,
  ProjectService,
  QuarterlyUpdateService,
  RemarksService,
} from '../../utils/services';
import { MainState, refreshPage } from '../index';
import {
  addMonthlyUpdate,
  addPlan,
  addProject,
  addProjectActivity,
  addQuarterlyUpdate,
  deletePlan,
  deleteProject,
  updatePlan,
  updateProject,
} from './plan.actions';
import { PpmrService } from '../../utils/services/ppmr.service';

@Injectable()
export class PlanEffects {
  addMonthlyUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addMonthlyUpdate),
      exhaustMap((action) => {
        return this.monthlyUpdateService.addMonthlyUpdate(action.monthlyUpdate).pipe(
          map((monthlyUpdate) => {
            return addedMonthlyUpdate({ monthlyUpdate });
          }),
          catchError((err) => {
            console.error(err);
            if (err.status == 409) {
              this.toaster.error(err.error.message, 'Error');
            }
            this.toaster.error('Error creating monthly update', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addPlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addPlan),
      exhaustMap((action) => {
        return this.planService.createPlan(action.plan).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            if (err.status == 409) {
              this.toaster.error(err.error.message, 'Error');
              return EMPTY;
            }
            this.toaster.error('Error creating plan', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addPpmr$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addPpmr),
      exhaustMap((action) => {
        return this.ppmrService.addPpmr(action.ppmr).pipe(
          map((ppmr) => {
            return addedPpmr({ ppmr });
          }),
          catchError((err) => {
            console.error(err);
            if (err.status == 409) {
              this.toaster.error(err.error.message, 'Error');
            }
            this.toaster.error('Error creating ppmr', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addProject),
      exhaustMap((action) => {
        return this.projectService.addProject(action.project).pipe(
          map((project) => {
            return addedProject({ project });
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error creating project', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addProjectActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addProjectActivity),
      exhaustMap((action) => {
        return this.projectActivityService.addProjectActivity(action.projectActivity).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error creating project activity', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addQuarterlyUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addQuarterlyUpdate),
      exhaustMap((action) => {
        return this.quarterlyUpdateService.addQuarterlyUpdate(action.quarterlyUpdate).pipe(
          map((quarterlyUpdate) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error creating quarterly update', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addRemark$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addRemark),
      exhaustMap((action) => {
        return this.remarkService.createRemark(action.remark).pipe(
          map((remark) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error creating remark', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  addedMonthlyUpdate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(addedMonthlyUpdate),
        exhaustMap((action) => {
          this.router.navigate(['/projects', action.monthlyUpdate.project.id]).then();
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
  addedPpmr$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(addedPpmr),
        exhaustMap((action) => {
          this.router.navigate(['/projects', action.ppmr.project.id]);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
  addedProject$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(addedProject),
        exhaustMap((action) => {
          this.router.navigate(['/plans', action.project.plan.id]);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
  deleteMonthlyUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteMonthlyUpdate),
      exhaustMap((action) => {
        return this.monthlyUpdateService.deleteMonthlyUpdate(action.monthlyUpdateId).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting monthly update', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deletePlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deletePlan),
      exhaustMap((action) => {
        return this.planService.deletePlan(action.planId).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting plan', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deletePpmr$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deletePpmr),
      exhaustMap((action) => {
        return this.ppmrService.deletePpmr(action.ppmrId).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting ppmr', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deleteProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteProject),
      exhaustMap((action) => {
        return this.projectService.deleteProject(action.projectId).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting project', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deleteProjectActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteProjectActivity),
      exhaustMap((action) => {
        return this.projectActivityService.deleteProjectActivity(action.projectActivityId).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting project activity', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deleteQuarterlyUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteQuarterlyUpdate),
      exhaustMap((action) => {
        return this.quarterlyUpdateService.deleteQuarterlyUpdate(action.quarterlyUpdateId).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting quarterly update', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  deleteRemark$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteRemark),
      exhaustMap((action) => {
        return this.remarkService.deleteRemark(action.remarkId).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error deleting remark', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updateMonthlyUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateMonthlyUpdate),
      exhaustMap((action) => {
        return this.monthlyUpdateService.updateMonthlyUpdate(action.monthlyUpdate).pipe(
          map((mu) => {
            return updatedMonthlyUpdate({ monthlyUpdate: mu });
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating monthly update', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updatePlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updatePlan),
      exhaustMap((action) => {
        return this.planService.updatePlan(action.plan).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            if (err.status == 409) {
              this.toaster.error(err.error.message, 'Error');
              return EMPTY;
            }
            this.toaster.error('Error updating plan', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updatePlanStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updatePlanStatus),
      exhaustMap((action) => {
        return this.planService.updatePlanStatus(action.plan).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating plan statusj', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updatePpmr$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updatePpmr),
      exhaustMap((action) => {
        return this.ppmrService.updatePpmr(action.ppmr).pipe(
          map((ppmr) => {
            return updatedPpmr({ ppmr: ppmr });
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating ppmr', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updateProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateProject),
      exhaustMap((action) => {
        return this.projectService.updateProject(action.project).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error(`Error: ${err.error.message}`, 'Error updating project', {
              timeOut: 5000,
            });
            return EMPTY;
          })
        );
      })
    );
  });
  updateProjectActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateProjectActivity),
      exhaustMap((action) => {
        return this.projectActivityService.updateProjectActivity(action.projectActivity).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating project activity', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updateQuarterlyUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateQuarterlyUpdate),
      exhaustMap((action) => {
        return this.quarterlyUpdateService.updateQuarterlyUpdate(action.quarterlyUpdate).pipe(
          map((plan) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating quarterly update', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updateRemark$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateRemark),
      exhaustMap((action) => {
        return this.remarkService.updateRemark(action.remark).pipe(
          map((remark) => {
            return refreshPage();
          }),
          catchError((err) => {
            console.error(err);
            this.toaster.error('Error updating remark', 'Error');
            return EMPTY;
          })
        );
      })
    );
  });
  updatedMonthlyUpdate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updatedMonthlyUpdate),
        exhaustMap((action) => {
          this.router.navigate(['/projects', action.monthlyUpdate.project.id]);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
  updatedPpmr$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updatedPpmr),
        exhaustMap((action) => {
          this.router.navigate(['/projects', action.ppmr.project.id]);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<MainState>,
    private toaster: ToastrService,
    private authService: AuthService,
    private planService: PlanService,
    private projectService: ProjectService,
    private projectActivityService: ProjectActivityService,
    private monthlyUpdateService: MonthlyUpdateService,
    private ppmrService: PpmrService,
    private quarterlyUpdateService: QuarterlyUpdateService,
    private remarkService: RemarksService
  ) {}
}
