<h2>TLMO</h2>
<div class="content-wrapper w-full-no-scroll shadow-md">
  <p-treeTable
    #dt1
    [globalFilterFields]="['sequenceNumber', 'tlmo']"
    [loading]="isLoading"
    [paginator]="true"
    sortField="sequenceNumber"
    [rowsPerPageOptions]="[10, 25, 50]"
    [rows]="10"
    [showCurrentPageReport]="true"
    [value]="tlmoTreeList"
    class="block w-full-no-scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    dataKey="id"
    responsiveLayout="scroll"
    styleClass="p-datatable-gridlines w-screen md:w-full flow-root"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
        <ng-container>
          <button
            (click)="openAddPlanForm()"
            *hasPermissions="['library:createAny']"
            class="p-button-outlined"
            icon="pi pi-plus"
            label="Add"
            pButton
          ></button>
        </ng-container>
        <div class="p-d-flex flex justify-between gap-2">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input (input)="filter($event)" pInputText placeholder="Search keyword" type="text" />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th ttSortableColumn="sequenceNumber">
          Sequence Number
          <p-treeTableSortIcon field="sequenceNumber"></p-treeTableSortIcon>
        </th>
        <th ttSortableColumn="tlmo">
          TLMO
          <p-treeTableSortIcon field="tlmo"></p-treeTableSortIcon>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>

    <ng-template let-rowNode let-tlmo="rowData" pTemplate="body">
      <tr class="dark:bg-gray-700">
        <td>
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          <span *ngIf='rowNode.parent'>
            {{ rowNode.parent.data.sequenceNumber }}.{{ tlmo.sequenceNumber }}
          </span>
          <span *ngIf='!rowNode.parent'>
            {{ tlmo.sequenceNumber }}
          </span>
        </td>
        <td>
          {{ tlmo.tlmo }}
        </td>
        <td>
          <div class="flex gap-2">
            <button
              (click)="openEditDialog(tlmo, rowNode)"
              *hasPermissions="['library:editAny']"
              class="p-button-rounded p-button-outlined"
              icon="pi pi-pencil"
              pButton
              pRipple
              pTooltip="Edit TLMO"
              type="button"
            ></button>
            <button
              (click)="deleteTlmo($event, tlmo)"
              *hasPermissions="['library:deleteAny']"
              class="p-button-rounded p-button-outlined p-button-danger"
              icon="pi pi-times"
              pButton
              pRipple
              pTooltip="Delete TLMO"
              type="button"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3">No TLMO found.</td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>

<p-dialog
  *ngIf="showActivityDialog"
  [(visible)]="showActivityDialog"
  [blockScroll]="true"
  [breakpoints]="{ '960px': '75vw' }"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'clamp(250px, 120ch, 90vw)', overflowY: 'auto !important', zIndex: '9999' }"
  contentStyleClass="overflow-y-auto lg:overflow-y-visible"
  header="Add TLMO"
  styleClass="overflow-y-visible"
>
  <app-tlmo-form #activityForm [project]="project" [tlmoList]="tlmoList"></app-tlmo-form>
  <ng-template pTemplate="footer">
    <p-button
      (click)="showActivityDialog = false; activityForm.resetForm()"
      icon="pi pi-cross"
      label="Cancel"
      styleClass="p-button-outlined p-button-danger"
    ></p-button>
    <p-button (click)="showActivityDialog = !activityForm.submit()" icon="pi pi-check" label="Save"></p-button>
  </ng-template>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
