<app-project-form #projectForm (onSubmit)='onSubmit($event)' [plan]='project.plan' [project]='project'
                  class='h-auto'></app-project-form>
<div class='flex gap-2'>
  <p-button
    (click)='close()'
    icon='pi pi-cross'
    label='Cancel'
    styleClass='p-button-outlined p-button-danger'
  ></p-button>
  <p-button (click)='projectForm.submit()' icon='pi pi-check' label='Save'></p-button>
</div>