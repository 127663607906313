<div class="main-content mb-4 flex flex-col gap-4">
  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class="block w-full">
      <div>
        <label class='block font-raleway font-bold'>Parent Category</label>
      </div>
      <p-treeSelect
        [formControl]="getFormControl('parent')"
        [options]="categoryList"
        label="Parent Objective"
        styleClass="w-full"
        class="w-full"
      ></p-treeSelect>
      <ul *ngIf="isFormControlInvalid('parent')" class="p-error">
        <ng-container *ngFor="let err of getFormControl('parent')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a Parent</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class="block w-full">
      <label for="name" class='block font-raleway font-bold'>Sequence Number *</label>
      <input
        [formControl]="getFormControl('sequenceNumber')"
        [pTooltip]="isFormControlInvalid('sequenceNumber') ? 'Sequence number is required' : ''"
        class="w-full rounded-none"
        id="sequenceNumber"
        pInputText
        tooltipEvent="focus"
        tooltipPosition="bottom"
        type="text"
      />
      <ul *ngIf="isFormControlInvalid('sequenceNumber')" class="p-error">
        <ng-container *ngFor="let err of getFormControl('sequenceNumber')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Enter sequence number</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class="block w-full">
      <label for="name" class='block font-raleway font-bold'>Name *</label>
      <input
        [formControl]="getFormControl('name')"
        [pTooltip]="isFormControlInvalid('name') ? 'Name is required' : ''"
        class="w-full rounded-none"
        id="name"
        pInputText
        tooltipEvent="focus"
        tooltipPosition="bottom"
        type="text"
      />
      <ul *ngIf="isFormControlInvalid('name')" class="p-error">
        <ng-container *ngFor="let err of getFormControl('name')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a Name</li>
        </ng-container>
      </ul>
    </span>
  </div>
</div>

<div *ngIf="false" class="buttons flex w-full gap-4">
  <app-button
    (click)="submit()"
    [block]="isLoading"
    [disabled]="isLoading"
    [loading]="isLoading"
    [text]="project && project.id ? 'Save' : 'Add Category'"
    [type]="'button'"
    class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md"
  >
  </app-button>

  <app-button
    (click)="deleteCategory()"
    *ngIf="project.id && showDeleteButton"
    [block]="isLoading"
    [disabled]="isLoading"
    [loading]="isLoading"
    [text]="'Delete Category'"
    [type]="'button'"
    class="w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md"
  >
  </app-button>
</div>
