import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AppConfigService } from '../../app-config.service';
import { environment } from '../../../environments/environment';
import { QueryPlanParams } from '../../models/reports/query-params';

const CURRENT_YEAR = new Date().getFullYear();

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private wfpApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
    } else {
      this.wfpApi = environment.wfpApi;
    }
  }

  openMipsReport(queryParams: QueryPlanParams) {
    const url = `${this.wfpApi}/reports/mips?format=${queryParams.format}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post(url, queryParams, { headers, responseType: 'arraybuffer' });
  }

  openMonthlyReport(queryParams: QueryPlanParams) {
    const url = `${this.wfpApi}/reports/monthly?format=${queryParams.format}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post(url, queryParams, { headers, responseType: 'arraybuffer' });
  }

  openProgramsReport(queryParams: QueryPlanParams) {
    const url = `${this.wfpApi}/reports/ppmr?format=${queryParams.format}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post(url, queryParams, { headers, responseType: 'arraybuffer' });
  }

  openQpmrReport(queryParams: QueryPlanParams) {
    const url = `${this.wfpApi}/reports/quarterly?format=${queryParams.format}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post(url, queryParams, { headers, responseType: 'arraybuffer' });
  }

  openWFPReport(queryParams: QueryPlanParams) {
    const url = `${this.wfpApi}/reports/wfp?format=${queryParams.format}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post(url, queryParams, { headers, responseType: 'arraybuffer' });
  }
}
