import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Plan, Project, ProjectActivity, Remarks, Status } from '../../../models/plans';
import { ProjectActivityService, RemarksService } from '../../../utils/services';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { MainState } from '../../../redux';
import { deleteProject, deleteProjectActivity, updatePlanStatus } from '../../../redux/plan';

@Component({
  selector: 'app-remarks-list',
  templateUrl: './remarks-list.component.html',
  styleUrls: ['./remarks-list.component.scss'],
})
export class RemarksListComponent implements OnInit {
  public isLoading = false;
  public remarks: Remarks[];
  public revisionRemarks = '';
  public showActivityDialog = false;
  public showForRevisionDialog = false;
  public statuses: string[] = ['Draft', 'Approved', 'Rejected'];
  @ViewChild(Table)
  private table: Table;

  constructor(
    private projectActivityService: ProjectActivityService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService,
    private remarksService: RemarksService
  ) {}

  private _plan: Plan;

  get plan() {
    return this._plan;
  }

  @Input()
  set plan(plan: Plan) {
    this._plan = plan;
    if (this._plan.id) {
      this.loadData();
    }
  }

  private _projectType: 'MAIN_PROJECT' | 'SUPPLEMENTAL_PROJECT';

  get projectType() {
    return this._projectType;
  }

  @Input()
  set projectType(projectType: 'MAIN_PROJECT' | 'SUPPLEMENTAL_PROJECT') {
    this._projectType = projectType;
  }

  deleteActivity($event: Event, activity: ProjectActivity) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this activity?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteProjectActivity({ projectActivityId: activity.id }));
      },
      reject: () => {},
    });
  }

  deleteProject($event: Event, project: Project) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this project?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteProject({ projectId: project.id }));
      },
      reject: () => {},
    });
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      //dt1.filterGlobal($event.target, 'contains')
    }
  }

  loadData() {
    this.isLoading = true;
    this.remarksService.getRemarksByPlanId(this._plan.id).subscribe({
      next: (remarks) => {
        this.isLoading = false;
        this.remarks = remarks;
      },
      error: (err) => {
        if (err.error.statusCode && err.error.statusCode != 404) {
          this.toaster.error(err.message, 'Error');
        }
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  ngOnInit(): void {}

  openAddPlanForm() {
    this.showActivityDialog = true;
  }

  openAddProjectForm() {
    this.showActivityDialog = true;
  }

  openEditDialog(activity: ProjectActivity) {
    // const ref = this.dialog.open(ProjectActivityFormDialogComponent, {
    //   data: {
    //     project: this.project,
    //     activity: activity,
    //   },
    // });
    //
    // ref.afterClosed().subscribe({
    //   next: (result) => {
    //     console.log(result);
    //   },
    // });
  }

  showRevisionDialog() {
    this.showForRevisionDialog = true;
  }

  updatePlanStatus(newStatus: Status) {
    this._plan.status = newStatus;
    this.store.dispatch(updatePlanStatus({ plan: this._plan }));
  }

  updatePlanStatusForRevision() {
    this._plan.status = 'For Revision';
    this._plan.revisionRemarks = this.revisionRemarks;
    this.store.dispatch(updatePlanStatus({ plan: this._plan }));
  }
}
