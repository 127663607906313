import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MenuItem, TreeNode } from 'primeng/api';
import { TreeTable } from 'primeng/treetable';
import { Plan, TLMO } from '../../../models/plans';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { deleteTlmo } from '../../../redux/library';
import { LibraryService } from '../../../utils/services';
import { TlmoFormDialogComponent } from '../tlmo-form-dialog/tlmo-form-dialog.component';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-tlmo-list',
  templateUrl: './tlmo-list.component.html',
  styleUrls: ['./tlmo-list.component.scss'],
})
export class TlmoListComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/libraries/TLMOList',
      label: 'TLMOs',
    },
  ];
  public isLoading = false;
  public plan: Plan;
  public showActivityDialog = false;
  public statuses: string[] = ['Draft', 'Approved', 'Rejected'];
  public tlmoList: TLMO[];
  public tlmoTreeList: TreeNode<TLMO>[];
  @ViewChild(TreeTable)
  private table: TreeTable;

  constructor(
    private libraryService: LibraryService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService
  ) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
  }

  private _project: TLMO;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: TLMO) {
    if (!project || !project.id) {
      return;
    }
    this._project = project;
    this.plan = project;
    this.loadData();
  }

  convertToTreeNodes(tlmos: TLMO[], parent: TLMO = null) {
    return tlmos.map((obj) => {
      const node: TreeNode<TLMO> = {
        data: obj,
        parent: {
          data: parent,
        },
        key: obj.id.toString(),
        children: obj.children ? this.convertToTreeNodes(obj.children, obj) : [],
        leaf: obj.children.length <= 0,
      };
      return node;
    });
  }

  deleteTlmo($event: Event, activity: TLMO) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this TLMO?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteTlmo({ payload: activity }));
      },
      reject: () => {},
    });
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      //dt1.filterGlobal($event.target, 'contains')
    }
  }

  getMonthChoices() {
    return monthNames;
  }

  getMonthString(monthNumber: number) {
    return monthNames[monthNumber];
  }

  loadData() {
    this.isLoading = true;
    this.libraryService.getTlmos().subscribe({
      next: (tlmos) => {
        this.tlmoList = tlmos
          .map((tlmo) => {
            let ellipsis = '...';
            if (tlmo.tlmo?.length < 57) {
              ellipsis = '';
            }
            tlmo.tlmo = `${tlmo.tlmo?.substring(0, 57)}${ellipsis}`;
            return tlmo;
          })
          .sort((a, b) => {
            return 0 - (parseInt(b.sequenceNumber) - parseInt(a.sequenceNumber));
          });
        this.tlmoTreeList = this.convertToTreeNodes(tlmos);
        this.isLoading = false;
      },
      error: (err) => {
        if (err.error.statusCode && err.error.statusCode != 404) {
          this.toaster.error(err.message, 'Error');
        }
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  openAddPlanForm() {
    this.showActivityDialog = true;
  }

  openEditDialog(tlmo: TLMO, rowNode: TreeNode<TLMO>) {
    const ref = this.dialog.open(TlmoFormDialogComponent, {
      width: '60ch',
      data: {
        project: this.project,
        tlmo: tlmo,
        tlmoList: this.tlmoList,
        parent: rowNode.parent,
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        // console.info(result);
      },
    });
  }
}
