import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { assignIn } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { exhaustMap } from 'rxjs/operators';
import { Category, Division } from '../../../models';
import { FuncObjective, Plan, Project, TLMO } from '../../../models/plans';
import { addProject, deleteProject, updateProject } from '../../../redux/plan';
import { userHasPermission } from '../../../utils/functions/has-permission';
import { LibraryService, ProjectService } from '../../../utils/services';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit {
  public categories: Category[];
  public divisions: Division[];
  public form: FormGroup;
  public isLoading = false;
  public objectives: FuncObjective[];
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  public projectStatuses = ['Active', 'Partially Completed', 'Completed', 'Deferred'];
  public projectType: 'MAIN_PROJECT' | 'SUPPLEMENTAL_PROJECT' = 'MAIN_PROJECT';
  public projects: Project[] = [];
  @Input()
  public readOnly = false;
  @Input()
  public showDeleteButton = false;
  public statusWarning = '';
  public tlmos: TLMO[];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private divisionService: LibraryService,
    private toaster: ToastrService,
    private activeRoute: ActivatedRoute,
    private projectService: ProjectService,
  ) {
    this.form = this.fb.group({
      title: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      description: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      objective: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      beneficiaries: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      timeTable: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      budget: [{ value: 0, disabled: !this.canEdit() }],
      otherSourceOfFunds: [{ value: '', disabled: !this.canEdit() }],
      status: [{ value: 'Active', disabled: !this.canEdit() }],
      tlmo: [{ value: 0, disabled: !this.canEdit() }, [Validators.required]],
      subTlmo: [{ value: 0, disabled: !this.canEdit() }],
      linkedProjectId: [{ value: 0, disabled: !this.canEdit() }],
      functionalObj: [{ value: 0, disabled: !this.canEdit() }, [Validators.required]],
      functionalObjValue: [{ value: 0, disabled: !this.canEdit() }],
      category1: [{ value: 0, disabled: !this.canEdit() }, [Validators.required]],
      category2: [{ value: 0, disabled: !this.canEdit() }],
      category3: [{ value: 0, disabled: !this.canEdit() }],
    });
    this.activeRoute.queryParams.subscribe({
      next: (params) => {
        if (params.type) {
          this.projectType = params.type;
        }
      },
    });
  }

  public _plan: Plan;

  public get plan() {
    return this._plan;
  }

  @Input()
  public set plan(plan: Plan) {
    this._plan = plan;
    if (plan?.id && this.projectType == 'SUPPLEMENTAL_PROJECT') {
      this.projectService.getProjectsByPlanId(plan?.id).subscribe({
        next: (projets) => {
          this.projects = projets;
        },
        error: (err) => {
          console.error(err);
          this.toaster.error('Error retrieving projects', 'Error');
        },
      });
    }
  }

  public _project: Project;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Project) {
    this._project = project;
    if (!this.tlmos) {
      this.divisionService
        .getTlmos()
        .pipe(
          exhaustMap((tlmos) => {
            this.tlmos = tlmos;
            return this.divisionService.getFunctionalObjectives().pipe(
              exhaustMap((objectives) => {
                this.objectives = objectives;
                return this.divisionService.getCategories();
              }),
            );
          }),
        )
        .subscribe({
          next: (categories) => {
            this.categories = categories;
          },
          error: (err) => {
            console.error(err);
          },
        });
    }
    if (this._project?.id) {
      this.form.patchValue({
        ...this._project,
        tlmo: this._project.tlmo?.length > 0 ? this._project.tlmo[0].id : null,
        subTlmo: this._project.subTlmo?.length > 0 ? this._project.subTlmo[0].id : null,
        functionalObj: this._project.functionalObj?.length > 0 ? this._project.functionalObj[0].id : null,
        functionalObjValue:
          this._project.functionalObjValue?.length > 0 ? this._project.functionalObjValue[0].id : null,
        category1: this._project.category1?.length > 0 ? this._project.category1[0].id : null,
        category2: this._project.category2?.length > 0 ? this._project.category2[0].id : null,
        category3: this._project.category3?.length > 0 ? this._project.category3[0].id : null,
      });
      this.checkFormFieldsIfEditable();
    }
  }

  canEdit() {
    if (this.readOnly) {
      return false;
    }
    if (this.project?.id) {
      const result =
        this.isProjectEditable() ||
        (userHasPermission(['project:editAny', 'project:editOwn']) && this.isPlanEditable());
      return result;
    } else {
      const result = userHasPermission(['project:createAny', 'project:createOwn']);
      return result;
    }
  }

  checkFormFieldsIfEditable() {
    for (const key in this.form.controls) {
      if (Object.prototype.hasOwnProperty.call(this.form.controls, key)) {
        const control = this.form.controls[key];

        if (key != 'status' && !this.canEdit()) {
          control.disable();
          continue;
        }
        if (key == 'status' && !this.readOnly && !this.canEdit()) {
          control.enable();
          continue;
        }
        if (key == 'status' && this.readOnly) {
          control.disable();
          continue;
        }
      }
    }
  }

  deleteProject() {
    this.store.dispatch(deleteProject({ projectId: this.project.id }));
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  getSubCategory2() {
    const chosenCategory1: Category = this.categories?.find((cat) => cat.id == this.getFormControl('category1').value);
    return chosenCategory1?.children ?? [];
  }

  getSubCategory3() {
    const chosenCategory2: Category = this.getSubCategory2()?.find(
      (cat) => cat.id == this.getFormControl('category2').value,
    );
    return chosenCategory2?.children ?? [];
  }

  getSubObjectives() {
    const chosenObjective: FuncObjective = this.objectives?.find(
      (obj) => obj.id == this.getFormControl('functionalObj').value,
    );
    return chosenObjective?.children ?? [];
  }

  getSubTlmos() {
    const chosenTlmo: TLMO = this.tlmos?.find((tlmo) => tlmo.id == this.getFormControl('tlmo').value);
    return chosenTlmo?.children ?? [];
  }

  isFormControlInvalid(name: string) {
    return (this.getFormControl(name).touched || this.getFormControl(name).dirty) && this.getFormControl(name).invalid;
  }

  isPlanEditable() {
    return this.plan.status == 'Draft' || this.plan.status == 'For Revision';
  }

  isProjectEditable() {
    if (this.plan.status == 'Approved') {
      return this.project.projectType == 'SUPPLEMENTAL_PROJECT';
    } else {
      return this.project.projectType == 'MAIN_PROJECT';
    }
  }

  ngOnInit(): void {
  }

  public onProjectStatusChange(event: string) {
    if (this.getFormControl('status').value == 'Completed') {
      this.statusWarning = 'Completing this project will prevent it from being updated in the future.';
    } else {
      this.statusWarning = '';
    }
  }

  resetForm() {
    if (this._project && this._project.id) {
      this.form.patchValue({
        ...this._project,
        tlmo: this._project.tlmo?.length > 0 ? this._project.tlmo[0].id : null,
        subTlmo: this._project.subTlmo?.length > 0 ? this._project.subTlmo[0].id : null,
        functionalObj: this._project.functionalObj?.length > 0 ? this._project.functionalObj[0].id : null,
        functionalObjValue:
          this._project.functionalObjValue?.length > 0 ? this._project.functionalObjValue[0].id : null,
        category1: this._project.category1?.length > 0 ? this._project.category1[0].id : null,
        category2: this._project.category2?.length > 0 ? this._project.category2[0].id : null,
        category3: this._project.category3?.length > 0 ? this._project.category3[0].id : null,
      });
    } else {
      this.form.reset({
        title: '',
        description: '',
        objective: '',
        beneficiaries: '',
        milestones: '',
        timeTable: '',
        budget: '',
        otherSourceOfFunds: '',
        tlmo: null,
        subTlmo: null,
        functionalObj: null,
        functionalObjValue: null,
        category1: null,
        category2: null,
        category3: null,
      });
    }
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.markAsDirty();
    if (!this.form.valid) {
      this.toaster.error('Please check if everything\'s valid', 'Error');
      return false;
    }
    this.isLoading = true;
    const formValue = this.form.getRawValue();
    if (!this._project?.projectType) {
      if (this.plan && (this.plan.status == 'Draft' || this.plan.status == 'For Revision')) {
        this._project.projectType = 'MAIN_PROJECT';
      } else {
        this._project.projectType = 'SUPPLEMENTAL_PROJECT';
      }
    }
    this._project = assignIn(this._project, formValue);

    this._project.tlmo = formValue.tlmo ? [this.tlmos.find((tlmo) => tlmo.id == formValue.tlmo)] : [];
    this._project.subTlmo = formValue.subTlmo
      ? [this._project.tlmo[0].children.find((tlmo) => tlmo.id == formValue.subTlmo)]
      : [];
    this._project.functionalObj = formValue.functionalObj
      ? [this.objectives.find((obj) => obj.id == formValue.functionalObj)]
      : [];
    this._project.functionalObjValue = formValue.functionalObjValue
      ? [this._project.functionalObj[0].children.find((obj) => obj.id == formValue.functionalObjValue)]
      : [];
    this._project.category1 = formValue.category1 ? [this.categories.find((cat) => cat.id == formValue.category1)] : [];
    this._project.category2 = formValue.category2
      ? [this._project.category1[0].children.find((cat) => cat.id == formValue.category2)]
      : [];
    this._project.category3 = formValue.category3
      ? [this._project.category2[0].children.find((cat) => cat.id == formValue.category3)]
      : [];
    this._project.plan = this.plan;

    if (this.project.id) {
      this.store.dispatch(updateProject({ project: this.project }));
    } else {
      // this.project.division = this.selectedDivision;
      this.store.dispatch(addProject({ project: this.project }));
    }
    return true;
  }
}
