<div class="page-wrapper">
  <div class="header flex justify-between items-center">
    <h2 class="mb-2 md:mb-4">Roles</h2>
  </div>
  <div class="content-wrapper relative w-full-no-scroll shadow-md">
    <p-table
      #dt1
      [globalFilterFields]="['title', 'roleName']"
      [loading]="isLoading"
      [paginator]="true"
      [rowsPerPageOptions]="[10, 25, 50]"
      [rows]="10"
      [showCurrentPageReport]="true"
      [value]="roles"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      dataKey="id"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines w-screen md:w-full flow-root"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-between items-center">
          <button
            (click)="openEditDialog()"
            *hasPermissions="['user:createAny', 'user:createOwn']"
            class="p-button-outlined"
            icon="pi pi-plus"
            label="Add"
            pButton
          ></button>
          <div class="p-d-flex flex justify-between gap-2">
            <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input (input)="filter($event)" pInputText placeholder="Search keyword" type="text" />
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="title">
            <div class="p-d-flex p-jc-between p-ai-center">
              Role
              <p-columnFilter display="menu" field="title" type="text"></p-columnFilter>
              <p-sortIcon field="title"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="roleName">
            <div class="p-d-flex p-jc-between p-ai-center">
              Code
              <p-columnFilter display="menu" field="roleName" type="text"></p-columnFilter>
              <p-sortIcon field="roleName"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="active">
            <div class="p-d-flex p-jc-between p-ai-center">
              Active
              <p-columnFilter display="menu" field="active" matchMode="equals">
                <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                  <p-dropdown
                    (onChange)="filter($event.value)"
                    [filter]="false"
                    [ngModel]="value"
                    [options]="[]"
                    placeholder="Any"
                  >
                    <ng-template let-option pTemplate="item">
                      <span>{{ option }}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
              <p-sortIcon field="active"></p-sortIcon>
            </div>
          </th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template let-expanded="expanded" let-role pTemplate="body">
        <tr class="dark:bg-gray-700">
          <td>
            {{ role.title }}
          </td>
          <td>
            {{ role.roleName }}
          </td>
          <td>
            <span [class]="'customer-badge status-' + role.status">{{ role.active }}</span>
          </td>
          <td>
            <div>
              <button
                (click)="openEditDialog(role)"
                class="p-button-rounded p-button-outlined"
                icon="pi pi-pencil"
                pButton
                pRipple
                pTooltip="Edit role"
                type="button"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template let-user pTemplate="rowexpansion">
        <tr class="expanded-tr-logs">
          <td colspan="4">
            <div class="content">
              <div class="form grid grid-cols-1 md:grid-cols-2">
                <div class="data">
                  <span><strong>First Name: </strong></span>
                  <span>{{ user.firstName }}</span>
                </div>

                <div class="data">
                  <span><strong>Last Name: </strong></span>
                  <span>{{ user.lastName }}</span>
                </div>

                <div class="data">
                  <span><strong>Mobile Number: </strong></span>
                  <span>{{ user.mobile }}</span>
                </div>

                <div class="data">
                  <span><strong>Email: </strong></span>
                  <span>{{ user.email }}</span>
                </div>

                <div class="data">
                  <span><strong>Referrer: </strong></span>
                  <span>{{ user.referralName }}</span>
                </div>
              </div>
            </div>

            <div class="buttons flex gap-2">
              <div *ngIf="!isUserActivated(user)" class="resend-verification-email-button-container w-max">
                <app-button
                  (click)="resendUserVerificationEmail(user)"
                  [block]="true"
                  [disabled]="user.isLoading"
                  [loading]="user.isLoading"
                  [text]="'Resend email verification'"
                  [type]="'button'"
                  class="bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md"
                >
                </app-button>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No users found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
