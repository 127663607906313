import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Division, DivisionUnit, User } from '../../../models';
import { Plan } from '../../../models/plans';
import { addPlan, deletePlan, updatePlan } from '../../../redux/plan';
import { userHasPermission } from '../../../utils/functions/has-permission';
import { AuthService, LibraryService } from '../../../utils/services';

@Component({
  selector: 'app-plan-form',
  templateUrl: './plan-form.component.html',
  styleUrls: ['./plan-form.component.scss'],
})
export class PlanFormComponent implements OnInit, OnDestroy {
  public divisions: DivisionUnit[];
  public form: FormGroup;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  public selectedDivision: Division;
  @Input()
  public showButtons = false;
  @Input()
  public showDeleteButton = false;
  public yearChoices: string[] = [];
  private currentUser: User;
  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private divisionService: LibraryService,
    private toaster: ToastrService,
    private authService: AuthService
  ) {
    this.form = this.fb.group({
      year: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      division: [{ value: null, disabled: !this.canEdit() }],
    });
    this.currentUser = this.authService.getUserObject();
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    for (let i = nextYear; i >= currentYear - 5; i--) {
      this.yearChoices.push(i.toString());
    }
    this.getFormControl('year').setValue(currentYear.toString());
  }

  private _plan: Plan;

  public get plan() {
    return this._plan;
  }

  @Input()
  public set plan(plan: Plan) {
    if (this._plan && this._plan.id && this._plan.id == plan.id) {
      return;
    }
    this._plan = plan;
    this.subscription = this.divisionService.getDivisionUnits().subscribe({
      next: (divisions) => {
        this.divisions = divisions;
        this.getFormControl('division').disable();
        if (plan && plan.id) {
          const selectedDivision = this.divisions.filter((division) => division.id == plan.division.id)[0];
          this.getFormControl('year').setValue(plan.year.toString());
          this.getFormControl('division').setValue(selectedDivision);
        } else {
          const selectedDivision = this.divisions.filter((division) => division.id == this.currentUser.divisionId)[0];
          this._plan = {
            division: selectedDivision,
          };
          this.form.patchValue({
            division: selectedDivision,
          });
        }
      },
    });
  }

  public canEdit() {
    return userHasPermission(['plan:editAny']);
  }

  public deletePlan() {
    this.store.dispatch(deletePlan({ planId: this.plan.id }));
  }

  public getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public ngOnInit(): void {}

  public submit() {
    const formValue = this.form.getRawValue();
    if (!formValue.year) {
      this.toaster.error('Please select a year', 'Error');
      return false;
    }
    if (!formValue.division) {
      this.toaster.error('Please select a division', 'Error');
      return false;
    }

    this.isLoading = true;
    this._plan.year = formValue.year;
    this._plan.division = formValue.division;

    if (this.plan.id) {
      this.store.dispatch(updatePlan({ plan: this.plan }));
    } else {
      this.store.dispatch(addPlan({ plan: this.plan }));
    }
    this.onSubmit.emit('Done');

    return true;
  }
}
