import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { City, Country, State } from '../../../models';
import { saveCountry } from '../../../redux';

@Component({
  selector: 'app-city-form',
  templateUrl: './city-form.component.html',
  styleUrls: ['./city-form.component.scss'],
})
export class CityFormComponent implements OnInit {
  @Input()
  public country: Country;

  @Input()
  public stateIndex = -1;

  @Input()
  public cityIndex = -1;

  @Input()
  public currentUrl: string;

  @Input()
  public showDeleteButton = false;

  public form: FormGroup;
  public isLoading = false;
  public state: State;
  public city: City;

  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();

  constructor(private fb: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      description: [''],
    });

    if (this.stateIndex > -1 && this.cityIndex > -1) {
      this.state = this.country.stateList[this.stateIndex];
      this.city = this.state.cityList[this.cityIndex];
      this.form.setValue({
        name: this.city.name,
        code: this.city.code,
        description: this.city.description,
      });
    }
  }

  getNameControl() {
    return this.form.get('name') as FormControl;
  }
  getCodeControl() {
    return this.form.get('code') as FormControl;
  }
  getDescriptionControl() {
    return this.form.get('description') as FormControl;
  }

  submit() {
    this.isLoading = true;
    if (this.cityIndex > -1) {
      this.city.name = this.form.value.name;
      this.city.code = this.form.value.code;
      this.city.description = this.form.value.description;

      this.country.stateList[this.stateIndex].cityList[this.cityIndex] =
        this.city;
    } else {
      if (this.country.stateList[this.stateIndex].cityList) {
        this.country.stateList[this.stateIndex].cityList.push({
          ...this.form.value,
        });
      } else {
        this.country.stateList[this.stateIndex].cityList = [
          {
            ...this.form.value,
          },
        ];
      }
    }

    this.store.dispatch(
      saveCountry({ payload: { ...this.country, currentUrl: this.currentUrl } })
    );
    this.onSubmit.emit('Done');
  }

  deleteCity() {
    if (
      this.country.stateList &&
      this.country.stateList[this.stateIndex].cityList
    ) {
      this.country.stateList[this.stateIndex].cityList.splice(
        this.cityIndex,
        1
      );
    }
    this.store.dispatch(
      saveCountry({ payload: { ...this.country, currentUrl: this.currentUrl } })
    );
  }
}
