import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Plan, Project } from '../../../models/plans';
import { ProjectService } from '../../../utils/services';

type DialogData = {
  project: Project;
  plan: Plan;
};

@Component({
  selector: 'app-project-form-dialog',
  templateUrl: './project-form-dialog.component.html',
  styleUrls: ['./project-form-dialog.component.scss'],
})
export class ProjectFormDialogComponent implements OnInit {
  public project: Project = {
    plan: {},
  };
  public plan: Plan;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private projectService: ProjectService
  ) {
    const data = config.data as DialogData;
    const project = data.project;
    this.projectService.getProjectById(project.id).subscribe({
      next: (value) => {
        this.project = value;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  close() {
    this.ref.close();
  }

  ngOnInit(): void {}

  onSubmit(event) {
    this.ref.close();
  }
}
