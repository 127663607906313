import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Plan } from '../../../models/plans';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { AuthService, PlanService } from '../../../utils/services';
import { deletePlan } from '../../../redux/plan';
import { PlanFormDialogComponent } from '../../../components/plans';
import { User } from '../../../models';
import { Router } from '@angular/router';

type PlanWithEditing = Plan & { isEditing?: boolean };

@Component({
  selector: 'app-plans-list-page',
  templateUrl: './plans-list-page.component.html',
  styleUrls: ['./plans-list-page.component.scss'],
  providers: [DialogService],
})
export class PlansListPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      label: 'Plans',
    },
  ];
  public isLoading = false;
  public plans: PlanWithEditing[];
  public showActivityDialog = false;
  public statuses: string[] = ['Draft', 'Approved', 'Rejected'];
  private currentUser: User;
  @ViewChild(Table)
  private table: Table;

  constructor(
    private planService: PlanService,
    private dialogService: DialogService,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private router: Router
  ) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
    this.currentUser = this.authService.getUserObject();
  }

  deletePlan(event: Event, plan: Plan) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this plan?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deletePlan({ planId: plan.id }));
      },
      reject: () => {},
    });
  }

  editPlan($event: MouseEvent, plan: Plan) {
    const dialogRef = this.dialogService.open(PlanFormDialogComponent, {
      header: 'Edit Plan',
      width: 'clamp(250px, 50%, 700px)',
      data: {
        plan: plan,
      },
    });
    dialogRef.onClose.subscribe({
      next: (result) => {
        console.log('onClose:', result);
      },
    });
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      // dt1.filterGlobal($event.target, 'contains')
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    let divisionId = 0;
    if (this.currentUser.username == 'admin') {
      this.router.navigate(['/users/list']);
    }
    if (this.currentUser.divisionId) {
      divisionId = this.currentUser.divisionId;
    }
    this.planService.getPlans(currentYear - 5, nextYear, divisionId).subscribe({
      next: (result) => {
        this.plans = result;
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  onRowEditCancel(plan: Plan) {}

  onRowEditInit(plan: Plan) {}

  onRowEditSave(plan: Plan) {}

  openAddPlanForm() {
    this.showActivityDialog = true;
  }
}
