import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { AppConfigService } from './app-config.service';
import { slideInAnimation } from './utils/animations/route-animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent {
  compactMode = false;
  horizontal = true;
  inputStyle = 'outlined';
  layoutColor = 'blue';
  menuColor = 'layout-menu-light';
  ripple = true;
  themeColor = 'blue';
  title = 'dashboard';
  topbarColor = 'layout-topbar-blue';
  topbarSize = 'medium';
  private gtagId: string;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private appConfigService: AppConfigService
  ) {}

  addGtag() {
    if (!environment.local) {
      const gtagLib = this.renderer.createElement('script');
      gtagLib.src = `https://www.googletagmanager.com/gtag/js?id=${this.gtagId}`;
      this.renderer.appendChild(this.document.body, gtagLib);

      const gtagContents = this.renderer.createElement('script');
      gtagContents.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', '${this.gtagId}');
        `;

      this.renderer.appendChild(this.document.body, gtagContents);
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
