import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TLMO } from '../../../models/plans';
import { addTlmo, deleteTlmo, updateTlmo } from '../../../redux/library';
import { userHasPermission } from '../../../utils/functions/has-permission';
import { LibraryService, ProjectActivityService } from '../../../utils/services';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-tlmo-form',
  templateUrl: './tlmo-form.component.html',
  styleUrls: ['./tlmo-form.component.scss'],
})
export class TlmoFormComponent implements OnInit {
  public form: FormGroup;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  @Input()
  public showDeleteButton = false;
  @Input()
  public tlmoList: TLMO[] = [];
  public yearChoices: string[] = [];
  private monthChoices: { name: string; id: string }[];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private divisionService: LibraryService,
    private toaster: ToastrService,
    private projectActivityService: ProjectActivityService
  ) {
    this.form = this.fb.group({
      parent: [{ value: null, disabled: !this.canEdit() }],
      sequenceNumber: [{ value: null, disabled: !this.canEdit() }, [Validators.required]],
      tlmo: [{ value: null, disabled: !this.canEdit() }, [Validators.required]],
    });
  }

  private _parent: TLMO;

  public get parent() {
    return this._parent;
  }

  @Input()
  public set parent(parent: TLMO) {
    this._parent = parent;
    if (parent?.id) {
      this.getFormControl('parent').setValue(parent);
    }
  }

  private _project: TLMO;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: TLMO) {
    this._project = project;
  }

  private _tlmo: TLMO;

  public get tlmo() {
    return this._tlmo;
  }

  @Input()
  public set tlmo(tlmo: TLMO) {
    this._tlmo = tlmo;
    this.form.patchValue({
      ...this._tlmo,
    });
  }

  canEdit() {
    const result = userHasPermission(['library:editAny', 'library:createAny']);
    return result;
  }

  deleteTlmo() {
    this.store.dispatch(deleteTlmo({ payload: this.project }));
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  isFormControlInvalid(name: string) {
    return (this.getFormControl(name).touched || this.getFormControl(name).dirty) && this.getFormControl(name).invalid;
  }

  ngOnInit(): void {}

  onStartMonthSelect(event: any, value: string) {
    console.log(event);
    console.log(value);
  }

  resetForm() {
    this.form.reset({
      task: '',
      assignedTo: '',
    });
  }

  submit() {
    this.form.markAsTouched();
    this.form.markAsDirty();
    if (this.form.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }
    this.isLoading = true;
    const selectedParent: TLMO = this.getFormControl('parent').value;
    const newTlmo: TLMO = Object.assign(this._tlmo ?? {}, this.form.value);
    if (selectedParent?.id) {
      newTlmo.parent = {
        ...selectedParent,
        children: [],
      };
    }
    if (newTlmo.id) {
      this.store.dispatch(updateTlmo({ payload: newTlmo }));
    } else {
      this.store.dispatch(addTlmo({ payload: newTlmo }));
    }
    return true;
  }
}
