import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Project, ProjectActivity } from '../../../models/plans';
import { addProjectActivity, deletePlan, updateProjectActivity } from '../../../redux/plan';
import { userHasPermission } from '../../../utils/functions/has-permission';
import { LibraryService, ProjectActivityService } from '../../../utils/services';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-project-activity-form',
  templateUrl: './project-activity-form.component.html',
  styleUrls: ['./project-activity-form.component.scss'],
})
export class ProjectActivityFormComponent implements OnInit {
  public form: FormGroup;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  @Input()
  public showDeleteButton = false;
  public yearChoices: string[] = [];
  private monthChoices: { name: string; id: string }[];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private divisionService: LibraryService,
    private toaster: ToastrService,
    private projectActivityService: ProjectActivityService
  ) {
    this.form = this.fb.group({
      task: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      assignedTo: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      startMonth: [{ value: '', disabled: false }, [Validators.required]],
      startYear: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      endMonth: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      endYear: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      milestones: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      remarks: [{ value: '', disabled: !this.canEdit() }],
    });

    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 1; i >= currentYear - 5; i--) {
      this.yearChoices.push(i.toString());
    }
    this.getFormControl('startYear').setValue(currentYear.toString());
    this.getFormControl('endYear').setValue(currentYear.toString());
  }

  private _activity: ProjectActivity;

  public get activity() {
    return this._activity;
  }

  @Input()
  public set activity(activity: ProjectActivity) {
    this._activity = activity;
    this.form.patchValue({
      ...this._activity,
      startMonth: this._activity.startMonth.toString(),
      endMonth: this._activity.endMonth.toString(),
      startYear: this._activity.startYear.toString(),
      endYear: this._activity.endYear.toString(),
    });
  }

  private _project: Project;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Project) {
    this._project = project;
  }

  canEdit() {
    const result = userHasPermission(['plan:editAny']);
    return result;
  }

  deletePlan() {
    this.store.dispatch(deletePlan({ planId: this.project.id }));
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  getMonths() {
    if (!this.monthChoices) {
      this.monthChoices = monthNames.map((month, index) => ({
        name: month,
        id: index.toString(),
      }));
    }

    return this.monthChoices;
  }

  isFormControlInvalid(name: string) {
    return (this.getFormControl(name).touched || this.getFormControl(name).dirty) && this.getFormControl(name).invalid;
  }

  ngOnInit(): void {}

  onStartMonthSelect(event: any, value: string) {
    console.log(event);
    console.log(value);
  }

  resetForm() {
    this.form.reset({
      task: '',
      assignedTo: '',
      startMonth: 0,
      startYear: 0,
      endMonth: 0,
      endYear: 0,
      milestones: '',
      remarks: '',
    });
  }

  submit() {
    this.form.markAsTouched();
    this.form.markAsDirty();
    if (this.form.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }
    this.isLoading = true;
    const projectActivity: ProjectActivity = Object.assign(this._activity ?? {}, this.form.value);
    projectActivity.project = this.project;
    if (projectActivity.id) {
      this.store.dispatch(updateProjectActivity({ projectActivity: projectActivity }));
    } else {
      this.store.dispatch(addProjectActivity({ projectActivity: projectActivity }));
    }
    return true;
  }
}
