import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthService, UserService } from '../../../utils/services';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../models';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
})
export class UserProfileFormComponent implements OnInit {
  public accountForm: FormGroup;
  public isAuthLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  public profileForm: FormGroup;
  public referralForm: FormGroup;
  @Input()
  public showDeleteButton = false;
  public userTypes = ['INTERNAL', 'EXTERNAL'];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService
  ) {
    this.getProfileForm();
  }

  private _user: User;

  get user() {
    return this._user;
  }

  @Input()
  set user(user: User) {
    this._user = user;
    if (!!user?.id) {
      this.profileForm.patchValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobile: user.mobile,
      });
    }
  }

  getFirstnameControl() {
    return this.profileForm.get('firstName') as FormControl;
  }

  getFormControl(name: string) {
    return this.profileForm.get(name) as FormControl;
  }

  getProfileForm() {
    if (!this.profileForm) {
      this.profileForm = this.fb.group(
        {
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.required, Validators.email], [this.checkEmailIfExists()]],
          mobile: ['', [Validators.required, Validators.pattern('[0-9]{8,}')], [this.checkMobileIfExists()]],
        },
        {
          updateOn: 'blur',
        }
      );
    }
    return this.profileForm;
  }

  ngOnInit(): void {}

  private apiCheckEmailExists(email: string) {
    return this.userService.checkEmailIfExists(email).pipe(
      map((isEmailExists) => {
        if (isEmailExists) {
          return { isEmailExists };
        }
        return null;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  private apiCheckMobileExists(mobile: string) {
    return this.userService.checkMobileIfExists(mobile).pipe(
      map((isMobileExists) => {
        if (isMobileExists) {
          return { isMobileExists };
        }
        return null;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  private checkEmailIfExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (this.user && this.user.id) {
        if (this.user.email !== control.value) {
          return this.apiCheckEmailExists(control.value);
        }
        return of(null);
      }
      return this.apiCheckEmailExists(control.value);
    };
  }

  private checkMobileIfExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      if (this.user && this.user.id) {
        if (this.user.mobile !== control.value) {
          return this.apiCheckMobileExists(control.value);
        }
        return of(null);
      }
      return this.apiCheckMobileExists(control.value);
    };
  }
}
