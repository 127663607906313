<div class='list-countries-wrapper relative'>
  <div class='header flex justify-between mb-4'>
    <h1 class='text-lg md:text-3xl'>Orders - {{orderStatus.toUpperCase()}}</h1>
    <div class='buttons'>
      <!--      <app-button (click)="openAddCountryForm()" [type]="'button'" [disabled]="false" [block]="true"-->
      <!--                  [text]="'Add Product'"-->
      <!--                  class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md" [loading]="false">-->
      <!--      </app-button>-->
    </div>
  </div>
  <app-order-list [orderStatus]='orderStatus ? orderStatus.toUpperCase() : "PENDING"'></app-order-list>
</div>
