<h2>Categories</h2>
<div class='content-wrapper w-full-no-scroll shadow-md'>
  <p-treeTable
    #dt1
    [globalFilterFields]="['sequenceNumber', 'name']"
    [loading]='isLoading'
    [paginator]='true'
    [rowsPerPageOptions]='[10, 25, 50]'
    [rows]='10'
    [showCurrentPageReport]='true'
    [value]='categoryTreeList'
    class='block w-full-no-scroll'
    currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
    dataKey='id'
    responsiveLayout='scroll'
    sortField='sequenceNumber'
    styleClass='p-datatable-gridlines w-screen md:w-full flow-root'
  >
    <ng-template pTemplate='caption'>
      <div class='flex flex-col md:flex-row justify-between items-start md:items-center'>
        <ng-container>
          <button
            (click)='openAddPlanForm()'
            *hasPermissions="['library:createAny']"
            class='p-button-outlined'
            icon='pi pi-plus'
            label='Add'
            pButton
          ></button>
        </ng-container>
        <div class='p-d-flex flex justify-between gap-2'>
          <span class='p-input-icon-left p-ml-auto'>
            <i class='pi pi-search'></i>
            <input (input)='filter($event)' pInputText placeholder='Search keyword' type='text' />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate='header'>
      <tr>
        <th ttSortableColumn='sequenceNumber'>
          Sequence Number
          <p-treeTableSortIcon field='sequenceNumber'></p-treeTableSortIcon>
        </th>
        <th ttSortableColumn='name'>
          Category
          <p-treeTableSortIcon field='name'></p-treeTableSortIcon>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>

    <ng-template let-category='rowData' let-rowNode pTemplate='body'>
      <tr class='dark:bg-gray-700'>
        <td>
          <p-treeTableToggler [rowNode]='rowNode'></p-treeTableToggler>
          <span *ngIf='rowNode.parent'>
            {{ rowNode.parent.data.sequenceNumber }}.{{ category.sequenceNumber }}
          </span>
          <span *ngIf='!rowNode.parent'>
            {{ category.sequenceNumber }}
          </span>
        </td>
        <td>
          {{ category.name }}
        </td>
        <td>
          <div class='flex gap-2'>
            <button
              (click)='openEditDialog(category, rowNode)'
              *hasPermissions="['library:editAny']"
              class='p-button-rounded p-button-outlined'
              icon='pi pi-pencil'
              pButton
              pRipple
              pTooltip='Edit Category'
              type='button'
            ></button>
            <button
              (click)='deleteCategory($event, category)'
              *hasPermissions="['library:editAny']"
              class='p-button-rounded p-button-outlined p-button-danger'
              icon='pi pi-times'
              pButton
              pRipple
              pTooltip='Delete Category'
              type='button'
            ></button>
            <!-- <button
              (click)="openAddDialog(rowNode)"
              *hasPermissions="['library:editAny']"
              class="p-button-rounded p-button-outlined"
              icon="pi pi-plus"
              pButton
              pRipple
              pTooltip="Add Child Category"
              type="button"
            ></button> -->
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template let-commission pTemplate='rowexpansion'>
      <tr class='expanded-tr-logs'>
        <td colspan='9'>
          <div class='details'></div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate='emptymessage'>
      <tr>
        <td colspan='3'>No categories found.</td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>

<p-dialog
  *ngIf='showActivityDialog'
  [(visible)]='showActivityDialog'
  [blockScroll]='true'
  [breakpoints]="{ '960px': '75vw' }"
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: 'clamp(250px, 120ch, 90vw)', overflowY: 'auto !important', zIndex: '9999' }"
  contentStyleClass='overflow-y-auto lg:overflow-y-visible'
  header='Add Category'
  styleClass='overflow-y-visible'
>
  <app-category-form #activityForm [categoryList]='categoryTreeList' [project]='project'></app-category-form>
  <ng-template pTemplate='footer'>
    <p-button
      (click)='showActivityDialog = false; activityForm.resetForm()'
      icon='pi pi-cross'
      label='Cancel'
      styleClass='p-button-outlined p-button-danger'
    ></p-button>
    <p-button (click)='showActivityDialog = !activityForm.submit()' icon='pi pi-check' label='Save'></p-button>
  </ng-template>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
