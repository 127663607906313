<div class='dialogWrapper'>
  <h1 class='dark:text-gray-200' mat-dialog-title>Switch Division</h1>
  <mat-dialog-content class='overflow-visible'>
    <div class='content mb-4'>
      <app-switch-division-fom #form></app-switch-division-fom>
    </div>
  </mat-dialog-content>
</div>
<div class='flex gap-2' mat-dialog-actions>
  <button class='p-button-outlined p-button-secondary' label='Cancel' mat-dialog-close pButton pRipple
          type='button'></button>
  <button (click)='form.submitForm()' cdkFocusInitial label='Save' pButton pRipple type='button' class='bg-blue-500'></button>
</div>
