<div class="non-stepper">
  <h4 class="-mb-1 bg-white shadow-md rounded-t-lg w-max px-4 py-2">Profile Form</h4>
  <div class="profile-form mb-4 form bg-white rounded-r-lg rounded-b-lg shadow-md p-10 dark:bg-gray-700">
    <app-user-profile-form [user]="user"></app-user-profile-form>
  </div>
  <h4 class="-mb-1 bg-white shadow-md rounded-t-lg w-max px-4 py-2">Account Form</h4>
  <div class="account-form mb-4 form bg-white rounded-r-lg rounded-b-lg shadow-md p-10 dark:bg-gray-700">
    <app-user-account-form
      [user]="user"
      [hasPassword]="hasPassword"
      [userDivision]="division"
    ></app-user-account-form>
  </div>
</div>

<div class="buttons flex w-full gap-4">
  <app-button
    (click)="submit()"
    [block]="isLoading"
    [disabled]="isLoading"
    [loading]="isLoading"
    [text]="user ? 'Save' : 'Add User'"
    [type]="'button'"
    class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md"
  >
  </app-button>

  <app-button
    (click)="close()"
    *ngIf="!user"
    [block]="isLoading"
    [disabled]="isLoading"
    [loading]="isLoading"
    [text]="'Cancel'"
    [type]="'button'"
    class="w-max bg-transparent text-black border border-gray-700 hover:bg-gray-100 rounded-md dark:border-gray-200"
  >
  </app-button>

  <app-button
    (click)="deleteUser()"
    *ngIf="user && showDeleteButton"
    [block]="isLoading"
    [disabled]="isLoading"
    [loading]="isLoading"
    [text]="'Delete User'"
    [type]="'button'"
    class="w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md"
  >
  </app-button>
</div>
