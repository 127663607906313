import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Country } from '../../../models';
import { addCountry } from '../../../redux';

@Component({
  selector: 'app-add-country-form',
  templateUrl: './add-country-form.component.html',
  styleUrls: ['./add-country-form.component.scss'],
})
export class AddCountryFormComponent implements OnInit {
  public form: FormGroup;
  public isLoading = false;

  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();

  constructor(private fb: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      description: [''],
    });
  }

  getNameControl() {
    return this.form.get('name') as FormControl;
  }
  getCodeControl() {
    return this.form.get('code') as FormControl;
  }
  getDescriptionControl() {
    return this.form.get('description') as FormControl;
  }

  submit() {
    const country: Country = {
      ...this.form.value,
    };
    this.store.dispatch(addCountry({ payload: country }));
    this.onSubmit.emit('Done');
  }
}
