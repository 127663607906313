import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AppConfigService } from '../../app-config.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Fulfillment, Invoice, Payment } from '../../models/ops';

interface InvoiceWrapper {
  invoice: Invoice;
}

interface PaymentWrapper {
  payment: Payment;
}

interface FulfillmentWrapper {
  fulfillment: Fulfillment;
}

@Injectable({
  providedIn: 'root',
})
export class OpsService {
  private opsApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (
      this.appConfigService.getConfig() &&
      this.appConfigService.getConfig().opsApi
    ) {
      this.opsApi = this.appConfigService.getConfig().opsApi;
    } else {
      this.opsApi = environment.opsApi;
    }
  }

  getInvoices(origin: string, status = 'PENDING'): Observable<Invoice[]> {
    const url = `${this.opsApi}/invoice/list/${origin}/${status}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<InvoiceWrapper[]>(url, { headers }).pipe(
      map((invoiceWrappers) => {
        return invoiceWrappers.map((wrapper) => wrapper.invoice);
      })
    );
  }

  getInvoice(orderId: string): Observable<Invoice> {
    const url = `${this.opsApi}/invoice/order/${orderId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<InvoiceWrapper>(url, { headers }).pipe(
      map((invoiceWrapper) => {
        return invoiceWrapper.invoice;
      })
    );
  }

  addInvoicePaymentStatus(
    payment: Payment,
    invoiceId: string
  ): Observable<Invoice> {
    const url = `${this.opsApi}/invoice/addPayment/${invoiceId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    const paymentWrapper: PaymentWrapper = { payment: payment };
    return this.httpClient
      .put<InvoiceWrapper>(url, paymentWrapper, { headers })
      .pipe(
        map((invoiceWrapper) => {
          return invoiceWrapper.invoice;
        })
      );
  }

  addInvoiceFulfillmentStatus(
    fulfillment: Fulfillment,
    invoiceId: string
  ): Observable<Invoice> {
    const url = `${this.opsApi}/invoice/addFulfillment/${invoiceId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    const fulfillmentWrapper: FulfillmentWrapper = { fulfillment: fulfillment };
    return this.httpClient
      .put<InvoiceWrapper>(url, fulfillmentWrapper, { headers })
      .pipe(
        map((invoiceWrapper) => {
          return invoiceWrapper.invoice;
        })
      );
  }
}
