import { FormControl, FormGroup } from '@angular/forms';

export class ParentForm {
  private _form: FormGroup;

  public get form() {
    return this._form;
  }

  public set form(form: FormGroup) {
    this._form = form;
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  getFormControlErrors(name: string) {
    return this.getFormControl(name).errors as ReadonlyMap<any, any>;
  }

  isFormControlInvalid(name: string) {
    return (this.getFormControl(name).touched || this.getFormControl(name).dirty) && this.getFormControl(name).invalid;
  }
}
