<div class="main-content mb-4 flex flex-col gap-4">
  <div class="grid grid-cols-1 gap-2 lg:gap-0 md:grid-cols-3 items-end">
    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label class="font-raleway font-bold">Reporting Year: *</label>
      &nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500'
        pTooltip='Fiscal Year of when the PPAs are planned and implemented' tooltipPosition="left"></i>
      <p-dropdown [formControl]="getFormControl('reportingYear')" [options]="yearChoices" inputId="reportingYear"
        label="Reporting Year" placeholder="Select reporting year" scrollHeight="300px"
        styleClass="w-full rounded-none"></p-dropdown>
      <p>
        <i *ngIf="isFormControlInvalid('reportingYear')" class="text-red-600">Reporting Year is required</i>&nbsp;
      </p>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label class="font-raleway font-bold">Reporting Month: *</label>
      &nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500'
        pTooltip='Month covering the implementation and reporting of the accomplishment of the PPA'
        tooltipPosition="left"></i>
      <p-dropdown [formControl]="getFormControl('reportingMonth')" [options]="getMonths()" inputId="reportingMonth"
        label="Reporting Month" optionLabel="name" optionValue="id" placeholder="Select reporting month"
        scrollHeight="300px" styleClass="w-full rounded-none"></p-dropdown>
      <p>
        <i *ngIf="isFormControlInvalid('reportingMonth')" class="text-red-600">Reporting Month is required</i>&nbsp;
      </p>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class="block w-full">
        <label class="font-raleway font-bold">
          Budget Utilized for the Month per Program/Project/Activity: *
        </label>
        &nbsp;
        <i class='pi pi-fw pi-question-circle text-gray-500'
          pTooltip='Actual cost/ budget used in the implementation of the PPA' tooltipPosition="left"></i>
        <p-inputNumber [formControl]="getFormControl('actualCostOfBudget')" [maxFractionDigits]="5"
          [minFractionDigits]="2" class="w-full" inputId="actualCostOfBudget" inputStyleClass="rounded-none w-full"
          mode="decimal" styleClass="w-full">
        </p-inputNumber>
        <p>
          <i *ngIf="isFormControlInvalid('actualCostOfBudget')" class="text-red-600">Budget Utilized for the Month per
            Program/Project/Activity is required</i>&nbsp;
        </p>
      </span>
    </div>
  </div>

  <!-- <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold'> Timeline/Target for each Program/Project/Activity: * </label>
      <textarea [formControl]="getFormControl('milestones')" class='w-full' pInputTextarea rows='3'></textarea>
      <ul
        *ngIf="
          (getFormControl('milestones').touched || getFormControl('milestones').dirty) &&
          getFormControl('milestones').invalid
        "
      >
        <ng-container *ngFor="let err of getFormControlErrors('milestones') | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a milestone</li>
        </ng-container>
      </ul>
    </span>
  </div> -->

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class="block w-full">
      <label class="font-raleway font-bold"> Actual Accomplishments per Program/Project/Activity: * </label>
      &nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500'
        pTooltip='Statement of the actual accomplishment of the implementation of the PPA which includes the date of implementation, and venue.'
        tooltipPosition="left"></i>
      <p-editor [formControl]="getFormControl('accomplishments')" [style]="{'height':'320px'}"></p-editor>
      <p>
        <i *ngIf="isFormControlInvalid('accomplishments')" class="text-red-600">Actual Accomplishments per
          Program/Project/Activity is required</i>&nbsp;
      </p>
    </span>
  </div>

  <div class="grid grid-cols-1 gap-2 lg:gap-0 md:grid-cols-2 items-end">
    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class="block w-full">
        <label class="block font-raleway font-bold">FO Action Plan Status: *</label>
        <p-dropdown [formControl]="getFormControl('remarks')" [options]="remarkChoices" optionLabel="value"
          optionValue="value" inputId="remarks" label="Remarks" placeholder="Select remarks" scrollHeight="300px"
          styleClass="w-full rounded-none">
          <ng-template pTemplate="selectedItem">
            <div class="country-item country-item-value" *ngIf="!isFormControlInvalid('remarks')">
              <span>{{getFormControl('remarks').value}}</span> &nbsp;
            </div>
          </ng-template>
          <ng-template let-action pTemplate="item">
            <div class="flex">
              <span class="flex-grow">{{action.value}}</span> &nbsp;
              <i class='pi pi-fw pi-question-circle text-gray-500' [pTooltip]='action.description'
                tooltipPosition="left"></i>
            </div>
          </ng-template>
        </p-dropdown>
        <p>
          <i *ngIf="isFormControlInvalid('remarks')" class="text-red-600">FO Action Plan Status is required</i>&nbsp;
        </p>
      </span>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class="block w-full">
        <label class="font-raleway font-bold">Project Monitoring Status: *</label>
        &nbsp;
        <i class='pi pi-fw pi-question-circle text-gray-500'
          pTooltip='Identification of the attainment of the Objective/ Goals of the PPA if on track, not attained, deferred, accomplished/ implemented, delayed, ahead of time, and not implemented.'
          tooltipPosition="left"></i>
        <p-dropdown (onChange)="onStatusChange()" [formControl]="getFormControl('status')" [options]="statusChoices"
          inputId="status" label="Status" placeholder="Select status" scrollHeight="300px"
          styleClass="w-full rounded-none"></p-dropdown>
        <p>
          <i *ngIf="isFormControlInvalid('status')" class="text-red-600">Project Monitoring Status is required</i>&nbsp;
        </p>
      </span>
    </div>
  </div>

  <div *ngIf="
      getFormControl('status').value == 'Delayed' ||
      getFormControl('status').value == 'Not attained' ||
      getFormControl('status').value == 'Not implemented'
    ">
    <span class="block w-full">
      <label class="font-raleway font-bold">
        Corrective Action for Non-Attainment of Objective/Target:
        <span>*</span>
      </label>
      &nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500'
        pTooltip='Statement of the reason for non-attainment of the objective/ target of the PPA and the action plan/s to address non- attainment (if any)'
        tooltipPosition="left"></i>
      <textarea [formControl]="getFormControl('actions')" class="w-full" pInputTextarea rows="3"></textarea>
      <p>
        <i *ngIf="isFormControlInvalid('actions')" class="text-red-600">Corrective Action for Non-Attainment of
          Objective/Target is required</i>&nbsp;
      </p>
    </span>
  </div>

  <!--  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">-->
  <!--    <label class='block font-raleway font-bold'>Monitoring Status: *</label>-->
  <!--    <p-dropdown-->
  <!--      [formControl]="getFormControl('monitored')"-->
  <!--      [options]='monitoredChoices'-->
  <!--      inputId='monitored'-->
  <!--      label='Reporting Year'-->
  <!--      optionLabel='name'-->
  <!--      optionValue='code'-->
  <!--      placeholder='Select monitoring status'-->
  <!--      scrollHeight='300px'-->
  <!--      styleClass='w-full rounded-none'-->
  <!--    ></p-dropdown>-->
  <!--  </div>-->

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <label class="font-raleway font-bold">Dates Implemented: *</label>
    &nbsp;
    <i class='pi pi-fw pi-question-circle text-gray-500'
      pTooltip='Timetable or period range of the implementation of the PPA' tooltipPosition="left"></i>
    <p-calendar [formControl]="getFormControl('dateImplemented')" [readonlyInput]="true" inputId="range"
      selectionMode="range" styleClass="w-full rounded-none"></p-calendar>
    <p>
      <i *ngIf="isFormControlInvalid('dateImplemented')" class="text-red-600">Dates Implemented is required</i>&nbsp;
    </p>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class="block w-full">
      <label class="font-raleway font-bold"> Actual Output/Type/No. of Participants: * </label>
      &nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500'
        pTooltip='Statement of the actual accomplishment of the implementation of the PPA which number and description of participants'
        tooltipPosition="left"></i>
      <textarea [formControl]="getFormControl('actualOutput')" class="w-full" pInputTextarea rows="3"></textarea>
      <p>
        <i *ngIf="isFormControlInvalid('actualOutput')" class="text-red-600">Actual Output/Type/No. of Participants is
          required</i>&nbsp;
      </p>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class="block w-full">
      <label class="font-raleway font-bold"> Remarks </label>
      &nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500'
        pTooltip='Indicate whether FQO action plans is completed, action plans ongoing, target/milestone achieved, delays encountered'
        tooltipPosition="left"></i>
      <textarea [formControl]="getFormControl('varianceReason')" class="w-full" pInputTextarea rows="3"></textarea>
      <p>
        <i *ngIf="isFormControlInvalid('varianceReason')" class="text-red-600">Remarks is required</i>&nbsp;
      </p>
    </span>
  </div>

  <div *ngIf="false" class="grid grid-cols-1 gap-2 lg:gap-0 md:grid-cols-3 items-end">
    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class="block w-full">
        <label class="block font-raleway font-bold" for="actionPlanVariance">
          Action Plan To Attain Target No. Of Participants/ Address Delay
        </label>
        <input [formControl]="getFormControl('actionPlanVariance')" class="w-full rounded-none" id="actionPlanVariance"
          pInputText type="text" />
        <ul *ngIf="isFormControlInvalid('actionPlanVariance')">
          <ng-container *ngFor="let err of getFormControlErrors('actionPlanVariance') | keyvalue">
            <li *ngIf="err.key == 'required'">Please enter an Action Plan</li>
          </ng-container>
        </ul>
      </span>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class="block w-full">
        <label class="block font-raleway font-bold"> Remarks </label>
        <!--        <input-->
        <!--          [formControl]="getFormControl('monitoringRemarks')"-->
        <!--          class='w-full rounded-none'-->
        <!--          id='monitoringRemarks'-->
        <!--          pInputText-->
        <!--          type='text'-->
        <!--        />-->
        <textarea [formControl]="getFormControl('monitoringRemarks')" pInputTextarea></textarea>
        <ul *ngIf="isFormControlInvalid('monitoringRemarks')">
          <ng-container *ngFor="let err of getFormControlErrors('monitoringRemarks') | keyvalue">
            <li *ngIf="err.key == 'required'">Please enter remarks</li>
          </ng-container>
        </ul>
      </span>
    </div>
  </div>
</div>

<div *ngIf="false" class="buttons flex w-full gap-4">
  <app-button (click)="submit()" [block]="isLoading" [disabled]="isLoading" [loading]="isLoading"
    [text]="project && project.id ? 'Save' : 'Add Project Activity'" [type]="'button'"
    class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md">
  </app-button>

  <app-button (click)="deletePlan()" *ngIf="project.id && showDeleteButton" [block]="isLoading" [disabled]="isLoading"
    [loading]="isLoading" [text]="'Delete Project Activity'" [type]="'button'"
    class="w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md">
  </app-button>
</div>