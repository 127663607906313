<div class='main-content'>
  <div class='top-container grid grid-cols-1 md:grid-cols-4 gap-4 items-start'>

    <div
      class='main-form mb-4 form bg-white rounded-lg shadow-md p-10 dark:bg-gray-700 flex flex-col gap-4 md:col-span-3'>
      <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
        <label class='block font-raleway font-bold'>Year *</label>
        <p-dropdown
          [formControl]="getFormControl('year')"
          [options]='yearChoices'
          inputId='year'
          label='year'
          placeholder='Select year'
          scrollHeight='300px'
          styleClass='w-full rounded-none'
        ></p-dropdown>
      </div>
      <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
        <label class='block font-raleway font-bold'>Quarter *</label>
        <p-dropdown
          [formControl]="getFormControl('quarter')"
          [options]='quarters'
          inputId='quarter'
          label='Quarter'
          optionLabel='name'
          optionValue='id'
          placeholder='Select quarter'
          scrollHeight='300px'
          styleClass='w-full rounded-none'
        ></p-dropdown>
      </div>
    </div>

    <div class='button mb-4 form bg-white rounded-lg shadow-md p-10 dark:bg-gray-700 flex flex-col md:flex-row gap-4'>
      <button (click)='submit()' icon='pi pi-file-pdf' label='Open Report Preview' pButton type='button'></button>
      <button (click)='downloadExcel()' class='p-button-success' icon='pi pi-file-excel' label='Download Excel' pButton
              type='button'>
      </button>
    </div>
  </div>

  <app-signatories></app-signatories>
</div>
