import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Plan } from '../../../models/plans';

type PlanData = Plan;

@Component({
  selector: 'app-plan-form-dialog',
  templateUrl: './plan-form-dialog.component.html',
  styleUrls: ['./plan-form-dialog.component.scss'],
})
export class PlanFormDialogComponent implements OnInit {
  public plan: Plan;
  public showActivityDialog: boolean;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.plan = config.data.plan as Plan;
  }

  close() {
    this.ref.close();
  }

  public ngOnInit(): void {}

  public onSubmit(event) {
    this.ref.close();
  }
}
