<!--<div class='flex flex-col mb-5 w-full'>-->
<!--  <label class='font-bold mb-1 text-gray-700 dark:text-gray-200 block text-sm' for='realm'>User Type</label>-->
<!--  <ng-select (change)='realmSelected($event)'-->
<!--             [ngModel]='getRealmControl().value'-->
<!--             appearance='outline'-->
<!--             class='w-full'-->
<!--             labelForId='realm' placeholder='Select a package'>-->
<!--    <ng-option *ngFor='let userType of userTypes' [value]='userType'>{{userType}}</ng-option>-->
<!--  </ng-select>-->
<!--</div>-->

<app-input [inputControl]='getFirstnameControl()' [inputId]="user && user.id ? 'firstName__'+user.username : 'firstName'"
           label='First Name'
           placeHolder='ex. John'>
  <ng-container error>
    <div
      *ngIf='(getFirstnameControl().touched || getFirstnameControl().dirty) && getFirstnameControl().invalid'
      class='alert'>
      <ul>
        <ng-container *ngFor='let err of getFirstnameControl()!.errors! | keyvalue'>
          <li *ngIf="err.key == 'required'">Please enter your first name</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>

<app-input [inputControl]="profileForm.get('lastName')"
           [inputId]="user && user.id ? 'lastName__'+user.username : 'lastName'"
           label='Last Name'
           placeHolder='ex. Doe'>
  <ng-container error>
    <div
      *ngIf="(profileForm.get('lastName').touched || profileForm.get('lastName').dirty) && profileForm.get('lastName').invalid"
      class='alert'>
      <ul>
        <ng-container *ngFor="let err of profileForm.get('lastName')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter your last name</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>

<app-input [inputControl]="profileForm.get('email')" [inputId]="user && user.id ? 'email__'+user.username : 'email'"
           label='Email' placeHolder='ex. john@example.com'
           type='email'>
  <ng-container error>
    <div
      *ngIf="(profileForm.get('email').touched || profileForm.get('email').dirty) && profileForm.get('email').invalid"
      class='alert'>
      <ul>
        <ng-container *ngFor="let err of profileForm.get('email')?.errors | keyvalue">
          <li *ngIf="err.key == 'email'">Please enter a valid email</li>
          <li *ngIf="err.key == 'isEmailExists'">This email is already registered</li>
          <li *ngIf="err.key == 'required'">Please enter your email</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>

<app-input [inputControl]="profileForm.get('mobile')" [inputId]="user && user.id ? 'mobile__'+user.username : 'mobile'"
           label='Mobile Number &ndash; (min. 8 digits)'
           placeHolder='ex. john@example.com'
           type='mobile'>
  <ng-container error>
    <div
      *ngIf="(profileForm.get('mobile').touched || profileForm.get('mobile').dirty) && profileForm.get('mobile').invalid"
      class='alert'>
      <ul>
        <ng-container *ngFor="let err of profileForm.get('mobile')?.errors | keyvalue">
          <li *ngIf="err.key == 'pattern'">Please enter at least 8 digit mobile number
          </li>
          <li *ngIf="err.key == 'isMobileExists'">This mobile is already registered
          </li>
          <li *ngIf="err.key == 'required'">Please enter your mobile number</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>
