import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { MonthlyUpdate, Project } from '../../../models/plans';
import { addMonthlyUpdate, deletePlan, updateMonthlyUpdate } from '../../../redux/plan';
import { userHasPermission } from '../../../utils/functions/has-permission';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monitoredChoices = [
  { name: 'Ahead of Time', code: 'Ahead of time' },
  { name: 'On Time', code: 'Ahead on time' },
  { name: 'Delayed', code: 'Delayed' },
  { name: 'Not Implemented', code: 'Not yet implemented' },
];

@Component({
  selector: 'app-monthly-update-form',
  templateUrl: './monthly-update-form.component.html',
  styleUrls: ['./monthly-update-form.component.scss'],
})
export class MonthlyUpdateFormComponent implements OnInit {
  public form: FormGroup;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  public remarkChoices = [
    {
      value: 'Action plans completed',
      description: 'Action plans or activities stated in the FQO has been completed',
    },
    {
      value: 'Action plans on-going',
      description:
        'Action plan or activities has been started/implemented on specified timeframe in the FQO reference manual',
    },
    {
      value: 'Target milestone achieved',
      description: 'Target/milestones stated in the FQO reference manual has been achieved',
    },
    {
      value: 'Delays encountered',
      description:
        'Action plan or activities not implemented on specified time frame stated on the FQO reference manual',
    },
  ];
  @Input()
  public showDeleteButton = false;
  public monitoredChoices = [
    { name: 'Ahead of Time', code: 'Ahead of time' },
    { name: 'On Time', code: 'Ahead on time' },
    { name: 'Delayed', code: 'Delayed' },
    { name: 'Not Implemented', code: 'Not yet implemented' },
  ];
  public statusChoices = [
    'Ongoing',
    'On track',
    'Not attained',
    'To be implemented or deferred',
    'Accomplished/Implemented',
    'Delayed',
    'Ahead of Time',
    'Not implemented',
  ];
  public yearChoices: string[] = [];
  private monthChoices: { name: string; id: string }[];

  constructor(private fb: FormBuilder, private store: Store, private toaster: ToastrService) {
    this.form = this.fb.group({
      reportingMonth: [{ value: 0, disabled: !this.canEdit() }, [Validators.required]],
      reportingYear: [{ value: 0, disabled: !this.canEdit() }, [Validators.required]],
      actualCostOfBudget: [{ value: 0, disabled: !this.canEdit() }, [Validators.required]],
      accomplishments: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      milestones: [{ value: '', disabled: !this.canEdit() }],
      status: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      conditions: [{ value: null, disabled: !this.canEdit() }],
      actions: [{ value: '', disabled: !this.canEdit() }],
      ofi: [{ value: null, disabled: !this.canEdit() }],
      participantsCount: [{ value: null, disabled: !this.canEdit() }],
      varianceReason: [{ value: null, disabled: !this.canEdit() }],
      actionPlanVariance: [{ value: null, disabled: !this.canEdit() }],
      monitoringRemarks: [{ value: null, disabled: !this.canEdit() }],
      remarks: [{ value: null, disabled: !this.canEdit() }, [Validators.required]],
      dateImplemented: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
      actualOutput: [{ value: '', disabled: !this.canEdit() }, [Validators.required]],
    });
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    for (let i = currentYear + 1; i >= currentYear - 5; i--) {
      this.yearChoices.push(i.toString());
    }
    this.getFormControl('reportingYear').setValue(currentYear.toString());
    this.getFormControl('reportingMonth').setValue(currentMonth.toString());
  }

  private _monthlyUpdate: MonthlyUpdate;

  get monthlyUpdate() {
    return this._monthlyUpdate;
  }

  @Input()
  set monthlyUpdate(monthlyUpdate: MonthlyUpdate) {
    this._monthlyUpdate = monthlyUpdate;
    if (monthlyUpdate?.id) {
      const dates = this._monthlyUpdate.dateImplemented?.split(',').map((date) => {
        return new Date(date);
      });
      this.form.patchValue({
        ...this._monthlyUpdate,
        dateImplemented: dates,
        reportingYear: this._monthlyUpdate.reportingYear.toString(),
        reportingMonth: this._monthlyUpdate.reportingMonth.toString(),
      });
    }
  }

  private _project: Project;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Project) {
    this._project = project;
  }

  canEdit() {
    return userHasPermission(['plan:editAny']);
  }

  deletePlan() {
    this.store.dispatch(deletePlan({ planId: this.project.id }));
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  getFormControlErrors(name: string) {
    return this.getFormControl(name).errors as ReadonlyMap<any, any>;
  }

  getMonitoredOptions() {
    return monitoredChoices;
  }

  getMonths() {
    // const currentDate = new Date();
    // const currentMonth = currentDate.getMonth();
    // return monthNames
    //   .filter((month, index) => {
    //     console.log(currentMonth, index, this.project.projectType);
    //     if (this.project.projectType == 'MAIN_PROJECT') {
    //       return currentMonth == index || currentMonth - 1 == index;
    //     }
    //     return true;
    //   })
    //   .map((month, index) => ({
    //     name: month,
    //     id: index.toString(),
    //   }));
    return monthNames.map((month, index) => ({
      name: month,
      id: index.toString(),
    }));
  }

  isFormControlInvalid(name: string) {
    return (this.getFormControl(name).touched || this.getFormControl(name).dirty) && this.getFormControl(name).invalid;
  }

  isMonitoredOptionSelected(option: any) {
    if (this.getFormControl('monitored').valid) {
      return this.getFormControl('monitored').value == option.code;
    }

    return false;
  }

  ngOnInit(): void {}

  onStatusChange() {
    const isBadStatus =
      this.getFormControl('remarks').value == 'Delays encountered' &&
      (this.getFormControl('status').value == 'Delayed' ||
        this.getFormControl('status').value == 'Not attained' ||
        this.getFormControl('status').value == 'Not implemented');
    if (isBadStatus) {
      this.getFormControl('actions').addValidators(Validators.required);
    } else {
      this.getFormControl('actions').removeValidators(Validators.required);
    }
  }

  resetForm() {
    this.form.patchValue({
      accomplishments: '',
      actionPlanVariance: '',
      actions: '',
      actualCostOfBudget: 0,
      conditions: '',
      dateImplemented: '',
      milestones: '',
      monitored: '',
      monitoringRemarks: '',
      ofi: '',
      participantsCount: '',
      percentage: 0,
      remarks: '',
      reportingMonth: 0,
      reportingYear: 0,
      status: '',
      varianceReason: '',
    });
  }

  submit() {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }
    this.isLoading = true;
    const monthlyUpdate: MonthlyUpdate = Object.assign(this._monthlyUpdate ?? {}, this.form.value);
    monthlyUpdate.project = this.project;
    monthlyUpdate.dateImplemented = this.getFormControl('dateImplemented').value.toString();
    if (monthlyUpdate.id) {
      this.store.dispatch(updateMonthlyUpdate({ monthlyUpdate: monthlyUpdate }));
    } else {
      this.store.dispatch(addMonthlyUpdate({ monthlyUpdate: monthlyUpdate }));
    }
    return true;
  }
}
