<div class='main-content mb-4 flex flex-col gap-2'>
  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full flex flex-col' *ngIf="projectType == 'SUPPLEMENTAL_PROJECT'">
      <span>
        <label>Main Project: </label>
        <i *ngIf="isFormControlInvalid('linkedProjectId')" class='pi pi-fw pi-exclamation-circle font-bold text-red-600'
          pTooltip='Pick a main project'></i>
      </span>
      <p-dropdown [autoDisplayFirst]='false' [formControl]="getFormControl('linkedProjectId')" [options]='projects'
        [showClear]='true' inputId='linkedProjectId' optionLabel='title' optionValue='id'
        styleClass='w-full rounded-none'></p-dropdown>
    </span>
  </div>
  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <label for='title'>Title *</label>&nbsp;
    <i class='pi pi-fw pi-question-circle text-gray-500' pTooltip='Title of the Program/ Project/ Activity'
      tooltipPosition="left"></i>
    <span class='block w-full p-field p-input-icon-right'>
      <input [formControl]="getFormControl('title')" autofocus class='w-full' id='title' pInputText tooltipEvent='focus'
        tooltipPosition='bottom' type='text' />

    </span>
    <p>
      <i *ngIf="isFormControlInvalid('title')" class="text-red-600">Title is required</i>&nbsp;
    </p>
  </div>

  <div class='grid grid-cols-1 md:grid-cols-2 w-full'>
    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label for='description'>Description *</label>&nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500'
        pTooltip='Short narrative describing the PPA to be implemented by the Division/ Units'
        tooltipPosition="left"></i>
      <span class='block w-full p-input-icon-right'>
        <input [formControl]="getFormControl('description')" class='w-full' id='description' pInputText
          tooltipEvent='focus' tooltipPosition='bottom' type='text' />
      </span>
      <p>
        <i *ngIf="isFormControlInvalid('description')" class="text-red-600">Description is required</i>&nbsp;
      </p>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label for='objective'>Objective *</label>&nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500' pTooltip='General goal the PPA wanted to achieve.'
        tooltipPosition="left"></i>
      <span class='block w-full p-input-icon-right'>
        <input [formControl]="getFormControl('objective')" class='w-full' id='objective' pInputText tooltipEvent='focus'
          tooltipPosition='bottom' type='text' />
      </span>
      <p>
        <i *ngIf="isFormControlInvalid('objective')" class="text-red-600">Objective is required</i>&nbsp;
      </p>
    </div>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <label for='beneficiaries'>Target Beneficiaries *</label>&nbsp;
    <i class='pi pi-fw pi-question-circle text-gray-500'
      pTooltip='Enumeration of the participants/beneficiaries of the PPA' tooltipPosition="left"></i>
    <span class='block w-full p-input-icon-right'>
      <input [formControl]="getFormControl('beneficiaries')" class='w-full' id='beneficiaries' pInputText
        tooltipEvent='focus' tooltipPosition='bottom' type='text' />
    </span>
    <p>
      <i *ngIf="isFormControlInvalid('beneficiaries')" class="text-red-600">Target Beneficiaries is required</i>&nbsp;
    </p>
  </div>

  <div class='grid grid-cols-1 md:grid-cols-2 w-full'>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label>Budget</label>&nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500' pTooltip='Planned budget for the implementation of the PPA'
        tooltipPosition="left"></i>
      <span class='block w-full p-input-icon-right'>
        <p-inputNumber [formControl]="getFormControl('budget')" [maxFractionDigits]='5' [minFractionDigits]='2'
          class='w-full' inputId='budget' inputStyleClass='rounded-none w-full' mode='decimal' styleClass='w-full'
          tooltipEvent='focus' tooltipPosition='bottom'>
        </p-inputNumber>
      </span>
      <p>
        <i *ngIf="isFormControlInvalid('budget')" class="text-red-600">Budget is required</i>&nbsp;
      </p>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class='block w-full'>
        <label for='otherSourceOfFunds'>Other Source of Funds</label>&nbsp;
        <i class='pi pi-fw pi-question-circle text-gray-500'
          pTooltip='Specification/ identification of the funding source other than the GAA' tooltipPosition="left"></i>
        <span class='block w-full p-input-icon-right'>
          <input [formControl]="getFormControl('otherSourceOfFunds')" class='w-full' id='otherSourceOfFunds' pInputText
            type='text' />
        </span>

      </span>
      <p>
        <i *ngIf="isFormControlInvalid('otherSourceOfFunds')" class="text-red-600">Other Source of Funds is
          required</i>&nbsp;
      </p>
    </div>
  </div>

  <div class='grid grid-cols-1 gap-2 md:gap-0 md:grid-cols-2 w-full'>
    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label for='timeTable'>Timetable *</label>&nbsp;
      <i class='pi pi-fw pi-question-circle text-gray-500'
        pTooltip='Timetable or period range of the implementation of the PPA' tooltipPosition="left"></i>
      <span class='block w-full p-input-icon-right'>
        <input [formControl]="getFormControl('timeTable')" class='w-full' id='timeTable' pInputText tooltipEvent='focus'
          tooltipPosition='bottom' type='text' />
      </span>
      <p>
        <i *ngIf="isFormControlInvalid('timeTable')" class="text-red-600">Timetable is required</i>&nbsp;
      </p>
    </div>

    <span class='w-full flex flex-col'>
      <div>
        <label>Functional Objective *</label>&nbsp;
        <i class='pi pi-fw pi-question-circle text-gray-500'
          pTooltip='Indicate the Functional Objective/s of your process' tooltipPosition="left"></i>
      </div>
      <p-dropdown [autoDisplayFirst]='false' [formControl]="getFormControl('functionalObj')" [options]='objectives'
        [showClear]='true' inputId='functionalObj' optionLabel='name' optionValue='id' styleClass='w-full rounded-none'>
      </p-dropdown>
      <p>
        <i *ngIf="isFormControlInvalid('functionalObj')" class="text-red-600">Functional Objective is required</i>&nbsp;
      </p>
    </span>
  </div>

  <div class='grid grid-cols-1 gap-2 md:gap-0 md:grid-cols-2 w-full'>
    <span class='w-full flex flex-col'>
      <span>
        <label>TLMO *</label>&nbsp;
        <i class='pi pi-fw pi-question-circle text-gray-500'
          pTooltip='Number of the Top-Level Management Objectives (TLMO) where the Functional Objectives (FOs) is/are aligned and to which the PPA corresponds to.'
          tooltipPosition="left"></i>
      </span>
      <p-dropdown [autoDisplayFirst]='false' [formControl]="getFormControl('tlmo')" [options]='tlmos'
        [pTooltip]="isFormControlInvalid('tlmo') ? 'TLMO is required' : ''" [showClear]='true' inputId='tlmo'
        optionLabel='tlmo' optionValue='id' styleClass='w-full rounded-none'></p-dropdown>
      <p>
        <i *ngIf="isFormControlInvalid('tlmo')" class="text-red-600">TLMO is required</i>&nbsp;
      </p>
    </span>

    <span class='w-full flex flex-col'>
      <div>
        <label>Sub TLMO *</label>&nbsp;
        <i class='pi pi-fw pi-question-circle text-gray-500'
          pTooltip='Policy statement of the Division/ Unit for identified PPA under an identified TLMO'
          tooltipPosition="left"></i>
      </div>
      <p-dropdown [autoDisplayFirst]='false' [formControl]="getFormControl('subTlmo')" [options]='getSubTlmos()'
        [showClear]='true' inputId='subTlmo' optionLabel='tlmo' optionValue='id' panelStyleClass='w-1/2'
        styleClass='w-full rounded-none'></p-dropdown>
      <p>
        <i *ngIf="isFormControlInvalid('subTlmo')" class="text-red-600">Sub TLMO is required</i>&nbsp;
      </p>
    </span>
  </div>

  <div class='grid grid-cols-1 gap-2 md:gap-0 md:grid-cols-3 w-full'>
    <span class='w-full flex flex-col'>
      <div>
        <label>Main Category *</label>&nbsp;
        <i class='pi pi-fw pi-question-circle text-gray-500'
          pTooltip='Classification of PPAs as Strategic Priorities and Support to Function' tooltipPosition="left"></i>
      </div>
      <p-dropdown [autoDisplayFirst]='false' [formControl]="getFormControl('category1')" [options]='categories'
        [showClear]='true' inputId='category1' optionLabel='name' optionValue='id' styleClass='w-full rounded-none'>
      </p-dropdown>
      <p>
        <i *ngIf="isFormControlInvalid('category1')" class="text-red-600">Main Category is required</i>&nbsp;
      </p>
    </span>

    <span class='w-full flex flex-col'>
      <div>
        <label>Sub-Category *</label>&nbsp;
        <i class='pi pi-fw pi-question-circle text-gray-500'
          pTooltip='Specific Classification of the objective/ target of the PPA to be implemented'
          tooltipPosition="left"></i>
      </div>
      <p-dropdown [autoDisplayFirst]='false' [formControl]="getFormControl('category2')" [options]='getSubCategory2()'
        [showClear]='true' inputId='category2' optionLabel='name' optionValue='id' styleClass='w-full rounded-none'>
      </p-dropdown>
      <p>
        <i *ngIf="isFormControlInvalid('category2')" class="text-red-600">Sub-Category is required</i>&nbsp;
      </p>
    </span>

    <span class='w-full flex flex-col'>
      <div>
        <label>2nd sub-category *</label>&nbsp;
        <i class='pi pi-fw pi-question-circle text-gray-500'
          pTooltip='Specific Classification of the objective/ target of the PPA to be implemented'
          tooltipPosition="left"></i>
      </div>
      <p-dropdown [autoDisplayFirst]='false' [formControl]="getFormControl('category3')" [options]='getSubCategory3()'
        [showClear]='true' inputId='category3' optionLabel='name' optionValue='id' styleClass='w-full rounded-none'>
      </p-dropdown>
      <p>
        <i *ngIf="isFormControlInvalid('category3')" class="text-red-600">2nd sub-category is required</i>&nbsp;
      </p>
    </span>
  </div>

  <div *ngIf='project?.id'>
    <label>Project Status</label>
    <p-dropdown [autoDisplayFirst]='false' [formControl]="getFormControl('status')" [options]='projectStatuses'
      [showClear]='true' (onChange)='onProjectStatusChange($event)' inputId='status' styleClass='w-full rounded-none'>
    </p-dropdown>
    <p *ngIf='statusWarning' class='text-orange-600 italic'>{{statusWarning}}</p>
  </div>
</div>