<app-input [inputControl]="getNameControl()" inputId="name" label="Name" placeHolder="ex. NCR">
  <ng-container error>
    <div *ngIf="(getNameControl().touched || getNameControl().dirty) && getNameControl().invalid" class="alert">
      <ul>
        <ng-container *ngFor="let err of getNameControl()?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter the state name</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>
<app-input [inputControl]="getCodeControl()" inputId="code" label="Code" placeHolder="ex. NCR">
  <ng-container error>
    <div *ngIf="(getCodeControl().touched || getCodeControl().dirty) && getCodeControl().invalid" class="alert">
      <ul>
        <ng-container *ngFor="let err of getCodeControl()?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter the state code</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</app-input>
<app-input [inputControl]="getDescriptionControl()" inputId="description" label="Description" placeHolder="">
</app-input>

<div class="buttons flex w-full gap-4">
  <app-button (click)="submit()" [type]="'button'" [disabled]="isLoading" [block]="isLoading"
    [text]="country ? 'Save' : 'Add State'"
    class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md" [loading]="isLoading">
  </app-button>

  <app-button *ngIf="stateIndex > -1 && showDeleteButton" (click)="deleteState()" [type]="'button'" [disabled]="isLoading" [block]="isLoading"
    [text]="'Delete State'" class="w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md"
    [loading]="isLoading">
  </app-button>
</div>