import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MainState } from '../index';
import { OpsState } from './ops.state';

export const selectOpsState = createFeatureSelector<MainState, OpsState>(
  'plan'
);

export const selectOpsStateInvoices = createSelector(
  selectOpsState,
  (state) => ({
    invoices: state.invoices!,
    invoiceStatus: state.invoiceStatus,
  })
);

export const selectOpsStateNextStatuses = createSelector(
  selectOpsState,
  (state) => state.nextOrderStatuses!
);
