<div class='main-content mb-4 flex flex-col gap-4'>
  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <label class='block font-raleway font-bold'>Division/Unit *</label>
    <p-dropdown
      [formControl]="getFormControl('divisionUnit')"
      [options]='divisionUnits'
      inputId='divisionUnit'
      label='Division Unit'
      optionLabel='name'
      placeholder='Select division unit'
      scrollHeight='300px'
      styleClass='w-full rounded-none'
    ></p-dropdown>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <label class='block font-raleway font-bold'>Division Chief</label>
    <p-dropdown
      [formControl]="getFormControl('chiefId')"
      [options]='users'
      inputId='chiefId'
      label='Division Chief'
      optionLabel='firstName'
      optionValue='id'
      placeholder='Select division chief'
      scrollHeight='300px'
      styleClass='w-full rounded-none'
    ></p-dropdown>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold'>Number of Personnel *</label>
      <input
        [formControl]="getFormControl('personnelCount')"
        [pTooltip]="isFormControlInvalid('personnelCount') ? 'Number of personnel is required' : ''"
        class='w-full rounded-none'
        id='modifiedBy'
        pInputText
        tooltipEvent='focus'
        tooltipPosition='bottom'
        type='text'
      />
      <ul *ngIf="isFormControlInvalid('personnelCount')" class='p-error'>
        <ng-container *ngFor="let err of getFormControl('personnelCount')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Enter number of personnel</li>
        </ng-container>
      </ul>
    </span>
  </div>

</div>

<div *ngIf='false' class='buttons flex w-full gap-4'>
  <app-button
    (click)='submit()'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="project && project.id ? 'Save' : 'Add Division'"
    [type]="'button'"
    class='w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md'
  >
  </app-button>

  <app-button
    (click)='deleteDivision()'
    *ngIf='project.id && showDeleteButton'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="'Delete Division'"
    [type]="'button'"
    class='w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md'
  >
  </app-button>
</div>
