import { Component, Inject, OnInit } from '@angular/core';
import { TLMO } from '../../../models/plans';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TreeNode } from 'primeng/api';

type DialogData = {
  project?: TLMO;
  tlmo?: TLMO;
  parent?: TreeNode<TLMO>;
  tlmoList?: TLMO[];
};

@Component({
  selector: 'app-tlmo-form-dialog',
  templateUrl: './tlmo-form-dialog.component.html',
  styleUrls: ['./tlmo-form-dialog.component.scss'],
})
export class TlmoFormDialogComponent implements OnInit {
  public parent: TLMO;
  public project: TLMO;
  public tlmo: TLMO;
  public tlmoList: TLMO[];

  constructor(
    public dialogRef: MatDialogRef<TlmoFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    dialogRef.updateSize('90%', '90%');
    this.project = data.project;
    this.tlmo = data.tlmo;
    this.tlmoList = data.tlmoList;
    this.parent = data.parent?.data;
  }

  ngOnInit(): void {}

  onSubmit(event) {
    this.dialogRef.close();
  }
}
