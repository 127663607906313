<div class='main-content mb-4 flex flex-col gap-4'>
  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold'>Parent TLMO</label>
      <p-dropdown [autoDisplayFirst]='false' [formControl]='getFormControl("parent")'
                  [maxlength]='20'
                  [options]='tlmoList'
                  emptyMessage='Select the parent tlmo'
                  label='Parent TLMO'
                  optionLabel='tlmo' styleClass='w-full'></p-dropdown>
      <ul *ngIf="isFormControlInvalid('parent')" class='p-error'>
        <ng-container *ngFor="let err of getFormControl('parent')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a Parent</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold' for='sequenceNumber'>Sequence Number *</label>
      <input
        [formControl]="getFormControl('sequenceNumber')"
        [pTooltip]="isFormControlInvalid('sequenceNumber') ? 'Sequence Number is required' : ''"
        class='w-full rounded-none'
        id='sequenceNumber'
        pInputText
        tooltipEvent='focus'
        tooltipPosition='bottom'
        type='text'
      />
      <ul *ngIf="isFormControlInvalid('sequenceNumber')" class='p-error'>
        <ng-container *ngFor="let err of getFormControl('sequenceNumber')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a Sequence Number</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold' for='tlmo'>TLMO *</label>
<!--      <input-->
<!--        [formControl]="getFormControl('tlmo')"-->
<!--        [pTooltip]="isFormControlInvalid('tlmo') ? 'TLMO is required' : ''"-->
<!--        class='w-full rounded-none'-->
<!--        id='tlmo'-->
<!--        pInputText-->
<!--        tooltipEvent='focus'-->
<!--        tooltipPosition='bottom'-->
<!--        type='text'-->
<!--      />-->
      <textarea [formControl]="getFormControl('tlmo')" class="w-full" pInputTextarea rows="3" [pTooltip]="isFormControlInvalid('tlmo') ? 'TLMO is required' : ''"></textarea>
      <ul *ngIf="isFormControlInvalid('tlmo')" class='p-error'>
        <ng-container *ngFor="let err of getFormControl('tlmo')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Enter TLMO</li>
        </ng-container>
      </ul>
    </span>
  </div>

</div>

<div *ngIf='false' class='buttons flex w-full gap-4'>
  <app-button
    (click)='submit()'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="project && project.id ? 'Save' : 'Add TLMO'"
    [type]="'button'"
    class='w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md'
  >
  </app-button>

  <app-button
    (click)='deleteTlmo()'
    *ngIf='project.id && showDeleteButton'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="'Delete TLMO'"
    [type]="'button'"
    class='w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md'
  >
  </app-button>
</div>
