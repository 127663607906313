import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-verified-page',
  templateUrl: './email-verified-page.component.html',
  styleUrls: ['./email-verified-page.component.scss'],
})
export class EmailVerifiedPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.navigate(['auth', 'login']);
  }
}
