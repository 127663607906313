<div class='flex gap-2 items-center'>
  <div>
    <h2 class='mb-0'>Project: {{ project?.title }}</h2>
    <div>
      Type: <i>{{ projectTypes[project?.projectType] }}</i>
    </div>
  </div>
  <button
    (click)='editPlan = true'
    *ngIf="project && (plan.status == 'Draft' || plan.status == 'For Revision')"
    class='p-button-rounded p-button-outlined'
    icon='pi pi-pencil'
    pButton
    pRipple
    pTooltip='Edit project'
    type='button'
  ></button>
</div>

<hr />

<div class='mb-4 w-full-no-scroll'>
  <app-project-form #projectForm [readOnly]="true" [plan]='plan' [project]='project'></app-project-form>
</div>

<!-- <hr />

<div class='mb-4 w-full-no-scroll'>
  <h3 class='mb-4'>Quarterly Updates</h3>
  <app-quarterly-update-list [project]='project'></app-quarterly-update-list>
</div>

<hr />

<div class='mb-4 w-full-no-scroll'>
  <h3 class='mb-4'>MIPs</h3>
  <app-ppmr-list [project]='project'></app-ppmr-list>
</div> -->

<!-- <hr />

<div class='mb-4 w-full-no-scroll'>
  <h3 class='mb-4'>Activities</h3>
  <app-project-activity-list [project]='project'></app-project-activity-list>
</div> -->

<p-dialog
  *ngIf='editPlan'
  [(visible)]='editPlan'
  [blockScroll]='true'
  [breakpoints]="{ '960px': '75vw' }"
  [contentStyle]="{ height: 'clamp(500px, 90%, 90%)' }"
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: 'clamp(250px, 120ch, 90vw)' }"
  contentStyleClass='h-full'
  header='Edit Project'
  position='center'
  styleClass='overflow-y-auto block'
>
  <app-project-form #projectForm [plan]='plan' [project]='project'></app-project-form>

  <ng-template pTemplate='footer'>
    <p-button
      (click)='editPlan = false; projectForm.resetForm()'
      icon='pi pi-cross'
      label='Cancel'
      styleClass='p-button-outlined p-button-danger'
    ></p-button>
    <p-button (click)='editPlan = !projectForm.submit()' icon='pi pi-check' label='Save'></p-button>
  </ng-template>
</p-dialog>
