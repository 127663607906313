import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../models/user';
import { fadeUpAndFadeDown } from '../../utils/animations/common';
import { UserService } from '../../utils/services/user.service';

@Component({
  selector: 'app-step-form-sample',
  templateUrl: './step-form-sample.component.html',
  styleUrls: ['./step-form-sample.component.scss'],
  animations: [fadeUpAndFadeDown],
})
export class StepFormSampleComponent implements OnInit {
  public isDone = false;
  public isPasswordVisible = false;
  public step = 1;
  public message = '';
  public isSuccessful = false;
  public isAuthLoading = false;

  @ViewChild('firstName') firstNameInput: ElementRef;
  @ViewChild('username') usernameInput: ElementRef;
  @ViewChild('referralName') referralNameInput: ElementRef;

  public profileForm: FormGroup;
  public accountForm: FormGroup;
  public referralForm: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private library: FaIconLibrary
  ) {
    library.addIconPacks(fas);
  }

  ngOnInit(): void {
    const a = new FormControl();
    this.profileForm = this.fb.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: [
          '',
          [Validators.required, Validators.email, this.checkEmailIfExists()],
        ],
        mobile: ['', Validators.pattern('[0-9]{8,}')],
      },
      {
        updateOn: 'blur',
      }
    );
    this.accountForm = this.fb.group(
      {
        username: [
          '',
          [Validators.required, Validators.pattern('[a-zA-Z_.-0-9]+')],
        ],
        password: ['', [Validators.required, Validators.minLength(4)]],
      },
      {
        updateOn: 'blur',
      }
    );
    this.referralForm = this.fb.group(
      {
        referralName: ['', Validators.required],
        userPackage: ['PARTNER', Validators.required],
      },
      {
        updateOn: 'blur',
      }
    );
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  previous() {
    window.scrollTo(0, 0);
    if (this.step > 1) {
      setTimeout(() => {
        if (this.firstNameInput) {
          const el = this.firstNameInput.nativeElement as HTMLInputElement;
          el.focus();
        }
        if (this.usernameInput) {
          const el = this.usernameInput.nativeElement as HTMLInputElement;
          el.focus();
        }
        if (this.referralNameInput) {
          const el = this.referralNameInput.nativeElement as HTMLInputElement;
          el.focus();
        }
      }, 500);
      this.step--;
      return;
    }

    this.step = 3;
  }

  next() {
    window.scrollTo(0, 0);
    if (this.step == 1 && this.profileForm.invalid) {
      this.validateProfileForm();
      this.profileForm.markAllAsTouched();
      this.profileForm.markAsDirty();
      return;
    }
    if (this.step == 2 && this.accountForm.invalid) {
      this.accountForm.markAllAsTouched();
      this.accountForm.markAsDirty();
      return;
    }
    if (this.step < 3) {
      setTimeout(() => {
        if (this.usernameInput) {
          const el = this.usernameInput.nativeElement as HTMLInputElement;
          el.focus();
        }
        if (this.referralNameInput) {
          const el = this.referralNameInput.nativeElement as HTMLInputElement;
          el.focus();
        }
      }, 500);
      this.step++;
      return;
    }
    this.step = 1;
  }

  public getErrors() {
    console.log(this.profileForm.get('email').errors);
    return this.profileForm.get('email').errors;
  }

  private checkEmailIfExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.userService.checkEmailIfExists(control.value).pipe(
        map((isEmailExists) => {
          if (isEmailExists) {
            return { isEmailExists };
          }
          return null;
        }),
        catchError((err) => {
          return null;
        })
      );
    };
  }

  private validateProfileForm() {
    this.userService
      .checkEmailIfExists(this.profileForm.get('email').value)
      .subscribe({
        next: (response) => {
          console.log(response);
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  selectPackage(userPackage: string, event: MouseEvent) {
    const userPackageForm = this.referralForm.get('userPackage');
    this.referralForm.get('userPackage').setValue(userPackage);
  }

  private addCompanyDetails(
    user: User,
    userPackage: 'PARTNER' | 'RESELLER'
  ): User {
    return Object.assign(new User(), {
      ...user,
      company: {
        code: 'CNM',
        name: 'Chef and Market',
        userPackage,
      },
    });
  }

  private isAllFormsValid() {
    this.profileForm.markAllAsTouched();
    this.accountForm.markAllAsTouched();
    this.referralForm.markAllAsTouched();

    return (
      this.profileForm.valid &&
      this.accountForm.valid &&
      this.referralForm.valid
    );
  }

  submit() {
    if (!this.isAllFormsValid()) {
      this.message = 'You have invalid inputs';
      this.isAuthLoading = false;
      this.toastr.error(
        'You have invalid inputs. Please check all forms.',
        'Error'
      );
      return;
    }
    const user: User = this.addCompanyDetails(
      {
        ...this.profileForm.value,
        ...this.accountForm.value,
        referralName: this.referralForm.get('referralName').value,
      },
      this.referralForm.get('userPackage').value
    );
    this.userService.registerUser(user).subscribe({
      next: (response) => {
        this.isSuccessful = true;
        console.log(response);
        // this.router.navigate(['sign-up-notif']);
      },
      error: (err) => {
        this.message = err.error;
        this.isAuthLoading = false;
        console.error(err);
        this.toastr.error(err.error.error_description, 'Error');
      },
    });
  }
}
