import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';
import { environment } from 'src/environments/environment';
import { ProjectActivity } from '../../models/plans';
import { IS_SPINNER_ENABLED } from '../constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
  deps: [AuthService],
})
export class ProjectActivityService {
  private wfpApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
    } else {
      this.wfpApi = environment.wfpApi;
    }
  }

  public addProjectActivity(projectActivity: ProjectActivity) {
    const url = `${this.wfpApi}/project-activities`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<ProjectActivity>(url, projectActivity, {
      headers,
    });
  }

  public deleteProjectActivity(id: number) {
    const url = `${this.wfpApi}/project-activities/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<any>(url, { headers });
  }

  public getProjectActivitiesByProjectId(projectId: number, showSpinner = false) {
    const url = `${this.wfpApi}/project-activities?projectId=${projectId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    const context = new HttpContext().set(IS_SPINNER_ENABLED, showSpinner);
    return this.httpClient.get<ProjectActivity[]>(url, { headers, context });
  }

  public getProjectActivityById(id: number) {
    const url = `${this.wfpApi}/project-activities/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<ProjectActivity>(url, { headers });
  }

  public updateProjectActivity(projectActivity: ProjectActivity) {
    const url = `${this.wfpApi}/project-activities`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<ProjectActivity>(url, projectActivity, {
      headers,
    });
  }
}
