import { createAction, props } from '@ngrx/store';
import { Country } from '../../models';

export const loadCountries = createAction('[Country List Page] Load country');
export const clearCountries = createAction(
  '[Country List Page] Clear country list'
);
export const loadedCountries = createAction(
  '[Country List Page] Loaded countries',
  props<{ payload: Country[] }>()
);
export const addCountry = createAction(
  '[Country] Add Country',
  props<{ payload: Country }>()
);
export const deleteCountry = createAction(
  '[Country] Delete Country',
  props<{ payload: Country }>()
);
export const deleteSuccessCountry = createAction(
  '[Country] Successfully Deleted Country'
);
export const saveCountry = createAction(
  '[Country] Save Country',
  props<{ payload: Country & { currentUrl?: string } }>()
);
export const saveState = createAction(
  '[Country] Save state',
  props<{ payload: Country & { currentUrl?: string } }>()
);
export const saveCity = createAction(
  '[Country] Save city',
  props<{ payload: Country & { currentUrl?: string } }>()
);
