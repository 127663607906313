import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { RegionalCompany, Courier, Country, Zone } from '../../../models';
import { saveCompany } from '../../../redux/company';
import { CountryService } from '../../../utils/services';

@Component({
  selector: 'app-zone-form',
  templateUrl: './zone-form.component.html',
  styleUrls: ['./zone-form.component.scss'],
})
export class ZoneFormComponent implements OnInit {
  @Input()
  public company: RegionalCompany;

  @Input()
  public courierIndex = -1;

  @Input()
  public zoneIndex = -1;

  @Input()
  public currentUrl: string;

  @Input()
  public showDeleteButton = false;

  public form: FormGroup;
  public isLoading = false;
  public courier: Courier;
  public zone: Zone;
  public country: Country;

  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      description: [''],
    });

    if (this.courierIndex > -1 && this.zoneIndex > -1) {
      this.courier = this.company.courierList[this.courierIndex];
      this.zone = this.courier.zoneList[this.zoneIndex];
      this.form.setValue({
        name: this.zone.name,
        code: this.zone.code,
        description: this.zone.description,
      });
    }
  }

  getNameControl() {
    return this.form.get('name') as FormControl;
  }
  getCodeControl() {
    return this.form.get('code') as FormControl;
  }
  getAmountControl() {
    return this.form.get('amount') as FormControl;
  }
  getDescriptionControl() {
    return this.form.get('description') as FormControl;
  }

  submit() {
    this.isLoading = true;
    if (this.zoneIndex > -1) {
      this.zone.name = this.form.value.name;
      this.zone.code = this.form.value.code;
      this.zone.description = this.form.value.description;

      this.company.courierList[this.courierIndex].zoneList[this.zoneIndex] =
        this.zone;
    } else {
      if (this.company.courierList[this.courierIndex].zoneList) {
        this.company.courierList[this.courierIndex].zoneList.push({
          ...this.form.value,
          cityCodeList: [],
        });
      } else {
        this.company.courierList[this.courierIndex].zoneList = [
          {
            ...this.form.value,
            cityCodeList: [],
          },
        ];
      }
    }

    this.store.dispatch(
      saveCompany({ payload: { ...this.company, currentUrl: this.currentUrl } })
    );
    this.onSubmit.emit('Done');
  }

  deleteZone() {
    if (this.company.courierList[this.courierIndex].zoneList) {
      this.company.courierList[this.courierIndex].zoneList.splice(
        this.zoneIndex,
        1
      );
    }
    this.store.dispatch(
      saveCompany({ payload: { ...this.company, currentUrl: this.currentUrl } })
    );
  }
}
