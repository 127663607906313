<div class="content-wrapper shadow-md">
  <p-table
    #dt1
    [globalFilterFields]="['remark', 'remarkCreator', 'createdDate']"
    [loading]="isLoading"
    [paginator]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [rows]="10"
    [showCurrentPageReport]="true"
    [value]="remarks"
    class="block w-full-no-scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    dataKey="id"
    responsiveLayout="scroll"
    styleClass="p-datatable-gridlines w-screen md:w-full-no-scroll flow-root"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div class="buttons flex gap-2">
          <ng-container>
            <button
              (click)="openAddProjectForm()"
              *hasPermissions="['remark:createAny']"
              class="p-button-outlined"
              icon="pi pi-plus"
              label="Add"
              pButton
            ></button>
          </ng-container>
        </div>
        <div class="p-d-flex flex justify-between gap-2">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input (input)="filter($event)" pInputText placeholder="Search keyword" type="text" />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="remark">
          <div class="p-d-flex p-jc-between p-ai-center">
            Remark
            <p-columnFilter display="menu" field="remark" type="text"></p-columnFilter>
            <p-sortIcon field="remark"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="remarkCreator">
          <div class="p-d-flex p-jc-between p-ai-center">
            User
            <p-columnFilter display="menu" field="remarkCreator" type="text"></p-columnFilter>
            <p-sortIcon field="remarkCreator"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="createdDate">
          <div class="p-d-flex p-jc-between p-ai-center">
            Date Added
            <p-columnFilter display="menu" field="createdDate" type="date"></p-columnFilter>
            <p-sortIcon field="createdDate"></p-sortIcon>
          </div>
        </th>
        <!-- <th>Actions</th> -->
      </tr>
    </ng-template>
    <ng-template let-expanded="expanded" let-remark pTemplate="body">
      <tr class="dark:bg-gray-700">
        <td>
          {{ remark.remark }}
        </td>
        <td>
          {{ remark.remarkCreator }}
        </td>
        <td>
          {{ remark.createdDate | date: 'medium' }}
        </td>
        <!-- <td>
          <div>
            <button (click)='deleteProject($event, remark)'
                    *ngIf="plan && (plan.status == 'Draft' || plan.status == 'For Revision')"
                    class='p-button-rounded p-button-outlined p-button-danger'
                    icon='pi pi-times' pButton
                    pRipple
                    pTooltip='Delete Remark' type='button'>
            </button>
          </div>
        </td> -->
      </tr>
    </ng-template>
    <ng-template let-commission pTemplate="rowexpansion">
      <tr class="expanded-tr-logs">
        <td colspan="3">
          <div class="details"></div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No remarks found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="showActivityDialog"
  [blockScroll]="true"
  [breakpoints]="{ '960px': '75vw' }"
  [contentStyle]="{ height: 'clamp(500px, 90%, 90%)' }"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  contentStyleClass=""
  header="Add a remark"
  styleClass="block"
>
  <app-remarks-form #form [plan]="plan"></app-remarks-form>

  <ng-template pTemplate="footer">
    <p-button
      (click)="showActivityDialog = false"
      icon="pi pi-cross"
      label="Cancel"
      styleClass="p-button-outlined p-button-danger"
    ></p-button>
    <p-button (click)="showActivityDialog = !form.submit()" icon="pi pi-check" label="Save"></p-button>
  </ng-template>
</p-dialog>
