import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';
import { Permission, Role } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { Project } from '../../models/plans';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
  deps: [AuthService],
})
export class RoleService {
  private idp: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService,
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().idp) {
      this.idp = this.appConfigService.getConfig().idp;
    } else {
      this.idp = environment.idp;
    }
  }

  public addRole(role: Role) {
    const url = `${this.idp}/roles`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Role>(url, role, { headers });
  }

  public deleteRole(id: number) {
    const url = `${this.idp}/roles/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<unknown>(url, { headers });
  }

  public getPermissions() {
    const url = `${this.idp}/permissions`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Permission[]>(url, { headers });
  }

  public getProjectById(id: number): Observable<Project> {
    const url = `${this.idp}/projects/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Project>(url, { headers });
  }

  public getRoles() {
    const url = `${this.idp}/roles`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Role[]>(url, { headers });
  }

  public updateRole(role: Role) {
    const url = `${this.idp}/roles`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<Role>(url, role, { headers });
  }
}
