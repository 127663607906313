import { createReducer, on } from '@ngrx/store';
import { toggleMenu } from './sidebar-actions';

export interface ToggleMenuState {
  isMenuExpanded: boolean;
}

export const initialSidebarState = {
  isMenuExpanded: false,
};

const _toggleMenuReducer = createReducer(
  initialSidebarState,
  on(toggleMenu, (state, action) => {
    console.log('toggleMenu');
    return {
      isMenuExpanded: !state.isMenuExpanded,
    };
  })
);

export function toggleMenuReducer(
  state: ToggleMenuState | undefined,
  action: any
) {
  return _toggleMenuReducer(state, action);
}
