import { createReducer } from '@ngrx/store';
import { LibraryState } from '.';

export const initialProductState: LibraryState = {
  divisions: [],
  categories: [],
};

const _productReducer = createReducer(initialProductState);

export function productReducer(state: LibraryState | undefined, action: any) {
  return _productReducer(state, action);
}
