import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User, UserPasswordUpdate } from '../../models';
import { UserService } from '../../utils/services';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { MainState, updateBreadCrumbs } from '../../redux';

@Component({
  selector: 'app-user-profile-edit-page',
  templateUrl: './user-profile-edit-page.component.html',
  styleUrls: ['./user-profile-edit-page.component.scss'],
})
export class UserProfileEditPageComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [];
  public isNewPasswordVisible = false;
  public isOldPasswordVisible = false;
  public isPasswordVisible = false;
  public passwordForm: FormGroup & {
    isLoading?: boolean;
    errorMessage?: string;
  };
  public profileForm: FormGroup & {
    isLoading?: boolean;
    errorMessage?: string;
  };
  public user: User = new User();

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: Store<MainState>
  ) {
    this.profileForm = fb.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email], [this.checkEmailIfExists()]],
        mobile: ['', [Validators.pattern('[0-9]{8,}')], [this.checkMobileIfExists()]],
      },
      {
        updateOn: 'blur',
      }
    );

    this.passwordForm = fb.group(
      {
        oldPassword: ['', Validators.required],
        newPassword: ['', Validators.required],
        password: ['', Validators.required],
      },
      {
        updateOn: 'blur',
        validators: this.oldAndNewPasswordValidator,
      }
    );
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe({
      next: (user) => {
        this.user = user;
        this.breadCrumbLinks.push({
          routerLink: '/users/profile',
          label: user.username,
        });
        this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
        this.initFormValues();
      },
      error: (err) => {
        console.error(err);
        this.toastr.error(err.error, 'Error');
      },
    });
  }

  oldAndNewPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const newPassword = control.get('newPassword');

    if (password.value === newPassword.value) {
      return null;
    }

    return {
      passwordsDontMatch: true,
    };
  };

  submitPIForm() {
    this.profileForm.isLoading = true;
    this.profileForm.markAllAsTouched();
    if (this.profileForm.valid) {
      const userForUpdate: User = {
        ...this.user,
        ...this.profileForm.value,
      };

      this.userService.updateUser(userForUpdate).subscribe({
        next: (updatedUser) => {
          this.user = updatedUser;
          this.passwordForm.isLoading = false;
          this.passwordForm.reset();
          this.toastr.success('Profile has been updated', 'Success', {
            positionClass: 'toast-top-center',
          });
        },
        error: (err) => {
          console.error(err);
          this.passwordForm.isLoading = false;
          this.passwordForm.errorMessage = err.error;
          this.toastr.error(err.error, 'Error');
        },
      });
    }
  }

  submitPasswordForm() {
    this.passwordForm.isLoading = true;
    this.passwordForm.markAllAsTouched();
    if (this.passwordForm.valid) {
      const userPasswordUpdate: UserPasswordUpdate = {
        id: this.user.id,
        oldPassword: this.passwordForm.value.oldPassword,
        newPassword: this.passwordForm.value.newPassword,
      };

      this.userService.updateUserPassword(userPasswordUpdate).subscribe({
        next: (updatedUser) => {
          this.user = updatedUser;
          this.passwordForm.isLoading = false;
          this.passwordForm.reset();
          this.toastr.success('Password has been updated', 'Success', {
            positionClass: 'toast-top-center',
          });
        },
        error: (err) => {
          console.error(err);
          this.passwordForm.isLoading = false;
          this.passwordForm.errorMessage = err.error;
          this.toastr.error(err.error, 'Error', {
            disableTimeOut: true,
            positionClass: 'toast-top-center',
          });
        },
      });
    }
  }

  private checkEmailIfExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.dirty || control.touched) {
        if (this.user.email !== control.value) {
          return this.userService.checkEmailIfExists(control.value).pipe(
            map((isEmailExists) => {
              if (isEmailExists) {
                return { isEmailExists };
              }
              return null;
            }),
            catchError((err) => {
              return null;
            })
          );
        }
      }

      return of(null);
    };
  }

  private checkMobileIfExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.dirty || control.touched) {
        if (this.user.mobile !== control.value) {
          return this.userService.checkMobileIfExists(control.value).pipe(
            map((isMobileExists) => {
              if (isMobileExists) {
                return { isMobileExists };
              }
              return null;
            }),
            catchError((err) => {
              return null;
            })
          );
        }
      }
      return of(null);
    };
  }

  private checkUsernameIfExists(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.userService.checkUsernameIfExists(control.value).pipe(
        map((isUsernameExists) => {
          if (isUsernameExists) {
            return { isUsernameExists };
          }
          return null;
        }),
        catchError((err) => {
          return null;
        })
      );
    };
  }

  private initFormValues() {
    this.profileForm.get('firstName').setValue(this.user.firstName);
    this.profileForm.get('lastName').setValue(this.user.lastName);
    this.profileForm.get('email').setValue(this.user.email);
    this.profileForm.get('mobile').setValue(this.user.mobile);
  }
}
