import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';
import { RegionalCompany } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { Project } from '../../models/plans';
import { AuthService } from './auth.service';

interface CompanyWrapper {
  company: RegionalCompany;
}

@Injectable({
  providedIn: 'root',
  deps: [AuthService],
})
export class ProjectService {
  private wfpApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
    } else {
      this.wfpApi = environment.wfpApi;
    }
  }

  public addProject(project: Project) {
    const url = `${this.wfpApi}/projects`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Project>(url, project, { headers });
  }

  public deleteProject(id: number): Observable<Project> {
    const url = `${this.wfpApi}/projects/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<unknown>(url, { headers });
  }

  public getProjectById(id: number): Observable<Project> {
    const url = `${this.wfpApi}/projects/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Project>(url, { headers });
  }

  public getProjectsByPlanId(planId: number, projectType: 'MAIN_PROJECT' | 'SUPPLEMENTAL_PROJECT' = 'MAIN_PROJECT') {
    const url = `${this.wfpApi}/projects?planId=${planId}&projectType=${projectType}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Project[]>(url, { headers });
  }

  public updateProject(project: Project) {
    const url = `${this.wfpApi}/projects`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<Project>(url, project, { headers });
  }
}
