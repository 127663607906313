import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Division, User } from '../../../models';
import { addUser, updateUser } from '../../../redux/user';
import { AuthService, UserService } from '../../../utils/services';
import { UserAccountFormComponent } from '../user-account-form/user-account-form.component';
import { UserProfileFormComponent } from '../user-profile-form/user-profile-form.component';
import { UserReferrerFormComponent } from '../user-referrer-form/user-referrer-form.component';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Input()
  public division: Division;
  public isLoading = false;
  @Output()
  public onSubmit: EventEmitter<string> = new EventEmitter();
  @Input()
  public showDeleteButton = false;
  @ViewChild(UserAccountFormComponent)
  public userAccountForm: UserAccountFormComponent;
  @ViewChild(UserProfileFormComponent)
  public userProfileForm: UserProfileFormComponent;
  @ViewChild(UserReferrerFormComponent)
  public userReferrerForm: UserReferrerFormComponent;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  private _hasPassword: boolean = false;

  get hasPassword() {
    return this._hasPassword;
  }

  @Input()
  set hasPassword(hasPassword: boolean) {
    console.log('hasPassword: ', hasPassword);
    this._hasPassword = hasPassword;
  }

  private _user: User;

  get user() {
    return this._user;
  }

  @Input()
  set user(user: User) {
    if (user && user.id) {
      this._user = user;
    } else {
      this._user = new User();
      this._user.id = 1;
    }
  }

  close() {
    this.onSubmit.emit('cancel');
  }

  deleteUser() {}

  ngOnInit(): void {}

  submit() {
    if (!this.isAllFormsValid()) {
      this.isLoading = false;
      this.toastr.error('You have invalid inputs. Please check all forms.', 'Error');
      return;
    }
    const newUser: User = {
      ...this.userProfileForm.getProfileForm().value,
      ...this.userAccountForm.getAccountForm().value,
      realm: 'EXTERNAL',
    };
    const user = Object.assign(this._user ?? {}, newUser);
    this.isLoading = true;
    if (this._user && this._user.id) {
      this.store.dispatch(updateUser({ user: user }));
    } else {
      this.store.dispatch(addUser({ user: user }));
    }
  }

  private isAllFormsValid() {
    this.userProfileForm.getProfileForm().markAllAsTouched();
    this.userAccountForm.getAccountForm().markAllAsTouched();
    // this.userReferrerForm.getReferrerForm().markAllAsTouched();
    console.log(this.userProfileForm.getProfileForm().value);
    console.log(this.userAccountForm.getAccountForm().value);
    return (
      this.userProfileForm.getProfileForm().valid && this.userAccountForm.getAccountForm().valid
      // this.userReferrerForm.getReferrerForm().valid
    );
  }
}
