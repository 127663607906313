<app-input [inputControl]="getWeightControl()" type="number" inputId="weight" label="Weight" placeHolder="ex. 1"
    [step]="1" [min]=0>
    <ng-container error>
        <div *ngIf="(getWeightControl().touched || getWeightControl().dirty) && getWeightControl().invalid"
            class="alert">
            <ul>
                <ng-container *ngFor="let err of getWeightControl()?.errors | keyvalue">
                    <li *ngIf="err.key == 'pattern'">Please enter without decimals</li>
                    <li *ngIf="err.key == 'required'">Please enter the weight</li>
                </ng-container>
            </ul>
        </div>
    </ng-container>
</app-input>

<app-input [inputControl]="getAmountControl()" type="number" inputId="amount" label="Amount" placeHolder="ex. 1.00"
    [step]="0.01" [min]=0>
    <ng-container before>
        <span class="ml-2">{{currencyCode}}</span>
    </ng-container>
    <ng-container error>
        <div *ngIf="(getAmountControl().touched || getAmountControl().dirty) && getAmountControl().invalid"
            class="alert">
            <ul>
                <ng-container *ngFor="let err of getAmountControl()?.errors | keyvalue">
                    <li *ngIf="err.key == 'required'">Please enter the amount</li>
                </ng-container>
            </ul>
        </div>
    </ng-container>
</app-input>

<div class="buttons flex w-full gap-4">
    <app-button (click)="submit()" [type]="'button'" [disabled]="isLoading" [block]="isLoading"
        [text]="company ? 'Save' : 'Add price'"
        class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md" [loading]="isLoading">
    </app-button>

    <app-button *ngIf="courierIndex > -1 && showDeleteButton" (click)="deleteZone()" [type]="'button'"
        [disabled]="isLoading" [block]="isLoading" [text]="'Delete Price'"
        class="w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md" [loading]="isLoading">
    </app-button>
</div>