import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Role } from '../../../models';

type DataParam = {
  dialogTitle: string,
  role: Role,
}

@Component({
  selector: 'app-user-role-form-dialog',
  templateUrl: './user-role-form-dialog.component.html',
  styleUrls: ['./user-role-form-dialog.component.scss'],
})
export class UserRoleFormDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UserRoleFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataParam,
  ) {
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  onSubmit(event) {
    this.dialogRef.close();
  }
}
