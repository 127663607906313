import { createReducer, on } from '@ngrx/store';
import { AuthState, logoutAction } from '.';

export const initialAuthState: AuthState = {
  authToken: '',
  isLoggedIn: false,
};

const _authReducer = createReducer(initialAuthState);

export function authReducer(state: AuthState | undefined, action: any) {
  return _authReducer(state, action);
}
