import { createReducer, on } from '@ngrx/store';
import { User } from '../../models/user';
import { loadedUser, loadedUsers, loggedinUser } from './user.actions';

export interface UserState {
  authToken?: string;
  refreshToken?: string;
  user?: User;
  vendor?: User;
  users?: User[];
}

const initialState: UserState = {
  authToken: '',
  refreshToken: '',
  user: new User(),
  vendor: new User(),
  users: [],
};

const _userReducer = createReducer(
  initialState,
  on(loggedinUser, (state, action) => {
    return {
      ...state,
      authToken: action.authToken,
      refreshToken: action.refreshToken,
    };
  }),
  on(loadedUser, (state, action) => {
    return {
      ...state,
      user: action.user,
    };
  }),
  on(loadedUsers, (state, action) => {
    return {
      ...state,
      users: action.users,
    };
  })
);

export function userReducer(state: UserState | undefined, action: any) {
  return _userReducer(state, action);
}
