// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  local: false,
  idp: 'https://pims.ddb.gov.ph/wfp-idp',
  usersApi: 'https://pims.ddb.gov.ph/wfp-idp',
  wfpApi: 'https://pims.ddb.gov.ph/wfp-api',
  regionalApi: 'https://cnm-regional-api.websupport.me',
  profileApi: 'https://cnm-profile-api.websupport.me',
  catalogApi: 'https://cnm-catalog-api.websupport.me',
  opsApi: 'https://cnm-ops-api.websupport.me',
  shopApi: 'https://cnm-shop-api.websupport.me',
  imageBaseUrl: 'https://storage.googleapis.com/chef-n-market/catalog',
  commissionApi: 'https://cnm-commission-api.websupport.me',
  gtagId: 'G-DVG0WHGVB1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
