<div class="flex flex-row items-center overflow-hidden">
    <div class="h-screen w-full md:w-1/2 p-4 md:p-10 xl:p-52 flex flex-col justify-center items-center bg-blue-400 bg-gradient-to-br from-blue-400 to-blue-700">
        <div class="content w-full min-w-min sm:max-w-[70%] md:max-w-none shadow-lg bg-white p-4 md:p-10 rounded-xl">
            <div class="sign-up-logo mb-1 md:mb-8 flex justify-center">
                <a [routerLink]="['/']" class="flex items-center">
                    <svg class="w-6 h-6  md:w-12 md:h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z">
                        </path>
                    </svg>
                    <h1 class="text-lg xs:text-xl md:text-3xl ml-2">Sign Up</h1>
                </a>
            </div>

            <div class="card">
                <div class="card-body sign-up-card-body">
                    <p>
                        Thank you for signing up. Please check your email for the verification link. Return to <a routerLink="/">Log in</a>
                    </p>
                </div>
            </div>
        </div>

    </div>
    <div class="h-screen w-1/2 overflow-hidden hidden md:inline-block object-cover">
        <img class="h-full w-full object-cover object-left-top saturate-50" src="/assets/img/register-bg.jpg" />
    </div>
</div>