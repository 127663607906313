import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Asset } from '../../models/asset';
import { AuthService } from './auth.service';

function getAssets() {
  const assets: Asset[] = [];
  for (let i = 0; i < 10; i++) {
    assets.push({
      id: `${i}`,
      name: `Company ${i}`,
      type: 'company',
    } as Asset);
  }

  return assets;
}

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  getAssets() {
    const url = `${environment.idp}/api/oms/permissions`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    // return this.httpClient.get<Asset[]>(url, { headers });
    return of(getAssets());
  }
}
