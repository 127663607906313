import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { MonthlyUpdateFormDialogComponent } from '../../components/plans';
import { Division, User } from '../../models';
import { MainState, updateBreadCrumbs } from '../../redux';
import { generateTempPassword } from '../../redux/user';
import { fadeUpAndFadeDown } from '../../utils/animations';
import { LibraryService, UserService } from '../../utils/services';

type UserWithLoading = User & { isLoading?: boolean; isOpen?: boolean };

@Component({
  selector: 'app-user-list-page',
  templateUrl: './user-list-page.component.html',
  styleUrls: ['./user-list-page.component.scss'],
  animations: [fadeUpAndFadeDown],
})
export class UserListPageComponent implements OnInit, AfterViewInit, OnDestroy {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/users/list',
      label: 'Users',
    },
  ];
  public displayedColumns: string[] = ['username', 'email', 'status', 'buttons'];
  public divisions: Division[];
  public filterString: string;
  public getUsersSubscription: Subscription;
  public isLoading = false;
  public realm = 'EXTERNAL';
  public realms = ['EXTERNAL', 'INTERNAL'];
  public source: MatTableDataSource<UserWithLoading>;
  public users: Array<UserWithLoading> = [];
  public users$: Observable<User[]>;
  @ViewChild('filterInput')
  private filterInput: ElementRef;
  @ViewChild(Table)
  private table: Table;

  constructor(
    private usersService: UserService,
    private router: Router,
    private store: Store<MainState>,
    private libraryService: LibraryService,
    private dialog: MatDialog,
    private confirmationService: ConfirmationService
  ) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      // dt1.filterGlobal($event.target, 'contains')
    }
  }

  generateUserPassword(event: Event, user: User) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to generate a new password for this user?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(generateTempPassword({ user: user }));
      },
      reject: () => {},
    });
  }

  getRoles(user: User) {
    return user.roles.map((role) => role.roleName);
  }

  getUsersUnsubscribe() {
    if (this.getUsersSubscription) {
      this.getUsersSubscription.unsubscribe();
    }
  }

  isUserActivated(user: User) {
    return user.accountNonLocked && user.enabled;
  }

  loadData() {
    this.getUsersSubscription = this.usersService
      .getUsers(this.realm)
      .pipe(
        exhaustMap((users) => {
          this.users = users
            .map((user) => {
              const newUser = Object.assign(new User(), user);
              newUser.roleNames = this.getRoles(newUser);
              return newUser;
            })
            .filter((user) => user.username != 'admin');
          return of(null); // this.libraryService.getDivisions();
        })
      )
      .subscribe({
        next: (divisions) => {
          // this.divisions = divisions;
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.getUsersUnsubscribe();
  }

  ngOnInit(): void {
    this.loadData();
  }

  onPageUpdate(event: PageEvent) {
    console.log(event);
    this.source._updateChangeSubscription();
  }

  openAddUserFormDialog() {
    this.router.navigate(['users', 'add']);
  }

  openEditDialog(user: User) {
    const ref = this.dialog.open(MonthlyUpdateFormDialogComponent, {
      data: {
        user: user,
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
    });
  }

  realmChanged() {
    this.getUsersUnsubscribe();
    this.loadData();
  }

  resendUserVerificationEmail(user: User & { isLoading?: boolean }) {
    user.isLoading = true;
    this.usersService.resendUserVerificationEmail(user).subscribe({
      next: (updatedUser) => {
        user.isLoading = false;
        this.loadData();
      },
      error: (err) => {
        console.error(err);
        user.isLoading = false;
      },
    });
  }

  submit(user: User & { isLoading?: boolean }) {
    user.isLoading = true;
    this.usersService.verifyUserPayment(user).subscribe({
      next: (updatedUser) => {
        user.company.status = updatedUser.company.status;
        user.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        user.isLoading = false;
      },
    });
  }
}

interface PageUpdateEvent {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}
