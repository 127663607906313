<div class='main-content'>
  <div class='top-container grid grid-cols-1 md:grid-cols-4 gap-4 items-start'>

    <div
      class='main-form mb-4 form bg-white rounded-lg shadow-md p-10 dark:bg-gray-700 flex flex-col gap-4 md:col-span-3'>
      <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
        <label class='block font-raleway font-bold'>Division *</label>
        <p-dropdown
          [formControl]="getFormControl('divisionId')"
          [options]='divisions'
          inputId='divisionId'
          label='Division'
          optionLabel='name'
          optionValue='id'
          placeholder='Select division'
          scrollHeight='300px'
          styleClass='w-full rounded-none'
        ></p-dropdown>
      </div>
      <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
        <label class='block font-raleway font-bold'>Year *</label>
        <p-dropdown
          [formControl]="getFormControl('year')"
          [options]='yearChoices'
          inputId='year'
          label='year'
          placeholder='Select year'
          scrollHeight='300px'
          styleClass='w-full rounded-none'
        ></p-dropdown>
      </div>
      <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
        <label class='block font-raleway font-bold'>Month *</label>
        <p-dropdown
          [formControl]="getFormControl('month')"
          [options]='getMonths()'
          inputId='month'
          label='Month'
          optionLabel='name'
          optionValue='id'
          placeholder='Select month'
          scrollHeight='300px'
          styleClass='w-full rounded-none'
        ></p-dropdown>
      </div>
      <!--      <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">-->
      <!--        <label class='block font-raleway font-bold'>Project Type *</label>-->
      <!--        <p-dropdown-->
      <!--          [formControl]="getFormControl('projectType')"-->
      <!--          [options]='projectTypesChoices'-->
      <!--          inputId='projectType'-->
      <!--          label='Plan Status'-->
      <!--          optionLabel='name'-->
      <!--          optionValue='value'-->
      <!--          placeholder='Select a project type'-->
      <!--          scrollHeight='300px'-->
      <!--          styleClass='w-full rounded-none'-->
      <!--        ></p-dropdown>-->
      <!--      </div>-->
    </div>

    <div class='button mb-4 form bg-white rounded-lg shadow-md p-10 dark:bg-gray-700 flex flex-col md:flex-row gap-4'>
      <button (click)='submit()' icon='pi pi-file-pdf' label='Open Report Preview' pButton type='button'></button>
      <button (click)='downloadExcel()' class='p-button-success' icon='pi pi-file-excel' label='Download Excel' pButton
              type='button'>
      </button>
    </div>
  </div>

  <app-signatories></app-signatories>
</div>
