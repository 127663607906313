<div class="flex gap-2 mt-8">
  <h3>Signatories</h3>
</div>

<div
  class="signatories-form-container mb-4 form bg-white rounded-lg shadow-md p-10 dark:bg-gray-700 flex flex-col gap-4"
>
  <div *ngFor="let signatoryLine of _signatories; let i = index">
    <div class="flex items-center gap-4">
      <h4 class="mb-0">Line {{ i + 1 }}</h4>
      <button11
        (click)="addSignatory(i)"
        class="p-button-rounded p-button-outlined"
        icon="pi pi-plus"
        pButton
        pRipple
        pTooltip="Add Signatory"
        type="button"
      ></button11>
    </div>
    <div
      *ngFor="let signatory of signatoryLine; let j = index"
      class="signatory-form grid grid-cols-1 md:grid-cols-3 gap-4"
    >
      <div [ngClass]="{ 'cursor-not-allowed': !canEdit(), 'mb-4': true }">
        <span class="block w-full">
          <label class="block font-raleway font-bold">Action *</label>

          <input [(ngModel)]="signatory.action" class="w-full rounded-none" pInputText type="text" />
        </span>
      </div>

      <div [ngClass]="{ 'cursor-not-allowed': !canEdit(), 'mb-4': true }">
        <span class="block w-full">
          <label class="block font-raleway font-bold">Name *</label>
          <input [(ngModel)]="signatory.name" class="w-full rounded-none" pInputText type="text" />
        </span>
      </div>

      <div [ngClass]="{ 'cursor-not-allowed': !canEdit(), 'mb-4': true }">
        <span class="block w-full">
          <label class="block font-raleway font-bold">Designation *</label>
          <div class="flex items-center gap-4">
            <input [(ngModel)]="signatory.designation" class="w-full rounded-none" pInputText type="text" />
            <button
              (click)="removeSignatory(i, j)"
              [ngClass]="{
                'p-button-rounded p-button-outlined p-button-danger': true,
                invisible: _signatories.length == 1 && signatoryLine.length == 1
              }"
              icon="pi pi-times"
              pButton
              pRipple
              pTooltip="Remove Signatory"
              type="button"
            ></button>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div>
    <button
      (click)="addGroup()"
      icon="pi pi-file-pdf"
      label="Add another signatory line"
      pButton
      type="button"
    ></button>
  </div>
</div>
