import { createReducer, on } from '@ngrx/store';
import { clearCountries, CountryState, loadedCountries } from '.';

export const initialCountryState: CountryState = {
  countries: [],
};

const _countryReducer = createReducer(
  initialCountryState,
  on(loadedCountries, (state, action) => {
    return { ...state, countries: action.payload };
  }),
  on(clearCountries, (state, action) => {
    return {
      ...state,
      countries: null,
    };
  })
);

export function countryReducer(state: CountryState | undefined, action: any) {
  return _countryReducer(state, action);
}
