import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export interface SidebarState {
  isMenuExpanded: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SidebarComponentStore extends ComponentStore<SidebarState> {
  constructor() {
    super({
      isMenuExpanded: true,
    });
  }

  readonly isMenuExpanded$: Observable<boolean> = this.select(
    (state) => state.isMenuExpanded
  );

  readonly toggleMenu = this.updater((state) => {
    return {
      ...state,
      isMenuExpanded: !state.isMenuExpanded,
    } as SidebarState;
  });
}
