<div class='main-content mb-4 flex flex-col gap-4'>
  <div class='grid grid-cols-1 gap-2 lg:gap-0 md:grid-cols-2'>
    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label class='block font-raleway font-bold' for='reportingYear'>Reporting Year:</label>
      <p-dropdown
        [formControl]="getFormControl('reportingYear')"
        [options]='yearChoices'
        inputId='reportingYear'
        label='Reporting Year'
        placeholder='Select reporting year'
        scrollHeight='200px'
        styleClass='w-full rounded-none'
      ></p-dropdown>
    </div>

    <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <label class='block font-raleway font-bold'>Quarter of the Year:</label>
      <p-dropdown
        [formControl]="getFormControl('yearQuarter')"
        [options]='getQuarters()'
        inputId='yearQuarter'
        optionLabel='name'
        optionValue='id'
        placeholder='Select reporting month'
        scrollHeight='300px'
        styleClass='w-full rounded-none'
      ></p-dropdown>
    </div>

  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold'>Target/Milestone</label>
      <input
        [formControl]="getFormControl('milestones')"
        class='w-full rounded-none'
        id='milestones'
        pInputText
        type='text'
      />
      <ul
        *ngIf="
          (getFormControl('milestones').touched || getFormControl('milestones').dirty) &&
          getFormControl('milestones').invalid
        "
      >
        <ng-container *ngFor="let err of getFormControl('milestones')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a milestone</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
    <span class='block w-full'>
      <label class='block font-raleway font-bold'>Actual Accomplishment</label>
      <input
        [formControl]="getFormControl('accomplishments')"
        class='w-full rounded-none'
        id='accomplishments'
        pInputText
        type='text'
      />
      <ul
        *ngIf="
          (getFormControl('accomplishments').touched || getFormControl('accomplishments').dirty) &&
          getFormControl('accomplishments').invalid
        "
      >
        <ng-container *ngFor="let err of getFormControl('milestones')?.errors | keyvalue">
          <li *ngIf="err.key == 'required'">Please enter a milestone</li>
        </ng-container>
      </ul>
    </span>
  </div>

  <div [ngClass]="{ 'cursor-not-allowed': !canEdit() }">
      <span class='block w-full'>
        <label class='block font-raleway font-bold' for='remarks'> Remarks </label>
        <input
          [formControl]="getFormControl('remarks')"
          class='w-full rounded-none'
          id='remarks'
          pInputText
          type='text'
        />
        <ul
          *ngIf="
            (getFormControl('remarks').touched || getFormControl('remarks').dirty) && getFormControl('remarks').invalid
          "
        >
          <ng-container *ngFor="let err of getFormControl('remarks')?.errors | keyvalue">
            <li *ngIf="err.key == 'required'">Please enter remarks</li>
          </ng-container>
        </ul>
      </span>
  </div>

</div>

<div *ngIf='false' class='buttons flex w-full gap-4'>
  <app-button
    (click)='submit()'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="project && project.id ? 'Save' : 'Add Project Activity'"
    [type]="'button'"
    class='w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md'
  >
  </app-button>

  <app-button
    (click)='deletePlan()'
    *ngIf='project.id && showDeleteButton'
    [block]='isLoading'
    [disabled]='isLoading'
    [loading]='isLoading'
    [text]="'Delete Project Activity'"
    [type]="'button'"
    class='w-max bg-red-500 text-white border-red-600 hover:bg-red-400 rounded-md'
  >
  </app-button>
</div>
