import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const enterAndLeaveAnimation = trigger('enterAndLeave', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms', style({ opacity: 1, width: '100%' })),
  ]),
  transition(':leave', [animate('200ms', style({ opacity: 0, width: '0' }))]),
]);

export const fadeUpAndFadeDown = trigger('fadeUpAndFadeDown', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-100%)' }),
    animate('250ms 250ms', style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    animate('200ms', style({ opacity: 0, transform: 'translateY(-100%)' })),
  ]),
]);

export const fadeInAndFadeOut = trigger('fadeInAndFadeOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('250ms 250ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('200ms', style({ opacity: 0 })),
  ]),
]);

export const backInDownAndBackOutDown = trigger('backInDownAndBackOutDown', [
  transition(':enter', [
    style({
      position: 'absolute',
      opacity: 0.7,
      offset: 0,
    }),
    animate(
      '500ms',
      keyframes([
        style({
          transform: 'translateY(-1200px) scale(0.7)',
          opacity: 0.7,
          offset: 0,
        }),
        style({
          transform: 'translateY(0px) scale(0.7)',
          opacity: 0.7,
          offset: 0.8,
        }),
        style({
          transform: 'scale(1)',
          position: 'relative',
          opacity: 1,
          offset: 1,
        }),
      ])
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'scale(1)',
      position: 'absolute',
      opacity: 1,
      offset: 0,
    }),
    animate(
      '500ms',
      keyframes([
        style({
          transform: 'scale(1)',
          opacity: 1,
          offset: 0,
        }),
        style({
          transform: 'translateY(0px) scale(0.7)',
          opacity: 0.7,
          offset: 0.2,
        }),
        style({
          transform: 'translateY(700px) scale(0.7)',
          opacity: 0.7,
          offset: 1,
        }),
      ])
    ),
  ]),
]);
