<div class="main-wrapper my-auto mx-auto w-11/12 sm:w-3/4 xl:w-1/2 flex flex-col sans-serif py-4 md:pt-0">
    <h1 class="page-title text-center font-bold mb-5 text-white">Sign Up Form</h1>
    <div class="content-wrapper relative bg-gray-200 rounded-2xl">
        <div class="body max-w-3xl mx-auto p-4 md:p-8">
            <ng-container *ngIf="isSuccessful">
                <div class="bg-white rounded-lg p-10 flex flex-col items-center shadow justify-between">
                    <ng-container>
                        <svg class="mb-4 h-20 w-20 text-green-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd" />
                        </svg>

                        <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">Registration Success</h2>

                        <div class="text-gray-600 mb-8">
                            Thank you. We have sent you an email to {{this.profileForm.get('email').value}}. Please
                            click the link in the message
                            to activate your account.
                        </div>

                        <a routerLink="/auth/login"
                            class="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border">Back
                            to home</a>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="message">
                <div class="bg-white rounded-lg p-10 flex flex-col items-center shadow justify-between">
                    <ng-container>
                        <fa-icon [icon]="['fas', 'times-circle']" size="4x" class="text-red-500"></fa-icon>

                        <h2 class="text-2xl mb-4 text-gray-800 text-center font-bold">Registration Failed</h2>

                        <div class="text-gray-600 mb-8">
                            Looks like you did not fill up all the forms correctly. Please check that all the items are
                            correct.
                        </div>

                        <button (click)="step = 1; message = ''"
                            class="w-40 block mx-auto focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border">
                            Close
                        </button>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="!isDone">
                <!-- Top Navigation -->
                <div class="subtitle-container border-b-2 py-4">
                    <div class="uppercase tracking-wide text-xs font-bold text-gray-500 mb-1 leading-tight" x-text="``">
                        Step: {{step}} of 3
                    </div>

                    <div class="flex flex-col md:flex-row md:items-center md:justify-between">
                        <div class="flex-1">
                            <ng-container *ngIf="step == 1">
                                <div class="text-lg font-bold text-gray-700 leading-tight">Your Profile</div>
                            </ng-container>

                            <ng-container *ngIf="step == 2">
                                <div class="text-lg font-bold text-gray-700 leading-tight">Account Credentials</div>
                            </ng-container>

                            <ng-container *ngIf="step == 3">
                                <div class="text-lg font-bold text-gray-700 leading-tight">Referral</div>
                            </ng-container>
                        </div>
                        <div class="flex flex-col items-start md:w-72 mt-4 md:mt-0">
                            <div class="text-xs w-20 text-gray-600" x-text="parseInt(step / 3 * 100) +'%'">
                                {{ step / 3 * 100 | number: '1.2-2'}}%
                            </div>
                            <div class="w-full bg-white rounded-full mr-2">
                                <div class="rounded-full bg-green-500 text-xs leading-none h-2 text-center text-white"
                                    [ngStyle]="{'width': (step / 3 * 100) + '%'}"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Top Navigation -->

                <!-- Step Content -->
                <div class="forms">
                    <div @fadeUpAndFadeDown class="form" *ngIf="step == 1">
                        <form [formGroup]="profileForm">
                            <div class="input-container mb-5">
                                <label for="firstName" class="font-bold mb-1 text-gray-700 block">First Name</label>

                                <div class="input-icon-container bg-white rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 flex items-center pr-2"
                                    [ngClass]="{'border border-red-600': (profileForm.get('firstName').touched || profileForm.get('firstName').dirty) && profileForm.get('firstName').invalid}">
                                    <input type="text" formControlName="firstName" id="firstName" #firstName autofocus
                                        class="text-input w-full rounded-lg font-medium px-4 py-3 outline-none"
                                        placeholder="ex. John">
                                    <fa-icon title="Required" class="text-red-500"
                                        *ngIf="(profileForm.get('firstName').touched || profileForm.get('firstName').dirty) && profileForm.get('firstName').invalid"
                                        [icon]="['fas', 'exclamation-circle']"></fa-icon>
                                </div>
                            </div>

                            <div class="input-container mb-5">
                                <label for="lastName" class="font-bold mb-1 text-gray-700 block">Last Name</label>

                                <div class="input-icon-container bg-white rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 flex items-center pr-2"
                                    [ngClass]="{'border border-red-600': (profileForm.get('lastName').touched || profileForm.get('lastName').dirty) && profileForm.get('lastName').invalid}">
                                    <input type="text" id="lastName" formControlName="lastName"
                                        class="text-input w-full rounded-lg font-medium px-4 py-3 outline-none"
                                        placeholder="ex. John">
                                    <fa-icon title="Required" class="text-red-500"
                                        *ngIf="(profileForm.get('lastName').touched || profileForm.get('lastName').dirty) && profileForm.get('lastName').invalid"
                                        [icon]="['fas', 'exclamation-circle']"></fa-icon>
                                </div>
                            </div>

                            <div class="input-container mb-5">
                                <label for="email" class="font-bold mb-1 text-gray-700 block">Email</label>

                                <div class="input-icon-container bg-white rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 flex items-center pr-2"
                                    [ngClass]="{'border border-red-600': (profileForm.get('email').touched || profileForm.get('email').dirty) && profileForm.get('email').invalid}">
                                    <input type="email" formControlName="email"
                                        class="text-input w-full rounded-lg font-medium px-4 py-3 outline-none"
                                        placeholder="ex. john@example.com">
                                    <fa-icon title="Invalid email" class="text-red-500"
                                        *ngIf="(profileForm.get('email').touched || profileForm.get('email').dirty) && profileForm.get('email').invalid"
                                        [icon]="['fas', 'exclamation-circle']"></fa-icon>
                                </div>

                                {{getErrors()}}
                            </div>

                            <div class="input-container mb-5">
                                <label for="mobile" class="font-bold mb-1 text-gray-700 block">Mobile Number &ndash;
                                    <small><em>(optional, min 8 digits)</em></small></label>
                                <div class="input-icon-container bg-white rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 flex items-center pr-2"
                                    [ngClass]="{'border border-red-600': (profileForm.get('mobile').touched || profileForm.get('mobile').dirty) && profileForm.get('mobile').invalid}">
                                    <input type="tel" id="mobile" formControlName="mobile"
                                        class="text-input w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        placeholder="ex. 09171234567">
                                    <fa-icon title="Invalid mobile" class="text-red-500"
                                        *ngIf="(profileForm.get('mobile').touched || profileForm.get('mobile').dirty) && profileForm.get('mobile').invalid"
                                        [icon]="['fas', 'exclamation-circle']"></fa-icon>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div @fadeUpAndFadeDown class="form" *ngIf="step == 2">
                        <form [formGroup]="accountForm">
                            <div class="input-container mb-5">
                                <label for="username" class="font-bold mb-1 text-gray-700 block">Set up username</label>
                                <div class="text-gray-600 mt-2 mb-4">
                                    Only the characters below are allowed for the username.

                                    <ul class="list-disc text-sm ml-4 mt-2">
                                        <li>lowercase letters</li>
                                        <li>numbers</li>
                                        <li>capital letters</li>
                                        <li>"_"</li>
                                        <li>"."</li>
                                        <li>"-"</li>
                                    </ul>
                                </div>
                                <div class="input-icon-container bg-white rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 flex items-center pr-2"
                                    [ngClass]="{'border border-red-600': (accountForm.get('username').touched || accountForm.get('username').dirty) && accountForm.get('username').invalid}">
                                    <input type="text" id="username" formControlName="username" #username autofocus
                                        class="text-input w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        placeholder="ex. johndoe">
                                    <fa-icon title="Invalid username" class="text-red-500"
                                        *ngIf="(accountForm.get('username').touched || accountForm.get('username').dirty) && accountForm.get('username').invalid"
                                        [icon]="['fas', 'exclamation-circle']"></fa-icon>
                                </div>
                            </div>

                            <div class="input-container mb-5">
                                <label for="password" class="font-bold mb-1 text-gray-700 block">Set up password</label>
                                <div class="text-gray-600 mt-2 mb-4">
                                    Please create a secure password including the following criteria below.

                                    <ul class="list-disc text-sm ml-4 mt-2">
                                        <li>minimum of 4 characters</li>
                                        <li>lowercase letters</li>
                                        <li>numbers</li>
                                        <li>capital letters</li>
                                        <li>special characters</li>
                                    </ul>
                                </div>

                                <div class="relative">
                                    <input [type]="isPasswordVisible ? 'text' : 'password'"
                                        :type="togglePassword ? 'text' : 'password'" x-model="password"
                                        formControlName="password"
                                        class="w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        [ngClass]="{'border border-red-600': (accountForm.get('password').touched || accountForm.get('password').dirty) && accountForm.get('password').invalid}"
                                        placeholder="Your strong password...">

                                    <div class="absolute right-0 bottom-0 top-0 px-3 py-3 cursor-pointer">
                                        <a href="#" (click)="$event.preventDefault();togglePasswordVisibility()">
                                            <svg *ngIf="isPasswordVisible" class="block"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                                                <path
                                                    d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757C12.568 16.983 12.291 17 12 17c-5.351 0-7.424-3.846-7.926-5 .204-.47.674-1.381 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379-.069.205-.069.428 0 .633C2.073 12.383 4.367 19 12 19zM12 5c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657.069-.205.069-.428 0-.633C21.927 11.617 19.633 5 12 5zM16.972 15.558l-2.28-2.28C14.882 12.888 15 12.459 15 12c0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501C9.796 7.193 10.814 7 12 7c5.351 0 7.424 3.846 7.926 5C19.624 12.692 18.76 14.342 16.972 15.558z" />
                                            </svg>

                                            <svg *ngIf="!isPasswordVisible" class="block"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                                                <path
                                                    d="M12,9c-1.642,0-3,1.359-3,3c0,1.642,1.358,3,3,3c1.641,0,3-1.358,3-3C15,10.359,13.641,9,12,9z" />
                                                <path
                                                    d="M12,5c-7.633,0-9.927,6.617-9.948,6.684L1.946,12l0.105,0.316C2.073,12.383,4.367,19,12,19s9.927-6.617,9.948-6.684 L22.054,12l-0.105-0.316C21.927,11.617,19.633,5,12,5z M12,17c-5.351,0-7.424-3.846-7.926-5C4.578,10.842,6.652,7,12,7 c5.351,0,7.424,3.846,7.926,5C19.422,13.158,17.348,17,12,17z" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div @fadeUpAndFadeDown class="form" *ngIf="step == 3">
                        <form [formGroup]="referralForm">
                            <div class="input-container mb-8">
                                <label for="referralName" class="font-bold mb-1 text-gray-700 block">Who referred you to
                                    us?</label>
                                <div class="input-icon-container bg-white rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 flex items-center pr-2"
                                    [ngClass]="{'border border-red-600': (referralForm.get('referralName').touched || referralForm.get('referralName').dirty) && referralForm.get('referralName').invalid}">
                                    <input type="text" id="referralName" formControlName="referralName" #referralName
                                        class="text-input w-full px-4 py-3 rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                        placeholder="ex. John Doe">
                                    <fa-icon title="Invalid referral name1" class="text-red-500"
                                        *ngIf="(referralForm.get('referralName').touched || referralForm.get('referralName').dirty) && referralForm.get('referralName').invalid"
                                        [icon]="['fas', 'exclamation-circle']"></fa-icon>
                                </div>
                            </div>

                            <div class="input-container mb-5">
                                <label for="referralName" class="font-bold mb-4 text-gray-700 block">Which package do
                                    you want?</label>

                                <div class="card-selection-container">
                                    <div class="card-selection "
                                        [ngClass]="{'selected':referralForm.get('userPackage').value == 'PARTNER' ? true:false }"
                                        (click)="selectPackage('PARTNER', $event)">
                                        <fa-icon [icon]="['fas', 'handshake']" [fixedWidth]="true" size="4x"></fa-icon>
                                        <h4>Partner</h4>
                                        <h2>Php 8,998</h2>
                                        <input type="radio" name="userPackage" value="PARTNER"
                                            formControlName="userPackage" class="hidden" />
                                    </div>
                                    <div class="card-selection "
                                        [ngClass]="{'selected':referralForm.get('userPackage').value == 'RESELLER' ? true:false }"
                                        (click)="selectPackage('RESELLER', $event)">
                                        <fa-icon [icon]="['fas', 'store']" [fixedWidth]="true" size="4x"></fa-icon>
                                        <h4>Reseller</h4>
                                        <h2>Php 998</h2>
                                        <input type="radio" name="userPackage" value="RESELLER"
                                            formControlName="userPackage" class="hidden" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- / Step Content -->
            </ng-container>
        </div>

        <!-- Bottom Navigation -->
        <div class="footer py-5 bg-white rounded-b-2xl shadow-md">
            <div class="max-w-3xl mx-auto px-4">
                <div class="flex justify-between">
                    <div class="w-1/2">
                        <button *ngIf="step > 1" type="button" (click)="previous()"
                            class="w-32 focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-gray-600 bg-white hover:bg-gray-100 font-medium border border-gray-300">Previous</button>
                    </div>

                    <div class="w-1/2 text-right hidden">
                        <button *ngIf="step == 3" type="button" (click)="submit()"
                            class="w-32 focus:outline-none border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-green-500 hover:bg-green-400 font-medium">Complete</button>
                    </div>
                    <div class="w-1/2 text-right">
                        <button *ngIf="step < 3" type="button" (click)="next()"
                            class="w-32 focus:outline-none border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium">Next</button>

                        <app-button *ngIf="step == 3" (click)="submit()" [type]="'button'" [disabled]="isAuthLoading"
                            [block]="true" [text]="'Submit'"
                            class="w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md"
                            [loading]="isAuthLoading">
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Bottom Navigation https://placehold.co/300x300/e2e8f0/cccccc -->
    </div>
</div>