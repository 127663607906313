import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectAppBreadCrumbs } from '../../redux/app/app.selectors';
import { MainState } from '../../redux';
import { Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppComponent } from '../../app.component';
import { MenuService } from '../../utils/services';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [
    trigger('mask-anim', [
      state('void', style({
        opacity: 0,
      })),
      state('visible', style({
        opacity: 0.8,
      })),
      transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)')),
    ]),
  ],
})
export class MainPageComponent implements OnInit, AfterViewInit {
  public breadCrumbs$: Observable<MenuItem[]>;
  configDialogActive: boolean;
  menuActive: boolean;
  menuClick: boolean;
  menuHoverActive: boolean;
  topbarUserMenuActive: boolean;
  userMenuClick: boolean;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private activeRoute: ActivatedRoute,
    private store: Store<MainState>,
    public app: AppComponent,
    private menuService: MenuService,
  ) {
    this.breadCrumbs$ = this.store.pipe(select(selectAppBreadCrumbs));
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  isMobile() {
    return window.innerWidth <= 1024;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  ngAfterViewInit(): void {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page',
    );
    // this.router.navigate(['/dashboard']);
  }

  ngOnInit(): void {

  }

  onMenuButtonClick(event: Event) {
    this.menuClick = true;

    if (!this.app.horizontal || this.isMobile()) {
      this.menuActive = !this.menuActive;

      if (this.menuActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onRippleChange(event) {
    this.app.ripple = event.checked;
    // this.primengConfig.ripple = event.checked;
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
  }

  onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  onTopbarUserMenuButtonClick(event) {
    this.userMenuClick = true;
    this.topbarUserMenuActive = !this.topbarUserMenuActive;

    event.preventDefault();
  }

  onTopbarUserMenuClick(event) {
    this.userMenuClick = true;

    if (event.target.nodeName === 'A' || event.target.parentNode.nodeName === 'A') {
      this.topbarUserMenuActive = false;
    }
  }

  onWrapperClick() {
    if (!this.menuClick) {
      this.menuActive = false;

      if (this.app.horizontal) {
        this.menuService.reset();
      }

      this.menuHoverActive = false;
      this.unblockBodyScroll();
    }

    if (!this.userMenuClick) {
      this.topbarUserMenuActive = false;
    }

    this.userMenuClick = false;
    this.menuClick = false;
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp(`(^|\\b)${
        'blocked-scroll'.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
    }
  }
}
