import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IS_SPINNER_ENABLED } from '../constants';
import { SpinnerOverlayService } from '../services';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private readonly spinnerOverlayService: SpinnerOverlayService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const showSpinner = req.context.get(IS_SPINNER_ENABLED);
    let spinnerSubscription: Subscription;
    if (showSpinner) {
      spinnerSubscription = this.spinnerOverlayService.spinner$.subscribe();
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (showSpinner) {
          return spinnerSubscription.unsubscribe();
        }
        return EMPTY;
      })
    );
  }
}
