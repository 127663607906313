<div class="flex flex-row items-center overflow-hidden">
  <div class="h-screen w-1/2 overflow-hidden hidden md:inline-block object-cover">
      <img class="h-full w-full object-cover shadow-md" src="/assets/img/login-bg.jpg" />
  </div>
  <div class="h-screen w-full md:w-1/2 p-4 md:p-10 xl:p-52 flex flex-col justify-center items-center bg-blue-400 bg-gradient-to-bl from-blue-400 to-blue-700">
      <div class="content w-full shadow-md bg-white p-4 md:p-10 rounded-xl">
          <div class="login-logo mb-4 md:mb-8 flex justify-center">
              <a [routerLink]="['/']" class="flex items-center">
                  <h1 class="text-lg xs:text-xl md:text-3xl ml-2">Send password reset form</h1>
              </a>
          </div>

          <!-- /.login-logo -->
          <div class="card">
              <div class="card-body login-card-body">
                  <p class="mb-4 text-sm">Enter your email to receive the reset password link.</p>

                  <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()">
                      <label class="text-gray-600 inline-block mb-1" for="email">Email</label>
                      <div class="input-group shadow focus-within:border-blue-400 focus-within:shadow-md-blue-500 border-gray-300 rounded-md p-2 border flex w-full">
                          <div class="inline-block grayscale-0 text-gray-400 mr-2">
                              <fa-icon [icon]="['fas', 'user']"></fa-icon>
                          </div>
                          <input formControlName="email" id="email" type="text" class="form-control focus:outline-none w-full"
                              placeholder="ex. admin" />
                      </div>
                      <div @enterAndLeave *ngIf="!isValidEmail()" class="alert">
                          Email is required.
                      </div>

                      <div class="my-3 flex flex-row items-center">
                          <app-button [type]="'submit'" [disabled]="isAuthLoading" [block]="true" [text]="'Reset Password'"
                              class="bg-blue-700 text-white border-blue-600 hover:bg-blue-600 rounded-md mr-4"
                              [loading]="isAuthLoading">
                          </app-button> <span class="mr-4">or</span>
                          <a routerLink="/sign-up" class="hover:underline">Log in</a>
                      </div>
                  </form>

              </div>
              <!-- /.login-card-body -->
          </div>
      </div>

  </div>
</div>