<div class="page-wrapper">
  <p-table
    #dt1
    [globalFilterFields]="['orderId', 'orderStatus']"
    [loading]="isLoading"
    [paginator]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [rows]="10"
    [showCurrentPageReport]="true"
    [value]="invoices"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    dataKey="id"
    responsiveLayout="scroll"
    styleClass="p-datatable-gridlines shadow-md"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex flex gap-2">
        <button
          (click)="clear(dt1)"
          class="p-button-outlined"
          icon="pi pi-filter-slash"
          label="Clear"
          pButton
        ></button>
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            (input)="filter($event)"
            pInputText
            placeholder="Search keyword"
            type="text"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="orderId">
          <div class="p-d-flex p-jc-between p-ai-center">
            Order ID
            <p-columnFilter
              display="menu"
              field="orderId"
              type="text"
            ></p-columnFilter>
            <p-sortIcon field="orderId"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="createdDate">
          <div class="p-d-flex p-jc-between p-ai-center">
            Date
            <p-columnFilter
              display="menu"
              field="createdDate"
              type="date"
            ></p-columnFilter>
            <p-sortIcon field="createdDate"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="active">
          <div class="p-d-flex p-jc-between p-ai-center">
            Status
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template let-expanded="expanded" let-invoice pTemplate="body">
      <tr class="dark:bg-gray-700">
        <td>
          <div class="button-container flex items-center gap-2">
            <button
              *ngIf="orderStatus != 'PENDING'"
              type="button"
              pButton
              pRipple
              [pRowToggler]="invoice"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></button>
            <a [routerLink]="['/orders', invoice.orderId]">
              {{ invoice.orderId }}
            </a>
          </div>
        </td>
        <td>
          {{ invoice.createdDate | date: 'MM/dd/yyyy' }}
        </td>
        <td>
          <span [class]="'customer-badge status-' + invoice.orderStatus">{{
            invoice.orderStatus
          }}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template let-invoice pTemplate="rowexpansion">
      <tr class="expanded-tr-logs">
        <td colspan="3">
          <div class="details">
            <p-table [value]="getLogs(invoice)">
              <ng-template pTemplate="header">
                <tr>
                  <th>Status</th>
                  <th>User</th>
                  <th>Date</th>
                  <th>Remarks</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-log>
                <tr>
                  <td>{{ log.statusType }} - {{ log.status }}</td>
                  <td>
                    {{ log.createdBy }}
                  </td>
                  <td>
                    {{ log.createdDate | date: 'MM/dd/yyyy' }}
                  </td>
                  <td>
                    {{ log.remarks }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No invoices found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
