import { Component, Inject, OnInit } from '@angular/core';
import { MonthlyUpdate, Project, QuarterlyUpdate } from '../../../models/plans';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type DialogData = {
  project?: Project;
  quarterlyUpdate?: QuarterlyUpdate;
};

@Component({
  selector: 'app-quarterly-update-form-dialog',
  templateUrl: './quarterly-update-form-dialog.component.html',
  styleUrls: ['./quarterly-update-form-dialog.component.scss'],
})
export class QuarterlyUpdateFormDialogComponent implements OnInit {
  public project: Project;
  public quarterlyUpdate: MonthlyUpdate;

  constructor(
    public dialogRef: MatDialogRef<QuarterlyUpdateFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    dialogRef.updateSize('90%', '90%');
    this.project = data.project;
    this.quarterlyUpdate = data.quarterlyUpdate;
  }

  ngOnInit(): void {}

  onSubmit(event) {
    this.dialogRef.close();
  }
}
