<div class='layout-topbar'>
  <a
    (click)='app.onMenuButtonClick($event)'
    [ngClass]="{ 'layout-menubutton-active': app.menuActive }"
    class='layout-menubutton'
    href='#'
  >
    <div class='layout-menubutton-icon'></div>
  </a>

  <div class='layout-topbar-grid'>
    <div class='layout-topbar-grid-column layout-topbar-grid-column-fixed'>
      <a class='layout-logo flex justify-items-center gap-2' routerLink='/'>
        <img src='/assets/img/ddb_logo.png' class='block h-[42px]' />
        <h1 class='text-white m-0'>PIMS</h1>
      </a>
    </div>

    <div class='layout-topbar-grid-column'>
      <app-menu></app-menu>
      
    </div>

    <div *ngIf='false' class='layout-topbar-grid-column layout-topbar-grid-column-fixed'>
      <span class='layout-topbar-search'>
        <span class='p-input-icon-right'>
          <input pInputText placeholder='Search' type='text' />
          <i class='pi pi-search'></i>
        </span>
      </span>
    </div>

    <div class='layout-topbar-grid-column layout-topbar-grid-column-fixed'>
      <a *hasPermissions='["user:switchDivision"]' (click)='$event.preventDefault(); openSwitchDivisionMenu()' href='#' class='text-white'>{{ userDivision?.code }}</a>
      <span *hasNoPermissions='["user:switchDivision"]' class='text-white'>{{ userDivision?.code }}</span>
    </div>

    <div class='layout-topbar-grid-column layout-topbar-grid-column-fixed'>

      <a (click)='app.onTopbarUserMenuButtonClick($event)' class='profile-menu-button h-9 aspect-square rounded-full' href='#'>
        <p-avatar
          [label]='user.username.substring(0, 2).toUpperCase()'
          class='inline-block aspect-square'
          shape='circle'
          styleClass='aspect-square bg-slate-200 w-9 h-9'
        ></p-avatar>
      </a>
      <ul
        (click)='app.onTopbarUserMenuClick($event)'
        [ngClass]="{ 'layout-profile-menu-active': app.topbarUserMenuActive }"
        class='layout-profile-menu fadeInDown'
      >
        <li class='layout-profile-menu-search'>
          <span class='md-inputfield'>
            <input pInputText type='text' />
            <label>Search</label>
          </span>
        </li>
        <li role='menuitem'>
          <a pRipple routerLink='/users/profile'>
            <i class='pi pi-user'></i>
            <span>{{ user.username }} ({{ userDivision?.code }})</span>
          </a>
        </li>
        <li *ngIf='false' role='menuitem'>
          <a (click)='app.onTopbarSubItemClick($event)' href='#' pRipple>
            <i class='pi pi-envelope'></i>
            <span>Inbox</span>
          </a>
        </li>
        <li *ngIf='false' role='menuitem'>
          <a (click)='app.onTopbarSubItemClick($event)' href='#' pRipple>
            <i class='pi pi-cog'></i>
            <span>Settings</span>
          </a>
        </li>
        <li role='menuitem'>
          <a (click)='logout()' href='#' pRipple>
            <i class='pi pi-times'></i>
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!--<div class='bg-white'>-->
<!--  <nav class='flex flex-row justify-between shadow-md dark:bg-gray-800 h-14'>-->
<!--    <a class='logo ml-4 flex items-center' routerLink='/'>-->
<!--      <img alt='logo' class='h-8' src='/assets/img/logo.png' />-->
<!--    </a>-->

<!--    <ul class='left items-center flex'>-->
<!--      <li class='hidden md:flex md:flex-col'>-->
<!--        <a class='nav-link' routerLink='/notifications'>-->
<!--          <fa-icon [icon]="['far', 'bell']" class='dark:text-blue-400'></fa-icon>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class='hidden md:flex md:flex-col'>-->
<!--        <a class='nav-link' routerLink='/inbox'>-->
<!--          <fa-icon [icon]="['far', 'envelope']" class='dark:text-blue-400'></fa-icon>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class='nav-item'>-->
<!--        <a (click)='$event.preventDefault(); avatarMenu.toggle($event)' class='nav-link' href='#'>-->
<!--          <p-avatar label='P' shape='circle' styleClass='bg-blue-100'></p-avatar>-->
<!--          <p-menu #avatarMenu [model]='avatarMenuItems' [popup]='true'></p-menu>-->
<!--        </a>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </nav>-->

<!--  <nav class='flex bg-gray-100 h-14'>-->
<!--    <div class='burger-menu flex md:hidden'>-->
<!--      <a-->
<!--        (click)='$event.preventDefault(); menuBurger.toggle($event)'-->
<!--        class='flex items-center gap-1 hover:no-underline px-4 py-2 sm:px-8 sm:py-4 dark:text-blue-300'-->
<!--        href='#'-->
<!--      >-->
<!--        <svg class='h-6 w-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>-->
<!--          <path d='M4 6h16M4 12h16M4 18h16' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' />-->
<!--        </svg>-->
<!--      </a>-->
<!--      &lt;!&ndash; <p-menu #menuBurger [model]="burgerMenuItems" [popup]="true"></p-menu> &ndash;&gt;-->
<!--      <p-slideMenu #menuBurger [model]='burgerMenuItems' [popup]='true' [viewportHeight]='300'></p-slideMenu>-->
<!--    </div>-->
<!--    <ul #wfpMenu class='hidden md:flex'>-->
<!--      <li class='h-full border-b-2 border-transparent hover:border-blue-300 relative'>-->
<!--        <a-->
<!--          (click)='$event.preventDefault(); menu.toggle($event)'-->
<!--          class='flex items-center gap-1 hover:no-underline px-4 py-2 sm:px-8 sm:py-4 dark:text-blue-300'-->
<!--          href='#'-->
<!--        >-->
<!--          <span>WFP</span>-->
<!--          <i class='pi pi-angle-down'></i>-->
<!--        </a>-->
<!--        <p-menu #menu [appendTo]='wfpMenu' [model]='items' [popup]='true'></p-menu>-->
<!--      </li>-->
<!--      <li class='h-full border-b-2 border-transparent hover:border-blue-300 relative'>-->
<!--        <a-->
<!--          (click)='$event.preventDefault(); menuJourn.toggle($event)'-->
<!--          class='flex items-center gap-1 hover:no-underline px-4 py-2 sm:px-8 sm:py-4 dark:text-blue-300'-->
<!--          href='#'-->
<!--        >-->
<!--          <span>Journal</span>-->
<!--        </a>-->
<!--        <p-menu #menuJourn [appendTo]='wfpMenu' [model]='journals' [popup]='true'></p-menu>-->
<!--      </li>-->
<!--      <li class='h-full border-b-2 border-transparent hover:border-blue-300 relative'>-->
<!--        <a-->
<!--          (click)='$event.preventDefault(); menuLib.toggle($event)'-->
<!--          class='flex items-center gap-1 hover:no-underline px-4 py-2 sm:px-8 sm:py-4 dark:text-blue-300'-->
<!--          href='#'-->
<!--        >-->
<!--          <span>Library</span>-->
<!--        </a>-->
<!--        <p-menu #menuLib [appendTo]='wfpMenu' [model]='libraries' [popup]='true'></p-menu>-->
<!--      </li>-->
<!--      <li class='h-full border-b-2 border-transparent hover:border-blue-300 relative'>-->
<!--        <a-->
<!--          (click)='$event.preventDefault(); menuReports.toggle($event)'-->
<!--          class='flex items-center gap-1 hover:no-underline px-4 py-2 sm:px-8 sm:py-4 dark:text-blue-300'-->
<!--          href='#'-->
<!--        >-->
<!--          <span>Reports</span>-->
<!--        </a>-->
<!--        <p-menu #menuReports [appendTo]='wfpMenu' [model]='reports' [popup]='true'></p-menu>-->
<!--      </li>-->
<!--      <li-->
<!--        *hasPermissions="['user:createAny', 'user:editAny']"-->
<!--        class='h-full border-b-2 border-transparent hover:border-blue-300 relative'-->
<!--      >-->
<!--        <a-->
<!--          (click)='$event.preventDefault(); usersMenu.toggle($event)'-->
<!--          class='flex items-center gap-1 hover:no-underline px-4 py-2 sm:px-8 sm:py-4 dark:text-blue-300'-->
<!--          href='#'-->
<!--        >-->
<!--          <span>Users</span>-->
<!--          <i class='pi pi-angle-down'></i>-->
<!--        </a>-->
<!--        <p-menu #usersMenu [appendTo]='wfpMenu' [model]='userMenuItems' [popup]='true'></p-menu>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </nav>-->
<!--</div>-->
