import { Component, OnInit } from '@angular/core';
import { Signatories } from '../../../models/reports/query-params';
import { AuthService } from '../../../utils/services';

@Component({
  selector: 'app-signatories',
  templateUrl: './signatories.component.html',
  styleUrls: ['./signatories.component.scss'],
})
export class SignatoriesComponent implements OnInit {
  constructor(private authService: AuthService) {}

  public _signatories: Array<Signatories[]> = [
    [
      {
        action: 'Submitted by',
        designation: 'Division Chief/Unit Head',
        name: '',
      },
      {
        action: 'Noted by',
        designation: 'Cluster Head',
        name: '',
      },
    ],
    [
      {
        action: 'Prepared by',
        name: `${this.authService.getUserObject().firstName} ${this.authService.getUserObject().lastName}`,
        date: new Date(),
      },
    ],
  ];

  get signatories() {
    return this._signatories;
  }

  set signatories(signatories: Array<Signatories[]>) {
    this._signatories = signatories;
  }

  addSignatory(index: number) {
    this._signatories[index].push({});
  }

  canEdit() {
    // return userHasPermission(['reports:createAny']);
    return true;
  }

  ngOnInit(): void {}

  removeSignatory(groupIndex: number, signatoryIndex: number) {
    this._signatories[groupIndex] = this._signatories[groupIndex].filter((signatory, i) => i != signatoryIndex);
    if (this._signatories[groupIndex].length <= 0) {
      this._signatories = this._signatories.filter((groups, i) => i != groupIndex);
    }
  }

  public addGroup() {
    this._signatories.push([{}]);
  }
}
