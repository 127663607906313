import { Company } from './company';
import { Permission } from './permission';

export class User {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  accountType?: string;
  address?: string;
  company?: Company;
  companyId?: string;
  createdById?: string;
  createdDate?: Date;
  deleted?: boolean;
  divisionId?: number;
  email?: string;
  enabled?: boolean;
  firstName?: string;
  id?: number;
  lastName?: string;
  mobile?: string;
  modifiedById?: null;
  modifiedDate?: Date;
  permissions?: string[];
  realm?: string;
  referralName?: string;
  remarks?: string;
  revision?: string;
  roleNames?: string[];
  roles?: Role[];
  username?: string;

  public get fullName(): string {
    return `${this.firstName ?? ''} ${this.lastName ?? ''}`;
  }

  public get userPermissions(): string[] {
    if (!this.permissions || (this.permissions && this.permissions.length <= 0)) {
      let temp = [];
      this.roles.forEach((role) => {
        const rolePermissions = role.permissions.map((permission) => permission.permission);
        temp = temp.concat(rolePermissions);
      });
      this.permissions = temp;
    }

    return this.permissions;
  }

  public get userRolenames(): string[] {
    if (!this.roleNames || (this.roleNames && this.roleNames.length <= 0)) {
      this.roleNames = this.roles.map((role) => role.roleName);
    }

    return this.roleNames;
  }
}

export interface Role {
  active?: boolean;
  createdById?: number;
  createdDate?: Date;
  id?: number;
  modifiedById?: number;
  modifiedDate?: Date;
  permissions?: Permission[];
  roleName?: string;
  title?: string;
}
