<div class="list-countries-wrapper relative">
  <div class="header flex justify-between mb-4">
    <h2>Dashboard</h2>
    <div class="buttons">
      <!--            <app-button (click)="openAddCountryForm()" [block]='true' [disabled]='false' [loading]='false'-->
      <!--                        [text]="'Add Product'"-->
      <!--                        [type]="'button'"-->
      <!--                        class='w-32 bg-green-500 text-white border-green-600 hover:bg-green-400 rounded-md'>-->
      <!--            </app-button>-->
    </div>
  </div>

  <div class="content-wrapper w-full-no-scroll shadow-md">
    <p-table #dt1 [loading]="isLoading" [globalFilterFields]="['code', 'ACC', 'ON_TRACK', 'DELAYED', 'DEFERRED', 'AHEAD_OF_TIME', 'NOT_IMPLEMENTED']"
      [sortOrder]="-1" [value]="dashboardData" class="block w-full-no-scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id"
      responsiveLayout="stack" breakpoint="640px" scrollDirection="horizontal"
      styleClass="p-datatable-gridlines w-screen md:w-full-no-scroll flow-root"  (onFilter)="onFilter($event)">
      <ng-template pTemplate="caption">
        <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
          <p-dropdown [(ngModel)]="currentMonth" (onChange)="onMonthChange($event)" [options]="monthMap"
            inputId="reportingMonth" label="Reporting Month" optionLabel="name" optionValue="id"
            placeholder="Select reporting month" scrollHeight="300px" styleClass="w-full rounded-none"></p-dropdown>
          <p-dropdown [(ngModel)]="currentYear" (onChange)="onYearChange($event)" [options]="yearMap"
            inputId="reportingYear" label="Reporting Year" placeholder="Select reporting year" scrollHeight="300px"
            styleClass="w-full rounded-none"></p-dropdown>
          <p></p>
          <div class="p-d-flex flex justify-between gap-2"></div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="code">
            <div class="p-d-flex p-jc-between p-ai-center">
              Division/Units
              <p-columnFilter display="menu" field="code" type="text"></p-columnFilter>
              <p-sortIcon field="code"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="ACC">
            <div class="p-d-flex p-jc-between p-ai-center">
              Accomplished / Implemented
              <p-columnFilter display="menu" field="ACC" type="text"></p-columnFilter>
              <p-sortIcon field="ACC"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="ON_GOING">
            <div class="p-d-flex p-jc-between p-ai-center">
              Ongoing
              <p-columnFilter display="menu" field="ON_GOING" type="text"></p-columnFilter>
              <p-sortIcon field="ON_GOING"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="ON_TRACK">
            <div class="p-d-flex p-jc-between p-ai-center">
              On-track
              <p-columnFilter display="menu" field="ON_TRACK" type="text"></p-columnFilter>
              <p-sortIcon field="ON_TRACK"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="DELAYED">
            <div class="p-d-flex p-jc-between p-ai-center">
              Delayed
              <p-columnFilter display="menu" field="DELAYED" type="text"></p-columnFilter>
              <p-sortIcon field="DELAYED"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="DEFERRED">
            <div class="p-d-flex p-jc-between p-ai-center">
              To be implemented
              <p-columnFilter display="menu" field="DEFERRED" type="text"></p-columnFilter>
              <p-sortIcon field="DEFERRED"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="AHEAD_OF_TIME">
            <div class="p-d-flex p-jc-between p-ai-center">
              Ahead of Time
              <p-columnFilter display="menu" field="AHEAD_OF_TIME" type="text"></p-columnFilter>
              <p-sortIcon field="AHEAD_OF_TIME"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="NOT_IMPLEMENTED">
            <div class="p-d-flex p-jc-between p-ai-center">
              Not implemented
              <p-columnFilter display="menu" field="NOT_IMPLEMENTED" type="text"></p-columnFilter>
              <p-sortIcon field="NOT_IMPLEMENTED"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="NOT_ATTAINED">
            <div class="p-d-flex p-jc-between p-ai-center">
              Not Attained
              <p-columnFilter display="menu" field="NOT_ATTAINED" type="text"></p-columnFilter>
              <p-sortIcon field="NOT_ATTAINED"></p-sortIcon>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template let-editing="editing" let-expanded="expanded" let-plan pTemplate="body">
        <tr [pEditableRow]="plan" class="dark:bg-gray-700">
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input [(ngModel)]="plan.code" pInputText type="text" />
              </ng-template>
              <ng-template pTemplate="output">
                <div class="button-container flex items-center gap-2">
                  {{ plan.code }}
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.ACC }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.ACC }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.ON_GOING }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.ON_GOING }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.ON_TRACK }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.ON_TRACK }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.DELAYED }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.DELAYED }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.DEFERRED }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.DEFERRED }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.AHEAD_OF_TIME }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.AHEAD_OF_TIME }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.NOT_IMPLEMENTED }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.NOT_IMPLEMENTED }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                {{ plan.NOT_ATTAINED }}
              </ng-template>
              <ng-template pTemplate="output">
                {{ plan.NOT_ATTAINED }}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
      <ng-template let-commission pTemplate="rowexpansion">
        <tr class="expanded-tr-logs">
          <td colspan="3">
            <div class="details"></div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No plans found.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
      
        <tr class="dark:bg-gray-700"  *ngIf="dashboardData?.length">
          <td >
            <div class="p-d-flex p-ai-center p-jc-between">
              Total
          </div>
          </td>
          <td >
            <div class="p-d-flex p-ai-center p-jc-between">
             {{total.ACC}}
          </div>
          </td>
          <td>
            <div class="p-d-flex p-ai-center p-jc-between">
              {{total.ON_GOING}}
          </div>
          </td>
          <td>
            <div class="p-d-flex p-ai-center p-jc-between">
              {{total.ON_TRACK}}
          </div>
          </td>
          <td >
            <div class="p-d-flex p-ai-center p-jc-between">
              {{total.DELAYED}}
          </div>
          </td>
          <td >
            <div class="p-d-flex p-ai-center p-jc-between">
              {{total.DEFERRED}}
          </div>
          </td>
          <td >
            <div class="p-d-flex p-ai-center p-jc-between">
              {{total.AHEAD_OF_TIME}}
          </div>
          </td>
          <td >
            <div class="p-d-flex p-ai-center p-jc-between">
               {{total.NOT_IMPLEMENTED}}
          </div>
          </td>
          <td >
            <div class="p-d-flex p-ai-center p-jc-between">
               {{total.NOT_ATTAINED}}
          </div>
          </td>
        </tr> 
       
    </ng-template>
    </p-table>
  </div>
</div>