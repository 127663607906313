import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../app-config.service';
import { Category, Division, DivisionUnit } from '../../models';
import { FuncObjective, TLMO } from '../../models/plans';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {
  private readonly wfpApi: string;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private appConfigService: AppConfigService
  ) {
    if (this.appConfigService.getConfig() && this.appConfigService.getConfig().wfpApi) {
      this.wfpApi = this.appConfigService.getConfig().wfpApi;
    } else {
      this.wfpApi = environment.wfpApi;
    }
  }

  addCategory(category: Category) {
    const url = `${this.wfpApi}/library/categories`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Category>(url, category, { headers });
  }

  addDivision(division: Division): Observable<Division> {
    const url = `${this.wfpApi}/library/divisions`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Division>(url, division, { headers });
  }

  addDivisionUnit(divisionUnit: DivisionUnit): Observable<DivisionUnit> {
    const url = `${this.wfpApi}/library/division-units`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<DivisionUnit>(url, divisionUnit, { headers });
  }

  addObjective(objective: FuncObjective) {
    const url = `${this.wfpApi}/library/objectives`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<FuncObjective>(url, objective, { headers });
  }

  addTlmo(tlmo: TLMO) {
    const url = `${this.wfpApi}/library/tlmos`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<TLMO>(url, tlmo, { headers });
  }

  deleteCategory(id: number) {
    const url = `${this.wfpApi}/library/categories/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<unknown>(url, { headers });
  }

  deleteDivision(id: number) {
    const url = `${this.wfpApi}/library/divisions/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<unknown>(url, { headers });
  }

  deleteDivisionUnit(id: number) {
    const url = `${this.wfpApi}/library/division-units/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<unknown>(url, { headers });
  }

  deleteObjective(id: number) {
    const url = `${this.wfpApi}/library/objectives/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<unknown>(url, { headers });
  }

  deleteTlmo(id: number) {
    const url = `${this.wfpApi}/library/tlmos/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.delete<unknown>(url, { headers });
  }

  getCategories() {
    const url = `${this.wfpApi}/library/categories`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Category[]>(url, { headers });
  }

  getCategoryById(id: number) {
    const url = `${this.wfpApi}/library/categories/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Category>(url, { headers });
  }

  getDivisionById(id: number) {
    const url = `${this.wfpApi}/library/divisions/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Division>(url, { headers });
  }

  getDivisionUnitById(id: number) {
    const url = `${this.wfpApi}/library/division-units/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<DivisionUnit>(url, { headers });
  }

  getDivisionUnits(): Observable<DivisionUnit[]> {
    const url = `${this.wfpApi}/library/division-units`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<DivisionUnit[]>(url, { headers });
  }

  getDivisions(): Observable<Division[]> {
    const url = `${this.wfpApi}/library/divisions`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Division[]>(url, { headers });
  }

  getFunctionalObjectiveById(id: number) {
    const url = `${this.wfpApi}/library/objectives/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<FuncObjective>(url, { headers });
  }

  getFunctionalObjectives() {
    const url = `${this.wfpApi}/library/objectives?divisionBased=true`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<FuncObjective[]>(url, { headers });
  }

  getFunctionalObjectivesForLibraryForm() {
    const url = `${this.wfpApi}/library/objectives`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<FuncObjective[]>(url, { headers });
  }

  getTlmoById(id: number) {
    const url = `${this.wfpApi}/library/tlmos/${id}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<TLMO>(url, { headers });
  }

  getTlmos(): Observable<TLMO[]> {
    const url = `${this.wfpApi}/library/tlmos`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<TLMO[]>(url, { headers });
  }

  updateCategory(category: Category) {
    const url = `${this.wfpApi}/library/categories`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<Category>(url, category, { headers });
  }

  updateDivision(division: Division) {
    const url = `${this.wfpApi}/library/divisions`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<Division>(url, division, { headers });
  }

  updateDivisionUnit(divisionUnit: DivisionUnit) {
    const url = `${this.wfpApi}/library/division-units`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<DivisionUnit>(url, divisionUnit, { headers });
  }

  updateObjective(objective: FuncObjective) {
    const url = `${this.wfpApi}/library/objectives`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<FuncObjective>(url, objective, { headers });
  }

  updateTlmo(tlmo: TLMO) {
    const url = `${this.wfpApi}/library/tlmos`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.put<TLMO>(url, tlmo, { headers });
  }
}
