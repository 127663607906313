import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Country } from '../../../models';

type CountryData = Country & {
  isLoading?: boolean;
  stateIndex: number;
  cityIndex: number;
};

@Component({
  selector: 'app-city-form-dialog',
  templateUrl: './city-form-dialog.component.html',
  styleUrls: ['./city-form-dialog.component.scss'],
})
export class CityFormDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CityFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public countryData: CountryData
  ) {}

  ngOnInit(): void {}

  onSubmit(event) {
    this.dialogRef.close();
  }
}
