<div class="flex flex-row items-center overflow-hidden">
    <div class="h-screen w-1/2 overflow-hidden hidden md:inline-block object-cover">
        <img class="h-full w-full object-cover shadow-md" src="/assets/img/login-bg.jpg" />
    </div>
    <div
        class="h-screen w-full md:w-1/2 p-4 md:p-10 xl:p-52 flex flex-col justify-center items-center bg-blue-400 bg-gradient-to-bl from-blue-400 to-blue-700">
        <div class="content w-full shadow-md bg-white p-4 md:p-10 rounded-xl">
            <div class="login-logo mb-4 md:mb-8 flex justify-center">
                <a [routerLink]="['/']" class="flex items-center">
                    <h1 class="text-lg xs:text-xl md:text-3xl ml-2">Change Password</h1>
                </a>
            </div>

            <!-- /.login-logo -->
            <div class="card">
                <div class="card-body login-card-body">

                    <form [formGroup]="newPasswordForm" (ngSubmit)="updatePassword()">
                        <div class="input-container mb-5">
                            <label for="newPassword" class="font-bold mb-1 text-gray-700 block">New Password</label>
                            <div class="relative input-icon-container bg-white dark:bg-gray-400 focus:outline-none focus:shadow-outline p-1"
                                [ngClass]="{'error': (newPasswordForm.get('newPassword').touched || newPasswordForm.get('newPassword').dirty) && newPasswordForm.get('newPassword').invalid}">
                                <input [type]="newPasswordForm.isNewPasswordVisible ? 'text' : 'password'"
                                    formControlName="newPassword" id="newPassword"
                                    class="w-full px-4 py-3 focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                    placeholder="Your current password...">

                                <div class="absolute right-0 bottom-0 top-0 px-3 py-3 cursor-pointer">
                                    <a href="#" tabindex="-1"
                                        (click)="$event.preventDefault();newPasswordForm.isNewPasswordVisible = !newPasswordForm.isNewPasswordVisible">
                                        <svg *ngIf="newPasswordForm.isNewPasswordVisible" class="block"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                                            <path
                                                d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757C12.568 16.983 12.291 17 12 17c-5.351 0-7.424-3.846-7.926-5 .204-.47.674-1.381 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379-.069.205-.069.428 0 .633C2.073 12.383 4.367 19 12 19zM12 5c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657.069-.205.069-.428 0-.633C21.927 11.617 19.633 5 12 5zM16.972 15.558l-2.28-2.28C14.882 12.888 15 12.459 15 12c0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501C9.796 7.193 10.814 7 12 7c5.351 0 7.424 3.846 7.926 5C19.624 12.692 18.76 14.342 16.972 15.558z" />
                                        </svg>

                                        <svg *ngIf="!newPasswordForm.isNewPasswordVisible" class="block"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                                            <path
                                                d="M12,9c-1.642,0-3,1.359-3,3c0,1.642,1.358,3,3,3c1.641,0,3-1.358,3-3C15,10.359,13.641,9,12,9z" />
                                            <path
                                                d="M12,5c-7.633,0-9.927,6.617-9.948,6.684L1.946,12l0.105,0.316C2.073,12.383,4.367,19,12,19s9.927-6.617,9.948-6.684 L22.054,12l-0.105-0.316C21.927,11.617,19.633,5,12,5z M12,17c-5.351,0-7.424-3.846-7.926-5C4.578,10.842,6.652,7,12,7 c5.351,0,7.424,3.846,7.926,5C19.422,13.158,17.348,17,12,17z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="input-container mb-5">
                            <label for="password" class="font-bold mb-1 text-gray-700 block">Confirm Password</label>
                            <div *ngIf="(newPasswordForm.touched || newPasswordForm.dirty) && newPasswordForm.invalid" class="alert">
                                <ul>
                                  <ng-container *ngFor="let err of newPasswordForm.errors | keyvalue">
                                    <li *ngIf="err.key == 'passwordsDontMatch'">
                                      Passwords do not match
                                    </li>
                                    <li *ngIf="err.key == 'isMobileExists'">
                                      This mobile is already registered
                                    </li>
                                  </ng-container>
                                </ul>
                              </div>
                            <div class="relative input-icon-container bg-white dark:bg-gray-400 focus:outline-none focus:shadow-outline p-1"
                                [ngClass]="{'error': (newPasswordForm.get('password').touched || newPasswordForm.get('password').dirty) && newPasswordForm.get('password').invalid}">
                                <input [type]="newPasswordForm.isPasswordVisible ? 'text' : 'password'"
                                    formControlName="password" id="password"
                                    class="w-full px-4 py-3 focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                                    placeholder="Your current password...">

                                <div class="absolute right-0 bottom-0 top-0 px-3 py-3 cursor-pointer">
                                    <a href="#" tabindex="-1"
                                        (click)="$event.preventDefault();newPasswordForm.isPasswordVisible = !newPasswordForm.isPasswordVisible">
                                        <svg *ngIf="newPasswordForm.isPasswordVisible" class="block"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                                            <path
                                                d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757C12.568 16.983 12.291 17 12 17c-5.351 0-7.424-3.846-7.926-5 .204-.47.674-1.381 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379-.069.205-.069.428 0 .633C2.073 12.383 4.367 19 12 19zM12 5c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657.069-.205.069-.428 0-.633C21.927 11.617 19.633 5 12 5zM16.972 15.558l-2.28-2.28C14.882 12.888 15 12.459 15 12c0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501C9.796 7.193 10.814 7 12 7c5.351 0 7.424 3.846 7.926 5C19.624 12.692 18.76 14.342 16.972 15.558z" />
                                        </svg>

                                        <svg *ngIf="!newPasswordForm.isPasswordVisible" class="block"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-6 h-6 fill-current text-gray-500" viewBox="0 0 24 24">
                                            <path
                                                d="M12,9c-1.642,0-3,1.359-3,3c0,1.642,1.358,3,3,3c1.641,0,3-1.358,3-3C15,10.359,13.641,9,12,9z" />
                                            <path
                                                d="M12,5c-7.633,0-9.927,6.617-9.948,6.684L1.946,12l0.105,0.316C2.073,12.383,4.367,19,12,19s9.927-6.617,9.948-6.684 L22.054,12l-0.105-0.316C21.927,11.617,19.633,5,12,5z M12,17c-5.351,0-7.424-3.846-7.926-5C4.578,10.842,6.652,7,12,7 c5.351,0,7.424,3.846,7.926,5C19.422,13.158,17.348,17,12,17z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="my-3 flex flex-row items-center">
                            <app-button [type]="'submit'" [disabled]="isAuthLoading" [block]="true"
                                [text]="'Update Password'"
                                class="bg-blue-700 text-white border-blue-600 hover:bg-blue-600 rounded-md mr-4"
                                [loading]="isAuthLoading">
                            </app-button> <span class="mr-4">or</span>
                            <a routerLink="/auth/login" class="hover:underline">Log in</a>
                        </div>
                    </form>

                </div>
                <!-- /.login-card-body -->
            </div>
        </div>

    </div>
</div>