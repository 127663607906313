import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
  deps: [AuthService],
})
export class RouterService {
  constructor(private router: Router, private authService: AuthService) {
    console.log('Initiate router service');
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.refreshToken();
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  private refreshToken() {
    if (this.authService.isLoggedIn()) {
      const isAuthTokenExpired = this.authService.getTokenExpiryDate().getTime() - Date.now() <= 0;
      const isRefreshTokenExpired = this.authService.getRefreshTokenExpiryDate().getTime() - Date.now() <= 0;
      if (isAuthTokenExpired && !isRefreshTokenExpired) {
        if (this.authService.getRememberMe()) {
          this.authService.refreshToken().subscribe();
        } else {
          this.authService.logout();
          this.router.navigate(['/auth/login'], {
            queryParams: {
              message: 'Log in token has expired. You need to log in again.',
            },
          });
        }
      } else if (isRefreshTokenExpired) {
        this.authService.logout();
        this.router.navigate(['/auth/login'], {
          queryParams: {
            message: 'Log in token has expired. You need to log in again.',
          },
        });
      }
    }
  }
}
