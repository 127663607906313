import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { addedUser, addUser, generateTempPassword, loadedUsers, loadUsers, updatedUser, updateUser } from '.';
import { AuthService, UserService } from '../../utils/services';
import { noOp } from '../app';

@Injectable()
export class UserEffects {
  // getUser$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(loggedinUser),
  //     exhaustMap((action) => {
  //       return this.userService.getUser().pipe(
  //         map((user) => {
  //           localStorage.setItem(USER_KEY, JSON.stringify(user));
  //           return loadedUser({ user: user });
  //         }),
  //         catchError((err) => {
  //           console.error('getUser: ', err);
  //           return EMPTY;
  //         })
  //       );
  //     })
  //   );
  // });

  addUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addUser),
      exhaustMap((action) => {
        return this.userService.registerUser(action.user).pipe(
          map((users) => {
            return addedUser();
          }),
          catchError((err) => {
            console.error('addUser: ', err);
            return EMPTY;
          })
        );
      })
    );
  });

  addedUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(addedUser),
        exhaustMap((action) => {
          this.router.navigate(['users', 'list']);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
  generateTempPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generateTempPassword),
      exhaustMap((action) => {
        return this.userService.generateTempPasswordUser(action.user).pipe(
          map((users) => {
            this.toasterService.success('A new password was generated and sent to the user.', 'Success');
            return noOp();
          }),
          catchError((err) => {
            this.toasterService.error(err.message, 'Generate password for user Error');
            console.error('generateTempPassword: ', err);
            return EMPTY;
          })
        );
      })
    );
  });
  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUsers),
      exhaustMap((action) => {
        return this.userService.getUsers(action.realm ?? 'EXTERNAL').pipe(
          map((users) => {
            return loadedUsers({ users: users });
          }),
          catchError((err) => {
            console.error('loadUsers: ', err);
            return EMPTY;
          })
        );
      })
    );
  });
  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUser),
      exhaustMap((action) => {
        return this.userService.updateUser(action.user).pipe(
          map((users) => {
            return updatedUser();
          }),
          catchError((err) => {
            this.toasterService.error(err.message, 'Update User Error');
            console.error('updateUser: ', err);
            return EMPTY;
          })
        );
      })
    );
  });
  updatedUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updatedUser),
        exhaustMap((action) => {
          this.router.navigate(['users', 'list']);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private toasterService: ToastrService,
    private router: Router
  ) {}
}
