import { createAction, props } from '@ngrx/store';
import { RegionalCompany } from '../../models';

export const loadCompanies = createAction('[Company] Load companies');
export const clearCompanies = createAction('[Company] Clear company list');
export const loadedCompanies = createAction(
  '[Company] Loaded companies',
  props<{ payload: RegionalCompany[] }>()
);
export const addCompany = createAction(
  '[Company] Add company',
  props<{ payload: RegionalCompany }>()
);
export const deleteCountry = createAction(
  '[Company] Delete Company',
  props<{ payload: RegionalCompany }>()
);
export const deleteSuccessCompany = createAction(
  '[Company] Successfully Deleted Company'
);
export const saveCompany = createAction(
  '[Company] Save Company',
  props<{ payload: RegionalCompany & { currentUrl?: string } }>()
);
