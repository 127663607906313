import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LibraryService, UserService } from '../../../utils/services';
import { DivisionUnit, User } from '../../../models';
import { exhaustMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { MainState, updateBreadCrumbs } from '../../../redux';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-edit-user-page',
  templateUrl: './edit-user-page.component.html',
  styleUrls: ['./edit-user-page.component.scss'],
})
export class EditUserPageComponent implements OnInit {
  public division: DivisionUnit;
  public user: User;
  private breadcrumbLinks: MenuItem[] = [
    {
      routerLink: '/users/list',
      label: 'Users',
    },
    {
      label: 'Edit User',
    },
  ];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private toaster: ToastrService,
    private libraryService: LibraryService,
    private store: Store<MainState>
  ) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadcrumbLinks }));
    this.activeRoute.params
      .pipe(
        exhaustMap((params) => {
          return this.userService.getUserById(params.id, 'EXTERNAL').pipe(
            exhaustMap((user) => {
              this.user = Object.assign(new User(), user);
              return this.libraryService.getDivisionUnitById(user.divisionId);
            })
          );
        })
      )
      .subscribe({
        next: (division) => {
          this.division = division;
        },
        error: (err) => {
          console.error(err);
          this.toaster.error('Error retrieving user', 'Error');
          this.router.navigate(['/users', 'list']);
        },
      });
  }

  ngOnInit(): void {}

  onSubmit(event) {
    this.router.navigate(['users', 'list']);
  }
}
