import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppConfigKeyValue } from '../../../models/app-settings';
import { refreshPage } from '../../../redux';
import { numberToOrdinal } from '../../../utils/functions/numberToOrdinal';
import { AppSettingsService } from '../../../utils/services/app-settings.service';

const MONTHLY_UPDATES_LIMITS_KEY = 'MONTHLY_UPDATES_LIMITS';

@Component({
  selector: 'app-app-settings-page',
  templateUrl: './app-settings-page.component.html',
  styleUrls: ['./app-settings-page.component.scss'],
})
export class AppSettingsPageComponent implements OnInit {
  public appConfigKeyValue: AppConfigKeyValue;
  public form: FormGroup;
  public extensionOptions = ['0', '5', '10', '15', '20'];
  public isLoading = false;

  constructor(
    private appSettingsService: AppSettingsService,
    private fb: FormBuilder,
    private store: Store,
    private toaster: ToastrService
  ) {
    this.form = this.fb.group({
      extensionDays: ['0', [Validators.required]],
    });
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }

  getData() {
    this.appSettingsService.getConfigByKey(MONTHLY_UPDATES_LIMITS_KEY).subscribe({
      next: (appConfig) => {
        this.appConfigKeyValue = appConfig;
        this.getFormControl('extensionDays').setValue(this.appConfigKeyValue?.jsonValue?.extensionDays.toString());
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  getMonthlyUpdateDateLimit() {
    const monthClose = parseInt(this.appConfigKeyValue?.jsonValue?.dayOfMonthClose || 0, 10);
    return numberToOrdinal(monthClose);
  }

  getNewDeadline() {
    const monthClose = parseInt(this.appConfigKeyValue?.jsonValue?.dayOfMonthClose || 0, 10);
    const extension = parseInt(this.appConfigKeyValue?.jsonValue?.extensionDays || 0, 10);
    return numberToOrdinal(monthClose + extension);
  }

  ngOnInit(): void {
    this.getData();
  }

  submit() {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.toaster.error('Invalid form', 'Error');
      return false;
    }
    this.appSettingsService
      .updateConfig({
        ...this.appConfigKeyValue,
        jsonValue: {
          dayOfMonthClose: 10,
          extensionDays: parseInt(this.form.value.extensionDays),
        },
      })
      .subscribe({
        next: (value) => {
          this.store.dispatch(refreshPage());
        },
        error: (err) => {
          console.error(err);
        },
      });
    return true;
  }
}
