<div class='mb-4'>
  <h5>Users</h5>
  <div class='grid grid-cols-1 md:grid-cols-3 gap-2'>
    <ng-container *ngFor='let permission of permissions'>
      <div *ngIf='permission.permission.startsWith("user:")' class='flex gap-2'>
        <p-checkbox (onChange)='setPermissions()' [(ngModel)]='selectedPermissions' [falseValue]='null'
                    [inputId]='permission.id.toString()'
                    [trueValue]='permission' [value]='permission' name='permission'></p-checkbox>
        <label [for]='permission.id'>{{permission.title}}</label>
      </div>
    </ng-container>
  </div>
</div>
<hr />
<div class='mb-4'>
  <h5>Plans</h5>
  <div class='grid grid-cols-1 md:grid-cols-3 gap-2'>
    <ng-container *ngFor='let permission of permissions'>
      <div *ngIf='permission.permission.startsWith("plan:")' class='flex gap-2'>
        <p-checkbox (onChange)='setPermissions()' [(ngModel)]='selectedPermissions' [falseValue]='null'
                    [inputId]='permission.id.toString()'
                    [trueValue]='permission' [value]='permission' name='permission'></p-checkbox>
        <label [for]='permission.id'>{{permission.title}}</label>
      </div>
    </ng-container>
  </div>
</div>
<hr />
<div class='mb-4'>
  <h5>Projects</h5>
  <div class='grid grid-cols-1 md:grid-cols-3 gap-2'>
    <ng-container *ngFor='let permission of permissions'>
      <div *ngIf='permission.permission.startsWith("project:")' class='flex gap-2'>
        <p-checkbox (onChange)='setPermissions()' [(ngModel)]='selectedPermissions' [falseValue]='null'
                    [inputId]='permission.id.toString()'
                    [trueValue]='permission' [value]='permission' name='permission'></p-checkbox>
        <label [for]='permission.id'>{{permission.title}}</label>
      </div>
    </ng-container>
  </div>
</div>
<hr />
<div class='mb-4'>
  <h5>Library</h5>
  <div class='grid grid-cols-1 md:grid-cols-3 gap-2'>
    <ng-container *ngFor='let permission of permissions'>
      <div *ngIf='permission.permission.startsWith("library:")' class='flex gap-2'>
        <p-checkbox (onChange)='setPermissions()' [(ngModel)]='selectedPermissions' [falseValue]='null'
                    [inputId]='permission.id.toString()'
                    [trueValue]='permission' [value]='permission' name='permission'></p-checkbox>
        <label [for]='permission.id'>{{permission.title}}</label>
      </div>
    </ng-container>
  </div>
</div>
<hr />
<div class='mb-4'>
  <h5>Reports</h5>
  <div class='grid grid-cols-1 md:grid-cols-3 gap-2'>
    <ng-container *ngFor='let permission of permissions'>
      <div *ngIf='permission.permission.startsWith("report:")' class='flex gap-2'>
        <p-checkbox (onChange)='setPermissions()' [(ngModel)]='selectedPermissions' [falseValue]='null'
                    [inputId]='permission.id.toString()'
                    [trueValue]='permission' [value]='permission' name='permission'></p-checkbox>
        <label [for]='permission.id'>{{permission.title}}</label>
      </div>
    </ng-container>
  </div>
</div>
<hr />
<div class='mb-4'>
  <h5>Remarks</h5>
  <div class='grid grid-cols-1 md:grid-cols-3 gap-2'>
    <ng-container *ngFor='let permission of permissions'>
      <div *ngIf='permission.permission.startsWith("remark:")' class='flex gap-2'>
        <p-checkbox (onChange)='setPermissions()' [(ngModel)]='selectedPermissions' [falseValue]='null'
                    [inputId]='permission.id.toString()'
                    [trueValue]='permission' [value]='permission' name='permission'></p-checkbox>
        <label [for]='permission.id'>{{permission.title}}</label>
      </div>
    </ng-container>
  </div>
</div>