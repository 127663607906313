import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { ProfileService, UserService } from 'src/app/utils/services';
import {
  loadedUserAction,
  loginSuccessAction,
  logoutAction,
  logoutSuccessAction,
} from '.';
import { ORIGIN_KEY, USER_KEY } from '../../utils/constants';

@Injectable()
export class AuthEffects {
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      map(() => {
        console.log('logoutAction triggered ');
        localStorage.clear();
        return {
          type: '[Auth] logout success',
        };
      })
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutSuccessAction),
      map(() => {
        this.router.navigate(['auth', 'login']);
        return {
          type: '[Auth] log in page',
        };
      })
    )
  );

  // loginSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(loginSuccessAction),
  //     mergeMap(() => {
  //       return this.userService.getUser().pipe(
  //         switchMap((user) => {
  //           localStorage.setItem(USER_KEY, JSON.stringify(user));
  //           return this.profileService.getOriginByCode(user.company.code).pipe(
  //             map((origin) => {
  //               localStorage.setItem(ORIGIN_KEY, JSON.stringify(origin));
  //               return {
  //                 type: loadedUserAction.type,
  //                 payload: user,
  //               };
  //             })
  //           );
  //         })
  //       );
  //     })
  //   )
  // );

  constructor(
    private actions$: Actions,
    private router: Router,
    private userService: UserService,
    private profileService: ProfileService
  ) {}
}
