import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Division, Plan } from '../../../models/plans';
import { deleteDivision, MainState, updateBreadCrumbs } from '../../../redux';
import { LibraryService } from '../../../utils/services';
import { JournalFormDialogComponent } from '../journal-form-dialog/journal-form-dialog.component';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Component({
  selector: 'app-journal-unit-list',
  templateUrl: './journal-unit-list.component.html',
  styleUrls: ['./journal-unit-list.component.scss'],
})
export class JournalDivisionUnitListComponent implements OnInit {
  public breadCrumbLinks: MenuItem[] = [
    {
      routerLink: '/libraries/journalDivList',
      label: 'Divisions',
    },
  ];
  public divisionList: Division[];
  public isLoading = false;
  public plan: Plan;
  public showActivityDialog = false;
  public statuses: string[] = ['Draft', 'Approved', 'Rejected'];
  @ViewChild(Table)
  private table: Table;

  constructor(
    private libraryService: LibraryService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private store: Store<MainState>,
    private confirmationService: ConfirmationService
  ) {
    this.store.dispatch(updateBreadCrumbs({ items: this.breadCrumbLinks }));
  }

  private _project: Division;

  public get project() {
    return this._project;
  }

  @Input()
  public set project(project: Division) {
    if (!project || !project.id) {
      return;
    }
    this._project = project;
    this.plan = project;
    this.loadData();
  }

  deleteDivision($event: Event, activity: Division) {
    this.confirmationService.confirm({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this activity?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(deleteDivision({ payload: activity }));
      },
      reject: () => {},
    });
  }

  filter(event: Event) {
    if (event instanceof InputEvent) {
      const el = event.currentTarget as HTMLInputElement;
      this.table.filterGlobal(el.value, 'contains');
      //dt1.filterGlobal($event.target, 'contains')
    }
  }

  getMonthChoices() {
    return monthNames;
  }

  getMonthString(monthNumber: number) {
    return monthNames[monthNumber];
  }

  loadData() {
    this.isLoading = true;
    this.libraryService.getDivisions().subscribe({
      next: (divisions) => {
        this.divisionList = divisions;
        this.isLoading = false;
      },
      error: (err) => {
        if (err.error.statusCode && err.error.statusCode != 404) {
          this.toaster.error(err.message, 'Error');
        }
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  openAddPlanForm() {
    this.showActivityDialog = true;
  }

  openEditDialog(division: Division) {
    const ref = this.dialog.open(JournalFormDialogComponent, {
      width: '100vw',
      height: 'clamp(400px, 50vh, 60vh)',
      data: {
        project: this.project,
        division: division,
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
    });
  }
}
