import { createReducer, on } from '@ngrx/store';
import { refreshPage, updateBreadCrumbs } from './app-action';
import { AppState } from './app-state';

export const initialAppState: AppState = {
  appTitle: 'Admin',
  breadCrumbs: [],
};

const _appReducer = createReducer(
  initialAppState,
  on(refreshPage, (state, action) => {
    window.location.reload();
    return {
      ...state,
    };
  }),
  on(updateBreadCrumbs, (state, action) => {
    return {
      ...state,
      breadCrumbs: action.items,
    };
  }),
);

export function appReducer(state: AppState | undefined, action: any) {
  return _appReducer(state, action);
}
